import { configureStore } from '@reduxjs/toolkit'
import { localStorageMiddleware } from './middleware'
import commonReducer from '../reducers/common'
import dashboardReducer from '../reducers/dashboard'
import userReducer from '../reducers/user/user'
import workspaceReducer from '../reducers/workspace/workspace'
import signInFormReducer from '../reducers/user/sign-in-form'
import signUpFormReducer from '../reducers/user/sign-up-form'
import forgotPasswordFormReducer from '../reducers/user/forgot-password-form'
import userGeneralSettingsFormReducer from '../reducers/user/user-general-settings-form'
import createMeetingReducer from '../reducers/meetings/create-meeting-form'
import meetingListReducer from '../reducers/meetings/meeting-list'
import typeListReducer from '../reducers/meeting-type/type-list'
import meetingDetailsReducer from '../reducers/meetings/meeting-details'
import workspaceListReducer from '../reducers/workspace/workspace-list'
import createWorkspaceReducer from '../reducers/workspace/create-form'
import workspaceUserListReducer from '../reducers/workspace/user/user-list'
import acceptWorkspaceInvitationReducer from '../reducers/workspace/user/accept-invitation'
import inviteWorkspaceUserReducer from '../reducers/workspace/user/invite'
import removeWorkspaceUserReducer from '../reducers/workspace/user/remove'
import workspaceGroupListReducer from '../reducers/workspace/group/group-list'
import createWorkspaceGroupReducer from '../reducers/workspace/group/create'
import workspaceGeneralSettingsFormReducer from '../reducers/workspace/settings/general-settings-form'
import workspaceSecuritySettingsFormReducer from '../reducers/workspace/settings/security-settings-form'
import groupDetailsReducer from '../reducers/workspace/group/details'
import updateWorkspaceGroupReducer from '../reducers/workspace/group/update'
import workspaceUsageReducer from '../reducers/workspace/usage'
import groupUsageReducer from '../reducers/workspace/group/usage'
import depositFormReducer from '../reducers/workspace/settings/billing/deposit-form'
import addPaymentMethodFormReducer from '../reducers/workspace/settings/billing/add-payment-method-form'
import renameMeetingFormReducer from '../reducers/meetings/rename-meeting-form'
import paymentMethodsReducer from '../reducers/workspace/settings/billing/payment-methods'
import deletePaymentMethodConfirmationReducer from '../reducers/workspace/settings/billing/delete-payment-method-confirmation'
import upgradeFormReducer from '../reducers/workspace/upgrade-form'
import subscriptionReducer from '../reducers/workspace/subscription'
import payForSubscriptionFormReducer from '../reducers/workspace/settings/billing/pay-for-subscription-form'
import autoRechargeFormReducer from '../reducers/workspace/settings/billing/auto-recharge-form'
import cancelSubscriptionFormReducer from '../reducers/workspace/settings/cancel-subscription-form'
import revokeSubscriptionCancellationFormReducer from '../reducers/workspace/settings/revoke-subscription-cancellation-form'
import invoicesReducer from '../reducers/workspace/settings/billing/invoices'
import recordDetailsReducer from '../reducers/records/record-details'
import recordListReducer from '../reducers/records/record-list'
import uploadRecordReducer from '../reducers/records/upload-record-form'
import renameRecordFormReducer from '../reducers/records/rename-record-form'
import useForMeetingFormReducer from '../reducers/records/user-for-meeting-form'
import createMeetingTypeReducer from '../reducers/meeting-type/create-type'
import deleteMeetingTypeReducer from '../reducers/meeting-type/delete-type'
import duplicateMeetingTypeReducer from '../reducers/meeting-type/duplicate-type'
import updateMeetingTypeReducer from '../reducers/meeting-type/update-type'
import meetingTypeDetailsReducer from '../reducers/meeting-type/type-details'
import templateListReducer from '../reducers/meeting-template/template-list'
import createMeetingTypeTaskReducer from '../reducers/meeting-type-task/create-task'
import meetingTypeTaskListReducer from '../reducers/meeting-type-task/task-list'
import meetingTypeTaskDetailsReducer from '../reducers/meeting-type-task/task-details'
import addColumnReducer from '../reducers/meeting-type-task/add-column'
import renameMeetingTypeTaskReducer from '../reducers/meeting-type-task/rename-task'
import updateMeetingTypeTaskDescriptionReducer from '../reducers/meeting-type-task/update-task-description'
import updateColumnReducer from '../reducers/meeting-type-task/update-column'
import taskConstructorReducer from '../reducers/meeting-type-task/constructor'
import updateCellContentReducer from '../reducers/meeting-type-task/update-cell-content'
import sharePopupReducer from '../reducers/share-popup'
import workspaceApiKeyListReducer from '../reducers/workspace/api-keys/list'
import createApiKeyReducer from '../reducers/workspace/api-keys/create'
import deleteApiKeyReducer from '../reducers/workspace/api-keys/delete'
import duplicateMeetingTypeTaskReducer from '../reducers/meeting-type-task/duplicate-task'
import { asyncDispatchMiddleware } from './async-dispatch-midleware'

export function makeStore(preloadedState) {
  return configureStore({
    reducer: {
      common: commonReducer,
      sharePopup: sharePopupReducer,
      dashboard: dashboardReducer,
      // User
      user: userReducer,
      signInForm: signInFormReducer,
      signUpForm: signUpFormReducer,
      forgotPasswordForm: forgotPasswordFormReducer,
      userGeneralSettingsForm: userGeneralSettingsFormReducer,

      // Meeting
      meetingDetails: meetingDetailsReducer,
      meetingList: meetingListReducer,
      createMeeting: createMeetingReducer,
      renameMeetingForm: renameMeetingFormReducer,
      typeList: typeListReducer,
      createMeetingType: createMeetingTypeReducer,
      updateMeetingType: updateMeetingTypeReducer,
      deleteMeetingType: deleteMeetingTypeReducer,
      duplicateMeetingType: duplicateMeetingTypeReducer,
      duplicateMeetingTypeTask: duplicateMeetingTypeTaskReducer,
      meetingTypeDetails: meetingTypeDetailsReducer,
      templateList: templateListReducer,
      createMeetingTypeTask: createMeetingTypeTaskReducer,
      meetingTypeTaskList: meetingTypeTaskListReducer,
      meetingTypeTaskDetails: meetingTypeTaskDetailsReducer,
      addColumn: addColumnReducer,
      renameMeetingTypeTask: renameMeetingTypeTaskReducer,
      updateMeetingTypeTaskDescription: updateMeetingTypeTaskDescriptionReducer,
      updateColumn: updateColumnReducer,
      taskConstructor: taskConstructorReducer,
      updateCellContent: updateCellContentReducer,
      workspaceApiKeyList: workspaceApiKeyListReducer,
      createApiKey: createApiKeyReducer,
      deleteApiKey: deleteApiKeyReducer,

      // Record
      recordDetails: recordDetailsReducer,
      recordList: recordListReducer,
      uploadRecord: uploadRecordReducer,
      renameRecordForm: renameRecordFormReducer,
      useForMeetingForm: useForMeetingFormReducer,

      // Workspace
      workspace: workspaceReducer,
      subscription: subscriptionReducer,
      createWorkspace: createWorkspaceReducer,
      workspaceGeneralSettingsForm: workspaceGeneralSettingsFormReducer,
      workspaceSecuritySettingsForm: workspaceSecuritySettingsFormReducer,
      workspaceList: workspaceListReducer,
      workspaceUserList: workspaceUserListReducer,
      inviteWorkspaceUser: inviteWorkspaceUserReducer,
      acceptWorkspaceInvitation: acceptWorkspaceInvitationReducer,
      removeWorkspaceUser: removeWorkspaceUserReducer,
      workspaceGroupList: workspaceGroupListReducer,
      createWorkspaceGroup: createWorkspaceGroupReducer,
      groupDetails: groupDetailsReducer,
      updateWorkspaceGroup: updateWorkspaceGroupReducer,
      workspaceUsage: workspaceUsageReducer,
      groupUsage: groupUsageReducer,
      depositForm: depositFormReducer,
      addPaymentMethodForm: addPaymentMethodFormReducer,
      paymentMethods: paymentMethodsReducer,
      deletePaymentMethodConfirmation: deletePaymentMethodConfirmationReducer,
      upgradeForm: upgradeFormReducer,
      payForSubscriptionForm: payForSubscriptionFormReducer,
      cancelSubscriptionForm: cancelSubscriptionFormReducer,
      revokeSubscriptionCancellationForm: revokeSubscriptionCancellationFormReducer,
      autoRechargeForm: autoRechargeFormReducer,
      invoices: invoicesReducer,
    },
    devTools: true,
    preloadedState,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), asyncDispatchMiddleware, localStorageMiddleware],
  })
}

const store = makeStore()

export default store
