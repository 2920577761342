import { createAsyncThunk } from '@reduxjs/toolkit'
import { BillingAgent } from '../../../../../api/billingAgent'

export const getInvoices = createAsyncThunk('workspaces/invoices/list', async ({}, thunkApi) => {
  try {
    const response = await BillingAgent.getInvoices()

    return { invoices: response.invoices }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
