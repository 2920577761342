import { createSlice } from '@reduxjs/toolkit'
import { failed } from '../../../reducer-helper'
import { rechargeUpdate } from '../../../../components/workspace/settings/billing/auto-recharge/slice'

const initialState = {
  data: {
    enabled: false,
    rechargeAt: 10,
    rechargeTo: 100,
  },
  showPopup: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'autoRechargeForm',
  initialState,
  reducers: {
    setShowPopupState(state, action) {
      state.data.enabled = action.payload.enabled
      state.data.rechargeAt = action.payload.rechargeAt
      state.data.rechargeTo = action.payload.rechargeTo
      state.showPopup = action.payload.showPopup
    },
    toggleRecharge: (state) => {
      state.data.enabled = !state.data.enabled
    },
    changeAtAmount: (state, action) => {
      state.data.rechargeAt = action.payload.rechargeAt
    },
    changeToAmount: (state, action) => {
      state.data.rechargeTo = action.payload.rechargeTo
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetForm: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(rechargeUpdate.fulfilled, () => initialState)
    builder.addCase(rechargeUpdate.rejected, failed)
  },
})

const selectSlice = (state) => state.autoRechargeForm
export const selectData = (state) => selectSlice(state).data
export const selectShowPopup = (state) => selectSlice(state).showPopup
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { toggleRecharge, changeAtAmount, changeToAmount, resetForm, changeRequestStatus, setShowPopupState } =
  actions

export default reducer
