import React, { memo, useEffect, useState } from 'react'
import Invite from './invite/Invite'
import MainLayout from '../common/protected/main-layout/MainLayout'
import { Bars3Icon, EllipsisVerticalIcon, UserCircleIcon, UserIcon } from '@heroicons/react/24/outline'
import { CheckIcon, PlusIcon } from '@heroicons/react/20/solid'
import { changeShowNavigationSidebar } from '../../reducers/common'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUSES } from '../../reducers/reducer-helper'
import {
  STRIPE_SUBSCRIPTION_STATUSES,
  WORKSPACE_USER_ROLES,
  WORKSPACE_USER_ROLES_NAMES,
  WORKSPACE_USER_STATUSES,
  WORKSPACE_USER_STATUSES_NAMES,
} from '../../constants'
import { updateRole, getAllUsers } from './slice'
import Loader from '../common/loader/Loader'
import {
  selectAllUsers,
  usersUnloaded,
  changeRequestStatus,
  selectRequestStatus,
} from '../../reducers/workspace/user/user-list'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { setSidebarState as setInviteUserSidebarState } from '../../reducers/workspace/user/invite'
import { setUserUuidForRemove } from '../../reducers/workspace/user/remove'
import Remove from './remove/Remove'
import { selectWorkspace } from '../../reducers/workspace/workspace'
import { selectSubscription } from '../../reducers/workspace/subscription'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { classNames } from '../../utils/className'
import { setShowPopupState as setShowPayForSubscriptionPopupState } from '../../reducers/workspace/settings/billing/pay-for-subscription-form'
import { CND_URL } from '../../configs'

const tabsKeys = {
  ALL: 'all',
  ACTIVE: 'active',
  INVITED: 'invited',
}

const tabs = [
  { key: tabsKeys.ALL, name: 'All' },
  { key: tabsKeys.ACTIVE, name: 'Active' },
  { key: tabsKeys.INVITED, name: 'Invited' },
]

const statuses = {
  [WORKSPACE_USER_STATUSES.ACTIVE]:
    'text-green-800 bg-green-50 ring-green-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
  [WORKSPACE_USER_STATUSES.INVITED]:
    'text-yellow-800 bg-yellow-50 ring-yellow-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
}

function Users() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const users = useSelector(selectAllUsers)
  const workspace = useSelector(selectWorkspace)
  const subscription = useSelector(selectSubscription)

  const [filteredUsers, setFilteredUsers] = useState(null)
  const [currentTab, setCurrentTab] = useState(tabs[0].key)

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchUsers = dispatch(getAllUsers())

    return () => {
      dispatch(usersUnloaded())
      fetchUsers.abort()
    }
  }, [])

  useEffect(() => {
    if (users) {
      let filtered

      switch (currentTab) {
        case tabsKeys.ALL:
          filtered = [...users]
          break
        case tabsKeys.ACTIVE:
          filtered = [...users.filter((user) => user.status === WORKSPACE_USER_STATUSES.ACTIVE)]
          break
        case tabsKeys.INVITED:
          filtered = [...users.filter((user) => user.status === WORKSPACE_USER_STATUSES.INVITED)]
          break
      }

      filtered.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))
      setFilteredUsers(filtered)
    }
  }, [currentTab, users])

  const showInviteUserSidebarProcess = () => {
    dispatch(setInviteUserSidebarState({ showSidebar: true }))
  }

  const changeShowNavigationSidebarProcess = (status) => {
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: status }))
  }

  const showPayForSubscriptionFormHandler = () => {
    dispatch(setShowPayForSubscriptionPopupState({ showPopup: true }))
  }

  const changeRoleProcess = (uuid, role) => {
    dispatch(updateRole({ uuid, role }))
  }

  const showRemoveDialog = (uuid) => {
    dispatch(setUserUuidForRemove({ userUuid: uuid }))
  }

  return (
    <MainLayout>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
      {requestStatus === REQUEST_STATUSES.DONE && filteredUsers !== null && (
        <div className="bg-whiteX mx-auto max-w-full">
          <header>
            <div className="mx-auto flex h-16 flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
              <button
                type="button"
                className="mr-4 p-2.5 text-black lg:hidden"
                onClick={() => changeShowNavigationSidebarProcess(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-5 w-5" aria-hidden="true" />
              </button>
              <div>
                <h1 className="text-base font-semibold leading-7 text-gray-900">Users</h1>
                <p className="text-sm text-gray-700">A list of all the users in your workspace</p>
              </div>
              <div className={'ml-auto'}>
                {subscription.status === STRIPE_SUBSCRIPTION_STATUSES.ACTIVE &&
                  (workspace.user.role === WORKSPACE_USER_ROLES.ADMIN ||
                    workspace.user.role === WORKSPACE_USER_ROLES.OWNER) && (
                    <button
                      type="button"
                      onClick={() => showInviteUserSidebarProcess()}
                      className=" flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                      Invite user
                    </button>
                  )}
              </div>
            </div>
            {(subscription.status === STRIPE_SUBSCRIPTION_STATUSES.INCOMPLETE ||
              subscription.status === STRIPE_SUBSCRIPTION_STATUSES.PAST_DUE) &&
              (workspace.user.role === WORKSPACE_USER_ROLES.ADMIN ||
                workspace.user.role === WORKSPACE_USER_ROLES.OWNER) && (
                <div className={'p-6'}>
                  <div
                    className={
                      'text-sm flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4 justify-between rounded-md bg-red-100 px-4 py-4'
                    }
                  >
                    <div className={'flex flex-row items-center space-x-2'}>
                      <div>
                        <ExclamationTriangleIcon className={'w-6 h-6 text-red-600'} />
                      </div>
                      <div className={'text-gray-700'}>
                        Your workspace has not completed payment. Please fix it to be able to invite new user.
                      </div>
                    </div>
                    <div className={'flex flex-row items-center space-x-2'}>
                      <button
                        type={'button'}
                        onClick={showPayForSubscriptionFormHandler}
                        className={`flex text-sm text-nowrap items-center justify-center rounded-md px-3 py-1 text-white bg-indigo-600 hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500`}
                      >
                        Pay now
                      </button>
                    </div>
                  </div>
                </div>
              )}
          </header>
          <div className={'flex px-4 sm:px-6 lg:px-8 justify-end'}>
            <div>
              <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                  id="tabs"
                  name="tabs"
                  className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                  defaultValue={tabs.find((tab) => tab.key === currentTab).name}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block pt-4">
                <nav className="flex space-x-4" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      onClick={() => setCurrentTab(tab.key)}
                      className={classNames(
                        tab.key === currentTab ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                        'rounded-md px-3 py-1.5 text-sm font-medium',
                      )}
                      aria-current={tab.key === currentTab ? 'page' : undefined}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          <div className="mt-4 px-4 sm:px-6 lg:px-8 flow-root">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {filteredUsers.length === 0 && (
                  <div className={'w-full text-center text-sm pt-12'}>
                    {currentTab === tabsKeys.ALL && <>There are no users in the workspace..</>}
                    {currentTab !== tabsKeys.ALL && (
                      <>
                        There are no{' '}
                        <span className={'font-semibold'}>{tabs.find((tab) => tab.key === currentTab).name}</span>{' '}
                        users...
                      </>
                    )}
                  </div>
                )}
                {filteredUsers.length > 0 && (
                  <table className="min-w-full">
                    <thead className={'border-b border-black/15'}>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Name
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Status
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Role
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-black/5 bg-white">
                      {filteredUsers.map((user) => (
                        <tr key={user.uuid}>
                          <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                            <div className="flex items-center">
                              <div className="mr-4 w-10 h-10 flex items-center justify-center rounded-full overflow-hidden border-gray-100 border-2">
                                {user.account.profile.profileImage ? (
                                  <img
                                    className={'flex-shrink-0 w-10 h-10'}
                                    src={`${CND_URL}/${user.account.profile.profileImage}`}
                                    alt=""
                                  />
                                ) : (
                                  <UserIcon className={'flex-shrink-0 w-6 h-6 text-gray-200'} />
                                )}
                              </div>
                              <div>
                                <div className="font-medium text-gray-900">{user.account.profile.fullName}</div>
                                <div className="mt-1 text-gray-500">{user.account.email}</div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            <span className={statuses[user.status]}>{WORKSPACE_USER_STATUSES_NAMES[user.status]}</span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            {(user.uuid === workspace.user.uuid || user.role === WORKSPACE_USER_ROLES.OWNER) &&
                              WORKSPACE_USER_ROLES_NAMES[user.role]}
                            {user.uuid !== workspace.user.uuid && user.role !== WORKSPACE_USER_ROLES.OWNER && (
                              <Menu as="div" className="relative inline-block text-left">
                                <div>
                                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    {WORKSPACE_USER_ROLES_NAMES[user.role]}
                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            onClick={() => {
                                              changeRoleProcess(user.uuid, WORKSPACE_USER_ROLES.ADMIN)
                                            }}
                                            className={classNames(
                                              active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                              'flex items-center justify-between w-full text-left px-4 py-2 text-sm',
                                            )}
                                          >
                                            Admin
                                            {user.role === WORKSPACE_USER_ROLES.ADMIN && (
                                              <span className={'text-indigo-600'}>
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            )}
                                          </button>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            onClick={() => {
                                              changeRoleProcess(user.uuid, WORKSPACE_USER_ROLES.MEMBER)
                                            }}
                                            className={classNames(
                                              active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                              'flex items-center justify-between w-full text-left px-4 py-2 text-sm',
                                            )}
                                          >
                                            Member
                                            {user.role === WORKSPACE_USER_ROLES.MEMBER && (
                                              <span className={'text-indigo-600'}>
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            )}
                                          </button>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            )}
                          </td>
                          <td className="py-6 flex flex-row justify-end">
                            {user.role !== WORKSPACE_USER_ROLES.OWNER && user.uuid !== workspace.user.uuid && (
                              <Menu as="div" className="relative flex-none">
                                <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                  <span className="sr-only">Open options</span>
                                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          onClick={() => showRemoveDialog(user.uuid)}
                                          className={classNames(
                                            active ? 'bg-gray-50' : '',
                                            'w-full text-left block px-3 py-1 text-sm leading-6 text-gray-900',
                                          )}
                                        >
                                          Remove from workspace
                                          <span className="sr-only">
                                            ,{' '}
                                            {user.account.profile.fullName
                                              ? user.account.profile.fullName
                                              : user.account.email}
                                          </span>
                                        </button>
                                      )}
                                    </Menu.Item>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          <Invite />
          <Remove />
        </div>
      )}
    </MainLayout>
  )
}

export default memo(Users)
