import React from 'react'
import { PlusIcon } from '@heroicons/react/20/solid'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { WalletIcon, ClipboardDocumentListIcon, StarIcon, UserIcon } from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import MainLayout from '../../common/protected/main-layout/MainLayout'
import { changeShowNavigationSidebar } from '../../../reducers/common'
import { setShowPopupState } from '../../../reducers/meetings/create-meeting-form'
import Sidebar from '../../common/sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import { WORKSPACE_PLANS, WORKSPACE_USER_ROLES } from '../../../constants'
import { selectWorkspace } from '../../../reducers/workspace/workspace'

export default function MeetingsList() {
  const dispatch = useDispatch()

  const workspace = useSelector(selectWorkspace)

  const changeShowNavigationSidebarProcess = (status) => {
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: status }))
  }

  const showFormHandler = () => {
    dispatch(setShowPopupState({ showPopup: true }))
  }

  const secondaryNavigation = [
    {
      type: 'button',
      name: 'New analysis',
      action: showFormHandler,
      icon: PlusIcon,
    },
    { type: 'link', name: 'All analysis', end: true, href: '/meetings', icon: WalletIcon },
    // { type: 'link', name: 'My meetings', end: true, href: '/meetings/personal', icon: UserIcon },
    // { type: 'link', name: 'Starred meetings', end: true, href: '/meetings/starred', icon: StarIcon },
    ...(workspace.plan === WORKSPACE_PLANS.TEAM && workspace.user.role !== WORKSPACE_USER_ROLES.MEMBER
      ? [
          { type: 'divider', name: 'sections-divider-1' },
          { type: 'link', name: 'Meeting types', end: false, href: '/meetings/types', icon: ClipboardDocumentListIcon },
        ]
      : []),
  ]

  return (
    <MainLayout>
      <div className="bg-whiteX">
        <header>
          <div className="mx-auto h-16 flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
            <button
              type="button"
              className="mr-4 p-2.5 text-black lg:hidden"
              onClick={() => changeShowNavigationSidebarProcess(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-5 w-5" aria-hidden="true" />
            </button>
            <h1 className="text-base font-semibold leading-7 text-gray-900">Meetings analysis</h1>
          </div>
        </header>

        <div className="overflow-hidden border-t border-gray-100">
          <div className="xl:flex lg:gap-x-8 lg:pr-8 lg:pl-4">
            <Sidebar navigation={secondaryNavigation} />
            <div className="sm:px-6 lg:flex-auto lg:px-0">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
