import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAccount } from './slice'
import {
  changeEmail,
  changeRequestStatus,
  selectError,
  selectRequestStatus,
  selectSignupFormData,
} from '../../../reducers/user/sign-up-form'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { Link } from 'react-router-dom'
import Loader from '../../common/loader/Loader'
import NotificationPopup from '../../common/notification-popup/NotificationPopup'

function EmailForm() {
  const dispatch = useDispatch()

  const formData = useSelector(selectSignupFormData)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  const changeEmailProcess = (event) => {
    event.preventDefault()
    dispatch(changeEmail({ email: event.target.value }))
  }

  const createAccountProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(createAccount({ email: formData.email }))
  }

  useEffect(() => {
    return () => {
      dispatch(changeRequestStatus({ status: REQUEST_STATUSES.NOT_TRIGGERED }))
    }
  }, [])

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Create account</h2>
        <div className="mt-4 text-center text-sm text-gray-500">Please provide your email to proceed</div>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form
          className="space-y-6"
          onSubmit={(e) => {
            createAccountProcess(e)
          }}
        >
          {error && <NotificationPopup message={error.message} />}
          <div>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={(e) => {
                  changeEmailProcess(e)
                }}
                placeholder="Email address"
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={requestStatus === REQUEST_STATUSES.PENDING}
              className="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
              <span>Continue</span>
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Already has an account?{' '}
          <Link to="/sign-in" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
            Sign In
          </Link>
        </p>
      </div>
    </>
  )
}

export default memo(EmailForm)
