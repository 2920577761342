import React, { useEffect, useState, Fragment, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeDescription,
  changeDialog,
  changeGroupUuid,
  changeLanguage,
  changeName,
  changeRequestStatus,
  changeTypeUuid,
  selectFormData,
  selectRequestStatus,
  selectShowPopup,
  setShowPopupState,
  resetForm,
} from '../../../reducers/meetings/create-meeting-form'
import { selectShowPopup as selectAddPaymentMethodPopup } from '../../../reducers/workspace/settings/billing/add-payment-method-form'
import {
  selectShowPopup as selectShowDepositPopup,
  setShowPopupState as setShowDepositForm,
} from '../../../reducers/workspace/settings/billing/deposit-form'
import { createNewMeeting } from './slice'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { selectWorkspace } from '../../../reducers/workspace/workspace'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { DocumentIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { RecordLanguagesSelectboxOptions, PRICE, WORKSPACE_PLANS, WORKSPACE_USER_ROLES } from '../../../constants'
import Loader from '../../common/loader/Loader'
import { classNames } from '../../../utils/className'
import { getAllGroups } from '../../groups/slice'
import { groupsUnloaded, selectAllGroups } from '../../../reducers/workspace/group/group-list'
import { RecordAgent } from '../../../api/recordAgent'
import { structuredMeetingTypes } from '../../../components-helpers/structured-meeting-types'

const DATA_TYPE = {
  FILE: 'file',
  TEXT: 'text',
  RECORD: 'record',
}

export default function CreateDialog() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const formData = useSelector(selectFormData)
  const workspace = useSelector(selectWorkspace)
  const showPopup = useSelector(selectShowPopup)
  const showDepositPopup = useSelector(selectShowDepositPopup)
  const showAddPaymentMethodPopup = useSelector(selectAddPaymentMethodPopup)
  const groups = useSelector(selectAllGroups)

  const inputRef = useRef(null)

  const [isPrivateMeeting, setIsPrivateMeeting] = useState(true)
  const [types, setTypes] = useState(null)
  const [showMoreConfigs, setShowMoreConfigs] = useState(false)
  const [filteredGroups, setFilteredGroups] = useState(null)
  const [filteredTypes, setFilteredTypes] = useState(null)
  const [dataType, setDataType] = useState(DATA_TYPE.FILE)
  const [formError, setFormError] = useState(null)

  // Can't be stored in the Redux as file is not serializable
  const [record, addRecord] = useState(null)

  useEffect(() => {
    if (showPopup) {
      structuredMeetingTypes().then(({ structuredTypes, allTypes }) => {
        setTypes(allTypes)
        setFilteredTypes(structuredTypes)
      })

      let fetchGroups

      if (workspace.plan !== WORKSPACE_PLANS.PERSONAL) {
        fetchGroups = dispatch(getAllGroups())
      }

      return () => {
        setTimeout(() => {
          dispatch(groupsUnloaded())
          dispatch(resetForm())
          setFilteredGroups(null)
          setTypes(null)
          setFilteredTypes(null)
          setIsPrivateMeeting(true)
          addRecord(null)
          setDataType(DATA_TYPE.FILE)
          setFormError(null)
          if (fetchGroups) {
            fetchGroups.abort()
          }
        }, 200)
      }
    }
  }, [showPopup])

  useEffect(() => {
    if (groups) {
      const finalGroups = []
      if (groups.length > 0) {
        finalGroups.push({
          items: groups.map((group) => {
            return { name: group.name, uuid: group.uuid }
          }),
        })

        dispatch(changeGroupUuid({ groupUuid: groups[0].uuid }))
      }

      setFilteredGroups(finalGroups)
    }
  }, [groups])

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const changeDescriptionProcess = (event) => {
    event.preventDefault()
    dispatch(changeDescription({ description: event.target.value }))
  }

  const changeLanguageProcess = (value) => {
    dispatch(changeLanguage({ language: value }))
  }

  const changeGroupUuidProcess = (groupUuid) => {
    dispatch(changeGroupUuid({ groupUuid }))
  }

  const changeTypeUuidProcess = (typeUuid) => {
    dispatch(changeTypeUuid({ typeUuid }))
    if (formError && formError.field === 'meeting-type') {
      setFormError(null)
    }
  }

  const changeDialogProcess = (event) => {
    event.preventDefault()
    dispatch(changeDialog({ dialog: event.target.value }))
  }

  const changeRecord = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      addRecord(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    const file = event.target.files[0]

    addRecord(file)
  }

  const showDepositForm = () => {
    dispatch(setShowDepositForm({ showPopup: true }))
  }

  const createMeeting = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))

    if (!formData.typeUuid) {
      setFormError({
        field: 'meeting-type',
        message: 'Meeting type is required',
      })
      dispatch(changeRequestStatus({ status: REQUEST_STATUSES.NOT_TRIGGERED }))

      return
    }

    switch (dataType) {
      case DATA_TYPE.TEXT:
        if (!formData.dialog || formData.dialog.trim() === '') {
          setFormError({
            field: 'dialog',
            message: 'Text is required for this type of data',
          })
          dispatch(changeRequestStatus({ status: REQUEST_STATUSES.NOT_TRIGGERED }))

          return
        }

        dispatch(
          createNewMeeting({
            groupUuid: formData.groupUuid,
            typeUuid: formData.typeUuid,
            name: formData.name,
            description: formData.description,
            text: formData.dialog,
          }),
        )
        break

      case DATA_TYPE.FILE:
        if (!record) {
          setFormError({
            field: 'file',
            message: 'Audio or Video file is required',
          })
          dispatch(changeRequestStatus({ status: REQUEST_STATUSES.NOT_TRIGGERED }))

          return
        }

        RecordAgent.upload(record, formData.name, formData.groupUuid, formData.language).then((result) => {
          dispatch(
            createNewMeeting({
              groupUuid: formData.groupUuid,
              typeUuid: formData.typeUuid,
              name: formData.name,
              description: formData.description,
              text: formData.dialog,
              recordUuid: result.uuid,
            }),
          )
        })

        break
    }
  }

  const closeFormHandler = () => {
    dispatch(setShowPopupState({ showPopup: false }))
  }

  const selectedGroup = formData.groupUuid && groups ? groups.find((group) => group.uuid === formData.groupUuid) : null
  const selectedType = formData.typeUuid && types ? types.find((type) => type.uuid === formData.typeUuid) : null
  const noGroups = !workspace.allowPrivateMeetings && groups?.length === 0
  const forcedPrivateMeeting =
    workspace.plan === WORKSPACE_PLANS.PERSONAL || (workspace.allowPrivateMeetings && groups?.length === 0)

  const ErrorMessage = (message) => {
    return <div className={'text-xs text-red-400 pt-1 pl-2'}>{message}</div>
  }

  return (
    <Transition.Root show={showPopup && !showDepositPopup && !showAddPaymentMethodPopup} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={closeFormHandler}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg max-w-xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full">
                <div className="bg-gray-50 px-6 py-4 rounded-t-lg">
                  <div className="flex items-start justify-between space-x-3">
                    <div className="py-4 space-y-2">
                      <Dialog.Title className="text-2xl font-semibold leading-6 text-gray-700">
                        Create new meeting
                      </Dialog.Title>
                      <p className="text-sm text-gray-500">
                        {workspace.balance < 0
                          ? 'Not enough credits'
                          : 'Get started by filling in the information below to create new meeting.'}
                      </p>
                    </div>
                    <div className="flex h-7 items-center">
                      <button
                        type="button"
                        className="relative text-gray-400 hover:text-gray-500"
                        onClick={() => closeFormHandler()}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>

                {workspace.balance < PRICE.FIRST_LLM_REQUEST && (
                  <div className="px-6 py-8 text-center">
                    <p className={'text-sm text-gray-700'}>Not enough credits for processing more meetings</p>
                    {(workspace.user.role === WORKSPACE_USER_ROLES.OWNER ||
                      workspace.user.role === WORKSPACE_USER_ROLES.ADMIN) && (
                      <div>
                        <button
                          type="button"
                          onClick={showDepositForm}
                          className="mt-4 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Deposit
                        </button>
                      </div>
                    )}
                    {workspace.user.role === WORKSPACE_USER_ROLES.MEMBER && (
                      <p>Please ask your manager to replenish Workspace credits balance</p>
                    )}
                  </div>
                )}

                {workspace.balance >= PRICE.FIRST_LLM_REQUEST && (
                  <form onSubmit={createMeeting}>
                    <div className={'px-6 py-4'}>
                      {noGroups && (
                        <div className={'text-sm px-2 pb-2 text-gray-500'}>
                          You are not part of any group, and private meetings are not allowed in this workspace. Please
                          ask your manager to add you to a group or enable private meetings.
                        </div>
                      )}

                      {!noGroups && (
                        <>
                          {forcedPrivateMeeting && (
                            <div className={'text-sm px-2 pb-2 text-gray-500'}>
                              This meeting will be processed as Private meeting
                            </div>
                          )}

                          {!forcedPrivateMeeting && (
                            <div className={'w-full'}>
                              {workspace && !workspace.allowPrivateMeetings && groups?.length > 0 && (
                                <div className={'text-sm px-2 pb-2 text-gray-500'}>Select group</div>
                              )}
                              {workspace && workspace.allowPrivateMeetings && groups?.length > 0 && (
                                <div className={classNames('w-full text-gray-500 flex flex-row gap-x-2 rounded-md')}>
                                  <button
                                    type="button"
                                    className={classNames(
                                      'rounded-md px-3 py-2 text-sm ring-inset font-medium',
                                      isPrivateMeeting
                                        ? 'text-indigo-600 bg-indigo-50'
                                        : 'text-gray-500 hover:text-gray-700',
                                    )}
                                    onClick={() => {
                                      setIsPrivateMeeting(true)
                                      changeGroupUuidProcess(null)
                                    }}
                                  >
                                    Private meeting
                                  </button>
                                  <div
                                    className={classNames(
                                      'flex flex-row flex-grow',
                                      !isPrivateMeeting ? 'bg-white rounded-md' : '',
                                    )}
                                  >
                                    <button
                                      type="button"
                                      className={classNames(
                                        'px-3 py-2 text-sm ring-inset font-medium',
                                        !isPrivateMeeting
                                          ? 'text-indigo-600 bg-indigo-50 rounded-l-md'
                                          : 'text-gray-500 hover:text-gray-700 rounded-md',
                                      )}
                                      onClick={() => {
                                        setIsPrivateMeeting(false)
                                      }}
                                    >
                                      Attach to group{!isPrivateMeeting ? ':' : ''}
                                    </button>
                                    {!isPrivateMeeting && (
                                      <div className={'flex-grow'}>
                                        <Menu as="div" className="relative inline-block gap-x-4 w-full text-left">
                                          {({ open }) => (
                                            <>
                                              <Menu.Button
                                                className={classNames(
                                                  'w-full ring-inset ring-1 ring-gray-200 hover:bg-gray-50 justify-between flex items-center gap-x-2 px-4 rounded-r-md py-2 text-sm leading-6',
                                                )}
                                              >
                                                <span className="sr-only">Open user menu</span>
                                                <span className="text-sm font-medium text-gray-700" aria-hidden="true">
                                                  {selectedGroup ? selectedGroup.name : 'Select group'}
                                                </span>
                                                <ChevronDownIcon
                                                  className="ml-2 h-5 w-5 text-gray-400"
                                                  aria-hidden="true"
                                                />
                                              </Menu.Button>

                                              <Transition
                                                show={open}
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                              >
                                                <Menu.Items className="absolute w-full max-h-64 overflow-y-scroll z-10 mb-0 py-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                  {filteredGroups &&
                                                    filteredGroups.map((group, groupIndex) => (
                                                      <div key={`group-${groupIndex}`}>
                                                        {group.title && (
                                                          <div
                                                            className={
                                                              'text-xs text-gray-400 py-1 pt-2 px-4 mt-2 border-t border-gray-100'
                                                            }
                                                          >
                                                            {group.title}
                                                          </div>
                                                        )}
                                                        {group.items &&
                                                          group.items.map((item) => (
                                                            <Menu.Item key={item.name}>
                                                              {({ active }) => {
                                                                return (
                                                                  <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                      changeGroupUuidProcess(item.uuid)
                                                                    }}
                                                                    className={classNames(
                                                                      selectedGroup && selectedGroup.uuid === item.uuid
                                                                        ? 'bg-gray-50 font-semibold'
                                                                        : '',
                                                                      active ? 'bg-gray-50' : '',
                                                                      'w-full text-left block px-6 py-1 text-sm leading-6 text-gray-700',
                                                                    )}
                                                                  >
                                                                    {item.name}
                                                                  </button>
                                                                )
                                                              }}
                                                            </Menu.Item>
                                                          ))}
                                                      </div>
                                                    ))}
                                                </Menu.Items>
                                              </Transition>
                                            </>
                                          )}
                                        </Menu>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                          <div className={' mt-8'}>
                            <div>
                              <Menu
                                as="div"
                                className="relative inline-block gap-x-4 w-full text-left hover:bg-gray-50"
                              >
                                {({ open }) => (
                                  <>
                                    <Menu.Button
                                      className={classNames(
                                        'w-full  hover:bg-gray-50 bg-white justify-between flex items-center gap-x-2 px-4 rounded-md ring-1  py-2 text-sm leading-6',
                                        formError && formError.field === 'meeting-type'
                                          ? 'ring-red-600'
                                          : 'ring-gray-200',
                                      )}
                                    >
                                      <span className="sr-only">Open user menu</span>
                                      <span
                                        className={classNames(
                                          'text-sm ',
                                          selectedType ? 'text-gray-700 font-medium' : 'text-gray-500',
                                        )}
                                        aria-hidden="true"
                                      >
                                        {selectedType ? selectedType.name : 'Select meeting type'}
                                      </span>
                                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </Menu.Button>

                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="absolute w-full max-h-64 overflow-y-scroll z-10 mb-0 py-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {filteredTypes &&
                                          filteredTypes.map((type, typeIndex) => (
                                            <div key={`meeting-type-${typeIndex}`}>
                                              {type.title && (
                                                <div
                                                  className={classNames(
                                                    'text-xs text-gray-400 py-1 px-4 ',
                                                    typeIndex > 0 ? 'pt-2 mt-2 border-t border-gray-100' : '',
                                                  )}
                                                >
                                                  {type.title}
                                                </div>
                                              )}
                                              {type.items && (
                                                <div>
                                                  {type.items.map((item) => (
                                                    <Menu.Item key={item.name}>
                                                      {({ active }) => {
                                                        return (
                                                          <button
                                                            type="button"
                                                            onClick={() => {
                                                              changeTypeUuidProcess(item.uuid)
                                                            }}
                                                            className={classNames(
                                                              selectedType && selectedType.uuid === item.uuid
                                                                ? 'bg-gray-50 font-semibold'
                                                                : '',
                                                              active ? 'bg-gray-50' : '',
                                                              'w-full border-t border-1 border-gray-100 py-4 text-left block px-6 text-sm leading-6 text-gray-700',
                                                            )}
                                                          >
                                                            <div>{item.name}</div>
                                                            <div className={'text-xs text-gray-500'}>
                                                              {item.description}
                                                            </div>
                                                          </button>
                                                        )
                                                      }}
                                                    </Menu.Item>
                                                  ))}
                                                </div>
                                              )}
                                              {/*{groupIndex !== userNavigation.length - 1 && (*/}
                                              {/*  <div className="w-full my-2 border-t border-gray-100" />*/}
                                              {/*)}*/}
                                            </div>
                                          ))}
                                      </Menu.Items>
                                    </Transition>
                                  </>
                                )}
                              </Menu>
                              {formError && formError.field === 'meeting-type' && ErrorMessage(formError.message)}
                            </div>
                            <div className="w-full my-8" />
                          </div>
                          <div className={'w-full p-4 bg-gray-50 rounded-md'}>
                            <div className={'w-full grid grid-cols-4 text-gray-500 gap-x-2 mb-4 rounded-md'}>
                              <button
                                type="button"
                                className={classNames(
                                  'w-full rounded-md text-sm py-2 ring-inset font-medium',
                                  dataType === DATA_TYPE.FILE ? 'text-indigo-600 bg-indigo-50' : 'hover:text-gray-700',
                                )}
                                onClick={() => {
                                  setDataType(DATA_TYPE.FILE)
                                }}
                              >
                                Audio/Video
                              </button>
                              {/*<button*/}
                              {/*  type="button"*/}
                              {/*  className={classNames(*/}
                              {/*    'w-full rounded-md text-sm py-2 ring-inset font-medium',*/}
                              {/*    dataType === DATA_TYPE.RECORD ? 'bg-indigo-100 text-gray-900' : 'hover:text-gray-700',*/}
                              {/*  )}*/}
                              {/*  onClick={() => {*/}
                              {/*    setDataType(DATA_TYPE.RECORD)*/}
                              {/*  }}*/}
                              {/*>*/}
                              {/*  Existing record*/}
                              {/*</button>*/}
                              <button
                                type="button"
                                className={classNames(
                                  'w-full rounded-md text-sm py-2 ring-inset font-medium',
                                  dataType === DATA_TYPE.TEXT ? 'text-indigo-600 bg-indigo-50' : 'hover:text-gray-700',
                                )}
                                onClick={() => {
                                  setDataType(DATA_TYPE.TEXT)
                                }}
                              >
                                Text(Chat)
                              </button>
                              <div></div>
                            </div>
                            {dataType === DATA_TYPE.TEXT && (
                              <div>
                                <textarea
                                  id="dialog"
                                  name="dialog"
                                  placeholder="Please provide chat for processing here..."
                                  rows={3}
                                  onChange={changeDialogProcess}
                                  value={formData.dialog}
                                  className={classNames(
                                    'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
                                    formError && formError.field === 'dialog' ? 'ring-red-600' : 'ring-gray-200',
                                  )}
                                />
                                {formError && formError.field === 'dialog' && ErrorMessage(formError.message)}
                              </div>
                            )}
                            {dataType === DATA_TYPE.FILE && (
                              <>
                                <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                  <div className={'col-span-4'}>
                                    <div
                                      className={classNames(
                                        'w-full cursor-pointer hover:bg-gray-50 py-2 px-4 ring-inset ring-1 ring-gray-200 rounded-md bg-white flex flex-row gap-x-2 items-center',
                                        formError && formError.field === 'file' ? 'ring-red-600' : 'ring-gray-200',
                                      )}
                                      onClick={() => {
                                        inputRef.current?.click()
                                      }}
                                    >
                                      <div>
                                        <DocumentIcon className={'w-4 h-4 text-gray-700'} />
                                      </div>
                                      <div
                                        className={
                                          'w-full overflow-hidden text-sm text-gray-500 text-nowrap text-ellipsis'
                                        }
                                      >
                                        {!record ? 'Upload file' : record.name}
                                      </div>
                                      <span className={'ml-auto text-sm'}>Browse</span>
                                    </div>
                                    <input
                                      onChange={changeRecord}
                                      type="file"
                                      ref={inputRef}
                                      className="hidden w-full text-black text-sm bg-white border file:cursor-pointer cursor-pointer file:border-0 file:py-2 file:px-4 file:bg-gray-100 file:hover:bg-gray-200 file:text-black rounded-md"
                                    />
                                  </div>
                                  <div className={'col-span-2'}>
                                    <Menu as="div" className="relative inline-block gap-x-4 w-full text-left">
                                      {({ open }) => (
                                        <>
                                          <Menu.Button
                                            className={classNames(
                                              'w-full bg-white ring-inset ring-1 ring-gray-200 hover:bg-gray-50 justify-between flex items-center gap-x-2 px-4 rounded-md py-2 text-sm leading-6',
                                            )}
                                          >
                                            <span className="sr-only">Open user menu</span>
                                            <span className="text-sm font-semibold text-gray-700" aria-hidden="true">
                                              {
                                                RecordLanguagesSelectboxOptions.find(
                                                  (lang) => lang.index === formData.language,
                                                ).title
                                              }
                                            </span>
                                            <ChevronDownIcon
                                              className="ml-2 h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                          </Menu.Button>

                                          <Transition
                                            show={open}
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                          >
                                            <Menu.Items className="absolute w-full max-h-64 overflow-y-scroll z-10 mb-0 py-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                              {RecordLanguagesSelectboxOptions.map((language, languageIndex) => (
                                                <Menu.Item key={`language-${languageIndex}`}>
                                                  {({ active }) => {
                                                    return (
                                                      <button
                                                        type={'button'}
                                                        onClick={() => {
                                                          changeLanguageProcess(language.index)
                                                        }}
                                                        className={classNames(
                                                          formData.language === language.index
                                                            ? 'bg-gray-50 font-semibold'
                                                            : '',
                                                          active ? 'bg-gray-50' : '',
                                                          'w-full text-left block px-6 py-1 text-sm leading-6 text-gray-700',
                                                        )}
                                                      >
                                                        {language.title}
                                                      </button>
                                                    )
                                                  }}
                                                </Menu.Item>
                                              ))}
                                            </Menu.Items>
                                          </Transition>
                                        </>
                                      )}
                                    </Menu>
                                  </div>
                                </div>
                                {formError && formError.field === 'file' && ErrorMessage(formError.message)}
                              </>
                            )}
                          </div>
                          <div className={'text-gray-700 mt-4'}>
                            <button
                              type={'button'}
                              onClick={(e) => {
                                e.preventDefault()
                                setShowMoreConfigs(!showMoreConfigs)
                              }}
                              className={classNames(
                                showMoreConfigs ? 'bg-gray-50 rounded-b-none' : '',
                                'flex flex-row ml-auto py-2 px-4 rounded-md justify-between items-center hover:bg-gray-50',
                              )}
                            >
                              <div className={'text-sm font-semibold mr-4'}>More configs</div>
                              {!showMoreConfigs && <ChevronDownIcon className={'w-6 h-6'} />}
                              {showMoreConfigs && <ChevronUpIcon className={'w-6 h-6'} />}
                            </button>
                          </div>
                          {showMoreConfigs && (
                            <div className={'bg-gray-50 pl-8 pr-4 py-2 rounded-md'}>
                              <div className={'mt-4'}>
                                <div
                                  className={
                                    'flex bg-white rounded-md ring-1 ring-inset ring-gray-200 focus-within:ring-inset focus-within:ring-indigo-600'
                                  }
                                >
                                  <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    autoComplete="name"
                                    onChange={changeNameProcess}
                                    value={formData.name}
                                    className={
                                      'block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-700 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
                                    }
                                    placeholder="Meeting name..."
                                  />
                                </div>
                              </div>
                              <div className={'mt-8'}>
                                <textarea
                                  id="description"
                                  name="description"
                                  rows={3}
                                  onChange={changeDescriptionProcess}
                                  value={formData.description}
                                  className="block w-full max-w-2xl rounded-md border-0 py-1.5 text-gray-700 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder={'Additional context...'}
                                />
                                <p className=" leading-6 text-xs text-gray-400">
                                  This information will be used by AI as additional context for analysis
                                </p>
                              </div>
                            </div>
                          )}
                          <div className="grid grid-cols-2 justify-center space-x-4 py-6 mt-8 sm:mt-6">
                            <button
                              type={'button'}
                              onClick={(event) => {
                                event.preventDefault()
                                closeFormHandler()
                              }}
                              className={`text-sm rounded-md bg-white px-3 py-2 font-medium text-gray-700 hover:text-gray-600 hover:bg-gray-50 ring-1 ring-gray-200`}
                            >
                              Cancel
                            </button>

                            <button
                              type="submit"
                              className="flex flex-row justify-center items-center text-sm text-white rounded-md bg-indigo-600 px-3 py-2 font-medium hover:bg-indigo-500 ring-1 ring-indigo-600 hover:ring-indigo-500"
                            >
                              {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
                              Send to process
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </form>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
