import { createSlice } from '@reduxjs/toolkit'
import { updateWorkspaceGeneralSettings } from '../../../components/workspace/settings/general/slice'
import { failed, resetErrors, resetStatus } from '../../reducer-helper'

const initialState = {
  settings: {
    allowPrivateMeetings: '',
  },
  formLoaded: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'workspaceSecuritySettingsForm',
  initialState,
  reducers: {
    changeAllowPrivateMeetings: (state, action) => {
      state.settings.allowPrivateMeetings = action.payload.allowPrivateMeetings
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    setSettings: (state, action) => {
      state.settings.allowPrivateMeetings = action.payload.allowPrivateMeetings

      state.formLoaded = true
    },
    resetForm: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(updateWorkspaceGeneralSettings.fulfilled, (state, action) => {
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(updateWorkspaceGeneralSettings.rejected, failed)
  },
})

const selectSlice = (state) => state.workspaceSecuritySettingsForm
export const selectSettings = (state) => selectSlice(state).settings
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeAllowPrivateMeetings, changePassword, setSettings, resetForm, changeRequestStatus } = actions

export default reducer
