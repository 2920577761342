import { createSlice } from '@reduxjs/toolkit'
import { updateMeetingType } from '../../components/meeting/list/types/update-form-popup/slice'

const initialState = {
  data: {
    uuid: '',
    name: '',
    templateUuid: null,
    description: '',
    context: '',
    variables: [],
  },
  openForm: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'updateMeetingType',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateMeetingType.fulfilled, () => initialState)
  },
  reducers: {
    openForm: (state, action) => {
      state.data.uuid = action.payload.uuid
      state.data.name = action.payload.name
      state.data.templateUuid = action.payload.templateUuid
      state.data.description = action.payload.description
      state.data.context = action.payload.context
      state.data.variables = action.payload.variables
      state.openForm = true
    },
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    changeTemplate: (state, action) => {
      state.data.templateUuid = action.payload.templateUuid
    },
    changeDescription: (state, action) => {
      state.data.description = action.payload.description
    },
    changeContext: (state, action) => {
      state.data.context = action.payload.context
    },
    addVariable: (state, action) => {
      state.data.variables.push({ name: '', value: '' })
    },
    deleteVariable: (state, action) => {
      state.data.variables.splice(action.payload.indexForDelete, 1)
    },
    changeVariable: (state, action) => {
      state.data.variables[action.payload.index] = action.payload.variable
    },
    closeForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.updateMeetingType
export const selectFormData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const isFormOpened = (state) => selectSlice(state).openForm
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const {
  changeRequestStatus,
  openForm,
  closeForm,
  changeTemplate,
  changeName,
  changeDescription,
  changeContext,
  addVariable,
  deleteVariable,
  changeVariable,
} = actions

export default reducer
