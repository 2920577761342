import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeTaskAgent } from '../../../../../../api/meetingTypeTaskAgent'

export const getTaskDetails = createAsyncThunk(
  'meeting-type-tasks/details',
  async ({ taskUuid, typeUuid }, thunkApi) => {
    try {
      const task = await MeetingTypeTaskAgent.getTaskDetails(taskUuid, typeUuid)

      return { task }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const renameTask = createAsyncThunk(
  'meeting-type-tasks/rename',
  async ({ taskUuid, typeUuid, name }, thunkApi) => {
    try {
      await MeetingTypeTaskAgent.renameTask(taskUuid, typeUuid, name)

      return { name, taskUuid }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const updateDescriptionTask = createAsyncThunk(
  'meeting-type-tasks/update-description',
  async ({ taskUuid, typeUuid, description }, thunkApi) => {
    try {
      await MeetingTypeTaskAgent.updateTaskDescription(taskUuid, typeUuid, description)

      return { description, taskUuid }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
