import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { getAllWorkspaces } from '../../components/workspace/list/slice'
import { createNewWorkspace } from '../../components/workspace/create/slice'

const initialState = {
  workspaces: null,
  error: null,
  status: null,
  showSidebar: false,
}

const slice = createSlice({
  name: 'workspaceList',
  initialState,
  extraReducers(builder) {
    builder.addCase(getAllWorkspaces.fulfilled, (state, action) => {
      state.workspaces = action.payload.workspaces
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(getAllWorkspaces.rejected, failed)
  },
  reducers: {
    setSidebarState(state, action) {
      state.showSidebar = action.payload.showSidebar
    },
    workspacesUnloaded: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.workspaceList
export const selectAllWorkspaces = (state) => selectSlice(state).workspaces
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status
export const selectShowSidebar = (state) => selectSlice(state).showSidebar

const { reducer, actions } = slice

export const { changeRequestStatus, workspacesUnloaded, setSidebarState } = actions

export default reducer
