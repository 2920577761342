import React, { useState } from 'react'
import { PlusCircleIcon, MinusCircleIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { flattenDataStructure } from '../../../../../utils/flattenDataStructure'
import { ChevronUpIcon } from '@heroicons/react/16/solid'

const DetailComponent = ({ detail }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => setIsOpen(!isOpen)

  return (
    <div className="bg-white rounded-lg overflow-hidden my-4">
      <div
        className="bg-gray-100 px-6 py-4 flex justify-between items-center cursor-pointer hover:bg-indigo-50 transition duration-150 ease-in-out"
        onClick={toggleOpen}
      >
        <h2 className="text-lg font-semibold text-gray-900">{detail.title}</h2>
        {isOpen ? (
          <ChevronUpIcon className="h-6 w-6 text-indigo-600" />
        ) : (
          <ChevronDownIcon className="h-6 w-6 text-indigo-600" />
        )}
      </div>
      {isOpen && (
        <div className="px-6 py-4 border">
          {Object.entries(detail)
            .filter(([key]) => key !== 'title')
            .map(([key, value], index) => {
              let cleanKey = key.replaceAll('_', ' ').trim()
              cleanKey = cleanKey.charAt(0).toUpperCase() + cleanKey.slice(1)
              return (
                <div key={index} className="mt-5">
                  <strong className="text-indigo-600">{cleanKey}:</strong>
                  <span className="ml-2 text-gray-800">{value.toString()}</span>
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}

const SectionItem = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="bg-white  border border-gray-200 overflow-hidden rounded-lg mb-4">
      <div
        className="px-6 py-4 flex justify-between items-center cursor-pointer hover:bg-gray-50 transition duration-150 ease-in-out"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className="text-lg font-semibold text-gray-900">{item.title}</h2>
        {isOpen ? (
          <MinusCircleIcon className="h-6 w-6 text-indigo-600" />
        ) : (
          <PlusCircleIcon className="h-6 w-6 text-indigo-600" />
        )}
      </div>
      {isOpen && (
        <div className="px-6 py-4 border-t ">
          <blockquote className="mt-8 italic border-l-4 border-indigo-600 pl-4 text-gray-900">
            <p className="text-gray-700 font-medium">{item.value}</p>
          </blockquote>
          {item.items && (
            <div className="mt-4 space-y-4">
              {item.items.map((subItem, idx) => (
                <DetailComponent key={idx} detail={flattenDataStructure(subItem)} level={1} />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const ToggleSection = ({ data }) => {
  return (
    <div className="mx-auto mt-0 space-y-12">
      {data.map((item, index) => (
        <SectionItem key={index} item={item} />
      ))}
    </div>
  )
}

export default ToggleSection
