import React, { memo } from 'react'
import Avatart_0 from '../../../assets/images/avatars/0.svg'
import Avatart_1 from '../../../assets/images/avatars/1.svg'
import Avatart_2 from '../../../assets/images/avatars/2.svg'
import Avatart_3 from '../../../assets/images/avatars/3.svg'
import Avatart_4 from '../../../assets/images/avatars/4.svg'
import Avatart_5 from '../../../assets/images/avatars/5.svg'
import Avatart_6 from '../../../assets/images/avatars/6.svg'
import Avatart_7 from '../../../assets/images/avatars/7.svg'
import Avatart_8 from '../../../assets/images/avatars/8.svg'
import Avatart_9 from '../../../assets/images/avatars/9.svg'

const avatars = [
  Avatart_0,
  Avatart_1,
  Avatart_2,
  Avatart_3,
  Avatart_4,
  Avatart_5,
  Avatart_6,
  Avatart_7,
  Avatart_8,
  Avatart_9,
]

function Transcription({ transcription }) {
  const showDialog = () => {
    let dialogResult

    if (typeof transcription === 'string' || transcription instanceof String) {
      dialogResult = <div className={'whitespace-pre-line'}>{transcription}</div>
    } else {
      dialogResult = (
        <ul role="list" className="divide-y max-w-4xl divide-gray-100">
          {transcription.map((segment, index) => {
            const number = segment.speaker ? segment.speaker[segment.speaker.length - 1] : 0
            const avatar = avatars[number]

            return (
              <li key={`segment-${index}`} className="flex gap-x-4 py-5">
                <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={avatar} alt="" />
                <div className="flex-auto">
                  <div className="flex items-baseline justify-between gap-x-4">
                    <p className="text-sm font-semibold leading-6 text-gray-900 lowercase first-letter:uppercase">
                      {segment.speaker}
                    </p>
                  </div>
                  <p className="mt-1 text-sm leading-6 text-gray-600">{segment.text}</p>
                </div>
              </li>
            )
          })}
        </ul>
      )
    }

    return dialogResult
  }

  return (
    <div className="space-y-6 p-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
      {transcription && showDialog()}
    </div>
  )
}

export default memo(Transcription)
