import React from 'react'

const TextTemplate = ({ title, text }) => {
  return (
    <div className="bg-white">
      <div className="mx-auto text-base text-gray-700">
        <div className="text-sm leading-6 max-w-4xl">{text.toString()}</div>
      </div>
    </div>
  )
}

export default TextTemplate
