import { Fragment } from 'react'
import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { selectAllTypes } from '../../../../../reducers/meeting-type/type-list'
import { openForm as openDeleteForm } from '../../../../../reducers/meeting-type/delete-type'
import { openForm as openDuplicateForm } from '../../../../../reducers/meeting-type/duplicate-type'
import { classNames } from '../../../../../utils/className'
import { Menu, Transition } from '@headlessui/react'

export default function MeetingTypeListItem({ type, isCommon = false }) {
  const dispatch = useDispatch()

  const types = useSelector(selectAllTypes)

  const openDeleteConfirmationFormProcess = (event, typeUuid) => {
    event.preventDefault()

    const deletedType = types.find((type) => type.uuid === typeUuid)

    if (deletedType) {
      dispatch(openDeleteForm({ uuid: typeUuid, name: deletedType.name }))
    }
  }

  const openDuplicateFormProcess = (event, typeUuid) => {
    event.preventDefault()

    const duplicatedType = types.find((type) => type.uuid === typeUuid)

    if (duplicatedType) {
      dispatch(
        openDuplicateForm({
          uuid: typeUuid,
          currentName: duplicatedType.name,
          newName: duplicatedType.name + ' Clone',
        }),
      )
    }
  }

  return (
    <Link
      className={classNames(
        'flex flex-row justify-between w-full px-4 py-4 rounded-md bg-gray-50 transition-all',
        isCommon ? 'cursor-default' : 'hover:bg-indigo-50',
      )}
      to={!isCommon && `/meetings/types/${type.uuid}`}
    >
      <div>
        <div className="text-sm font-medium leading-6 text-gray-700">{type.name}</div>
        <div className="text-xs text-gray-500">{type.description}</div>
      </div>
      <div className={classNames('flex', isCommon ? 'items-end' : 'items-start')}>
        {isCommon && (
          <button
            onClick={(event) => {
              openDuplicateFormProcess(event, type.uuid)
            }}
            className={`flex text-sm ml-8 items-center justify-center rounded-md px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-indigo-100 ring-2 ring-indigo-500`}
          >
            Duplicate
          </button>
        )}
        {!isCommon && (
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="flex items-center p-2 text-gray-400 hover:bg-indigo-100 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
              <span className="sr-only">Open options</span>
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ focus }) => (
                      <button
                        onClick={(event) => {
                          openDuplicateFormProcess(event, type.uuid)
                        }}
                        className={classNames(
                          focus ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                          'flex w-full text-left justify-between px-4 py-2 text-sm',
                        )}
                      >
                        <span>Duplicate</span>
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ focus }) => (
                      <button
                        onClick={(event) => {
                          openDeleteConfirmationFormProcess(event, type.uuid)
                        }}
                        className={classNames(
                          focus ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                          'flex w-full text-left justify-between px-4 py-2 text-sm',
                        )}
                      >
                        <span>Delete</span>
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
    </Link>
  )
}
