import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../../api/workspaceAgent'

export const updateWorkspaceSecuritySettings = createAsyncThunk(
  'workspaces/security-settings/update',
  async ({ allowPrivateMeetings }, thunkApi) => {
    try {
      const workspace = await WorkspaceAgent.updateSecuritySettings(allowPrivateMeetings)

      return { workspace }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)
