import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingAgent } from '../../../api/meetingAgent'

export const getMeeting = createAsyncThunk('meeting/details', async ({ meetingUuid }, thunkApi) => {
  try {
    const data = await MeetingAgent.getMeetingDetails(meetingUuid)

    return { meeting: data.meeting, record: data.record, transcription: data.transcription }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})

export const renameMeeting = createAsyncThunk('meeting/rename', async ({ meetingUuid, name }, thunkApi) => {
  try {
    await MeetingAgent.renameMeeting(meetingUuid, name)

    return { name }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
