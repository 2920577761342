import React, { Fragment, useEffect, useRef, useState } from 'react'
import { getMeeting, renameMeeting } from './slice'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  meetingUnloaded,
  selectMeeting,
  selectRequestStatus,
  changeRequestStatus,
  selectMeetingTranscription,
} from '../../../reducers/meetings/meeting-details'
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs'
import MainLayout from '../../common/protected/main-layout/MainLayout'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  ChartBarIcon,
  ChatBubbleLeftIcon,
  EllipsisVerticalIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  ShareIcon,
} from '@heroicons/react/24/outline'
import { deleteMeeting } from '../list/meetings/slice'
import Loader from '../../common/loader/Loader'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { Tooltip } from 'react-tooltip'
import { classNames } from '../../../utils/className'
import TranscriptionDialog from '../../common/transcription/TranscriptionDialog'
import { changeName, selectFormData } from '../../../reducers/meetings/rename-meeting-form'
import UsageDialog from './UsageDialog'
import MeetingDetailsV1 from './v1/MeetingDetails'
import MeetingDetailsV2 from './v2/MeetingDetailsV2'
import { showPopup } from '../../../reducers/share-popup'

export default function MeetingDetails() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const renameFormData = useSelector(selectFormData)
  const meeting = useSelector(selectMeeting)
  const transcription = useSelector(selectMeetingTranscription)

  const [showTranscription, setShowTranscription] = useState(false)
  const [showUsage, setShowUsage] = useState(false)
  const [showRenameForm, setShowRenameForm] = useState(false)
  const [emptyName, setEmptyName] = useState(false)

  const { meetingUuid } = useParams()

  const cancelButtonRef = useRef(null)

  const [meetingUuidForDelete, setMeetingUuidForDelete] = useState(null)

  const renameHandler = () => {
    const withoutSpaces = renameFormData.name.replace(/\s+/g, '')
    setEmptyName(withoutSpaces.length === 0)
    if (withoutSpaces.length === 0 && renameFormData.name.length > 0) {
      dispatch(changeName({ name: '' }))
    } else if (withoutSpaces.length > 0) {
      setShowRenameForm(false)
      if (renameFormData.name !== meeting.name) {
        dispatch(renameMeeting({ meetingUuid, name: renameFormData.name }))
      }
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      renameHandler(event)
    }
  }

  const handleNameFieldFocus = (event) => {
    return event.target.select()
  }

  const changeNameProcess = (event) => {
    event.preventDefault()

    dispatch(changeName({ name: event.target.value }))
  }

  const pages = [
    { name: 'Meetings', href: '/meetings', current: true },
    { name: meeting ? meeting.name : '', href: '#', current: true },
  ]

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchMeeting = dispatch(getMeeting({ meetingUuid }))

    return () => {
      dispatch(meetingUnloaded())
      fetchMeeting.abort()
    }
  }, [])

  useEffect(() => {
    if (meeting) {
      dispatch(changeName({ name: meeting.name }))
    }
  }, [meeting])

  const deleteMeetingProcess = (meetingUuid) => {
    dispatch(deleteMeeting({ meetingUuid }))
  }

  const showSharePopup = () => {
    dispatch(showPopup({ type: 'meeting', token: meeting.sharingToken, sharedItemUuid: meetingUuid }))
  }

  return (
    <MainLayout>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
      {requestStatus === REQUEST_STATUSES.DONE && (
        <div>
          {meeting && meeting.uuid && (
            <>
              <Breadcrumbs pages={pages} />
              <div className="w-full py-8 px-8 flex flex-col">
                <div className="w-full border-b border-gray-200 bg-white pb-4">
                  <div className="flex flex-wrap flex-col justify-between sm:flex-nowrap">
                    <div className="flex w-full justify-between">
                      <div className={'flex flex-row items-center w-full'}>
                        {!showRenameForm && (
                          <>
                            <h3 className="text-2xl font-bold leading-6 text-indigo-600">{meeting.name}</h3>
                            <button
                              onClick={() => {
                                setShowRenameForm(true)
                              }}
                              className={
                                'p-2 ml-2 flex flex-row items-center text-gray-300 transition-colors duration-200 hover:text-indigo-400 hover:bg-gray-50 rounded-md'
                              }
                            >
                              <PencilIcon className={'w-5 h-5'} />
                            </button>
                          </>
                        )}
                        {showRenameForm && (
                          <>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              onChange={(e) => {
                                changeNameProcess(e)
                              }}
                              onFocus={handleNameFieldFocus}
                              onKeyUp={handleKeyPress}
                              autoFocus
                              onBlur={renameHandler}
                              value={renameFormData.name}
                              className={classNames(
                                'max-w-2xl p-0 text-2xl font-bold leading-6 text-gray-700 block flex-1 border-0 bg-transparent  focus:ring-0',
                                emptyName ? 'placeholder:text-red-400' : 'placeholder:text-gray-400',
                              )}
                              placeholder="Meeting name"
                            />
                          </>
                        )}
                      </div>
                      <div className={'flex flex-row items-center'}>
                        <div className={'flex flex-row items-center mr-12 gap-x-2'}>
                          <button
                            onClick={showSharePopup}
                            className={
                              'share-tooltip-el p-2 flex flex-row items-center text-gray-500 transition-colors duration-200 hover:text-indigo-400 hover:bg-gray-50 rounded-md'
                            }
                          >
                            <ShareIcon className={'h-5 w-5'} />
                            <Tooltip anchorSelect=".share-tooltip-el" place="bottom">
                              Share
                            </Tooltip>
                          </button>

                          {/*<button*/}
                          {/*  className={*/}
                          {/*    'favorite-tooltip-el p-2 flex flex-row items-center text-gray-500 transition-colors duration-200 hover:text-indigo-400 hover:bg-gray-50 rounded-md'*/}
                          {/*  }*/}
                          {/*>*/}
                          {/*  <StarIcon className={classNames(`h-5 w-5`)} />*/}
                          {/*  <Tooltip anchorSelect=".favorite-tooltip-el" place="bottom">*/}
                          {/*    Favorite*/}
                          {/*  </Tooltip>*/}
                          {/*</button>*/}

                          <button
                            onClick={() => setShowUsage(true)}
                            className={
                              'usage-tooltip-el p-2 flex flex-row items-center text-gray-500 transition-colors duration-200 hover:text-indigo-400 hover:bg-gray-50 rounded-md'
                            }
                          >
                            <ChartBarIcon className={classNames(`h-5 w-5`)} />
                            <Tooltip anchorSelect=".usage-tooltip-el" place="bottom">
                              Usage
                            </Tooltip>
                          </button>

                          <button
                            onClick={() => setShowTranscription(true)}
                            className={
                              'record-tooltip-el p-2 flex flex-row items-center text-gray-500 transition-colors duration-200 hover:text-indigo-400 hover:bg-gray-50 rounded-md'
                            }
                          >
                            <ChatBubbleLeftIcon className={classNames(`h-5 w-5`)} />
                            <Tooltip anchorSelect=".record-tooltip-el" place="bottom">
                              Record & transcription
                            </Tooltip>
                          </button>
                        </div>

                        <Menu as="div" className="relative inline-block text-left">
                          <div className={'border-l pl-4 flex flex-row items-center'}>
                            <div className={'text-xs text-gray-500 text-nowrap'}>Other options</div>
                            <Menu.Button className="flex items-center bg-white p-2 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                              <span className="sr-only">Open options</span>
                              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault()
                                        setMeetingUuidForDelete(meeting.uuid)
                                      }}
                                      className={classNames(
                                        active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                        'flex w-full text-left justify-between px-4 py-2 text-sm',
                                      )}
                                    >
                                      <span>Delete</span>
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {meeting && meeting.type && <MeetingDetailsV1 />}
                  {meeting && meeting.typeV2 && <MeetingDetailsV2 />}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <Transition.Root show={meetingUuidForDelete !== null} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={() => setMeetingUuidForDelete(null)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Delete meeting
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this meeting? All meeting information and processing results
                          will be lost.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"
                      onClick={() => {
                        deleteMeetingProcess(meetingUuidForDelete)
                        setMeetingUuidForDelete(null)
                      }}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                      onClick={() => setMeetingUuidForDelete(null)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <TranscriptionDialog
        showTransaction={showTranscription}
        setShowTransaction={setShowTranscription}
        transcription={transcription}
      />
      <UsageDialog showUsage={showUsage} setShowUsage={setShowUsage} />
    </MainLayout>
  )
}
