import React, { memo } from 'react'
import { Fragment } from 'react'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { WORKSPACE_PLANS, WORKSPACE_PLANS_NAMES, WORKSPACE_USER_ROLES } from '../../../constants'
import { RadioGroup } from '@headlessui/react'
import { selectAllWorkspaces, setSidebarState as setListSidebarState } from '../../../reducers/workspace/workspace-list'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { createNewWorkspace } from './slice'
import {
  selectFormData,
  changeRequestStatus,
  selectError,
  selectRequestStatus,
  changeName,
  changePlan,
  setSidebarState,
  selectStep,
  changeStep,
} from '../../../reducers/workspace/create-form'
import Loader from '../../common/loader/Loader'
import NotificationPopup from '../../common/notification-popup/NotificationPopup'
import { changeShowNavigationSidebar } from '../../../reducers/common'
import { classNames } from '../../../utils/className'
import AddPaymentMethodForm from '../settings/billing/payment-methods/add/AddPaymentMethodForm'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from '../../../configs'
import { Elements } from '@stripe/react-stripe-js'

const stripePromise = loadStripe(STRIPE_KEY)

function Form() {
  const dispatch = useDispatch()
  const workspaces = useSelector(selectAllWorkspaces)
  const freeWorkspace = workspaces
    ? workspaces.find(
        (workspace) =>
          workspace.plan === WORKSPACE_PLANS.PERSONAL && workspace.user.role === WORKSPACE_USER_ROLES.OWNER,
      )
    : null

  const error = useSelector(selectError)
  const step = useSelector(selectStep)
  const requestStatus = useSelector(selectRequestStatus)

  const formData = useSelector(selectFormData)
  const createWorkspaceHandler = (event) => {
    event.preventDefault()

    if (formData.plan === WORKSPACE_PLANS.TEAM) {
      dispatch(changeStep({ step: 2 }))
    } else {
      createWorkspaceProcess()
    }
  }

  const createWorkspaceProcess = (paymentMethod) => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      createNewWorkspace({
        name: formData.name,
        plan: formData.plan,
        paymentMethodId: paymentMethod ? paymentMethod.id : null,
      }),
    )
    dispatch(setListSidebarState({ showSidebar: false }))
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: false }))
  }

  const plans = [
    {
      key: WORKSPACE_PLANS.PERSONAL,
      name: WORKSPACE_PLANS_NAMES[WORKSPACE_PLANS.PERSONAL],
      description: 'Personal workspace allows to create and analyze your meetings, and many more.',
      price: 'Free',
      disabled: !!freeWorkspace,
    },
    {
      key: WORKSPACE_PLANS.TEAM,
      name: WORKSPACE_PLANS_NAMES[WORKSPACE_PLANS.TEAM],
      description: 'Team workspace allows to invite users to the workspace, manage groups and add integrations.',
      price: '$17/seat',
      selected: true,
    },
  ]

  const hideCreateWorkspaceSidebarProcess = () => {
    dispatch(setSidebarState({ showSidebar: false }))
  }

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const changePlanProcess = (value) => {
    dispatch(changePlan({ plan: value }))
  }

  const stepBack = () => {
    dispatch(changeStep({ step: 1 }))
  }

  return (
    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
      {/* Header */}
      <div className="bg-gray-50 px-4 py-6 sm:px-6">
        <div className="flex items-start justify-between space-x-3">
          <div className="space-y-1">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              Create new workspace
            </Dialog.Title>
            <p className="text-sm text-gray-500">
              Get started by filling in the information below to create new workspace.
            </p>
          </div>
          <div className="flex h-7 items-center">
            <button
              type="button"
              className="relative text-gray-400 hover:text-gray-500"
              onClick={() => hideCreateWorkspaceSidebarProcess(false)}
            >
              <span className="absolute -inset-2.5" />
              <span className="sr-only">Close panel</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      {step === 1 && (
        <form onSubmit={createWorkspaceHandler}>
          <div className="flex-1">
            {error && <NotificationPopup message={error.message} />}

            <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
              <div className="flex items-center space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                  <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900">
                    Workspace name
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <input
                    type="text"
                    required={true}
                    value={formData.name}
                    onChange={(event) => changeNameProcess(event)}
                    placeholder="My workspace"
                    name="workspace-name"
                    id="workspace-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="px-4 sm:grid sm:space-y-0 sm:px-6 py-5">
                <div className={'pb-2'}>
                  <label htmlFor="project-description" className="block text-sm font-medium leading-6 text-gray-900">
                    Plan
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <RadioGroup value={formData.plan} onChange={(value) => changePlanProcess(value)}>
                    <RadioGroup.Label className="sr-only">Workspace plan</RadioGroup.Label>
                    <div className="-space-y-px rounded-md bg-white">
                      {plans.map((planData, indx) => (
                        <RadioGroup.Option
                          key={planData.name}
                          disabled={planData.disabled}
                          value={planData.key}
                          className={({ checked }) =>
                            classNames(
                              indx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                              indx === plans.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                              checked ? 'z-10 border-indigo-200 bg-indigo-50' : 'border-gray-200',
                              'relative flex cursor-pointer border p-4 focus:outline-none',
                            )
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <div className="w-full flex flex-col">
                                <div className={'flex pb-2 flex-row justify-between'}>
                                  <div className={'flex flex-row items-center'}>
                                    <span
                                      className={classNames(
                                        checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                                        active ? 'ring-2 ring-offset-2 ring-indigo-600' : '',
                                        'h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center',
                                      )}
                                      aria-hidden="true"
                                    >
                                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                                    </span>
                                    <RadioGroup.Label
                                      as="div"
                                      className={classNames(
                                        checked
                                          ? 'text-indigo-900'
                                          : planData.disabled
                                            ? 'text-gray-400'
                                            : 'text-gray-900',

                                        'block pl-2 text-sm font-medium',
                                      )}
                                    >
                                      {planData.name}
                                    </RadioGroup.Label>
                                  </div>
                                  <div
                                    className={classNames(
                                      checked
                                        ? 'text-indigo-900'
                                        : planData.disabled
                                          ? 'text-gray-400'
                                          : 'text-gray-900',

                                      'block pl-2 text-sm font-medium',
                                    )}
                                  >
                                    {planData.price}
                                  </div>
                                </div>

                                <RadioGroup.Description
                                  as="div"
                                  className={classNames(
                                    checked ? 'text-indigo-700' : planData.disabled ? 'text-gray-400' : 'text-gray-500',
                                    'block text-sm',
                                  )}
                                >
                                  {planData.description}
                                </RadioGroup.Description>
                                {!!freeWorkspace && planData.key === WORKSPACE_PLANS.PERSONAL && (
                                  <RadioGroup.Description as="div" className={'block pt-2 text-sm text-red-400'}>
                                    Yo can have only one personal workspace
                                  </RadioGroup.Description>
                                )}
                              </div>
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              </div>
            </div>
          </div>

          {/* Action buttons */}
          <div className="flex justify-end space-x-4 flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
            <button
              type="button"
              className={`ml-auto flex text-sm items-center justify-center rounded-md bg-white px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-gray-50 ring-2 ring-indigo-500`}
              onClick={() => hideCreateWorkspaceSidebarProcess()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500"
            >
              {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
              {formData.plan === WORKSPACE_PLANS.PERSONAL && <>Create workspace</>}
              {formData.plan === WORKSPACE_PLANS.TEAM && <>Continue</>}
            </button>
          </div>
        </form>
      )}
      {step === 2 && (
        <>
          <Elements stripe={stripePromise}>
            <AddPaymentMethodForm
              cancelHandler={stepBack}
              createWorkspaceFlow={true}
              addMethodHandler={(paymentMethod) => {
                createWorkspaceProcess(paymentMethod)
              }}
            />
          </Elements>
        </>
      )}
    </div>
  )
}

export default memo(Form)
