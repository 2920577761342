import { createAsyncThunk } from '@reduxjs/toolkit'
import { BillingAgent } from '../../../../../../api/billingAgent'

export const attachPaymentMethod = createAsyncThunk(
  'workspaces/billing/add-payment-method',
  async ({ paymentMethod, isDefault }, thunkApi) => {
    try {
      const newPaymentMethod = await BillingAgent.attachPaymentMethod(paymentMethod.id, isDefault)

      return { paymentMethod: newPaymentMethod, isDefault }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)
