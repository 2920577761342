import { createSlice } from '@reduxjs/toolkit'
import { initApplication } from '../components/app/slice'
import { ComponentRequestStatus } from '../constants'
import { signIn } from '../components/user/sign-in/slice'
import { createProfile } from '../components/user/sign-up/slice'
import { acceptInvitation, switchWorkspace } from '../components/workspace/list/slice'
import { createGroup } from '../components/groups/create/slice'
import { acceptWithSignIn, acceptWithSignUp } from '../components/workspace/accept-invitation/slice'
import { createNewWorkspace } from '../components/workspace/create/slice'
import { upgrade } from '../components/workspace/upgrade/slice'
import { acceptLegal } from '../components/legal/accept/slice'
import { downgrade } from '../components/workspace/activate-subscription/slice'
import { deleteMeeting } from '../components/meeting/list/meetings/slice'
import { deleteRecord } from '../components/records/list/slice'
import { deleteMeetingType } from '../components/meeting/list/types/confirm-delete-popup/slice'
import { deleteTask } from '../components/meeting/list/types/task/confirm-delete-popup/slice'
import { createNewTask } from '../components/meeting/list/types/task/slice'

const initialState = {
  appLoaded: false,
  showNavigationSidebar: false,
  showGroupsSidebar: false,
  status: ComponentRequestStatus.IDLE,
  redirectTo: undefined,
  error: null,
}

function success(state) {
  state.appLoaded = true
  state.status = ComponentRequestStatus.SUCCESS
  delete state.errors
}

function failed(state) {
  state.appLoaded = true

  state.status = ComponentRequestStatus.FAILURE
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    clearRedirect(state) {
      delete state.redirectTo
    },
    changeShowNavigationSidebar(state, action) {
      state.showNavigationSidebar = action.payload.showNavigationSidebar
    },
    changeShowGroupsSidebar(state, action) {
      state.showGroupsSidebar = action.payload.showGroupsSidebar
    },
    redirect(state, action) {
      state.redirectTo = action.payload.redirectTo
    },
  },
  extraReducers(builder) {
    builder.addCase(initApplication.fulfilled, success)

    builder.addCase(initApplication.rejected, failed)

    builder.addCase(signIn.fulfilled, (state) => {
      state.redirectTo = '/dashboard'
    })

    builder.addCase(acceptWithSignUp.fulfilled, (state) => {
      state.redirectTo = '/dashboard'
    })

    builder.addCase(acceptWithSignIn.fulfilled, (state) => {
      state.redirectTo = '/dashboard'
    })

    builder.addCase(deleteMeeting.fulfilled, (state) => {
      state.redirectTo = '/meetings'
    })

    builder.addCase(deleteRecord.fulfilled, (state) => {
      state.redirectTo = '/records'
    })

    builder.addCase(createProfile.fulfilled, (state) => {
      state.redirectTo = '/dashboard'
    })

    builder.addCase(switchWorkspace.fulfilled, (state) => {
      state.redirectTo = '/dashboard'
    })

    builder.addCase(createNewWorkspace.fulfilled, (state) => {
      state.redirectTo = '/dashboard'
    })

    builder.addCase(upgrade.fulfilled, (state) => {
      state.redirectTo = '/dashboard'
    })

    builder.addCase(acceptInvitation.fulfilled, (state) => {
      state.redirectTo = '/dashboard'
    })

    builder.addCase(acceptLegal.fulfilled, (state) => {
      state.redirectTo = '/dashboard'
    })

    builder.addCase(createGroup.fulfilled, (state, action) => {
      state.redirectTo = `/groups/${action.payload.group.uuid}`
      state.showGroupsSidebar = false
    })

    builder.addCase(downgrade.fulfilled, (state, action) => {
      state.redirectTo = `/dashboard`
    })

    builder.addCase(deleteMeetingType.fulfilled, (state, action) => {
      state.redirectTo = `/meetings/types`
    })

    builder.addCase(deleteTask.fulfilled, (state, action) => {
      state.redirectTo = `/meetings/types/${action.payload.typeUuid}/tasks`
    })

    builder.addCase(createNewTask.fulfilled, (state, action) => {
      state.redirectTo = `/meetings/types/${action.payload.type.uuid}/tasks/${action.payload.task.uuid}`
    })
  },
})

const selectSlice = (state) => state.common
export const selectShowNavigationSidebar = (state) => selectSlice(state).showNavigationSidebar
export const selectShowGroupsSidebar = (state) => selectSlice(state).showGroupsSidebar

const { reducer, actions } = commonSlice

export const { clearRedirect, redirect, changeShowNavigationSidebar, changeShowGroupsSidebar } = actions

export default reducer
