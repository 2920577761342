import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import {
  selectError,
  selectRequestStatus,
  selectData,
  changeRequestStatus,
  changeFullName,
  resetData,
  changeSignUpPassword,
} from '../../../reducers/workspace/user/accept-invitation'
import { Link } from 'react-router-dom'
import Loader from '../../common/loader/Loader'
import { acceptWithSignUp } from './slice'
import { useParams } from 'react-router'

function SignUpForm() {
  const dispatch = useDispatch()

  const { token } = useParams()

  const data = useSelector(selectData)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeFullName({ fullName: event.target.value }))
  }

  const changeSignUpPasswordProcess = (event) => {
    event.preventDefault()
    dispatch(changeSignUpPassword({ password: event.target.value }))
  }

  const acceptWithSignUpProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(acceptWithSignUp({ token, fullName: data.signUpForm.fullName, password: data.signUpForm.password }))
  }

  return (
    <form className="space-y-4" onSubmit={acceptWithSignUpProcess}>
      <div className="relative -space-y-px rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
        <div>
          <label htmlFor="email-address" className="sr-only">
            Full name
          </label>
          <input
            id="full-name"
            name="fullName"
            type="text"
            autoComplete="full-name"
            onChange={(e) => {
              changeNameProcess(e)
            }}
            value={data.signUpForm.fullName}
            required
            className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Full name"
          />
        </div>
        <div>
          <label htmlFor="password" className="sr-only">
            Password
          </label>
          <input
            id="password"
            name="password"
            type="password"
            onChange={(e) => {
              changeSignUpPasswordProcess(e)
            }}
            value={data.signUpForm.password}
            autoComplete="current-password"
            required
            className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Password"
          />
        </div>
      </div>

      <div>
        <p className="text-center text-sm text-gray-500">
          By clicking you accept our{' '}
          <Link
            to={'https://asistme.ai/privacy-policy/'}
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            Privacy policy
          </Link>{' '}
          and{' '}
          <Link
            to={'https://asistme.ai/terms-of-service/'}
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            Terms of service
          </Link>
        </p>
      </div>

      <button
        type="submit"
        disabled={requestStatus === REQUEST_STATUSES.PENDING}
        className="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
        <span>Create account and accept invitation</span>
      </button>
    </form>
  )
}

export default memo(SignUpForm)
