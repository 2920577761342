import { createSlice } from '@reduxjs/toolkit'
import { failed } from '../../reducer-helper'
import { inviteUsers } from '../../../components/users/invite/slice'

const initialState = {
  form: {
    emails: [{email: '', isAdmin: false }],
    isAdmin: false,
    groups: [],
  },
  showSidebar: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'inviteWorkspaceUser',
  initialState,
  extraReducers(builder) {
    builder.addCase(inviteUsers.fulfilled, () => initialState)
    builder.addCase(inviteUsers.rejected, failed)
  },
  reducers: {
    setSidebarState(state, action) {
      state.showSidebar = action.payload.showSidebar
    },
    changeIsAdmin: (state, action) => {
      state.form.emails[action.payload.index].isAdmin = action.payload.isAdmin

      state.form.isAdmin = action.payload.isAdmin
    },
    addEmail: (state, action) => {
      state.form.emails.push({
        email: action.payload.email,
        isAdmin: action.payload.isAdmin
      })
    },
    removeEmail: (state, action) => {
        state.form.emails.splice(action.payload.index, 1)
    },
    changeEmail: (state, action) => {
      state.form.emails[action.payload.index].email = action.payload.email
    },
    addToGroup: (state, action) => {
      state.form.groups.push({
        uuid: action.payload.groupUuid,
        isManager: false,
      })
    },
    changeIsManager: (state, action) => {
      const groupIndex = state.form.groups.findIndex((group) => group.uuid === action.payload.groupUuid)
      if (groupIndex !== -1) {
        state.form.groups[groupIndex].isManager = action.payload.isManager
      }
    },
    removeFromGroup: (state, action) => {
      const groupIndex = state.form.groups.findIndex((group) => group.uuid === action.payload.groupUuid)
      if (groupIndex !== -1) {
        state.form.groups.splice(groupIndex, 1)
      }
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetData: () => initialState,
  },
})

const selectSlice = (state) => state.inviteWorkspaceUser
export const selectFormData = (state) => selectSlice(state).form
export const selectError = (state) => selectSlice(state).error

export const selectShowSidebar = (state) => selectSlice(state).showSidebar
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice
export const {
  changeRequestStatus,
  changeEmail,
  changeIsAdmin,
  resetData,
  removeFromGroup,
  addToGroup,
  changeIsManager,
  setSidebarState,
  addEmail,
  removeEmail
} = actions

export default reducer
