function flattenObject(obj, prefix = '', parentProps = {}) {
  for (const [key, value] of Object.entries(obj)) {
    const newKey = prefix ? `${prefix}_${key}` : key

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(parentProps, flattenObject(value, newKey, parentProps))
    } else if (Array.isArray(value)) {
      return value.reduce((acc, item) => {
        const combinedProps = { ...parentProps, ...flattenObject(item, newKey, parentProps) }
        return Object.assign(acc, combinedProps)
      }, {})
    } else {
      parentProps[newKey] = value
    }
  }

  return parentProps
}

export const flattenDataStructure = (data, parentKey = '') => {
  if (!data || typeof data !== 'object') {
    return data
  }

  if (Array.isArray(data)) {
    return data.map((item) => flattenDataStructure(item, parentKey)).flat(Infinity)
  }

  const subArrayExist = Object.values(data).some((value) => Array.isArray(value))
  if (subArrayExist) {
    const flattenedData = flattenObject(data)
    return flattenDataStructure(flattenedData, parentKey)
  }

  return Object.entries(data).reduce((acc, [key, value]) => {
    const newKey = parentKey ? `${parentKey}_${key}` : key

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(acc, flattenDataStructure(value, newKey))
    } else {
      acc[newKey] = value
    }

    return acc
  }, {})
}
