import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeAgent } from '../../../../../api/meetingTypeAgent'

export const updateMeetingType = createAsyncThunk(
  'meeting-types/update-meeting-type',
  async ({ typeUuid, name, description, templateUuid, context, variables }, thunkApi) => {
    try {
      const type = await MeetingTypeAgent.updateMeetingType(
        typeUuid,
        name,
        description,
        templateUuid,
        context,
        variables,
      )

      return { type }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
