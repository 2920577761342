import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeAgent } from '../../../../../api/meetingTypeAgent'

export const duplicateMeetingType = createAsyncThunk(
  'meeting-types-v2/duplicate-meeting-type',
  async ({ typeUuid, name }, thunkApi) => {
    try {
      const type = await MeetingTypeAgent.duplicateMeetingType(typeUuid, name)

      return { type }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
