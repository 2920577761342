import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { getMeeting, renameMeeting } from '../../components/meeting/view/slice'
import { deleteShareMeetingToken, resetShareMeetingToken } from '../../components/common/share-popup/slice'

const initialState = {
  data: {
    meeting: null,
    transcription: null,
    record: null,
  },
  error: null,
  status: null,
}
const slice = createSlice({
  name: 'meetingDetails',
  initialState,
  extraReducers(builder) {
    builder.addCase(renameMeeting.fulfilled, (state, action) => {
      state.data.meeting.name = action.payload.name
    })
    builder.addCase(getMeeting.fulfilled, (state, actions) => {
      state.data.meeting = actions.payload.meeting
      state.data.transcription = actions.payload.transcription
      state.data.record = actions.payload.record

      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(resetShareMeetingToken.fulfilled, (state, action) => {
      state.data.meeting.sharingToken = action.payload.sharedToken
    })
    builder.addCase(deleteShareMeetingToken.fulfilled, (state, action) => {
      state.data.meeting.sharingToken = null
    })
    builder.addCase(getMeeting.rejected, failed)
  },
  reducers: {
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    meetingUnloaded: () => initialState,
  },
})

const selectSlice = (state) => state.meetingDetails
export const selectMeeting = (state) => selectSlice(state).data.meeting
export const selectMeetingTranscription = (state) => selectSlice(state).data.transcription
export const selectMeetingRecord = (state) => selectSlice(state).data.record
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, meetingUnloaded } = actions

export default reducer
