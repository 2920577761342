import { requests } from './agent'

export const UserAgent = {
  signIn: (email, password) => {
    return requests.post('/1_0/user/account/sign-in', { email, password })
  },
  createAccount: (email) => {
    return requests.post('/1_0/user/account/sign-up', { email })
  },
  createProfile: (fullName, password, token) => {
    return requests.post('/1_0/user/account/sign-up/complete', { fullName, password, token })
  },
  resendEmailConfirmationCode: (email) => {
    return requests.post('/1_0/user/account/sign-up/resend-token', { email })
  },
  confirmSignUpEmail: (token) => {
    return requests.post('/1_0/user/account/sign-up/confirm-email', { token })
  },
  update: (fullName, email, password) => {
    return requests.post('/1_0/user/update', { fullName, email, password })
  },
  removeProfileImage: () => {
    return requests.post('/1_0/user/image/remove')
  },
  addProfileImage: (file) => {
    const formData = new FormData()
    formData.append('file', file)

    return requests.post(`/1_0/user/image/add`, formData, { 'Content-Type': 'multipart/form-data' })
  },
  confirmNewEmail: (token) => {
    return requests.post('/1_0/user/account/change-email/confirm-new-email', { token })
  },
  changePassword: (newPassword, oldPassword) => {
    return requests.post('/1_0/user/account/change-password', { newPassword, oldPassword })
  },
  forgotPasswordSetNewPassword: (token, password) => {
    return requests.post('/1_0/user/account/forgot-password/restore', { token, newPassword: password })
  },
  forgotPasswordResetPassword: (email) => {
    return requests.post('/1_0/user/account/forgot-password/reset', { email })
  },
  forgotPasswordConfirmEmail: (token) => {
    return requests.post('/1_0/user/account/forgot-password/confirm-email', { token })
  },
  logOut: () => {
    return requests.post('/1_0/user/logout')
  },
  acceptLegal: () => {
    return requests.post('/1_0/user/account/accept-legal')
  },
}
