import { createSlice } from '@reduxjs/toolkit'
import { getMeetingTypeDetails } from '../../components/meeting/list/types/details/slice'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { updateMeetingType } from '../../components/meeting/list/types/update-form-popup/slice'

const initialState = {
  data: {
    uuid: '',
    name: '',
    description: '',
    context: '',
    templateUuid: null,
    variables: null,
  },
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'meetingTypeDetails',
  initialState,
  extraReducers(builder) {
    builder.addCase(getMeetingTypeDetails.fulfilled, (state, action) => {
      state.data = action.payload.type
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(getMeetingTypeDetails.rejected, failed)
    builder.addCase(updateMeetingType.fulfilled, (state, action) => {
      state.data = action.payload.type
    })
  },
  reducers: {
    reset: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.meetingTypeDetails
export const selectTypeDetails = (state) => selectSlice(state).data
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, reset } = actions

export default reducer
