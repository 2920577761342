import { createSlice } from '@reduxjs/toolkit'
import { duplicateTask } from '../../components/meeting/list/types/task/duplicate-form-popup/slice'

const initialState = {
  data: {
    uuid: null,
    typeUuid: null,
    currentName: '',
    newName: '',
  },
  openForm: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'duplicateMeetingTypeTask',
  initialState,
  extraReducers(builder) {
    builder.addCase(duplicateTask.fulfilled, () => initialState)
  },
  reducers: {
    changeNewName: (state, action) => {
      state.data.newName = action.payload.newName
    },
    openForm: (state, action) => {
      state.openForm = true
      state.data.uuid = action.payload.uuid
      state.data.typeUuid = action.payload.typeUuid
      state.data.currentName = action.payload.currentName
      state.data.newName = action.payload.newName
    },
    closeForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.duplicateMeetingTypeTask
export const selectFormData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const isFormOpened = (state) => selectSlice(state).openForm
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, changeNewName, openForm, closeForm } = actions

export default reducer
