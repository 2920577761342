import { Fragment, useEffect, useState } from 'react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { selectTypeDetails } from '../../../../../../reducers/meeting-type/type-details'
import { selectTaskDetails } from '../../../../../../reducers/meeting-type-task/task-details'
import ConfirmDeleteTaskPopup from '../confirm-delete-popup/ConfirmDeletePopup'
import { CheckIcon, PencilIcon } from '@heroicons/react/24/outline'
import OutsideClickHandler from '../../../../../common/outside-click-handler/OutsideClickHandler'
import {
  changeName,
  changeRequestStatus as changeRenameTaskRequestStatus,
  selectFormData as selectRenameFormData,
  formInit as renameFormInit,
  selectOpenForm as selectOpenRenameForm,
  setOpenForm as setOpenRenameForm,
} from '../../../../../../reducers/meeting-type-task/rename-task'
import {
  changeDescription,
  changeRequestStatus as changeUpdateTaskDescriptionRequestStatus,
  selectFormData as selectUpdateDescriptionFormData,
  formInit as updateDescriptionFormInit,
  selectOpenForm as selectOpenUpdateDescriptionForm,
  setOpenForm as setOpenUpdateDescriptionForm,
} from '../../../../../../reducers/meeting-type-task/update-task-description'
import { REQUEST_STATUSES } from '../../../../../../reducers/reducer-helper'
import { renameTask, updateDescriptionTask } from './slice'
import TaskConstructor from '../constructor/TaskConstructor'

export default function TaskDetails() {
  const dispatch = useDispatch()

  const [renameFormError, setRenameFormError] = useState(null)
  const [showDeletePopup, setShowDeletePopup] = useState(false)

  const openRenameTaskForm = useSelector(selectOpenRenameForm)
  const setOpenRenameTaskFormProcess = (status) => {
    dispatch(setOpenRenameForm({ openForm: status }))
  }

  const openUpdateTaskDescriptionForm = useSelector(selectOpenUpdateDescriptionForm)
  const setUpdateTaskDescriptionFormProcess = (status) => {
    dispatch(setOpenUpdateDescriptionForm({ openForm: status }))
  }

  const renameFormData = useSelector(selectRenameFormData)
  const updateDescriptionFormData = useSelector(selectUpdateDescriptionFormData)
  const type = useSelector(selectTypeDetails)
  const task = useSelector(selectTaskDetails)

  const changeNameProcess = (event) => {
    event.preventDefault()
    setRenameFormError(event.target.value.trim() === '')
    dispatch(changeName({ name: event.target.value }))
  }

  const changeDescriptionProcess = (event) => {
    event.preventDefault()
    dispatch(changeDescription({ description: event.target.value }))
  }

  const closeRenameForm = () => {
    if (renameFormData.name.trim() === '') {
      setRenameFormError(true)
    } else {
      if (renameFormData.name === task.name) {
        setOpenRenameTaskFormProcess(false)
      } else {
        renameTaskProcess()
      }
    }
  }

  const closeUpdateDescriptionForm = () => {
    updateDescriptionFormData.description === task.description
      ? setUpdateTaskDescriptionFormProcess(false)
      : updateTaskDescriptionProcess()
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  useEffect(() => {
    if (task) {
      dispatch(
        renameFormInit({
          name: task.name,
        }),
      )
      dispatch(
        updateDescriptionFormInit({
          description: task.description,
        }),
      )
    }
  }, [task])

  const renameTaskProcess = (event) => {
    if (event) {
      event.preventDefault()
    }

    if (renameFormData.name.trim() !== '') {
      dispatch(changeRenameTaskRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      dispatch(
        renameTask({
          taskUuid: task.uuid,
          typeUuid: type.uuid,
          name: renameFormData.name,
        }),
      )
    } else {
      setRenameFormError(true)
    }
  }

  const updateTaskDescriptionProcess = (event) => {
    if (event) {
      event.preventDefault()
    }

    dispatch(changeUpdateTaskDescriptionRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      updateDescriptionTask({
        taskUuid: task.uuid,
        typeUuid: type.uuid,
        description: updateDescriptionFormData.description,
      }),
    )
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:pr-8 lg:pl-16">
        <div>
          <div className={'flex flex-row justify-between items-start w-full'}>
            <div className="flex flex-row items-center px-4 sm:px-0 w-full">
              <div className={'relative w-full'}>
                {!openRenameTaskForm && (
                  <button
                    type={'button'}
                    onClick={() => {
                      setOpenRenameTaskFormProcess(true)
                    }}
                    className={
                      'flex h-9 justify-center content-center items-center text-center rounded-md text-gray-900 hover:text-gray-500 transition-all cursor-pointer'
                    }
                  >
                    <h3 className="flex items-center text-xl font-semibold leading-7  mr-4"> {task.name}</h3>
                    <PencilIcon className={'w-4 h-4 text-gray-300'} />
                  </button>
                )}

                {openRenameTaskForm && (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      closeRenameForm()
                    }}
                  >
                    <form onSubmit={renameTaskProcess} className={'relative w-full'}>
                      <input
                        type={'text'}
                        placeholder={renameFormError ? 'Task name is required...' : 'Task new name'}
                        autoFocus={true}
                        onChange={changeNameProcess}
                        value={renameFormData.name}
                        className={classNames(
                          'w-full rounded-md border-none focus:outline-none focus:border-none ring-1 text-sm text-gray-700',
                          renameFormError ? 'ring-red-600 focus:ring-red-600' : '',
                        )}
                        name={'task-name'}
                      />
                      <button
                        type={'submit'}
                        className={classNames(
                          'w-9 h-9 flex text-center items-center content-center justify-center rounded-md bg-gray-50 hover:bg-indigo-50 transition-all cursor-pointer absolute top-0 right-0',
                        )}
                      >
                        <CheckIcon className={'w-4 h-4 text-gray-700'} />
                      </button>
                    </form>
                  </OutsideClickHandler>
                )}

                {!openUpdateTaskDescriptionForm && (
                  <button
                    type={'button'}
                    onClick={() => {
                      setUpdateTaskDescriptionFormProcess(true)
                    }}
                    className={
                      'flex mt-4 text-left rounded-md text-gray-700 hover:text-gray-500 transition-all cursor-pointer'
                    }
                  >
                    {task.description && <p className="max-w-4xl text-sm leading-6">{task.description}</p>}
                    {!task.description && (
                      <p className="max-w-4xl text-sm leading-6">
                        Here you can provide some description about this task...
                      </p>
                    )}
                    <PencilIcon className={'mt-1 ml-2 w-4 h-4 text-gray-300'} />
                  </button>
                )}

                {openUpdateTaskDescriptionForm && (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      closeUpdateDescriptionForm()
                    }}
                  >
                    <form onSubmit={updateTaskDescriptionProcess} className={'relative mt-4 w-full'}>
                      <textarea
                        placeholder={'Here you can provide some description about this task...'}
                        autoFocus={true}
                        onChange={changeDescriptionProcess}
                        value={updateDescriptionFormData.description ? updateDescriptionFormData.description : ''}
                        className={'w-full rounded-md border-0 ring-1 text-sm text-gray-700'}
                        name={'task-name'}
                      />
                      <button
                        type={'submit'}
                        className={
                          'px-4 h-9 ml-auto flex text-center text-sm items-center content-center justify-center rounded-md bg-gray-50 hover:bg-indigo-50 transition-all cursor-pointer'
                        }
                      >
                        <CheckIcon className={'w-5 h-5 text-gray-700 mr-2'} /> Save
                      </button>
                    </form>
                  </OutsideClickHandler>
                )}
              </div>
            </div>
            <div className={'flex flex-row gap-x-4 items-center'}>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <Menu as="div" className="relative flex-none">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ focus }) => (
                          <button
                            onClick={() => {
                              setShowDeletePopup(true)
                            }}
                            className={classNames(
                              focus ? 'bg-gray-50' : '',
                              'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900',
                            )}
                          >
                            Delete task
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <TaskConstructor />
        </div>
      </div>
      <ConfirmDeleteTaskPopup open={showDeletePopup} setOpen={setShowDeletePopup} />
    </>
  )
}
