import { createSlice } from '@reduxjs/toolkit'
import { failed } from '../reducer-helper'
import { WORKSPACE_PLANS } from '../../constants'
import { createNewWorkspace } from '../../components/workspace/create/slice'

const initialState = {
  data: {
    name: '',
    plan: WORKSPACE_PLANS.TEAM,
  },
  step: 1,
  showSidebar: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'createWorkspace',
  initialState,
  extraReducers(builder) {
    builder.addCase(createNewWorkspace.fulfilled, (state) => initialState)
    builder.addCase(createNewWorkspace.rejected, failed)
  },
  reducers: {
    setSidebarState(state, action) {
      state.showSidebar = action.payload.showSidebar
    },
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    changeStep: (state, action) => {
      state.step = action.payload.step
    },
    changePlan: (state, action) => {
      state.data.plan = action.payload.plan
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.createWorkspace
export const selectFormData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectStep = (state) => selectSlice(state).step
export const selectRequestStatus = (state) => selectSlice(state).status
export const selectShowSidebar = (state) => selectSlice(state).showSidebar

const { reducer, actions } = slice
export const { changeRequestStatus, changeStep, resetForm, changeName, changePlan, setSidebarState } = actions

export default reducer
