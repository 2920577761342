import React from 'react'
import { Route } from 'react-router-dom'
import SignIn from '../user/sign-in/SignIn'
import SignUp from '../user/sign-up/SignUp'
import ForgotPassword from '../user/forgot-password/ForgotPassword'
import ProtectedRoute from '../common/ProtectedRoute'
import Dashboard from '../dashboard/Dashboard'
import UserSettings from '../user/settings/UserSettings'
import UserGeneralSettings from '../user/settings/general/UserGeneralSettings'
import SecuritySettings from '../user/settings/security/SecuritySettings'
import Meetings from '../meeting/list/meetings/Meetings'
import Users from '../users/Users'
import WorkspaceSettings from '../workspace/settings/Settings'
import Integrations from '../groups/details/integrations/Integrations'
import Create from '../groups/create/Create'
import Details from '../groups/details/Details'
import GroupUsage from '../groups/details/usage/Usage'
import Members from '../groups/details/members/Members'
import GroupSettings from '../groups/details/settings/Settings'
import GroupIntegrations from '../groups/details/integrations/Integrations'
import Usage from '../usage/Usage'
import WorkspaceGeneralSettings from '../workspace/settings/general/WorkspaceGeneralSettings'
import WorkspaceSecuritySettings from '../workspace/settings/security/WorkspaceSecuritySettings'
import ApiKeys from '../workspace/settings/api-keys/ApiKeys'
import Webhooks from '../groups/details/webhooks/Webhooks'
import WorkspaceList from '../workspace/list/WorkspaceList'
import AcceptInvitation from '../workspace/accept-invitation/AcceptInvitation'
import Billing from '../workspace/settings/billing/Billing'
import BillingOverview from '../workspace/settings/billing/overview/Overview'
import PaymentMethods from '../workspace/settings/billing/payment-methods/PaymentMethods'
import History from '../workspace/settings/billing/history/History'
import Upgrade from '../workspace/upgrade/Upgrade'
import PrivacyPolicy from '../legal/privacy-policy/PrivacyPolicy'
import AcceptLegal from '../legal/accept/AcceptLegal'
import TermsOfService from '../legal/terms-of-service/TermsOfService'
import ActivateSubscription from '../workspace/activate-subscription/ActivateSubscription'
import RecordList from '../records/list/RecordList'
import ViewRecord from '../records/view/ViewRecord'
import Demo from '../demo/Demo'
import MeetingsList from '../meeting/list/MeetingsList'
import Types from '../meeting/list/types/Types'
import MeetingDetails from '../meeting/view/MeetingDetails'
import Root from '../root/Root'
import MeetingTypeDetails from '../meeting/list/types/details/TypeDetails'
import MeetingTypeList from '../meeting/list/types/list/MeetingTypeList'
import MeetingTypeTasks from '../meeting/list/types/task/MeetingTypeTasks'
import TaskDetails from '../meeting/list/types/task/details/TaskDetails'
import ViewSharedMeeting from '../shared/meeting/ViewSharedMeeting'
import ViewSharedRecord from '../shared/record/ViewSharedRecord'

export default function getRoutes() {
  return (
    <Route path={'/'} element={<Root />}>
      <Route exact path="/invitation/:token/accept" element={<AcceptInvitation />} />
      <Route exact path="/" element={<Demo />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/legal/accept" element={<AcceptLegal />} />
      <Route exact path="/terms-of-service" element={<TermsOfService />} />
      <Route exact path="/sign-in" element={<SignIn />} />
      <Route exact path="/sign-up" element={<SignUp />} />
      <Route exact path="/forgot-password" element={<ForgotPassword />} />
      <Route exact path="/shared/meeting/:token" element={<ViewSharedMeeting />} />
      <Route exact path="/shared/record/:token" element={<ViewSharedRecord />} />
      <Route
        exact
        path="/workspaces"
        element={
          <ProtectedRoute workspacesRequired={false}>
            <WorkspaceList />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/integrations"
        element={
          <ProtectedRoute>
            <Integrations />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/usage"
        element={
          <ProtectedRoute>
            <Usage />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/meetings"
        element={
          <ProtectedRoute>
            <MeetingsList />
          </ProtectedRoute>
        }
      >
        <Route exact path="" element={<Meetings />} />
        <Route exact path="types" element={<Types />}>
          <Route exact path="" element={<MeetingTypeList />} />
          <Route exact path=":typeUuid" element={<MeetingTypeDetails />} />
          <Route exact path=":typeUuid/tasks" element={<MeetingTypeTasks />}>
            <Route exact path=":taskUuid" element={<TaskDetails />} />
          </Route>
        </Route>
      </Route>
      <Route
        exact
        path="/meetings/:meetingUuid"
        element={
          <ProtectedRoute>
            <MeetingDetails />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/records"
        element={
          <ProtectedRoute>
            <RecordList />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/records/:recordUuid"
        element={
          <ProtectedRoute>
            <ViewRecord />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/users"
        element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/groups/:groupUuid"
        element={
          <ProtectedRoute>
            <Details />
          </ProtectedRoute>
        }
      >
        <Route exact path="" element={<GroupUsage />} />
        <Route exact path="members" element={<Members />} />
        <Route exact path="integrations" element={<GroupIntegrations />} />
        <Route exact path="settings" element={<GroupSettings />} />
        <Route exact path="webhooks" element={<Webhooks />} />
      </Route>
      <Route
        exact
        path="/groups/create"
        element={
          <ProtectedRoute>
            <Create />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/account/settings"
        element={
          <ProtectedRoute workspacesRequired={false}>
            <UserSettings />
          </ProtectedRoute>
        }
      >
        <Route exact path="general" element={<UserGeneralSettings />} />
        <Route exact path="security" element={<SecuritySettings />} />
      </Route>
      <Route
        exact
        path="/subscription/activate"
        element={
          <ProtectedRoute>
            <ActivateSubscription />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/upgrade"
        element={
          <ProtectedRoute>
            <Upgrade />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/settings"
        element={
          <ProtectedRoute>
            <WorkspaceSettings />
          </ProtectedRoute>
        }
      >
        <Route exact path="" element={<WorkspaceGeneralSettings />} />
        <Route exact path="security" element={<WorkspaceSecuritySettings />} />
        <Route exact path="api-keys" element={<ApiKeys />} />
        <Route exact path="billing" element={<Billing />}>
          <Route exact path="" element={<BillingOverview />} />
          <Route exact path="payment-methods" element={<PaymentMethods />} />
          <Route exact path="history" element={<History />} />
        </Route>
      </Route>
    </Route>
  )
}
