import React, { memo, useEffect } from 'react'
import { Switch } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectFormData,
  changeName,
  selectError,
  selectRequestStatus,
  changeOpenMeetings,
  changeRequestStatus,
  setSettings,
} from '../../../../reducers/workspace/group/update'
import { selectGroup } from '../../../../reducers/workspace/group/details'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { updateGroup } from './slice'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function GroupSettings() {
  const dispatch = useDispatch()

  const group = useSelector(selectGroup)

  const formData = useSelector(selectFormData)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const changeOpenMeetingsProcess = (value) => {
    dispatch(changeOpenMeetings({ openMeetings: value }))
  }

  useEffect(() => {
    dispatch(setSettings({ name: group.name, openMeetings: group.openMeetings }))
  }, [group])

  const saveSettings = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      updateGroup({
        uuid: group.uuid,
        name: formData.name,
        openMeetings: formData.openMeetings,
      }),
    )
  }

  return (
    <div className="mt-6 max-w-4xl space-y-6 divide-y divide-gray-100 text-sm leading-6">
      <form onSubmit={saveSettings}>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Group name</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <input
              id="group-name"
              name="group-name"
              type="string"
              value={formData.name}
              onChange={changeNameProcess}
              placeholder="Group name"
              autoComplete="group-name"
              required
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Privacy</dt>
          <dd className="">
            <Switch.Group as="div" className="flex items-center">
              <Switch
                checked={formData.openMeetings}
                onChange={changeOpenMeetingsProcess}
                className={classNames(
                  formData.openMeetings ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    formData.openMeetings ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3 text-sm">
                <span className="font-normal text-gray-700">Open processing results and transcription</span>
              </Switch.Label>
            </Switch.Group>
            <div className={'pt-4 text-xs text-gray-400'}>
              Group members can see processing results and transcription for meetings their had.
            </div>
          </dd>
        </div>

        {(formData.name !== group.name || formData.openMeetings !== group.openMeetings) && (
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-500 sm:w-64 sm:flex-none sm:pr-6"></dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <button
                type="submit"
                className={`flex items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              >
                <span>Save changes</span>
              </button>
            </dd>
          </div>
        )}

        <div className="pt-24 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Delete group</dt>
          <dd className={'flex flex-col'}>
            <button className="mr-auto flex items-center gap-x-1 rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Delete group
            </button>

            <div className={'text-xs mt-2 text-gray-400'}>
              Please be careful, by clicking this button you will delete this group and all meetings related to it.
            </div>
          </dd>
        </div>
      </form>
    </div>
  )
}

export default memo(GroupSettings)
