import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../api/workspaceAgent'

export const getAllWorkspaces = createAsyncThunk('workspaces/list', async (undefined, thunkApi) => {
  try {
    const workspaces = await WorkspaceAgent.getAllWorkspaces()

    return { workspaces }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})

export const switchWorkspace = createAsyncThunk('workspaces/switch', async ({ workspaceUuid }, thunkApi) => {
  try {
    const workspace = await WorkspaceAgent.switchWorkspace(workspaceUuid)

    return { workspace }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})

export const acceptInvitation = createAsyncThunk(
  'workspaces/invitation/accept',
  async ({ workspaceUuid }, thunkApi) => {
    try {
      const workspace = await WorkspaceAgent.acceptInvitation(workspaceUuid)

      return { workspace }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)
