import React, { memo, useEffect } from 'react'
import { ChevronRightIcon, ChevronLeftIcon, Bars3Icon, UsersIcon } from '@heroicons/react/24/outline'
import Chart from 'react-apexcharts'
import { format } from 'date-fns'
import { chartSeries, secondsToDuration } from '../../utils/helper'
import MainLayout from '../common/protected/main-layout/MainLayout'
import { changeShowNavigationSidebar } from '../../reducers/common'
import { useDispatch, useSelector } from 'react-redux'
import { selectWorkspace } from '../../reducers/workspace/workspace'
import { WORKSPACE_PLANS, WorkspaceStatisticTypes } from '../../constants'
import {
  changeDateDown,
  changeDateUp,
  changeRequestStatus,
  selectUsage,
  selectUsageMonth,
  selectUsageYear,
  usageUnloaded,
} from '../../reducers/workspace/usage'
import { REQUEST_STATUSES } from '../../reducers/reducer-helper'
import { getUsageData } from './slice'

function Usage() {
  const dispatch = useDispatch()

  const chartOptions = {
    chart: {
      type: 'line',
      height: 240,
      toolbar: {
        show: false,
      },
    },
    title: {
      show: '',
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#6466F1'],
    stroke: {
      lineCap: 'round',
      curve: 'smooth',
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#616161',
          fontSize: '12px',
          fontFamily: 'inherit',
          fontWeight: 400,
        },
      },
    },
    grid: {
      show: true,
      borderColor: '#dddddd',
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 5,
        right: 20,
      },
    },
    fill: {
      opacity: 0.8,
    },
    tooltip: {
      theme: 'dark',
    },
  }

  const chartConfigs = (customConfig = {}) => {
    return { ...chartOptions, ...customConfig }
  }

  const changeShowNavigationSidebarProcess = (status) => {
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: status }))
  }

  const workspace = useSelector(selectWorkspace)
  const usage = useSelector(selectUsage)
  const month = useSelector(selectUsageMonth)
  const year = useSelector(selectUsageYear)

  useEffect(() => {
    if (year && month) {
      dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      const usageData = dispatch(getUsageData({ year, month }))

      return () => {
        usageData.abort()
      }
    }
  }, [month, year])

  useEffect(() => {
    return () => {
      dispatch(usageUnloaded())
    }
  }, [])

  return (
    <MainLayout>
      <div className={'bg-whiteX'}>
        <header className="border-b border-black/5">
          <div className="mx-auto flex h-16 flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
            <button
              type="button"
              className="mr-4 p-2.5 text-black lg:hidden"
              onClick={() => changeShowNavigationSidebarProcess(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-5 w-5" aria-hidden="true" />
            </button>
            <h1 className="text-base font-semibold leading-7 text-gray-900">Workspace usage</h1>
            <div className={'ml-auto flex gap-x-4 items-center justify-end'}>
              <span className="isolate inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault()
                    dispatch(changeDateDown())
                  }}
                  className="relative inline-flex items-center rounded-l-md bg-white px-1.5 py-1.5 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                >
                  {format(new Date(`${year}/${month}`), 'MMMM')} {year !== new Date().getUTCFullYear() && year}
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault()
                    dispatch(changeDateUp())
                  }}
                  className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-1.5 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </span>
              {/*<button*/}
              {/*  type="button"*/}
              {/*  className="rounded-md bg-white px-2.5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"*/}
              {/*>*/}
              {/*  Export*/}
              {/*</button>*/}
            </div>
          </div>
        </header>
      </div>

      <div className={'relative p-8'}>
        <div className={'flex flex-col gap-y-8 divide-y divide-black/5 md:pr-96'}>
          <div>
            <div className="relative flex flex-col gap-4 overflow-hidden rounded-none bg-transparent bg-clip-border text-gray-700 shadow-none md:flex-row md:items-center">
              <div>
                <h6 className="block font-sans text-base font-semibold leading-relaxed tracking-normal text-blue-gray-900 antialiased">
                  Total workspace meeting processed
                </h6>
                <p className="block max-w-sm font-sans text-sm font-normal leading-normal text-gray-700 antialiased">
                  Amount of the meetings that were processed this month
                </p>
              </div>
            </div>
            <div className="pb-0">
              <Chart
                options={chartConfigs()}
                series={chartSeries(usage, WorkspaceStatisticTypes.MEETINGS_PROCESSED)}
                type="bar"
                width={'100%'}
                height={320}
              />
            </div>
          </div>
          <div>
            <div className="relative mt-8 flex flex-col gap-4 overflow-hidden rounded-none bg-transparent bg-clip-border text-gray-700 shadow-none md:flex-row md:items-center">
              <div>
                <h6 className="block font-sans text-base font-semibold leading-relaxed tracking-normal text-blue-gray-900 antialiased">
                  Total workspace Time transcribed
                </h6>
                <p className="block max-w-sm font-sans text-sm font-normal leading-normal text-gray-700 antialiased">
                  Amount of the time of the meetings transcribed
                </p>
              </div>
            </div>
            <div className="pb-0">
              <Chart
                options={chartConfigs({
                  yaxis: {
                    labels: {
                      formatter: (val) => {
                        return secondsToDuration(val)
                      },
                    },
                  },
                })}
                series={chartSeries(usage, WorkspaceStatisticTypes.TOTAL_TRANSCRIPTION_TIME, 'Total time')}
                type="bar"
                width={'100%'}
                height={320}
              />
            </div>
          </div>
          {/*<div>*/}
          {/*  <div className="relative mt-8 flex flex-col gap-4 overflow-hidden rounded-none bg-transparent bg-clip-border text-gray-700 shadow-none md:flex-row md:items-center">*/}
          {/*    <div>*/}
          {/*      <h6 className="block font-sans text-base font-semibold leading-relaxed tracking-normal text-blue-gray-900 antialiased">*/}
          {/*        Total workspace costs*/}
          {/*      </h6>*/}
          {/*      <p className="block max-w-sm font-sans text-sm font-normal leading-normal text-gray-700 antialiased">*/}
          {/*        Costs for meeting processing*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="pb-0">*/}
          {/*    <Chart options={chartConfigs()} series={chartSeries(1, 'Cost')} type="bar" width={'100%'} height={320} />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <div
          className={
            'border-t md:border-t-0 mt-16 md:mt-0 md:absolute bg-white right-0 inset-y-0 md:border-l border-black/5 w-full md:w-96'
          }
        >
          {workspace.plan === WORKSPACE_PLANS.TEAM && (
            <div className="relative overflow-hidden px-4 pt-5 border-b border-black/5 sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-md bg-indigo-500 p-3">
                  <UsersIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div className={'flex flex-row'}>
                  <p className="ml-16 truncate text-sm font-medium text-gray-500">Active users</p>
                  <p className="ml-2 truncate text-sm font-normal text-gray-400">(Feb)</p>
                </div>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">{workspace.users.active}</p>
                <p className={'ml-2 flex items-baseline text-indigo-400 text-sm font-normal'}>
                  (${workspace.users.active * 9})
                </p>
              </dd>
            </div>
          )}

          {/*<div className="relative overflow-hidden px-4 pt-5 border-b border-black/5 sm:px-6 sm:pt-6">*/}
          {/*  <dt className={'flex flex-row justify-between mb-2'}>*/}
          {/*    <p className="truncate text-sm font-medium text-gray-500">*/}
          {/*      Credit Grants <span className={'text-gray-400 font-normal pl-2'}>USD</span>*/}
          {/*    </p>*/}
          {/*    <p className="text-sm text-gray-700 font-semibold">*/}
          {/*      <span>$250 / $578</span>*/}
          {/*    </p>*/}
          {/*  </dt>*/}
          {/*  <dd className="flex items-baseline pb-4">*/}
          {/*    <div className="w-full bg-gray-200 rounded-sm h-2.5 dark:bg-gray-200">*/}
          {/*      <div*/}
          {/*        className="bg-indigo-500 h-2.5 rounded-sm"*/}
          {/*        style={{ width: `${parseInt(250 / (578 / 100))}%` }}*/}
          {/*      ></div>*/}
          {/*    </div>*/}
          {/*  </dd>*/}
          {/*  <div className={'pb-4'}>*/}
          {/*    <button className="ml-auto flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">*/}
          {/*      Deposit*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="relative overflow-hidden px-4 pt-5 border-b border-black/5 sm:px-6 sm:pt-6">*/}
          {/*  <p className={'pb-4 font-semibold text-sm'}>Invoices</p>*/}
          {/*  <table className="min-w-full divide-y divide-gray-300">*/}
          {/*    <thead>*/}
          {/*      <tr>*/}
          {/*        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-0">*/}
          {/*          Month*/}
          {/*        </th>*/}
          {/*        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500">*/}
          {/*          State*/}
          {/*        </th>*/}
          {/*        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500">*/}
          {/*          Balance*/}
          {/*        </th>*/}
          {/*      </tr>*/}
          {/*    </thead>*/}
          {/*    <tbody className="divide-y divide-gray-200">*/}
          {/*      {invoices.map((invoice) => (*/}
          {/*        <tr key={invoice.id}>*/}
          {/*          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-500 sm:pl-0">*/}
          {/*            {invoice.month}*/}
          {/*          </td>*/}
          {/*          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invoice.state}</td>*/}
          {/*          <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-700">*/}
          {/*            ${invoice.balance}*/}
          {/*          </td>*/}
          {/*        </tr>*/}
          {/*      ))}*/}
          {/*    </tbody>*/}
          {/*  </table>*/}
          {/*</div>*/}
        </div>
      </div>
    </MainLayout>
  )
}

export default memo(Usage)
