import React, { memo } from 'react'
import { ReactSVG } from 'react-svg'
import Logo from '../../assets/images/as_new_logo_full.svg'
import { Link } from 'react-router-dom'

function Demo() {
  return (
    <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl" aria-hidden="true">
        <div
          className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
        <div className="flex justify-center items-center mb-12">
          <ReactSVG
            src={Logo}
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'height: 40px; width: 512px;')
            }}
          />
        </div>
        <p className="mt-2 text-4xl font-light tracking-tight text-gray-700">
          AI-powered internal workflow automation platform
        </p>
        <p className={'mt-4 text-xl text-gray-500'}>Revolutionizing the post-meeting routine with AsistmeAI</p>
        <div className={'w-full flex justify-center mt-24 text-center'}>
          <Link
            to={'/dashboard'}
            className="flex flex-row justify-center items-center text-xl text-white rounded-md bg-indigo-600 px-12 py-4 font-medium hover:bg-indigo-500 ring-1 ring-indigo-600 hover:ring-indigo-500"
          >
            Let's start
          </Link>
        </div>
      </div>
      <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600"></p>
    </div>
  )
}

export default memo(Demo)
