import React, { useEffect, useState } from 'react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import MainLayout from '../common/protected/main-layout/MainLayout'
import { changeShowNavigationSidebar } from '../../reducers/common'
import { PlusIcon } from '@heroicons/react/20/solid'
import {
  changeRequestStatus,
  dashboardDataUnloaded,
  selectLastMeetings,
  selectRequestStatus,
  selectStatistic,
} from '../../reducers/dashboard'
import { REQUEST_STATUSES } from '../../reducers/reducer-helper'
import { getDashboardData } from './slice'
import GroupedMeetingList from '../meeting/list/meetings/GroupedMeetingList'
import { selectUser } from '../../reducers/user/user'
import { selectWorkspace } from '../../reducers/workspace/workspace'
import NoMeetingsMessage from '../meeting/list/meetings/NoMeetingsMessage'
import {
  WORKSPACE_PLANS,
  WORKSPACE_USER_ROLES,
  WorkspaceStatisticTypes,
  WorkspaceStatisticTypesNames,
} from '../../constants'
import Loader from '../common/loader/Loader'
import { setShowPopupState } from '../../reducers/meetings/create-meeting-form'
import { setShowPopupState as setShowDepositForm } from '../../reducers/workspace/settings/billing/deposit-form'
import { classNames } from '../../utils/className'
import SubscriptionWarnings from '../common/subscription-warnings/SubscriptionWarnings'

export default function Dashboard() {
  const dispatch = useDispatch()

  const [statistic, setStatistic] = useState(null)

  const user = useSelector(selectUser)
  const workspace = useSelector(selectWorkspace)
  const lastMeetings = useSelector(selectLastMeetings)
  const meetingsStatistic = useSelector(selectStatistic)
  const requestStatus = useSelector(selectRequestStatus)

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const dashboardData = dispatch(getDashboardData())

    return () => {
      dispatch(dashboardDataUnloaded())
      dashboardData.abort()
    }
  }, [workspace.uuid])

  useEffect(() => {
    if (
      workspace.plan === WORKSPACE_PLANS.PERSONAL ||
      workspace.user.role === WORKSPACE_USER_ROLES.ADMIN ||
      workspace.user.role === WORKSPACE_USER_ROLES.OWNER
    ) {
      const statisticValues = []
      meetingsStatistic &&
        meetingsStatistic.total.map((stat) => {
          statisticValues.push({ type: stat.type, value: stat.value })
        })

      statisticValues.push({ type: WorkspaceStatisticTypes.BALANCE, value: workspace.balance })

      setStatistic(statisticValues)
    } else {
      setStatistic(null)
    }
  }, [meetingsStatistic, workspace])

  const changeShowNavigationSidebarProcess = (status) => {
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: status }))
  }

  const showCreateMeetingFormHandler = () => {
    dispatch(setShowPopupState({ showPopup: true }))
  }

  const showDepositForm = () => {
    dispatch(setShowDepositForm({ showPopup: true }))
  }

  return (
    <MainLayout>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
      {requestStatus === REQUEST_STATUSES.DONE && (
        <div className="bg-whiteX">
          <div className="pb-4">
            <header>
              <div className="mx-auto h-16 flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                <button
                  type="button"
                  className="mr-4 p-2.5 text-black lg:hidden"
                  onClick={() => changeShowNavigationSidebarProcess(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="h-5 w-5" aria-hidden="true" />
                </button>
                <h1 className="text-base font-semibold leading-7 text-gray-900">
                  {workspace.name}
                  <span className={'font-normal text-gray-400 px-4'}>|</span>
                  <span className={'font-normal text-gray-400'}>{user.profile.fullName}</span>
                </h1>
              </div>
            </header>

            {statistic && (
              <div className={'px-6 mt-6'}>
                <dl className={classNames('grid sm:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-4')}>
                  {statistic.map((stat) => (
                    <div
                      key={stat.type}
                      className={classNames(
                        'w-full px-4 py-4 rounded-md',
                        stat.type === WorkspaceStatisticTypes.BALANCE ? 'bg-indigo-50' : 'bg-gray-50',
                      )}
                    >
                      <div>
                        <dd
                          className={classNames(
                            'w-full flex-none text-6xl font-light text-gray-700 tracking-tight',
                            stat.value < 0 ? 'text-red-700' : 'text-gray-900',
                          )}
                        >
                          {stat.type === WorkspaceStatisticTypes.BALANCE && '$'}
                          {stat.type === WorkspaceStatisticTypes.TOTAL_TRANSCRIPTION_TIME && Math.ceil(stat.value / 60)}
                          {stat.type !== WorkspaceStatisticTypes.TOTAL_TRANSCRIPTION_TIME && stat.value}
                        </dd>
                        <dt className="text-sm font-medium leading-6 text-gray-500">
                          {WorkspaceStatisticTypesNames[stat.type]}
                        </dt>
                      </div>
                      {stat.type === WorkspaceStatisticTypes.BALANCE &&
                        (workspace.user.role === WORKSPACE_USER_ROLES.ADMIN ||
                          workspace.user.role === WORKSPACE_USER_ROLES.OWNER) && (
                          <button
                            onClick={showDepositForm}
                            className={`ml-auto flex text-sm items-center justify-center rounded-md px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-indigo-100 ring-2 ring-indigo-500`}
                          >
                            Deposit
                          </button>
                        )}
                    </div>
                  ))}
                </dl>
              </div>
            )}
          </div>

          <div className={'px-6'}>
            <SubscriptionWarnings />
          </div>

          <div className="space-y-16">
            <div>
              <div className="flex items-center mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none">
                  Last meetings
                </h2>
                {lastMeetings && lastMeetings.length > 0 && (
                  <button
                    onClick={showCreateMeetingFormHandler}
                    className="ml-auto flex items-center gap-x-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    New analysis
                  </button>
                )}
              </div>
              <div className="mt-6 overflow-hidden border-t border-gray-100">
                <div className="mx-auto">
                  <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    {(!lastMeetings || lastMeetings.length === 0) && requestStatus !== REQUEST_STATUSES.PENDING && (
                      <NoMeetingsMessage />
                    )}
                    {lastMeetings && <GroupedMeetingList meetings={lastMeetings} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  )
}
