import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changePassword } from './slice'
import {
  selectForgotPasswordFormData,
  changePassword as changePasswordValue,
  changeRequestStatus,
  selectError,
  selectRequestStatus,
} from '../../../reducers/user/forgot-password-form'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import NotificationPopup from '../../common/notification-popup/NotificationPopup'
import Loader from '../../common/loader/Loader'

function ChangePasswordForm() {
  const dispatch = useDispatch()

  const formData = useSelector(selectForgotPasswordFormData)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  const changePasswordProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(changePassword({ token: formData.token, password: formData.password }))
  }

  const changePasswordValueProcess = (event) => {
    event.preventDefault()
    dispatch(changePasswordValue({ password: event.target.value }))
  }

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Forgot password</h2>
        <div className="mt-4 text-center text-sm text-gray-500">Create new password for your account</div>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6">
          {error && <NotificationPopup message={error.message} />}
          {requestStatus === REQUEST_STATUSES.PENDING && (
            <div className="flex w-full items-center justify-center mt-6">
              <Loader />
            </div>
          )}
          <div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                onChange={changePasswordValueProcess}
                placeholder="New password"
                autoComplete="password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="button"
              disabled={requestStatus === REQUEST_STATUSES.PENDING}
              onClick={changePasswordProcess}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
              <span>Save new password</span>
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default memo(ChangePasswordForm)
