import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeRequestStatus,
  changeToken,
  selectError,
  selectForgotPasswordFormData,
  selectRequestStatus,
} from '../../../reducers/user/forgot-password-form'
import { resetPassword, resetPasswordConfirmEmail } from './slice'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { Link } from 'react-router-dom'
import RICIBs from 'react-individual-character-input-boxes'
import Loader from '../../common/loader/Loader'
import NotificationPopup from '../../common/notification-popup/NotificationPopup'

const CODE_SIZE = 6

const codeInputProps = {
  className:
    'inline-block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
}

function ConfirmEmailForm() {
  const dispatch = useDispatch()

  const formData = useSelector(selectForgotPasswordFormData)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  const changeTokenProcess = (token) => {
    dispatch(changeToken({ token }))
    if (token.length === CODE_SIZE) {
      dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      dispatch(resetPasswordConfirmEmail({ token }))
    }
  }

  const resendToken = (event) => {
    event.preventDefault()
    dispatch(resetPassword({ email: formData.email }))
  }

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Forgot password</h2>
        <div className="mt-4 text-center text-sm text-gray-500">
          Please confirm your email. We send a code to the <b>{formData.email}</b>
        </div>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST">
          <div>
            {error && <NotificationPopup message={error.message} />}
            {requestStatus === REQUEST_STATUSES.PENDING && (
              <div className="flex w-full items-center justify-center mt-6">
                <Loader />
              </div>
            )}
            <div className="flex justify-center items-center mt-2">
              <RICIBs
                amount={6}
                autoFocus
                handleOutputString={changeTokenProcess}
                inputProps={codeInputProps}
                inputRegExp={/^[0-9]$/}
              />
            </div>
          </div>

          <div>
            <button
              type="button"
              onClick={(e) => {
                resendToken(e)
              }}
              className="flex w-full justify-center px-3 py-1.5 text-sm font-semibold leading-6 hover:text-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-300"
            >
              Resend code
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Remember your password?{' '}
          <Link to="/sign-in" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
            Sign In
          </Link>
        </p>
      </div>
    </>
  )
}

export default memo(ConfirmEmailForm)
