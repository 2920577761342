import React from 'react'
import PropTypes from 'prop-types'

const TitleValueComponent = ({ data }) => {
  const { title, value, details } = data

  const displayValue = value !== undefined && value !== null ? value.toString() : 'N/A'

  const displayNestedArray = (array) => {
    return array.map((item, index) => {
      return (
        <div key={index} className="bg-gray-100 rounded-lg mt-5 px-5 py-3">
          {Object.entries(item).map(([key, value], index) => {
            let cleanKey = key.replaceAll('_', ' ').trim()
            cleanKey = cleanKey.charAt(0).toUpperCase() + cleanKey.slice(1)
            const mt = index > 0 ? 'mt-3' : ''
            return (
              <div key={index} className={`text-sm text-gray-500 ${mt}`}>
                <strong className="text-gray-600">{cleanKey}:</strong>
                <span className="ml-2 text-gray-800">{value.toString()}</span>
              </div>
            )
          })}
        </div>
      )
    })
  }

  const displayDetails = () => {
    if (details) {
      return (
        <div className="mt-4 space-y-2">
          {Object.entries(details)
            .filter(([key]) => key !== 'title')
            .map(([key, value], index) => {
              let cleanKey = key.replaceAll('_', ' ').trim()
              cleanKey = cleanKey.charAt(0).toUpperCase() + cleanKey.slice(1)
              let cleanValue = value.toString()

              if (Array.isArray(value)) {
                cleanValue = displayNestedArray(value)
              }

              return (
                <div key={index} className="text-sm text-gray-500">
                  <span className="text-nowrap font-medium text-gray-600">{cleanKey}:</span>
                  <span className="ml-2">{cleanValue}</span>
                </div>
              )
            })}
        </div>
      )
    }
  }

  return (
    <div className="bg-white pb-10 border-b mb-10">
      <div className="flex items-start space-x-4">
        <div className="flex-grow">
          <h3 className="text-lg mb-3 font-normal text-gray-700">{title}</h3>
          <div className="text-sm text-gray-500">
            <div className="max-w-4xl">{displayValue}</div>
          </div>
          {displayDetails()}
        </div>
      </div>
    </div>
  )
}

export default TitleValueComponent
