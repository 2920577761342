import { MeetingTypeAgent } from '../api/meetingTypeAgent'

export const structuredMeetingTypes = async (types = null) => {
  const allTypes = types ? types : await MeetingTypeAgent.getAllTypes()

  const structuredTypes = []
  const customMeetingTypes = allTypes.filter((type) => type.workspaceUuid !== null)
  const commonMeetingTypes = allTypes.filter((type) => type.workspaceUuid === null)

  if (customMeetingTypes.length > 0) {
    structuredTypes.push({
      type: 'custom',
      title: 'Custom meeting types',
      items: customMeetingTypes
        ? customMeetingTypes.map((type) => {
            return { name: type.name, description: type.description, uuid: type.uuid }
          })
        : [],
    })
  }

  if (commonMeetingTypes.length > 0) {
    structuredTypes.push({
      type: 'common',
      title: 'Common meeting types',
      items: commonMeetingTypes
        ? commonMeetingTypes.map((type) => {
            return { name: type.name, description: type.description, uuid: type.uuid }
          })
        : [],
    })
  }

  return { structuredTypes, allTypes }
}
