import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../api/workspaceAgent'

export const getAllGroups = createAsyncThunk('workspaces/groups/list', async (undefined, thunkApi) => {
  try {
    const groups = await WorkspaceAgent.getAllGroups()

    return { groups }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
