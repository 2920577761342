import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeTemplateAgent } from '../../../../api/meetingTypeTemplateAgent'

export const getAllTemplates = createAsyncThunk('meeting-type/get-all-templates', async ({}, thunkApi) => {
  try {
    const templates = await MeetingTypeTemplateAgent.getTemplates()

    return { templates }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
