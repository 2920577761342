import React, { memo, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../../../reducers/user/user'
import {
  changeFullName,
  changeEmail,
  selectSettings,
  setSettings,
  changeEmailConfirmationPassword,
  selectChangeEmailRequestSent,
  changeRequestStatus,
  selectError,
  selectRequestStatus,
  resetForm,
} from '../../../../reducers/user/user-general-settings-form'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { addProfileImage, removeProfileImage, updateProfileInformation } from './slice'
import ConfirmEmailForm from './ConfirmEmailForm'
import Loader from '../../../common/loader/Loader'
import NotificationPopup from '../../../common/notification-popup/NotificationPopup'
import { CND_URL } from '../../../../configs'
import { CameraIcon, XMarkIcon } from '@heroicons/react/24/outline'

function UserGeneralSettings() {
  const changeEmailRequestSent = useSelector(selectChangeEmailRequestSent)
  const inputRef = useRef(null)

  const dispatch = useDispatch()

  const user = useSelector(selectUser)
  const formData = useSelector(selectSettings)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeFullName({ fullName: event.target.value }))
  }

  const changeEmailProcess = (event) => {
    event.preventDefault()
    dispatch(changeEmail({ email: event.target.value }))
  }

  const changeEmailConfirmationPasswordProcess = (event) => {
    event.preventDefault()
    dispatch(changeEmailConfirmationPassword({ emailConfirmationPassword: event.target.value }))
  }

  const saveSettings = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      updateProfileInformation({
        fullName: formData.fullName,
        email: formData.email,
        password: formData.emailConfirmationPassword,
      }),
    )
  }

  const removeProfileImageAction = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(removeProfileImage({}))
  }

  const addProfileImageAction = (event) => {
    event.preventDefault()
    const file = event.target.files[0]
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(addProfileImage({ file }))
  }

  useEffect(() => {
    dispatch(setSettings({ fullName: user.profile.fullName, email: user.email }))
  }, [user])

  useEffect(() => {
    return () => {
      dispatch(resetForm())
    }
  }, [])

  return (
    <>
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">This is your main profile information</p>
          <div className="mt-2">{error && <NotificationPopup message={error.message} />}</div>
          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Profile Image</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                {user.profile.profileImage ? (
                  <div className={'relative'}>
                    <button
                      type="button"
                      onClick={(event) => removeProfileImageAction(event)}
                      className={`absolute z-10 bg-red-700 text-white p-1 rounded-3xl -top-1 -right-1 hover:bg-red-500 transition-all`}
                    >
                      <XMarkIcon className="h-3 w-3" aria-hidden="true" />
                    </button>
                    <div
                      className={
                        'w-24 h-24 flex items-center overflow-hidden justify-center rounded-full border-2 border-gray-200 relative'
                      }
                    >
                      <img className={'w-24 h-24'} src={`${CND_URL}/${user.profile.profileImage}`} alt="" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        inputRef.current?.click()
                      }}
                      className={`flex flex-col w-24 h-24 items-center justify-center rounded-full bg-gray-50 border-gray-100 border-1 px-3 py-1.5 text-xs hover:bg-indigo-100 transition-all`}
                    >
                      <CameraIcon className={'w-6 h-6 mb-1'} />
                      <div>Add image</div>
                    </button>
                    <input
                      onChange={addProfileImageAction}
                      type="file"
                      ref={inputRef}
                      className="hidden w-full text-black text-sm bg-white border file:cursor-pointer cursor-pointer file:border-0 file:py-2 file:px-4 file:bg-gray-100 file:hover:bg-gray-200 file:text-black rounded-md"
                    />
                  </div>

                  // <>
                  //   <button
                  //     type="button"
                  //     onClick={() => {
                  //       inputRef.current?.click()
                  //     }}
                  //     className={`flex items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  //   >
                  //     <p>UPLOAD</p>
                  //   </button>
                  //   <input
                  //     onChange={addProfileImageAction}
                  //     type="file"
                  //     ref={inputRef}
                  //     className="hidden w-full text-black text-sm bg-white border file:cursor-pointer cursor-pointer file:border-0 file:py-2 file:px-4 file:bg-gray-100 file:hover:bg-gray-200 file:text-black rounded-md"
                  //   />
                  // </>
                )}
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Full name</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <input
                  id="full-name"
                  name="full-name"
                  type="string"
                  value={formData.fullName}
                  onChange={(e) => {
                    changeNameProcess(e)
                  }}
                  placeholder="Full name"
                  autoComplete="full-name"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => {
                    changeEmailProcess(e)
                  }}
                  placeholder="Email address"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {user.email !== formData.email && (
                  <input
                    id="email-confirm-password"
                    name="email-confirm-password"
                    type="password"
                    value={formData.emailConfirmationPassword}
                    onChange={(e) => {
                      changeEmailConfirmationPasswordProcess(e)
                    }}
                    placeholder="Your password"
                    autoComplete="your-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                )}
              </dd>
            </div>

            {(user.email !== formData.email || user.profile.fullName !== formData.fullName) && (
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-500 sm:w-64 sm:flex-none sm:pr-6"></dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <button
                    disabled={requestStatus === REQUEST_STATUSES.PENDING}
                    type="button"
                    onClick={saveSettings}
                    className={`flex items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  >
                    {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
                    <span>Save changes</span>
                  </button>
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
      {changeEmailRequestSent && <ConfirmEmailForm />}
    </>
  )
}

export default memo(UserGeneralSettings)
