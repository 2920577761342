import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeCurrentPassword,
  changePassword,
  changeRequestStatus,
  resetForm,
  selectError,
  selectRequestStatus,
  selectSettings,
} from '../../../../reducers/user/user-general-settings-form'
import { changePassword as changePasswordRequest } from './slice'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import NotificationPopup from '../../../common/notification-popup/NotificationPopup'
import Loader from '../../../common/loader/Loader'

function SecuritySettings() {
  const dispatch = useDispatch()
  const [showForm, setShowForm] = useState(false)

  const formData = useSelector(selectSettings)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  const changeCurrentPasswordProcess = (event) => {
    event.preventDefault()
    dispatch(changeCurrentPassword({ currentPassword: event.target.value }))
  }

  const changePasswordProcess = (event) => {
    event.preventDefault()
    dispatch(changePassword({ password: event.target.value }))
  }

  const savePassword = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(changePasswordRequest({ newPassword: formData.password, oldPassword: formData.currentPassword }))
  }

  useEffect(() => {
    return () => {
      dispatch(resetForm())
    }
  }, [])

  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">Security</h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          This is very important information, so, please, be careful.
        </p>
        <div className="mt-2">{error && <NotificationPopup message={error.message} />}</div>
        <dl className="mt-6 space-y-6 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Password</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {showForm && (
                <>
                  <input
                    id="new-password"
                    name="new-password"
                    type="password"
                    onChange={changePasswordProcess}
                    value={formData.password}
                    placeholder="Your new password"
                    autoComplete="your-new-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {formData.password !== '' && (
                    <input
                      id="confirm-password"
                      name="confirm-password"
                      type="password"
                      onChange={changeCurrentPasswordProcess}
                      value={formData.currentPassword}
                      placeholder="Your current password"
                      autoComplete="your-current-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  )}
                </>
              )}
              {!showForm && (
                <>
                  <div />
                  <button
                    type="button"
                    onClick={() => setShowForm(true)}
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Change
                  </button>
                </>
              )}
            </dd>
          </div>
          {formData.password !== '' && (
            <div className="sm:flex">
              <dt className="font-medium text-gray-500 sm:w-64 sm:flex-none sm:pr-6"></dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <button
                  disabled={requestStatus === REQUEST_STATUSES.PENDING}
                  type="button"
                  onClick={savePassword}
                  className="flex items-center justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                  {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
                  <span>Save new password</span>
                </button>
              </dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  )
}

export default memo(SecuritySettings)
