import { requests } from './agent'

export const MeetingAgent = {
  getAllMeetings: (page, pageSize) => {
    return requests.get(`/1_0/meetings/list?page=${page}&pageSize=${pageSize}`)
  },

  getMeetingDetails: (meetingUuid) => {
    return requests.get(`/1_0/meetings/${meetingUuid}`)
  },

  createMeeting: (groupUuid, typeUuid, text, name, description, recordUuid) => {
    return requests.post(`/1_0/meetings/create`, {
      groupUuid,
      typeUuid,
      text,
      name,
      description,
      recordUuid,
    })
  },

  deleteMeeting: (meetingUuid) => {
    return requests.post(`/1_0/meetings/${meetingUuid}/delete`)
  },

  renameMeeting: (meetingUuid, name) => {
    return requests.post(`/1_0/meetings/${meetingUuid}/rename`, { name })
  },

  getSharedMeeting: (token) => {
    return requests.get(`/1_0/meetings/shared/${token}`)
  },

  resetSharedToken: (meetingUuid) => {
    return requests.post(`/1_0/meetings/${meetingUuid}/sharing-token/regenerate`, {})
  },

  deleteSharedToken: (meetingUuid) => {
    return requests.post(`/1_0/meetings/${meetingUuid}/sharing-token/delete`, {})
  },
}
