'use client'

import React from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useDispatch } from 'react-redux'
import OutsideClickHandler from '../../../../../../common/outside-click-handler/OutsideClickHandler'
import { renameSection } from '../../../../../../../reducers/meeting-type-task/constructor'
import { classNames } from '../../../../../../../utils/className'

export default function RenameSectionForm({ section, sectionIndex, saveContent }) {
  const dispatch = useDispatch()

  const changeSectionNameProcess = (event, index) => {
    dispatch(renameSection({ index: sectionIndex, name: event.target.value }))
  }

  return (
    <OutsideClickHandler onOutsideClick={saveContent}>
      <form onSubmit={saveContent} className={'relative w-full'}>
        <input
          type={'text'}
          placeholder={'Task new name'}
          autoFocus={true}
          onChange={(event) => {
            changeSectionNameProcess(event)
          }}
          value={section.name}
          className={classNames(
            'w-full rounded-md border-none focus:outline-none focus:border-none ring-1 text-sm text-gray-700',
          )}
          name={'task-section-name'}
        />
        <button
          type={'submit'}
          className={classNames(
            'w-9 h-9 flex text-center items-center content-center justify-center rounded-md bg-gray-50 hover:bg-indigo-50 transition-all cursor-pointer absolute top-0 right-0',
          )}
        >
          <CheckIcon className={'w-4 h-4 text-gray-700'} />
        </button>
      </form>
    </OutsideClickHandler>
  )
}
