import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppAgent } from '../../api/appAgent'

export const getDashboardData = createAsyncThunk('dashboard/data', async (undefined, thunkApi) => {
  try {
    const dashboardData = await AppAgent.getDashboardData()

    return dashboardData
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
