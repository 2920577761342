import { createAsyncThunk } from '@reduxjs/toolkit'
import { RecordAgent } from '../../../api/recordAgent'

export const getAllRecords = createAsyncThunk('record-list/get-all-records', async (undefined, thunkApi) => {
  try {
    const records = await RecordAgent.getAllRecords()

    return { records }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})

export const deleteRecord = createAsyncThunk('record/delete', async ({ recordUuid }, thunkApi) => {
  try {
    await RecordAgent.deleteRecord(recordUuid)

    return { recordUuid }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
