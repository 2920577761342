import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../api/workspaceAgent'

export const getUsageData = createAsyncThunk('usage/data', async ({ year, month }, thunkApi) => {
  try {
    const usage = await WorkspaceAgent.getUsageData(year, month)

    return usage
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
