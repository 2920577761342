import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../api/workspaceAgent'

export const downgrade = createAsyncThunk('workspaces/downgrade', async ({}, thunkApi) => {
  try {
    const workspace = await WorkspaceAgent.downgrade()

    return { workspace }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
