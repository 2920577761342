import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../../api/userAgent'

export const createAccount = createAsyncThunk('user/account/sign-up', async ({ email }, thunkApi) => {
  try {
    const response = await UserAgent.createAccount(email)

    return response
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const confirmSignUpEmail = createAsyncThunk(
  'user/account/sign-up/confirm-email',
  async ({ token }, thunkApi) => {
    try {
      const response = await UserAgent.confirmSignUpEmail(token)

      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const createProfile = createAsyncThunk(
  'user/account/sign-up/complete',
  async ({ fullName, password, token }, thunkApi) => {
    try {
      const response = await UserAgent.createProfile(fullName, password, token)

      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const resendConfirmationToken = createAsyncThunk(
  'user/account/sign-up/resend-token',
  async ({ email }, thunkApi) => {
    try {
      const response = await UserAgent.resendEmailConfirmationCode(email)

      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)
