import React, { memo, useEffect } from 'react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import MainLayout from '../../common/protected/main-layout/MainLayout'
import { changeShowNavigationSidebar } from '../../../reducers/common'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, Outlet } from 'react-router-dom'
import { useParams } from 'react-router'
import {
  groupUnloaded,
  selectGroup,
  changeRequestStatus,
  selectRequestStatus,
} from '../../../reducers/workspace/group/details'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { getGroup } from './slice'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Details() {
  const dispatch = useDispatch()

  const { groupUuid } = useParams()

  const requestStatus = useSelector(selectRequestStatus)
  const group = useSelector(selectGroup)

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchGroup = dispatch(getGroup({ uuid: groupUuid }))

    return () => {
      dispatch(groupUnloaded())
      fetchGroup.abort()
    }
  }, [groupUuid])

  const tabs = [
    { name: 'Usage', href: `/groups/${groupUuid}` },
    { name: 'Members', href: `/groups/${groupUuid}/members` },
    // { name: 'Integrations', href: `/groups/${groupUuid}/integrations` },
    // { name: 'Webhooks', href: `/groups/${groupUuid}/webhooks` },
    { name: 'Settings', href: `/groups/${groupUuid}/settings` },
  ]

  const changeShowNavigationSidebarProcess = (status) => {
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: status }))
  }

  return (
    <MainLayout>
      <div className="bg-whiteX">
        {group && (
          <>
            <div>
              <header className="border-b border-black/5">
                <div className="mx-auto flex h-16 flex-wrap items-center gap-6 px-4 md:flex-nowrap md:px-6 lg:px-8">
                  <div className={'flex items-center'}>
                    <button
                      type="button"
                      className="mr-4 p-2.5 text-black lg:hidden"
                      onClick={() => changeShowNavigationSidebarProcess(true)}
                    >
                      <span className="sr-only">Open sidebar</span>
                      <Bars3Icon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <div className={'flex flex-col'}>
                      <h2 className="text-base font-semibold leading-7 text-gray-900">{group.name}</h2>
                      <p className={'text-gray-400 text-xs'}>{group.uuid}</p>
                    </div>
                  </div>
                  <div className={'hidden md:block sm:ml-auto'}>
                    <div className="sm:block mt-4 sm:mt-0">
                      <nav className="flex gap-x-2 flex-col sm:flex-row" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <NavLink key={tab.name} end to={tab.href}>
                            {({ isActive }) => {
                              return (
                                <div
                                  className={classNames(
                                    isActive ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                    'rounded-md px-3 py-1.5 text-sm font-medium',
                                  )}
                                >
                                  {tab.name}
                                </div>
                              )
                            }}
                          </NavLink>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
                <div className={'md:hidden md:ml-auto'}>
                  <div className="md:block mt-4 md:mt-0">
                    <nav className="flex gap-x-2 flex-col sm:flex-row" aria-label="Tabs">
                      {tabs.map((tab) => (
                        <NavLink key={tab.name} end to={tab.href}>
                          {({ isActive }) => {
                            return (
                              <div
                                className={classNames(
                                  isActive ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                  'rounded-md px-3 py-1.5 text-sm font-medium',
                                )}
                              >
                                {tab.name}
                              </div>
                            )
                          }}
                        </NavLink>
                      ))}
                    </nav>
                  </div>
                </div>
              </header>
            </div>
            <div className="w-full px-4 md:px-6 lg:px-8">
              <Outlet />
            </div>
          </>
        )}
      </div>
    </MainLayout>
  )
}

export default memo(Details)
