import { createSlice } from '@reduxjs/toolkit'
import { failed } from '../reducer-helper'
import { renameRecord } from '../../components/records/view/slice'

const initialState = {
  data: {
    name: '',
  },
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'renameRecordForm',
  initialState,
  extraReducers(builder) {
    builder.addCase(renameRecord.rejected, failed)
  },
  reducers: {
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    resetForm: () => initialState,
  },
})

const selectSlice = (state) => state.renameRecordForm
export const selectFormData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice
export const { changeRequestStatus, changeName, resetForm, setShowPopupState } = actions

export default reducer
