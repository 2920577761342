import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import {
  selectError,
  selectRequestStatus,
  selectData,
  changeRequestStatus,
  changeSignInPassword,
  changeEmail,
} from '../../../reducers/workspace/user/accept-invitation'
import Loader from '../../common/loader/Loader'
import { acceptWithSignIn, acceptWithSignUp } from './slice'
import { useParams } from 'react-router'

function SignInForm() {
  const dispatch = useDispatch()

  const { token } = useParams()

  const data = useSelector(selectData)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  const changeSignInPasswordProcess = (event) => {
    event.preventDefault()
    dispatch(changeSignInPassword({ password: event.target.value }))
  }

  const changeEmailProcess = (event) => {
    event.preventDefault()
    dispatch(changeEmail({ email: event.target.value }))
  }

  const acceptWithSignInProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(acceptWithSignIn({ token, email: data.signInForm.email, password: data.signInForm.password }))
  }

  return (
    <form className="space-y-4" onSubmit={acceptWithSignInProcess}>
      <div className="relative -space-y-px rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
        <div>
          <label htmlFor="email-address" className="sr-only">
            Email
          </label>
          <input
            id="email"
            name="email"
            type="text"
            autoComplete="email"
            onChange={changeEmailProcess}
            value={data.signInForm.email}
            required
            className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Email"
          />
        </div>
        <div>
          <label htmlFor="password" className="sr-only">
            Password
          </label>
          <input
            id="password"
            name="password"
            type="password"
            onChange={changeSignInPasswordProcess}
            value={data.signInForm.password}
            autoComplete="current-password"
            required
            className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Password"
          />
        </div>
      </div>

      <button
        type="submit"
        disabled={requestStatus === REQUEST_STATUSES.PENDING}
        className="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
        <span>Sign in and accept invitation</span>
      </button>
    </form>
  )
}

export default memo(SignInForm)
