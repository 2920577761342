import { requests } from './agent'

export const MeetingTypeAgent = {
  createNewMeetingType: (name, description, templateUuid, context, variables) => {
    return requests.post(`/1_0/meeting-types/create`, {
      name,
      templateUuid,
      description,
      context,
      variables,
    })
  },

  duplicateMeetingType: (typeUuid, name) => {
    return requests.post(`/1_0/meeting-types/${typeUuid}/duplicate`, {
      name,
    })
  },

  getAllTypes: () => {
    return requests.get('/1_0/meetings/types/list')
  },

  getMeetingTypeDetails: (typeUuid) => {
    return requests.get(`/1_0/meeting-types/${typeUuid}`)
  },

  updateMeetingType: (typeUuid, name, description, templateUuid, context, variables) => {
    return requests.post(`/1_0/meeting-types/${typeUuid}/update`, {
      name,
      description,
      templateUuid,
      context,
      variables,
    })
  },

  deleteMeetingType: (typeUuid) => {
    return requests.post(`/1_0/meeting-types/${typeUuid}/delete`)
  },
}
