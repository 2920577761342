export const Errors = {
  LEGAL_ACCEPTANCE_REQUIRED_ERROR: {
    code: 'LEGAL_ACCEPTANCE_REQUIRED_ERROR',
    message: 'Please accept our Privacy policy and Terms of use',
  },
  SUBSCRIPTION_IS_NOT_ACTIVATED_ERROR: {
    code: 'SUBSCRIPTION_IS_NOT_ACTIVATED_ERROR',
    message: 'Please activate your subscription',
  },
}
