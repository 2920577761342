import React, { Fragment, useEffect } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowLeftIcon, Bars3Icon, CheckIcon, EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/outline'
import { selectTypeDetails } from '../../../../../reducers/meeting-type/type-details'
import { createNewTask, getAllTasks, reorderTask } from './slice'
import {
  selectFormData as selectCreateTaskFormData,
  changeName,
  changeRequestStatus as changeCreateTaskRequestStatus,
  selectOpenForm,
  setOpenForm,
} from '../../../../../reducers/meeting-type-task/create-task'
import { REQUEST_STATUSES } from '../../../../../reducers/reducer-helper'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import OutsideClickHandler from '../../../../common/outside-click-handler/OutsideClickHandler'
import { classNames } from '../../../../../utils/className'
import {
  changeRequestStatus as changeGetTaskListRequestStatus,
  reorderingTasks,
  selectAllTasks,
  reset as resetTaskList,
} from '../../../../../reducers/meeting-type-task/task-list'
import {
  changeRequestStatus as changeGetTaskRequestStatus,
  reset as resetTaskDetails,
} from '../../../../../reducers/meeting-type-task/task-details'
import { useParams } from 'react-router'
import { getTaskDetails } from './details/slice'
import { Menu, Transition } from '@headlessui/react'
import { openForm as openDuplicateForm } from '../../../../../reducers/meeting-type-task/duplicate-task'
import DuplicateMeetingTypeTask from './duplicate-form-popup/DuplicateMeetingTypeTask'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default function MeetingTypeTasks() {
  const dispatch = useDispatch()

  const formData = useSelector(selectCreateTaskFormData)
  const type = useSelector(selectTypeDetails)
  const tasks = useSelector(selectAllTasks)
  const openNewTaskForm = useSelector(selectOpenForm)

  const { taskUuid } = useParams()

  useEffect(() => {
    if (taskUuid) {
      dispatch(changeGetTaskRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      const fetchTask = dispatch(getTaskDetails({ taskUuid, typeUuid: type.uuid }))

      return () => {
        dispatch(resetTaskDetails())
        fetchTask.abort()
      }
    }
  }, [taskUuid])

  const setOpenNewTaskForm = (status) => {
    dispatch(setOpenForm({ openForm: status }))
  }

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const createNewTaskProcess = (event) => {
    event.preventDefault()
    event.stopPropagation()

    dispatch(changeCreateTaskRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      createNewTask({
        typeUuid: type.uuid,
        name: formData.name,
      }),
    )
  }

  useEffect(() => {
    if (type.uuid) {
      dispatch(changeGetTaskListRequestStatus({ status: REQUEST_STATUSES.PENDING }))

      const fetchTasks = dispatch(getAllTasks({ typeUuid: type.uuid, orderingMap: type.tasksOrdering }))

      return () => {
        dispatch(resetTaskList())
        fetchTasks.abort()
      }
    }
  }, [type])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const reorderedTasks = reorder(tasks, result.source.index, result.destination.index)
    const reorderedTasksUuid = reorderedTasks.map((task) => task.uuid)

    dispatch(
      reorderTask({
        typeUuid: type.uuid,
        newOrdering: reorderedTasksUuid,
      }),
    )

    dispatch(reorderingTasks({ tasks: reorderedTasks }))
  }

  const openDuplicateFormProcess = (event, taskUuid) => {
    event.preventDefault()

    const duplicatedTask = tasks.find((task) => task.uuid === taskUuid)

    if (duplicatedTask) {
      dispatch(
        openDuplicateForm({
          uuid: taskUuid,
          typeUuid: type.uuid,
          currentName: duplicatedTask.name,
          newName: duplicatedTask.name + ' Clone',
        }),
      )
    }
  }

  return (
    <div>
      <div className={'flex flex-row justify-between items-center'}>
        <div className="flex flex-row items-center px-4 sm:px-0">
          <NavLink
            className={'p-4 rounded-md bg-gray-50 hover:bg-indigo-50 mr-6 cursor-pointer'}
            to={`/meetings/types/${type.uuid}`}
          >
            <ArrowLeftIcon className={'w-5 h-5'} />
          </NavLink>
          <div>
            <h3 className="text-base font-semibold leading-7 text-gray-900">{type.name} tasks</h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              All AI tasks related to the selected meeting type
            </p>
          </div>
        </div>
      </div>
      <div className={'mt-8 relative flex flex-row justify-start'}>
        <div className={'w-64 space-y-4 mb-32'}>
          <div>
            <div>
              {tasks.length === 0 && <div className={'text-sm text-gray-500 py-4'}>There are no tasks yet.</div>}
              {tasks.length > 0 && (
                <ul>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {tasks.map((task, index) => (
                            <Draggable key={task.uuid} draggableId={task.uuid} index={index}>
                              {(provided, snapshot) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={'w-full mb-2'}
                                >
                                  <NavLink
                                    to={`/meetings/types/${type.uuid}/tasks/${task.uuid}`}
                                    className={'block text-sm'}
                                  >
                                    {({ isActive }) => {
                                      return (
                                        <div
                                          className={classNames(
                                            'rounded-md w-full p-2 flex justify-start items-center hover:bg-indigo-50 transition-all',
                                            isActive ? 'bg-indigo-50' : 'bg-gray-50',
                                          )}
                                        >
                                          <Bars3Icon className={'w-4 h-4 mr-2 text-gray-300 cursor-grab'} />
                                          <div className={'flex w-full flex-row justify-between items-center'}>
                                            {task.name}
                                            <Menu as="div" className="relative inline-block text-left">
                                              <Menu.Button className="flex items-center text-gray-400 hover:bg-indigo-100 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
                                                <span className="sr-only">Open options</span>
                                                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                              </Menu.Button>

                                              <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                              >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                  <div className="py-1">
                                                    <Menu.Item>
                                                      {({ focus }) => (
                                                        <button
                                                          onClick={(event) => {
                                                            openDuplicateFormProcess(event, task.uuid)
                                                          }}
                                                          className={classNames(
                                                            focus ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                            'flex w-full text-left justify-between px-4 py-2 text-sm',
                                                          )}
                                                        >
                                                          <span>Duplicate</span>
                                                        </button>
                                                      )}
                                                    </Menu.Item>
                                                  </div>
                                                </Menu.Items>
                                              </Transition>
                                            </Menu>
                                          </div>
                                        </div>
                                      )
                                    }}
                                  </NavLink>
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </ul>
              )}
            </div>
          </div>
          {!openNewTaskForm && (
            <div>
              <button
                type={'button'}
                onClick={() => {
                  setOpenNewTaskForm(true)
                }}
                className="relative w-full flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <PlusIcon className={'-ml-0.5 h-5 w-5'} /> Create new task
              </button>
            </div>
          )}
          {openNewTaskForm && (
            <div>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setOpenNewTaskForm(false)
                }}
              >
                <form onSubmit={createNewTaskProcess} className={'relative'}>
                  <input
                    type={'text'}
                    placeholder={'Task name'}
                    autoFocus={true}
                    onChange={changeNameProcess}
                    value={formData.name}
                    className={'w-full rounded-md border-0 ring-1 text-sm text-gray-700'}
                    name={'task-name'}
                  />
                  <button
                    className={
                      'w-9 h-9 flex text-center items-center content-center justify-center rounded-md bg-gray-50 hover:bg-indigo-50 transition-all cursor-pointer absolute top-0 right-0'
                    }
                  >
                    <CheckIcon className={'w-4 h-4 text-gray-700'} />
                  </button>
                </form>
              </OutsideClickHandler>
            </div>
          )}
        </div>
        <div className={'w-full'}>
          <Outlet />
        </div>
      </div>
      <DuplicateMeetingTypeTask />
    </div>
  )
}
