import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../../api/workspaceAgent'

export const updateGroupMemberRole = createAsyncThunk(
  'workspaces/groups/member/update-role',
  async ({ groupUuid, memberUuid, isManager }, thunkApi) => {
    try {
      const member = await WorkspaceAgent.updateGroupMemberRole(groupUuid, memberUuid, isManager)

      return { memberUuid, isManager }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)

export const addGroupMember = createAsyncThunk(
  'workspaces/groups/member/add',
  async ({ groupUuid, workspaceUserUuid }, thunkApi) => {
    try {
      const member = await WorkspaceAgent.addGroupMember(groupUuid, workspaceUserUuid)

      return { member }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)

export const removeGroupMember = createAsyncThunk(
  'workspaces/groups/member/remove',
  async ({ groupUuid, memberUuid }, thunkApi) => {
    try {
      await WorkspaceAgent.removeGroupMember(groupUuid, memberUuid)

      return { memberUuid }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)
