import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../api/workspaceAgent'

export const checkInvitation = createAsyncThunk('workspaces/users/invitations/check', async ({ token }, thunkApi) => {
  try {
    const data = await WorkspaceAgent.checkInvitation(token)

    return { data }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})

export const acceptWithSignUp = createAsyncThunk(
  'workspaces/invitations/accept-with-sign-up',
  async ({ token, fullName, password }, thunkApi) => {
    try {
      const response = await WorkspaceAgent.acceptInvitationWithSignUp(token, fullName, password)

      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)

export const acceptWithSignIn = createAsyncThunk(
  'workspaces/invitations/accept-with-sign-in',
  async ({ token, email, password }, thunkApi) => {
    try {
      const response = await WorkspaceAgent.acceptInvitationWithSignIn(token, email, password)

      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)
