import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../../../api/workspaceAgent'

export const getSubscription = createAsyncThunk('workspaces/get-subscription', async ({}, thunkApi) => {
  try {
    const response = await WorkspaceAgent.getSubscription()

    return response
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
