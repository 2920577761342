import React, { Fragment, memo, useEffect, useState } from 'react'
import { UsersIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/20/solid'
import { changeShowGroupsSidebar, changeShowNavigationSidebar, selectShowGroupsSidebar } from '../../reducers/common'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import {
  changeRequestStatus,
  groupsUnloaded,
  selectAllGroups,
  selectRequestStatus,
} from '../../reducers/workspace/group/group-list'
import { REQUEST_STATUSES } from '../../reducers/reducer-helper'
import { getAllGroups } from './slice'
import Loader from '../common/loader/Loader'
import { WORKSPACE_USER_ROLES } from '../../constants'
import { selectWorkspace } from '../../reducers/workspace/workspace'
import {
  selectShowSidebar as selectShowCreateGroupSidebar,
  setSidebarState as setShowCreateGroupSidebar,
} from '../../reducers/workspace/group/create'
import { useParams } from 'react-router'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Groups() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const groups = useSelector(selectAllGroups)
  const workspace = useSelector(selectWorkspace)
  const showGroupsSidebar = useSelector(selectShowGroupsSidebar)
  const showCreateGroupSidebar = useSelector(selectShowCreateGroupSidebar)

  const { groupUuid } = useParams()

  const [filteredGroups, setFilteredGroups] = useState(null)

  useEffect(() => {
    if (showGroupsSidebar) {
      dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      const fetchGroups = dispatch(getAllGroups())

      return () => {
        dispatch(groupsUnloaded())
        fetchGroups.abort()
      }
    }

    return () => {
      dispatch(groupsUnloaded())
    }
  }, [showGroupsSidebar])

  useEffect(() => {
    if (groups) {
      let filtered = [...groups]

      filtered.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))
      setFilteredGroups(filtered)
    }
  }, [groups])

  const changeShowGroupsSidebarProcess = (status) => {
    dispatch(changeShowGroupsSidebar({ showGroupsSidebar: status }))
  }

  const showCreateGroupSidebarProcess = () => {
    dispatch(setShowCreateGroupSidebar({ showSidebar: true }))
  }

  const closeAllPopups = () => {
    dispatch(changeShowGroupsSidebar({ showGroupsSidebar: false }))
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: false }))
  }

  return (
    <Transition.Root show={showGroupsSidebar && !showCreateGroupSidebar}>
      <Dialog as="div" className="relative z-50" onClose={() => changeShowGroupsSidebarProcess(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-80">
                  <div className={'fixed inset-y-0'}>
                    <div
                      className={
                        'bg-white sm:flex sm:flex-col fixed sm:w-80 inset-y-0 overflow-y-auto border-black/10 border-r'
                      }
                    >
                      <header className="pb-4 pt-6 sm:pb-6">
                        <div className="mx-auto flex max-w-full flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                          <div>
                            <h1 className="text-base font-semibold leading-7 text-gray-900">Groups</h1>
                            <p className="text-sm text-gray-700">A list of all groups in your workspace</p>
                          </div>
                        </div>
                      </header>
                      {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
                      {requestStatus !== REQUEST_STATUSES.PENDING && filteredGroups !== null && (
                        <div className="px-4 sm:px-6 lg:px-8 flow-root">
                          <div className="sm:block">
                            <nav className="flex flex-col w-64 gap-y-2" aria-label="Tabs">
                              {workspace.user.role !== WORKSPACE_USER_ROLES.MEMBER && (
                                <button
                                  type="button"
                                  onClick={() => showCreateGroupSidebarProcess()}
                                  className="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                  Create new group
                                </button>
                              )}

                              {filteredGroups.length === 0 && (
                                <div className={'text-sm mt-4 text-gray-500'}>There are no groups found.</div>
                              )}
                              {filteredGroups.length > 0 &&
                                filteredGroups.map((group) => (
                                  <Link
                                    key={group.uuid}
                                    onClick={() => {
                                      closeAllPopups()
                                    }}
                                    to={`/groups/${group.uuid}`}
                                    className={classNames(
                                      group.uuid === groupUuid
                                        ? 'bg-indigo-100 text-indigo-700'
                                        : 'text-gray-500 hover:text-gray-700',
                                      'rounded-md px-2 py-2 text-sm font-medium',
                                    )}
                                    aria-current={group.uuid === groupUuid ? 'page' : undefined}
                                  >
                                    <div className={'flex flex-row items-center justify-between'}>
                                      <span>{group.name}</span>
                                      <div className={'flex flex-row items-center gap-x-2'}>
                                        <span>{group.members.length}</span>
                                        <UsersIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                                      </div>
                                    </div>
                                  </Link>
                                ))}
                            </nav>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default memo(Groups)
