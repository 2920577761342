import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../../reducer-helper'
import { getGroup } from '../../../components/groups/details/slice'
import { updateGroup } from '../../../components/groups/details/settings/slice'
import {
  addGroupMember,
  removeGroupMember,
  updateGroupMemberRole,
} from '../../../components/groups/details/members/slice'

const initialState = {
  data: null,
  error: null,
  status: null,
}
const slice = createSlice({
  name: 'groupDetails',
  initialState,
  extraReducers(builder) {
    builder.addCase(getGroup.fulfilled, (state, action) => {
      state.data = action.payload.group
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(getGroup.rejected, failed)
    builder.addCase(updateGroup.fulfilled, (state, action) => {
      state.data = action.payload.group
    })
    builder.addCase(updateGroupMemberRole.fulfilled, (state, action) => {
      const memberIndex = state.data.members.findIndex((member) => member.uuid === action.payload.memberUuid)
      if (memberIndex !== -1) {
        state.data.members[memberIndex].isManager = action.payload.isManager
      }
    })
    builder.addCase(addGroupMember.fulfilled, (state, action) => {
      state.data.members.push(action.payload.member)
    })
    builder.addCase(removeGroupMember.fulfilled, (state, action) => {
      const deletedMemberIndex = state.data.members.findIndex((member) => member.uuid === action.payload.memberUuid)
      if (deletedMemberIndex !== -1) {
        state.data.members.splice(deletedMemberIndex, 1)
      }
    })
  },
  reducers: {
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    groupUnloaded: () => initialState,
  },
})

const selectSlice = (state) => state.groupDetails
export const selectGroup = (state) => selectSlice(state).data
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, groupUnloaded } = actions

export default reducer
