import { createSlice } from '@reduxjs/toolkit'
import { signIn } from '../../components/user/sign-in/slice'
import { createProfile } from '../../components/user/sign-up/slice'
import { initApplication } from '../../components/app/slice'
import { logout } from '../../components/common/protected/main-layout/slice'
import { acceptInvitation, switchWorkspace } from '../../components/workspace/list/slice'
import {
  addImage,
  removeImage,
  updateWorkspaceGeneralSettings,
} from '../../components/workspace/settings/general/slice'
import { updateWorkspaceSecuritySettings } from '../../components/workspace/settings/security/slice'
import { acceptWithSignIn, acceptWithSignUp } from '../../components/workspace/accept-invitation/slice'
import { deposit } from '../../components/workspace/settings/billing/deposit/slice'
import { createNewWorkspace } from '../../components/workspace/create/slice'
import { upgrade } from '../../components/workspace/upgrade/slice'
import { inviteUsers } from '../../components/users/invite/slice'
import { acceptLegal } from '../../components/legal/accept/slice'
import { rechargeUpdate } from '../../components/workspace/settings/billing/auto-recharge/slice'

const initialState = {
  workspace: null,
  error: null,
  status: null,
}

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  extraReducers(builder) {
    builder.addCase(initApplication.fulfilled, (state, action) => {
      state.workspace = action.payload.workspace
    })
    builder.addCase(acceptLegal.fulfilled, (state, action) => {
      state.workspace = action.payload.workspace
    })
    builder.addCase(updateWorkspaceGeneralSettings.fulfilled, (state, action) => {
      state.workspace.name = action.payload.workspace.name
    })
    builder.addCase(updateWorkspaceSecuritySettings.fulfilled, (state, action) => {
      state.workspace.allowPrivateMeetings = action.payload.workspace.allowPrivateMeetings
    })
    builder.addCase(createProfile.fulfilled, (state, action) => {
      state.workspace = action.payload.workspace
    })
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.workspace = action.payload.workspace
    })
    builder.addCase(acceptWithSignUp.fulfilled, (state, action) => {
      state.workspace = action.payload.workspace
    })
    builder.addCase(acceptWithSignIn.fulfilled, (state, action) => {
      state.workspace = action.payload.workspace
    })
    builder.addCase(switchWorkspace.fulfilled, (state, action) => {
      state.workspace = action.payload.workspace
    })
    builder.addCase(createNewWorkspace.fulfilled, (state, action) => {
      state.workspace = action.payload.workspace
    })
    builder.addCase(upgrade.fulfilled, (state, action) => {
      state.workspace = action.payload.workspace
    })
    builder.addCase(acceptInvitation.fulfilled, (state, action) => {
      state.workspace = action.payload.workspace
    })
    builder.addCase(deposit.fulfilled, (state, action) => {
      state.workspace.balance = parseInt(state.workspace.balance) + parseInt(action.payload.amount)
    })
    builder.addCase(inviteUsers.fulfilled, (state, action) => {
      state.workspace.users.active = state.workspace.users.active + action.payload.invitedUsers.length
    })
    builder.addCase(logout.fulfilled, (state) => {
      state.workspace = null
    })
    builder.addCase(rechargeUpdate.fulfilled, (state, action) => {
      state.workspace.autoRechargeConfig = action.payload.autoRechargeConfig
    })
    builder.addCase(addImage.fulfilled, (state, action) => {
      state.workspace.image = action.payload.image
    })
    builder.addCase(removeImage.fulfilled, (state) => {
      state.workspace.image = null
    })
  },
})

const selectSlice = (state) => state.workspace
export const selectWorkspace = (state) => selectSlice(state).workspace
export const selectError = (state) => selectSlice(state).error

const { reducer } = workspaceSlice

export default reducer
