import { createAsyncThunk } from '@reduxjs/toolkit'
import { BillingAgent } from '../../../../../api/billingAgent'

export const deletePaymentMethod = createAsyncThunk(
  'workspaces/billing/payment-methods/delete',
  async ({ paymentMethodId }, thunkApi) => {
    try {
      await BillingAgent.deletePaymentMethod(paymentMethodId)

      return { paymentMethodId }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)

export const setDefaultPaymentMethod = createAsyncThunk(
  'workspaces/billing/payment-methods/set-default',
  async ({ paymentMethodId }, thunkApi) => {
    try {
      await BillingAgent.setDefaultPaymentMethod(paymentMethodId)

      return { paymentMethodId }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)

export const getPaymentMethods = createAsyncThunk(
  'workspaces/billing/payment-methods/list',
  async (undefined, thunkApi) => {
    try {
      const response = await BillingAgent.getPaymentMethods()

      return { methods: response.paymentMethods, defaultPaymentMethod: response.defaultPaymentMethod }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)
