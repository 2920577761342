import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../api/workspaceAgent'

export const removeUser = createAsyncThunk('workspaces/users/remove', async ({ uuid }, thunkApi) => {
  try {
    await WorkspaceAgent.removeUser(uuid)

    return { uuid }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
