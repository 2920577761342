import { createSlice } from '@reduxjs/toolkit'
import { failed } from '../../../reducer-helper'
import { attachPaymentMethod } from '../../../../components/workspace/settings/billing/payment-methods/add/slice'

const initialState = {
  data: {
    name: '',
    address: {
      country: null,
      addressLine1: '',
      addressLine2: '',
      city: '',
      postalCode: '',
      state: '',
    },
    isDefault: false,
  },
  showPopup: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'addPaymentMethodForm',
  initialState,
  reducers: {
    setShowPopupState(state, action) {
      state.showPopup = action.payload.showPopup
    },
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    changeAddressCountry: (state, action) => {
      state.data.address.country = action.payload.country
    },
    changeAddressLine1: (state, action) => {
      state.data.address.addressLine1 = action.payload.addressLine1
    },
    changeAddressLine2: (state, action) => {
      state.data.address.addressLine2 = action.payload.addressLine2
    },
    changeCity: (state, action) => {
      state.data.address.city = action.payload.city
    },
    changePostalCode: (state, action) => {
      state.data.address.postalCode = action.payload.postalCode
    },
    changeState: (state, action) => {
      state.data.address.state = action.payload.state
    },
    changeIsDefault: (state, action) => {
      state.data.isDefault = action.payload.isDefault
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetForm: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(attachPaymentMethod.fulfilled, () => initialState)
    builder.addCase(attachPaymentMethod.rejected, failed)
  },
})

const selectSlice = (state) => state.addPaymentMethodForm
export const selectData = (state) => selectSlice(state).data
export const selectShowPopup = (state) => selectSlice(state).showPopup
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const {
  changeName,
  changeAddressCountry,
  changeAddressLine1,
  changeAddressLine2,
  changeCity,
  changePostalCode,
  changeState,
  resetForm,
  changeRequestStatus,
  setShowPopupState,
  changeIsDefault,
} = actions

export default reducer
