import { createSlice } from '@reduxjs/toolkit'
import { createNewTask, getAllTasks } from '../../components/meeting/list/types/task/slice'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { renameTask } from '../../components/meeting/list/types/task/details/slice'
import { deleteTask } from '../../components/meeting/list/types/task/confirm-delete-popup/slice'
import { duplicateTask } from '../../components/meeting/list/types/task/duplicate-form-popup/slice'

const initialState = {
  tasks: [],
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'meetingTypeTaskList',
  initialState,
  extraReducers(builder) {
    builder.addCase(getAllTasks.fulfilled, (state, action) => {
      state.tasks = action.payload.tasks
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(getAllTasks.rejected, failed)
    builder.addCase(renameTask.fulfilled, (state, action) => {
      const renamedTaskIndex = state.tasks.findIndex((task) => task.uuid === action.payload.taskUuid)
      if (renamedTaskIndex !== -1) {
        state.tasks[renamedTaskIndex].name = action.payload.name
      }
    })
    builder.addCase(createNewTask.fulfilled, (state, action) => {
      state.tasks.push(action.payload.task)
    })
    builder.addCase(duplicateTask.fulfilled, (state, action) => {
      state.tasks.push(action.payload.task)
    })
    builder.addCase(deleteTask.fulfilled, (state, action) => {
      const deletedTaskIndex = state.tasks.findIndex((task) => task.uuid === action.payload.deletedUuid)
      if (deletedTaskIndex !== -1) {
        state.tasks.splice(deletedTaskIndex, 1)
      }
    })
  },
  reducers: {
    reset: () => initialState,
    reorderingTasks: (state, action) => {
      state.tasks = action.payload.tasks
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.meetingTypeTaskList
export const selectAllTasks = (state) => selectSlice(state).tasks
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, reorderingTasks, reset } = actions

export default reducer
