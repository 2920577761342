import { requests } from './agent'

export const RecordAgent = {
  upload: (file, name, groupUuid, language) => {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('name', name)
    formData.append('language', language)
    if (groupUuid) {
      formData.append('groupUuid', groupUuid)
    }

    return requests.post(`/1_0/records/upload`, formData, { 'Content-Type': 'multipart/form-data' })
  },

  getAllRecords: () => {
    return requests.post('/1_0/records/list', {})
  },

  getRecordDetails: (recordUuid) => {
    return requests.get(`/1_0/records/${recordUuid}`)
  },

  renameRecord: (recordUuid, name) => {
    return requests.post(`/1_0/records/${recordUuid}/rename`, { name })
  },

  newTranscription: (recordUuid, language) => {
    return requests.post(`/1_0/records/${recordUuid}/transcriptions/create`, { language })
  },

  switchTranscription: (recordUuid, transcriptionUuid) => {
    return requests.post(`/1_0/records/${recordUuid}/switch-transcription`, { transcriptionUuid })
  },

  deleteRecord: (recordUuid) => {
    return requests.post(`/1_0/records/${recordUuid}/delete`)
  },

  getSharedRecord: (token) => {
    return requests.get(`/1_0/records/shared/${token}`)
  },

  resetSharedToken: (recordUuid) => {
    return requests.post(`/1_0/records/${recordUuid}/sharing-token/regenerate`, {})
  },

  deleteSharedToken: (recordUuid) => {
    return requests.post(`/1_0/records/${recordUuid}/sharing-token/delete`, {})
  },
}
