import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingAgent } from '../../../api/meetingAgent'

export const createNewMeeting = createAsyncThunk(
  'meeting/create',
  async ({ groupUuid, typeUuid, text, recordUuid, name, description }, thunkApi) => {
    try {
      const meeting = await MeetingAgent.createMeeting(groupUuid, typeUuid, text, name, description, recordUuid)

      return { meeting }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)
