import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { deleteRecord, getAllRecords } from '../../components/records/list/slice'
import { uploadRecord } from '../../components/records/upload-dialog/slice'

const initialState = {
  records: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'recordList',
  initialState,
  extraReducers(builder) {
    builder.addCase(getAllRecords.fulfilled, (state, action) => {
      state.records = action.payload.records
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(getAllRecords.rejected, failed)
    builder.addCase(uploadRecord.fulfilled, (state, action) => {
      if (state.records) {
        state.records.push(action.payload.record)
      }
    })
    builder.addCase(deleteRecord.fulfilled, (state, actions) => {
      if (state.records) {
        const deleteRecordIndex = state.records.findIndex((record) => record.uuid === actions.payload.recordUuid)
        state.records.splice(deleteRecordIndex, 1)
      }
    })
    builder.addCase(deleteRecord.rejected, failed)
  },
  reducers: {
    recordsUnloaded: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.recordList
export const selectAllRecords = (state) => selectSlice(state).records

export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, recordsUnloaded } = actions

export default reducer
