import { createAsyncThunk } from '@reduxjs/toolkit'
import { RecordAgent } from '../../../api/recordAgent'

export const getRecord = createAsyncThunk('record/details', async ({ recordUuid }, thunkApi) => {
  try {
    const data = await RecordAgent.getRecordDetails(recordUuid)

    return { record: data.record, transcription: data.transcription, existingMeetings: data.existingMeetings }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})

export const renameRecord = createAsyncThunk('record/rename', async ({ recordUuid, name }, thunkApi) => {
  try {
    await RecordAgent.renameRecord(recordUuid, name)

    return { name }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})

export const newTranscription = createAsyncThunk(
  'record/new-transcription',
  async ({ recordUuid, language }, thunkApi) => {
    try {
      const data = await RecordAgent.newTranscription(recordUuid, language)

      return { record: data.record, transcription: data.transcription }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)

export const switchTranscription = createAsyncThunk(
  'record/switch-transcription',
  async ({ recordUuid, transcriptionUuid }, thunkApi) => {
    try {
      const data = await RecordAgent.switchTranscription(recordUuid, transcriptionUuid)

      return { record: data.record, transcription: data.transcription }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)
