import { WorkspaceAgent } from '../../../../../api/workspaceAgent'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const createApiKey = createAsyncThunk('workspaces/api-keys/create', async ({ name }, thunkApi) => {
  try {
    const apiKey = await WorkspaceAgent.createApiKey(name)

    return { apiKey }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
