import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { confirmNewEmail, updateProfileInformation } from './slice'
import {
  changeConfirmNewEmailToken,
  changeEmail,
  changeRequestStatus,
  resetChangeEmailRequest,
  selectSettings,
} from '../../../../reducers/user/user-general-settings-form'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import RICIBs from 'react-individual-character-input-boxes'
import { selectUser } from '../../../../reducers/user/user'

const CODE_SIZE = 6

const codeInputProps = {
  className:
    'inline-block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
}

function ConfirmEmailForm() {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(true)

  const formData = useSelector(selectSettings)
  const user = useSelector(selectUser)

  const cancelChanging = () => {
    setOpen(false)
    dispatch(resetChangeEmailRequest())
    dispatch(changeEmail({ email: user.email }))
  }

  const changeTokenProcess = (token) => {
    dispatch(changeConfirmNewEmailToken({ token }))
    if (token.length === CODE_SIZE) {
      dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      dispatch(confirmNewEmail({ token, email: formData.email }))
    }
  }

  const resendToken = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      updateProfileInformation({
        fullName: formData.fullName,
        email: formData.email,
        password: formData.emailConfirmationPassword,
      }),
    )
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Confirm your new email
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          We've just send a unique code tou your new email. Please provide it below.
                        </p>
                      </div>
                    </div>
                  </div>
                  <form className="mt-6 space-y-6">
                    <div>
                      <div className="flex justify-center items-center mt-2">
                        <RICIBs
                          amount={6}
                          autoFocus
                          handleOutputString={changeTokenProcess}
                          inputProps={codeInputProps}
                          inputRegExp={/^[0-9]$/}
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        type="button"
                        onClick={(e) => {
                          resendToken(e)
                        }}
                        className="flex w-full justify-center px-3 py-1.5 text-sm font-semibold leading-6 hover:text-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-300"
                      >
                        Resend code
                      </button>
                    </div>

                    <div>
                      <button
                        type="button"
                        onClick={(e) => {
                          cancelChanging()
                        }}
                        // className="flex  w-full items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 ring-1 ring-inset ring-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        className={`flex w-full text-sm items-center justify-center rounded-md bg-white px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-gray-50 ring-2 ring-indigo-500`}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default memo(ConfirmEmailForm)
