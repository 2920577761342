import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { TranscriptionStatuses } from '../../../constants'
import Loader from '../../common/loader/Loader'
import Transcription from '../../common/transcription/Transcription'
import { RecordAgent } from '../../../api/recordAgent'
import SharedLayout from '../../common/shared-layout/SharedLayout'
import { ReactSVG } from 'react-svg'
import NotFoundIcon from '../../../assets/images/not-found-icon.svg'
import { Link } from 'react-router-dom'

const statusesMessages = {
  [TranscriptionStatuses.READY_FOR_PROCESS]: {
    title: 'This record has been uploaded and will be transcribed shortly.',
    subtitle: 'Please wait, it can takes a few minutes.',
  },
  [TranscriptionStatuses.IN_PROGRESS]: {
    title: 'This record is currently transcribing.',
    subtitle: 'Please wait, it can takes a few minutes.',
  },
  [TranscriptionStatuses.FAILED]: {
    title: 'Transcription failed.',
    subtitle: 'Please contact our support team for help.',
  },
}

export default function ViewSharedRecord() {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [record, setRecord] = useState(null)
  const [transcription, setTranscription] = useState(false)

  const { token } = useParams()

  useEffect(() => {
    RecordAgent.getSharedRecord(token)
      .then((data) => {
        setRecord(data.record)
        setTranscription(data.transcription)
      })
      .catch((err) => {
        if (err.response.data.errorCode === 'RECORD_NOT_FOUND_ERROR') {
          setError({
            code: err.response.data.errorCode,
          })
        }
      })
      .finally(() => {
        setLoaded(true)
      })
  }, [])

  return (
    <SharedLayout>
      {loaded && error && (
        <div className={'max-w-2xl mx-auto text-center pt-16'}>
          {error.code === 'RECORD_NOT_FOUND_ERROR' && (
            <div>
              <div className={'w-full text-center justify-center flex items-center py-8'}>
                <ReactSVG
                  src={NotFoundIcon}
                  beforeInjection={(svg) => {
                    svg.setAttribute('style', 'height: 40px; width: 40px;')
                  }}
                />
              </div>
              <div className={'text-sm text-gray-700'}>
                The shared record could not be located. It may have been removed or the link is incorrect. Please check
                the link one more time.
              </div>
              <div className={'text-sm text-gray-700'}>
                <Link
                  to={'/sign-in'}
                  className={`mx-auto mt-12 w-fit px-8 flex text-sm items-center justify-center rounded-md py-1 text-indigo-600 hover:text-indigo-600 hover:bg-indigo-100 ring-2 ring-indigo-500`}
                >
                  Sign In to Your workspace
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
      {!loaded && <Loader fullSize={true} />}
      {loaded && (
        <div>
          {record && record.uuid && (
            <div className="relative pt-4">
              <div className={'px-20 pt-16 pb-8'}>
                <h3 className="text-2xl font-bold leading-6 text-gray-700">{record.name}</h3>
              </div>
              {record.activeTranscription.status === TranscriptionStatuses.DONE && (
                <div className={'px-16'}>
                  <div className="text-gray-500 text-sm whitespace-pre-line">
                    <Transcription transcription={transcription} />
                  </div>
                </div>
              )}
              {record.activeTranscription.status !== TranscriptionStatuses.DONE && (
                <div className="mt-12 w-full text-center">
                  {(record.activeTranscription.status === TranscriptionStatuses.READY_FOR_PROCESS ||
                    record.activeTranscription.status === TranscriptionStatuses.IN_PROGRESS) && (
                    <div className={'mx-auto w-12 mb-4'}>
                      <Loader size={12} />
                    </div>
                  )}
                  <h3 className="mt-2 text-sm font-semibold text-gray-900">
                    {statusesMessages[record.activeTranscription.status]?.title}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {statusesMessages[record.activeTranscription.status].subtitle}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </SharedLayout>
  )
}
