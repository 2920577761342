import { createSlice } from '@reduxjs/toolkit'
import { failed } from '../reducer-helper'
import { uploadRecord } from '../../components/records/upload-dialog/slice'

const initialState = {
  data: {
    name: '',
    groupUuid: null,
    language: 'english',
  },
  showPopup: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'uploadRecord',
  initialState,
  extraReducers(builder) {
    builder.addCase(uploadRecord.fulfilled, () => initialState)
    builder.addCase(uploadRecord.rejected, failed)
  },
  reducers: {
    setShowPopupState(state, action) {
      state.showPopup = action.payload.showPopup
    },
    changeGroupUuid: (state, action) => {
      state.data.groupUuid = action.payload.groupUuid
    },
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    changeLanguage: (state, action) => {
      state.data.language = action.payload.language
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetForm: () => initialState,
  },
})

const selectSlice = (state) => state.uploadRecord
export const selectFormData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status
export const selectShowPopup = (state) => selectSlice(state).showPopup

const { reducer, actions } = slice
export const { changeGroupUuid, changeRequestStatus, changeName, changeLanguage, resetForm, setShowPopupState } =
  actions

export default reducer
