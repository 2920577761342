import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { RECORD_SOURCE_TYPES, TranscriptionStatuses, TranscriptionStatusesLabels } from '../../../constants'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import Loader from '../../common/loader/Loader'
import { Link } from 'react-router-dom'

const statuses = {
  [TranscriptionStatuses.READY_FOR_PROCESS]:
    'text-yellow-800 bg-yellow-50 ring-yellow-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
  [TranscriptionStatuses.DONE]:
    'text-green-800 bg-green-50 ring-green-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
  [TranscriptionStatuses.FAILED]:
    'text-red-800 bg-red-50 ring-red-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
  [TranscriptionStatuses.IN_PROGRESS]:
    'text-yellow-800 bg-yellow-50 ring-yellow-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
  [TranscriptionStatuses.CLEARED]:
    'text-gray-800 bg-gray-100 ring-gray-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
}

export default function GroupedRecordList({ records }) {
  const [groupedRecords, setGroupedRecords] = useState([])

  useEffect(() => {
    const recordsCopy = records && [...records]
    if (recordsCopy && recordsCopy.length > 0) {
      const groups = recordsCopy.reduce((groups, record) => {
        const date = format(new Date(record.createdTime), 'MMMM do, yyyy')
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(record)

        return groups
      }, {})

      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          records: groups[date],
        }
      })

      setGroupedRecords(groupArrays)
    }
  }, [records])

  return (
    <ul role="list" className="divide-y divide-gray-100">
      {groupedRecords.map((day) => (
        <li key={`day-${day.date}`}>
          <ul role="list" className="divide-y divide-gray-100">
            <li>
              <div className={'flex text-xs px-6 text-gray-400 items-center w-full h-8'}>{day.date}</div>
            </li>
            {day.records.map((record) => (
              <li key={record.uuid} className="w-full">
                <Link to={`/records/${record.uuid}`} className={'relative w-full block'}>
                  <div
                    className={
                      'relative flex items-center justify-between px-4 sm:px-6 lg:px-8 py-5 cursor-pointer hover:bg-gray-50'
                    }
                  >
                    <div className="flex gap-x-4 pr-6 sm:w-1/2 sm:flex-none">
                      <div className="min-w-0 flex-auto">
                        <div className="flex items-center text-sm font-semibold leading-6 text-gray-900">
                          <span className={'pr-2'}>{record.name}</span>
                          {record.activeTranscription.status && (
                            <>
                              <p className={statuses[record.activeTranscription.status]}>
                                {TranscriptionStatusesLabels[record.activeTranscription.status]}
                              </p>
                              {record.activeTranscription.status === TranscriptionStatuses.IN_PROGRESS && (
                                <div className={'ml-2'}>
                                  <Loader />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-between gap-x-4 sm:w-1/2 sm:flex-none">
                      <div className="hidden sm:block">
                        {record.creator && (
                          <>
                            {record.source.type === RECORD_SOURCE_TYPES.ACCOUNT && (
                              <>
                                <p className="text-sm leading-6 text-gray-900">{record.creator.profile.fullName}</p>
                                <p className="mt-1 text-xs leading-5 text-gray-500">{record.creator.email}</p>
                              </>
                            )}
                            {record.source.type === RECORD_SOURCE_TYPES.API_REQUEST && (
                              <>
                                <p className="text-sm leading-6 text-gray-900">API request</p>
                                <p className="mt-1 text-xs leading-5 text-gray-500">
                                  Create by: <span className={'font-semibold'}>{record.creator.apiKey.name}</span>
                                </p>
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}
