import React from 'react'
import { useDispatch } from 'react-redux'
import { changeShowNavigationSidebar } from '../../../reducers/common'
import MainLayout from '../../common/protected/main-layout/MainLayout'
import PrivacyPolicyContent from './PrivacyPolicyContent'
import { Bars3Icon } from '@heroicons/react/24/outline'

export default function PrivacyPolicy() {
  const dispatch = useDispatch()

  const changeShowNavigationSidebarProcess = (status) => {
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: status }))
  }

  return (
    <MainLayout>
      <div className="bg-whiteX max-w-6xl m-auto px-6">
        <div className="pb-4">
          <header>
            <div className="mx-auto h-16 flex flex-wrap items-center sm:flex-nowrap">
              <button
                type="button"
                className="mr-4 p-2.5 text-black lg:hidden"
                onClick={() => changeShowNavigationSidebarProcess(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-5 w-5" aria-hidden="true" />
              </button>
              <h1 className="text-base font-semibold leading-7 text-gray-900">Privacy policy</h1>
            </div>
          </header>
        </div>
        <div className={'pb-32'}>
          <PrivacyPolicyContent />
        </div>
      </div>
    </MainLayout>
  )
}
