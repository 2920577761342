import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeAgent } from '../../../../../api/meetingTypeAgent'

export const createNewMeetingType = createAsyncThunk(
  'meeting-types/create-new-meeting-type',
  async ({ name, description, templateUuid, context, variables }, thunkApi) => {
    try {
      const type = await MeetingTypeAgent.createNewMeetingType(name, description, templateUuid, context, variables)

      return { type }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
