import React from 'react'
import { PlusIcon } from '@heroicons/react/20/solid'
import { InboxIcon } from '@heroicons/react/24/outline'
import { useDispatch } from 'react-redux'
import { setShowPopupState } from '../../../../reducers/meetings/create-meeting-form'

export default function NoMeetingsMessage() {
  const dispatch = useDispatch()

  const showFormHandler = () => {
    dispatch(setShowPopupState({ showPopup: true }))
  }

  return (
    <div className="mt-6 text-center">
      <InboxIcon className="mx-auto w-12 h-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">There are no meetings yet</h3>
      <p className="mt-1 text-sm text-gray-500">Get started by creating a new meeting.</p>
      <div className="mt-6">
        <button
          onClick={showFormHandler}
          className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Create new meeting
        </button>
      </div>
    </div>
  )
}
