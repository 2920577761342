import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../../api/userAgent'

export const acceptLegal = createAsyncThunk('user/account/accept-legal', async (undefined, thunkApi) => {
  try {
    const result = await UserAgent.acceptLegal()

    return result
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
