import React, { useEffect } from 'react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { changeShowNavigationSidebar } from '../../../reducers/common'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loader from '../../common/loader/Loader'
import { selectUser } from '../../../reducers/user/user'
import SingleFormWrapper from '../../common/singl-form-wrapper/SingleFormWrapper'
import PrivacyPolicyContent from '../privacy-policy/PrivacyPolicyContent'
import { acceptLegal } from './slice'

export default function AcceptLegal() {
  const dispatch = useDispatch()

  const user = useSelector(selectUser)
  const requestStatus = REQUEST_STATUSES.DONE

  useEffect(() => {}, [])

  const changeShowNavigationSidebarProcess = (status) => {
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: status }))
  }
  const acceptLegalProcess = () => {
    dispatch(acceptLegal({}))
  }

  return (
    <SingleFormWrapper>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
      {requestStatus === REQUEST_STATUSES.DONE && (
        <div className="bg-whiteX max-w-6xl m-auto">
          <div className="pb-4">
            <header>
              <div className="mx-auto h-16 flex flex-wrap items-center gap-6 sm:flex-nowrap">
                <button
                  type="button"
                  className="mr-4 p-2.5 text-black lg:hidden"
                  onClick={() => changeShowNavigationSidebarProcess(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="h-5 w-5" aria-hidden="true" />
                </button>
                <h1 className="text-base font-semibold leading-7 text-gray-900">Privacy policy & Terms of use</h1>
              </div>
            </header>
          </div>
          <div className={'p-6 ring-1 ring-indigo-100 rounded-md h-96 overflow-y-scroll'}>
            <PrivacyPolicyContent />
          </div>
          <div className="flex flex-row justify-center space-x-4 py-6 mt-5 sm:mt-6">
            <button
              type="submit"
              onClick={acceptLegalProcess}
              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500"
            >
              {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
              Accept
            </button>
          </div>
        </div>
      )}
    </SingleFormWrapper>
  )
}
