import { createSlice } from '@reduxjs/toolkit'
import {
  getTaskDetails,
  renameTask,
  updateDescriptionTask,
} from '../../components/meeting/list/types/task/details/slice'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { updateTaskContent } from '../../components/meeting/list/types/task/constructor/slice'

const initialState = {
  data: {
    uuid: '',
    name: '',
    description: '',
    content: '',
    createdTime: '',
  },
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'meetingTypeTaskDetails',
  initialState,
  extraReducers(builder) {
    builder.addCase(getTaskDetails.fulfilled, (state, action) => {
      state.data = action.payload.task
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(getTaskDetails.rejected, failed)
    builder.addCase(renameTask.fulfilled, (state, action) => {
      state.data.name = action.payload.name
    })
    builder.addCase(updateTaskContent.fulfilled, (state, action) => {
      state.data.content = action.payload.content
    })
    builder.addCase(updateDescriptionTask.fulfilled, (state, action) => {
      state.data.description = action.payload.description
    })
  },
  reducers: {
    reset: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.meetingTypeTaskDetails
export const selectTaskDetails = (state) => selectSlice(state).data
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, reset } = actions

export default reducer
