import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingAgent } from '../../../api/meetingAgent'
import { RecordAgent } from '../../../api/recordAgent'

export const resetShareMeetingToken = createAsyncThunk(
  'share/meetings/reset-link',
  async ({ meetingUuid }, thunkApi) => {
    try {
      const response = await MeetingAgent.resetSharedToken(meetingUuid)

      return { sharedToken: response.sharedToken }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)

export const resetShareRecordToken = createAsyncThunk('share/records/reset-link', async ({ recordUuid }, thunkApi) => {
  try {
    const response = await RecordAgent.resetSharedToken(recordUuid)

    return { sharedToken: response.sharedToken }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})

export const deleteShareMeetingToken = createAsyncThunk(
  'share/meetings/delete-link',
  async ({ meetingUuid }, thunkApi) => {
    try {
      await MeetingAgent.deleteSharedToken(meetingUuid)
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)

export const deleteShareRecordToken = createAsyncThunk(
  'share/records/delete-link',
  async ({ recordUuid }, thunkApi) => {
    try {
      await RecordAgent.deleteSharedToken(recordUuid)
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)
