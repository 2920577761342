import { requests } from './agent'

export const AppAgent = {
  init: () => {
    return requests.get('/1_0/init')
  },

  getDashboardData: () => {
    return requests.get('/1_0/dashboard-data')
  },
}
