import React, { memo, useState } from 'react'
import { Fragment } from 'react'
import { PlusIcon } from '@heroicons/react/20/solid'
const people = [
  {
    name: 'Meeting processed',
    method: 'POST',
    title: 'https://myservice.com/api/1.0/meeting-processed',
    email: '17 Feb 2024',
  },
  { name: 'Meeting processed', method: 'POST', title: 'https://anotherservice.com/new-meeting', email: '17 Feb 2024' },
  // More people...
]

function Webhooks() {
  const [name, setName] = useState('Personal workspace')

  return (
    <>
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <dl className="mt-6 text-sm leading-6">
            <div className={'pt-4 text-gray-700'}>
              Your can use webhooks to receive data after specific event is triggered in our platform.
            </div>
            <div className="mt-8 flow-root">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Event
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          URL
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Created date
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                          <span className="sr-only">Delete</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {people.map((person) => (
                        <tr key={person.email}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-0">
                            {person.name}
                            <span className={'pl-2 text-yellow-600'}>{person.method}</span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{person.title}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{person.email}</td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <a href="#" className="text-indigo-600 hover:text-indigo-900">
                              Delete<span className="sr-only">, {person.name}</span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <button className="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Create webhook
                </button>
              </div>
            </div>

            {name !== 'Personal workspace' && (
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-500 sm:w-64 sm:flex-none sm:pr-6"></dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <button
                    type="button"
                    onClick={() => {
                      console.log('save')
                    }}
                    className={`flex items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  >
                    <span>Save changes</span>
                  </button>
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </>
  )
}

export default memo(Webhooks)
