import { createAsyncThunk } from '@reduxjs/toolkit'
import { BillingAgent } from '../../../../../api/billingAgent'

export const payLastInvoice = createAsyncThunk(
  'workspaces/billing/pay-last-invoice',
  async ({ paymentMethodId }, thunkApi) => {
    try {
      const response = await BillingAgent.payLastInvoice(paymentMethodId)

      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)

export const getLastInvoice = createAsyncThunk('workspaces/billing/get-last-invoice', async (undefined, thunkApi) => {
  try {
    const invoice = await BillingAgent.getLastInvoice()

    return { invoice }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
