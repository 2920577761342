import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../../reducer-helper'
import { cancelSubscription } from '../../../components/workspace/settings/billing/cancel-subscription/slice'

const initialState = {
  showPopup: false,
  error: null,
  status: REQUEST_STATUSES.DONE,
}

const slice = createSlice({
  name: 'cancelSubscriptionForm',
  initialState,
  reducers: {
    setShowPopupState(state, action) {
      state.showPopup = action.payload.showPopup
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetForm: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(cancelSubscription.fulfilled, () => initialState)
    builder.addCase(cancelSubscription.rejected, failed)
  },
})

const selectSlice = (state) => state.cancelSubscriptionForm
export const selectShowPopup = (state) => selectSlice(state).showPopup
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { resetForm, changeRequestStatus, setShowPopupState } = actions

export default reducer
