export function orderItems(items, orderingMap, fieldName = 'uuid') {
  const itemsCopy = [...items]
  const orderMap = new Map()
  orderingMap.forEach((uuid, index) => {
    orderMap[uuid] = index
  })

  itemsCopy.sort((a, b) => {
    const indexA = orderMap[a[fieldName]] !== undefined ? orderMap[a[fieldName]] : -1
    const indexB = orderMap[b[fieldName]] !== undefined ? orderMap[b[fieldName]] : -1

    return indexA - indexB
  })

  return itemsCopy
}
