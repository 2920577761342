import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeTaskAgent } from '../../../../../../api/meetingTypeTaskAgent'

export const duplicateTask = createAsyncThunk(
  'meeting-types-task/duplicate-task',
  async ({ taskUuid, typeUuid, name }, thunkApi) => {
    try {
      const task = await MeetingTypeTaskAgent.duplicateTask(taskUuid, typeUuid, name)

      return { task }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
