import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { MEETING_SOURCE_TYPES, MeetingStatuses, MeetingStatusesLabels } from '../../../../constants'
import { ChevronRightIcon } from '@heroicons/react/16/solid'
import Loader from '../../../common/loader/Loader'
import { MeetingStatusClasses } from '../../statuses'

export default function GroupedMeetingList({ meetings }) {
  const [groupedMeetings, setGroupedMeetings] = useState([])

  useEffect(() => {
    const meetingsCopy = meetings && [...meetings]
    if (meetingsCopy && meetingsCopy.length > 0) {
      const groups = meetingsCopy.reduce((groups, meeting) => {
        const date = format(new Date(meeting.createdTime), 'MMMM do, yyyy')
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(meeting)

        return groups
      }, {})

      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          meetings: groups[date],
        }
      })

      setGroupedMeetings(groupArrays)
    }
  }, [meetings])

  return (
    <ul role="list">
      {groupedMeetings.map((day, index) => (
        <li key={`day-${day.date}`}>
          <ul role="list">
            <li className={'pt-4'}>
              <div className={'flex text-xs font-semibold px-6 text-gray-400 items-center w-full h-8'}>{day.date}</div>
            </li>
            {day.meetings.map((meeting) => (
              <li key={meeting.uuid} className="w-full">
                <Link to={`/meetings/${meeting.uuid}`} className={'relative w-full block'}>
                  <div
                    className={
                      'relative flex rounded-md justify-between px-4 sm:px-6 lg:px-8 py-5 cursor-pointer hover:bg-gray-50'
                    }
                  >
                    <div className="flex gap-x-4 pr-6 sm:w-1/2 sm:flex-none">
                      <div className="min-w-0 flex-auto">
                        <div className="flex items-center text-sm font-semibold leading-6 text-gray-900">
                          <span className={'pr-2'}>{meeting.name}</span>
                          <p className={MeetingStatusClasses[meeting.status]}>
                            {MeetingStatusesLabels[meeting.status]}
                          </p>
                          {(meeting.status === MeetingStatuses.AI_PROCESSING ||
                            meeting.status === MeetingStatuses.WAITING_FOR_TRANSCRIPTION) && (
                            <div className={'ml-2'}>
                              <Loader />
                            </div>
                          )}
                        </div>
                        <p className="mt-1 flex text-xs leading-5 text-gray-500">
                          {meeting.type && meeting.type.name}
                          {meeting.typeV2 && meeting.typeV2.name}
                          {!meeting.type && !meeting.typeV2 && 'Type is not defined'}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between gap-x-4 sm:w-1/2 sm:flex-none">
                      <div className="hidden sm:block">
                        {meeting.source.type === MEETING_SOURCE_TYPES.ACCOUNT && (
                          <>
                            <p className="text-sm leading-6 text-gray-900">{meeting.creator.profile.fullName}</p>
                            <p className="mt-1 text-xs leading-5 text-gray-500">{meeting.creator.email}</p>
                          </>
                        )}
                        {meeting.source.type === MEETING_SOURCE_TYPES.API_REQUEST && (
                          <>
                            <p className="text-sm leading-6 text-gray-900">API request</p>
                            <p className="mt-1 text-xs leading-5 text-gray-500">
                              Create by: <span className={'font-semibold'}>{meeting.creator.apiKey.name}</span>
                            </p>
                          </>
                        )}
                      </div>
                      <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}
