import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../../../api/userAgent'

export const changePassword = createAsyncThunk(
  'user/account/change-password',
  async ({ newPassword, oldPassword }, thunkApi) => {
    try {
      await UserAgent.changePassword(newPassword, oldPassword)

      return { status: 'Ok' }
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)
