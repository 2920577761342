import { CheckIcon } from '@heroicons/react/20/solid'
import MainLayout from '../../common/protected/main-layout/MainLayout'
import React, { useEffect } from 'react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { changeShowNavigationSidebar, redirect } from '../../../reducers/common'
import { useDispatch, useSelector } from 'react-redux'
import { setShowPopupState } from '../../../reducers/workspace/upgrade-form'
import { PRICE, WORKSPACE_PLANS } from '../../../constants'
import { classNames } from '../../../utils/className'
import { downgrade } from './slice'
import { selectWorkspace } from '../../../reducers/workspace/workspace'

const tiers = [
  {
    name: 'Personal',
    id: 'tier-personal',
    href: '#',
    priceMonthly: 'Free',
    description: "The perfect plan if you're just getting started with our product.",
    extraInformation: 'Your workspace will be downgraded to the Personal plan. All workspace users will be removed.',
    featured: false,
  },
  {
    name: 'Team',
    id: 'tier-team',
    href: '#',
    priceMonthly: `$${PRICE.SEAT_PRICE}`,
    description: 'You will have access to all benefits of the Team plan.',
    benefits: [
      'Unlimited Meetings & Viewers',
      'Transcribe in 100+ Languages',
      'Automatic Speaker Recognition',
      'Video/Audio Recording',
      'Records management',
      'Team members management',
      'Groups management',
      'Group notifications system',
    ],
    featured: true,
  },
]

export default function ActivateSubscription() {
  const dispatch = useDispatch()

  const workspace = useSelector(selectWorkspace)

  const showUpgradeForm = () => {
    dispatch(setShowPopupState({ showPopup: true, isForActivate: true }))
  }

  const changeShowNavigationSidebarProcess = (status) => {
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: status }))
  }
  const downgradeProcess = (status) => {
    dispatch(downgrade({}))
  }

  useEffect(() => {
    if (
      workspace.plan === WORKSPACE_PLANS.PERSONAL ||
      (workspace.plan === WORKSPACE_PLANS.TEAM && workspace.stripe.subscriptionId)
    ) {
      dispatch(redirect({ redirectTo: '/dashboard' }))
    }
  }, [])

  return (
    <MainLayout>
      <button
        type="button"
        className="mr-4 p-2.5 text-black lg:hidden"
        onClick={() => changeShowNavigationSidebarProcess(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-5 w-5" aria-hidden="true" />
      </button>
      <div className="bg-white py-12">
        <div className="relative isolate bg-white px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Activate your Team plan subscription
            </h2>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            Your account has deactivated subscription, please activate it or downgrade to Personal plan
          </p>
          <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
            {tiers.map((tier, tierIdx) => (
              <div
                key={tier.id}
                className={classNames(
                  tier.featured ? 'relative bg-gray-900 shadow-2xl' : 'bg-white/60 sm:mx-8 lg:mx-0',
                  tier.featured
                    ? ''
                    : tierIdx === 0
                      ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-tr-none lg:rounded-bl-3xl'
                      : 'sm:rounded-t-none lg:rounded-tr-3xl lg:rounded-bl-none',
                  'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10',
                )}
              >
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.featured ? 'text-indigo-400' : 'text-indigo-600',
                    'text-base font-semibold leading-7',
                  )}
                >
                  {tier.name}
                </h3>
                <p className="mt-4 flex items-baseline gap-x-2">
                  <span
                    className={classNames(
                      tier.featured ? 'text-white' : 'text-gray-900',
                      'text-6xl font-light tracking-tight',
                    )}
                  >
                    {tier.priceMonthly}
                  </span>
                  {tier.id === 'tier-team' && (
                    <span className={classNames(tier.featured ? 'text-gray-400' : 'text-gray-500', 'text-base')}>
                      /seat
                    </span>
                  )}
                </p>
                <p
                  className={classNames(tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-6 text-base leading-7')}
                >
                  {tier.description}
                </p>
                {tier.extraInformation && <div className={'pt-8 text-sm text-red-700'}>{tier.extraInformation}</div>}
                {tier.benefits && (
                  <ul
                    role="list"
                    className={classNames(
                      tier.featured ? 'text-gray-300' : 'text-gray-600',
                      'mt-8 space-y-3 text-sm leading-6 sm:mt-10',
                    )}
                  >
                    {tier.benefits.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className={classNames(
                            tier.featured ? 'text-indigo-400' : 'text-indigo-600',
                            'h-6 w-5 flex-none',
                          )}
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                )}
                {tier.id === 'tier-personal' && (
                  <button
                    type={'button'}
                    onClick={downgradeProcess}
                    className={`w-full px-3.5 mt-8 block text-sm items-center justify-center rounded-md bg-white py-1.5 text-indigo-600 hover:text-indigo-600 hover:bg-gray-50 ring-2 ring-indigo-500`}
                  >
                    Downgrade
                  </button>
                )}
                {tier.id === 'tier-team' && (
                  <button
                    type={'button'}
                    onClick={showUpgradeForm}
                    className="w-full block mt-8 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500"
                  >
                    Activate subscription
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
