import { intervalToDuration } from 'date-fns'
import { WorkspaceStatisticTypes } from '../constants'

export const secondsToDuration = (seconds) => {
  if (seconds === 0) {
    return '00:00:00'
  }

  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

  const zeroPad = (num) => String(num).padStart(2, '0')

  return [
    duration.hours ? duration.hours : '00',
    duration.minutes ? duration.minutes : '00',
    duration.seconds ? duration.seconds : '00',
  ]
    .filter(Boolean)
    .map(zeroPad)
    .join(':')
}

export const chartSeries = (usage, s = WorkspaceStatisticTypes.MEETINGS_PROCESSED, title = 'Meetings') => {
  if (!usage) {
    return [
      {
        name: title,
        data: [],
      },
    ]
  }

  const result = Object.entries(usage).map(([date, stats]) => {
    const t = new Date(date).getTime()
    const stat = Array.isArray(stats) ? stats.find((stat) => stat.type === s) : null
    return [t, stat ? stat.value : 0]
  })

  return [
    {
      name: title,
      data: result,
    },
  ]
}
