import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { getRecord, newTranscription, renameRecord, switchTranscription } from '../../components/records/view/slice'
import { deleteShareRecordToken, resetShareRecordToken } from '../../components/common/share-popup/slice'

const initialState = {
  data: {
    record: null,
    transcription: null,
  },
  error: null,
  status: null,
}
const slice = createSlice({
  name: 'recordDetails',
  initialState,
  extraReducers(builder) {
    builder.addCase(renameRecord.fulfilled, (state, action) => {
      state.data.record.name = action.payload.name
    })
    builder.addCase(resetShareRecordToken.fulfilled, (state, action) => {
      state.data.record.sharingToken = action.payload.sharedToken
    })
    builder.addCase(deleteShareRecordToken.fulfilled, (state, action) => {
      state.data.record.sharingToken = null
    })
    builder.addCase(getRecord.fulfilled, (state, actions) => {
      state.data.record = actions.payload.record
      state.data.record.existingMeetings = actions.payload.existingMeetings

      state.data.transcription = actions.payload.transcription
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(newTranscription.fulfilled, (state, actions) => {
      state.data.record = actions.payload.record
      state.data.record.existingMeetings = actions.payload.existingMeetings
      state.data.transcription = actions.payload.transcription
    })
    builder.addCase(switchTranscription.fulfilled, (state, actions) => {
      state.data.record = actions.payload.record
      state.data.record.existingMeetings = actions.payload.existingMeetings
      state.data.transcription = actions.payload.transcription
    })
    builder.addCase(getRecord.rejected, failed)
  },
  reducers: {
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    recordUnloaded: () => initialState,
  },
})

const selectSlice = (state) => state.recordDetails
export const selectTranscription = (state) => selectSlice(state).data.transcription
export const selectRecord = (state) => selectSlice(state).data.record
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, recordUnloaded } = actions

export default reducer
