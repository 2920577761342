import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Bars3Icon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { changeShowNavigationSidebar } from '../../../reducers/common'
import { useDispatch } from 'react-redux'

function Breadcrumbs({ pages, homeLink = '/dashboard' }) {
  const dispatch = useDispatch()

  const changeShowNavigationSidebarProcess = (status) => {
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: status }))
  }

  return (
    <nav className="flex p-4 pb-4" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li className="flex lg:hidden">
          <button
            type="button"
            className="p-2.flex mr-4 items-center text-black"
            onClick={() => changeShowNavigationSidebarProcess(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-5 w-5" aria-hidden="true" />
          </button>
          <svg
            className="h-full w-6 flex-shrink-0 text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
        </li>
        <li>
          <div>
            <Link to={homeLink} className="text-sm font-normal text-gray-500 hover:text-gray-700">
              Home
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <Link
                to={page.href}
                className="ml-4 text-sm font-normal text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default memo(Breadcrumbs)
