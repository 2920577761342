import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../../../reducer-helper'
import { getInvoices } from '../../../../components/workspace/settings/billing/history/slice'

const initialState = {
  invoices: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    invoicesUnloaded: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      state.invoices = action.payload.invoices
      state.status = REQUEST_STATUSES.DONE
      state.error = null
    })
    builder.addCase(getInvoices.rejected, failed)
  },
})

const selectSlice = (state) => state.invoices
export const selectInvoices = (state) => selectSlice(state).invoices
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { invoicesUnloaded, changeRequestStatus } = actions

export default reducer
