import { createSlice } from '@reduxjs/toolkit'
import { upgrade } from '../../components/workspace/upgrade/slice'
import { failed } from '../reducer-helper'

const initialState = {
  data: {
    paymentMethodId: null,
    isForActivate: false,
  },
  showPopup: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'upgradeForm',
  initialState,
  reducers: {
    setShowPopupState(state, action) {
      state.showPopup = action.payload.showPopup
      if (action.payload.isForActivate) {
        state.data.isForActivate = action.payload.isForActivate
      }
    },
    changePaymentMethod: (state, action) => {
      state.data.paymentMethodId = action.payload.paymentMethodId
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetForm: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(upgrade.fulfilled, () => initialState)
    builder.addCase(upgrade.rejected, failed)
  },
})

const selectSlice = (state) => state.upgradeForm
export const selectData = (state) => selectSlice(state).data
export const selectShowPopup = (state) => selectSlice(state).showPopup
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changePassword, changePaymentMethod, resetForm, changeRequestStatus, setShowPopupState } = actions

export default reducer
