import React, { memo } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import { setSidebarState, selectShowSidebar } from '../../../reducers/workspace/create-form'
import Form from './Form'

function Create() {
  const dispatch = useDispatch()

  const showCreateWorkspaceSidebar = useSelector(selectShowSidebar)

  const hideCreateWorkspaceSidebarProcess = () => {
    dispatch(setSidebarState({ showSidebar: false }))
  }

  return (
    <Transition.Root show={showCreateWorkspaceSidebar} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          hideCreateWorkspaceSidebarProcess()
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <Form />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default memo(Create)
