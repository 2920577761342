import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../api/workspaceAgent'

export const createGroup = createAsyncThunk(
  'workspaces/groups/create',
  async ({ name, openMeetings, members }, thunkApi) => {
    try {
      const group = await WorkspaceAgent.createGroup(name, openMeetings, members)

      return { group }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)
