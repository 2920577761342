import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { STRIPE_SUBSCRIPTION_STATUSES, WORKSPACE_USER_ROLES } from '../../../constants'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { format } from 'date-fns'
import { selectSubscription } from '../../../reducers/workspace/subscription'
import { setShowPopupState as setShowPayForSubscriptionPopupState } from '../../../reducers/workspace/settings/billing/pay-for-subscription-form'
import { setShowPopupState as setShowRevokeCancellationPopupState } from '../../../reducers/workspace/settings/revoke-subscription-cancellation-form'
import { selectWorkspace } from '../../../reducers/workspace/workspace'

function Breadcrumbs({ pages, homeLink = '/dashboard' }) {
  const dispatch = useDispatch()

  const subscription = useSelector(selectSubscription)
  const workspace = useSelector(selectWorkspace)

  const showPayForSubscriptionFormHandler = () => {
    dispatch(setShowPayForSubscriptionPopupState({ showPopup: true }))
  }

  const showRevokeCancellationFormHandler = () => {
    dispatch(setShowRevokeCancellationPopupState({ showPopup: true }))
  }

  return (
    <>
      {(subscription?.status === STRIPE_SUBSCRIPTION_STATUSES.INCOMPLETE ||
        subscription?.status === STRIPE_SUBSCRIPTION_STATUSES.PAST_DUE) &&
        (workspace.user.role === WORKSPACE_USER_ROLES.ADMIN || workspace.user.role === WORKSPACE_USER_ROLES.OWNER) && (
          <div className={'py-4 text-left text-sm'}>
            <div className={'bg-red-100 rounded-md w-full p-6 text-gray-700'}>
              <div className={'mb-4'}>
                <ExclamationTriangleIcon className={'w-12 h-12 text-red-600'} />
              </div>
              <div>
                Your workspace has unfinished payment. Please update the payment method or ensure you have enough funds
                to finish the latest payment.
              </div>
              <div className={'pt-2'}>
                If we can't receive payment within 23 hours, your subscription will be deactivated.
              </div>
              <div className={'flex flex-col md:flex-row pt-8'}>
                <button
                  type={'button'}
                  onClick={showPayForSubscriptionFormHandler}
                  className={`flex text-sm items-center justify-center rounded-md px-3 py-1 text-white bg-indigo-600 hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500`}
                >
                  Pay last invoice now
                </button>
              </div>
            </div>
          </div>
        )}

      {subscription?.cancelAt && (
        <div className={'py-4 pb-8 text-left text-sm'}>
          <div className={'bg-indigo-50 rounded-md w-full p-6 text-gray-700'}>
            <div className={'flex flex-row items-center gap-x-4'}>
              <div>
                <ExclamationTriangleIcon className={'w-12 h-12 text-indigo-600'} />
              </div>
              <div className={'text-2xl font-light'}>Workspace subscription was canceled.</div>
            </div>
            <div className={'text-gray-700 pt-4'}>
              Current workspace will be downgraded to the Personal plan in{' '}
              <span className={'font-semibold'}>{format(new Date(subscription.cancelAt * 1000), 'MMMM do, yyyy')}</span>
            </div>
            <div className={'text-gray-700 pt-1'}>
              All Team plan features will be lost, as well as access to the current workspace for all users except an
              owner.
            </div>
            <div className={'pt-4'}>
              <button
                type="submit"
                onClick={showRevokeCancellationFormHandler}
                className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500"
              >
                Revoke cancellation
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default memo(Breadcrumbs)
