import React, { memo, useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Dialog, Listbox, Transition } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import {
  setShowPopupState,
  selectShowPopup,
  selectRequestStatus,
  selectData,
  changePaymentMethod,
  changeRequestStatus,
  selectError,
} from '../../../../../reducers/workspace/settings/billing/pay-for-subscription-form'
import {
  selectShowPopup as selectShowAddPaymentMethodPopup,
  setShowPopupState as setShowAddPaymentMethodPopupState,
} from '../../../../../reducers/workspace/settings/billing/add-payment-method-form'
import { REQUEST_STATUSES } from '../../../../../reducers/reducer-helper'
import Loader from '../../../../common/loader/Loader'
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../../../../utils/className'
import { payLastInvoice } from './slice'
import { BillingAgent } from '../../../../../api/billingAgent'
import NotificationPopup from '../../../../common/notification-popup/NotificationPopup'
import {
  changeRequestStatus as changePaymentMethodsRequestStatus,
  methodsUnloaded,
  selectDefaultPaymentMethod,
  selectMethods,
  selectRequestStatus as selectPaymentMethodsRequestStatus,
} from '../../../../../reducers/workspace/settings/billing/payment-methods'
import { getPaymentMethods } from '../payment-methods/slice'

function PayForSubscription() {
  const dispatch = useDispatch()

  const showPopup = useSelector(selectShowPopup)
  const showAddPaymentMethodPopup = useSelector(selectShowAddPaymentMethodPopup)
  const requestStatus = useSelector(selectRequestStatus)
  const requestError = useSelector(selectError)
  const formData = useSelector(selectData)
  const paymentMethods = useSelector(selectMethods)
  const defaultPaymentMethod = useSelector(selectDefaultPaymentMethod)
  const paymentMethodRequestStatus = useSelector(selectPaymentMethodsRequestStatus)

  const [loader, setLoader] = useState(false)
  const [lastInvoice, setLastInvoice] = useState(null)
  const [error, setError] = useState(null)

  const closeFormHandler = () => {
    dispatch(setShowPopupState({ showPopup: false }))
  }

  const payProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(payLastInvoice({ paymentMethodId: formData.paymentMethodId }))
  }

  const changePaymentMethodIdProcess = (value) => {
    dispatch(changePaymentMethod({ paymentMethodId: value }))
  }

  const showAddPaymentMethodForm = () => {
    dispatch(setShowAddPaymentMethodPopupState({ showPopup: true }))
  }

  useEffect(() => {
    if (showPopup) {
      setLoader(true)

      dispatch(changePaymentMethodsRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      const fetchMethods = dispatch(getPaymentMethods())

      BillingAgent.getLastInvoice()
        .then((results) => {
          setLastInvoice(results)
        })
        .catch((error) => {
          setError("Can't receive payment methods or last invoice")
        })
        .finally(() => {
          setLoader(false)
        })

      return () => {
        dispatch(methodsUnloaded())
        fetchMethods.abort()
      }
    }
  }, [showPopup])

  useEffect(() => {
    if (requestError) {
      setError('Payment failed. Please make sure that provided payment methods is correct.')
    }
  }, [requestError])

  useEffect(() => {
    if (paymentMethods !== null) {
      const selectedPaymentMethodId = defaultPaymentMethod
        ? defaultPaymentMethod
        : paymentMethods?.length > 0
          ? paymentMethods[0].id
          : null
      dispatch(changePaymentMethod({ paymentMethodId: selectedPaymentMethodId }))
    }
  }, [paymentMethods])

  let selectedMethod
  if (paymentMethods) {
    selectedMethod = formData.paymentMethodId
      ? paymentMethods.find((method) => method.id === formData.paymentMethodId)
      : paymentMethods[0]
  }

  return (
    <Transition.Root show={showPopup && !showAddPaymentMethodPopup} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={closeFormHandler}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg max-w-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full">
                <div className="bg-gray-50 px-6 py-4 rounded-t-lg">
                  <div className="flex items-start justify-between space-x-3">
                    <div className="space-y-1">
                      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                        Pay last invoice
                      </Dialog.Title>
                      <p className="text-sm text-gray-500">
                        Your last invoice payment was failed, please try to pay it one more time.
                      </p>
                    </div>
                    <div className="flex h-7 items-center">
                      <button
                        type="button"
                        className="relative text-gray-400 hover:text-gray-500"
                        onClick={() => closeFormHandler()}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                {!!error && (
                  <div className={'p-6'}>
                    <NotificationPopup message={error} />
                  </div>
                )}
                {(loader || paymentMethodRequestStatus === REQUEST_STATUSES.PENDING) && <Loader fullSize={true} />}
                {!loader &&
                  lastInvoice &&
                  paymentMethodRequestStatus === REQUEST_STATUSES.DONE &&
                  paymentMethods !== null &&
                  selectedMethod !== null && (
                    <>
                      <div className={'text-sm p-6 text-gray-700'}>
                        <div>
                          Last invoice: <span className={'font-semibold'}>#{lastInvoice.number}</span> was failed and
                          must to be paid.
                        </div>
                        <div className={'mt-2 text-right p-4 bg-indigo-50 rounded-md ml-auto'}>
                          <div className={'p-0'}>
                            <span className={'text-4xl font-light'}>${lastInvoice.amountDue / 100}</span>
                          </div>
                          <div className={'text-gray-500'}>Amount to pay</div>
                        </div>
                      </div>
                      {paymentMethods?.length === 0 && (
                        <div className={'px-6 py-12'}>
                          <div className={'text-sm text-center text-gray-700'}>There are no payment methods yet</div>
                          <button
                            onClick={showAddPaymentMethodForm}
                            type={'button'}
                            className="mx-auto mt-4 flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Add payment method
                          </button>
                        </div>
                      )}

                      {paymentMethods?.length > 0 && formData.paymentMethodId !== null && (
                        <form className={'px-6 py-4'} onSubmit={(event) => payProcess(event)}>
                          <div className={'flex flex-col space-y-4'}>
                            <div>
                              <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                                Payment method
                              </label>
                              {paymentMethods.length > 0 && (
                                <Listbox value={formData.paymentMethodId} onChange={changePaymentMethodIdProcess}>
                                  {({ open }) => (
                                    <div className={'mt-2'}>
                                      <Listbox.Label className="sr-only">Change payment method</Listbox.Label>
                                      <div className="relative">
                                        <div className="inline-flex w-full divide-x divide-gray-50 rounded-md shadow-sm">
                                          <Listbox.Button
                                            className={
                                              'flex flex-row justify-between w-full py-2 px-4 items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500'
                                            }
                                          >
                                            <div className={'flex flex-col w-full text-xs text-gray-700 text-left'}>
                                              <div>•••• {selectedMethod?.card.last4}</div>
                                              <div className={'text-gray-400 pt-1'}>
                                                Expires {selectedMethod?.card.expMonth}/{selectedMethod?.card.expYear}
                                              </div>
                                            </div>
                                            <ChevronDownIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
                                          </Listbox.Button>
                                        </div>

                                        <Transition
                                          show={open}
                                          as={Fragment}
                                          leave="transition ease-in duration-100"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <Listbox.Options className="absolute right-0 w-full z-50 mt-2 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {paymentMethods.map((method) => (
                                              <Listbox.Option
                                                key={method.id}
                                                className={({ active }) =>
                                                  classNames(
                                                    active ? 'bg-gray-50 cursor-pointer' : 'text-gray-900',
                                                    'cursor-default select-none p-4 text-sm',
                                                  )
                                                }
                                                value={method.id}
                                              >
                                                {({ selected, active }) => (
                                                  <div className="flex flex-col">
                                                    <div className="flex justify-between items-center">
                                                      <div
                                                        className={
                                                          'flex flex-col w-full text-xs text-gray-700 text-left'
                                                        }
                                                      >
                                                        <div>•••• {method.card.last4}</div>
                                                        <div className={'text-gray-400 pt-1'}>
                                                          Expires {method.card.expMonth}/{method.card.expYear}
                                                        </div>
                                                      </div>
                                                      {selected ? (
                                                        <span className={'text-indigo-600'}>
                                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                )}
                                              </Listbox.Option>
                                            ))}
                                          </Listbox.Options>
                                        </Transition>
                                      </div>
                                    </div>
                                  )}
                                </Listbox>
                              )}

                              <div className={'flex justify-end pt-2'}>
                                <button
                                  onClick={showAddPaymentMethodForm}
                                  type={'button'}
                                  className={'flex flex-row items-center py-1 text-gray-700 hover:text-indigo-500'}
                                >
                                  <PlusIcon className={'w-4 h-4'} />{' '}
                                  <p className={'text-xs font-semibold pl-1'}>Add payment method</p>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row justify-center space-x-4 py-6 mt-5 sm:mt-6">
                            <button
                              type={'button'}
                              onClick={(event) => {
                                event.preventDefault()
                                closeFormHandler()
                              }}
                              className={`ml-auto flex text-sm items-center justify-center rounded-md bg-white px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-gray-50 ring-2 ring-indigo-500`}
                            >
                              Cancel
                            </button>

                            <button
                              type="submit"
                              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500"
                            >
                              {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
                              Pay invoice
                            </button>
                          </div>
                        </form>
                      )}
                    </>
                  )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default memo(PayForSubscription)
