import { createAsyncThunk } from '@reduxjs/toolkit'
import { BillingAgent } from '../../../../../api/billingAgent'

export const rechargeUpdate = createAsyncThunk(
  'workspaces/billing/recharge-update',
  async ({ enabled, amountAt, amountTo }, thunkApi) => {
    try {
      return await BillingAgent.rechargeUpdate(enabled, amountAt, amountTo)
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)
