import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../../../reducer-helper'
import { attachPaymentMethod } from '../../../../components/workspace/settings/billing/payment-methods/add/slice'
import {
  deletePaymentMethod,
  getPaymentMethods,
  setDefaultPaymentMethod,
} from '../../../../components/workspace/settings/billing/payment-methods/slice'

const initialState = {
  methods: null,
  defaultPaymentMethod: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    methodsUnloaded: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
      state.methods = action.payload.methods
      state.defaultPaymentMethod = action.payload.defaultPaymentMethod
      state.status = REQUEST_STATUSES.DONE
      state.error = null
    })
    builder.addCase(attachPaymentMethod.fulfilled, (state, action) => {
      if (action.payload.isDefault) {
        const defaultMethodIndex = state.methods.findIndex((method) => method.isDefault)
        if (defaultMethodIndex !== -1) {
          state.methods[defaultMethodIndex].isDefault = false
        }
        state.defaultPaymentMethod = action.payload.paymentMethod.id
      }

      state.methods.unshift(action.payload.paymentMethod)
    })
    builder.addCase(deletePaymentMethod.fulfilled, (state, action) => {
      const deleteMethodId = state.methods.findIndex((method) => method.id === action.payload.paymentMethodId)
      if (deleteMethodId !== -1) {
        state.methods.splice(deleteMethodId, 1)
      }
    })
    builder.addCase(setDefaultPaymentMethod.fulfilled, (state, action) => {
      const currentDefaultMethodId = state.methods.findIndex((method) => method.isDefault)
      if (currentDefaultMethodId !== -1) {
        state.methods[currentDefaultMethodId].isDefault = false
      }
      const newDefaultMethodId = state.methods.findIndex((method) => method.id === action.payload.paymentMethodId)
      if (newDefaultMethodId !== -1) {
        state.methods[newDefaultMethodId].isDefault = true
      }
      state.defaultPaymentMethod = action.payload.paymentMethodId
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(getPaymentMethods.rejected, failed)
  },
})

const selectSlice = (state) => state.paymentMethods
export const selectMethods = (state) => selectSlice(state).methods
export const selectDefaultPaymentMethod = (state) => selectSlice(state).defaultPaymentMethod
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { methodsUnloaded, changeRequestStatus } = actions

export default reducer
