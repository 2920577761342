import { createSlice } from '@reduxjs/toolkit'
import { REQUEST_STATUSES } from '../reducer-helper'
import { getAllTemplates } from '../../components/meeting/list/types/slice'

const initialState = {
  templates: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'templateList',
  initialState,
  extraReducers(builder) {
    builder.addCase(getAllTemplates.fulfilled, (state, action) => {
      state.templates = action.payload.templates
      state.status = REQUEST_STATUSES.DONE
    })
  },
  reducers: {
    templatesUnloaded: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.templateList
export const selectAllTemplates = (state) => selectSlice(state).templates
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, templatesUnloaded } = actions

export default reducer
