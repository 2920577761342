import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../reducers/user/user'
import { useNavigate } from 'react-router'
import { selectWorkspace } from '../../reducers/workspace/workspace'

const ProtectedRoute = ({ children, workspacesRequired = true }) => {
  const [authChecked, setAuthChecked] = useState(false)
  const user = useSelector(selectUser)
  const workspace = useSelector(selectWorkspace)
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) {
      navigate('/sign-in')
    } else if (workspacesRequired && !workspace) {
      navigate('/workspaces')
    }

    setAuthChecked(true)
  }, [])

  useEffect(() => {
    if (!user) {
      navigate('/sign-in')
    } else if (workspacesRequired && !workspace) {
      navigate('/workspaces')
    }
  }, [user])

  return authChecked ? <>{user && children}</> : <span>Checking</span>
}

export default ProtectedRoute
