import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../../../api/workspaceAgent'

export const deleteApiKey = createAsyncThunk('workspaces/api-keys/delete', async ({ apiKeyUuid }, thunkApi) => {
  try {
    await WorkspaceAgent.deleteApiKey(apiKeyUuid)

    return { apiKeyUuid }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
