import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../api/workspaceAgent'

export const getAllUsers = createAsyncThunk('workspaces/users/list', async (undefined, thunkApi) => {
  try {
    const response = await WorkspaceAgent.getAllUsers()

    return { users: response.users, availableSeats: response.availableSeats }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})

export const updateRole = createAsyncThunk('workspaces/users/update-role', async ({ uuid, role }, thunkApi) => {
  try {
    await WorkspaceAgent.updateRole(uuid, role)

    return { uuid, role }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
