import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeTaskAgent } from '../../../../../api/meetingTypeTaskAgent'
import { orderItems } from '../../../../../utils/orderItems'

export const createNewTask = createAsyncThunk(
  'meeting-type-tasks/create-new-task',
  async ({ typeUuid, name }, thunkApi) => {
    try {
      const { task, type } = await MeetingTypeTaskAgent.createNewTask(typeUuid, name)

      return { task, type }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const getAllTasks = createAsyncThunk(
  'meeting-type-tasks/get-all-tasks',
  async ({ typeUuid, orderingMap }, thunkApi) => {
    try {
      const tasks = await MeetingTypeTaskAgent.getMeetingTypeTasks(typeUuid)

      let orderedTasks = tasks
      if (orderingMap) {
        orderedTasks = orderItems(tasks, orderingMap)
      }

      return { tasks: orderedTasks }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const reorderTask = createAsyncThunk(
  'meeting-type-tasks/reorder',
  async ({ typeUuid, newOrdering }, thunkApi) => {
    try {
      const tasks = await MeetingTypeTaskAgent.reorderTask(typeUuid, newOrdering)

      return { tasks }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
