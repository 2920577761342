import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { MeetingStatuses } from '../../../constants'
import Loader from '../../common/loader/Loader'
import SharedLayout from '../../common/shared-layout/SharedLayout'
import { MeetingAgent } from '../../../api/meetingAgent'
import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline'
import { classNames } from '../../../utils/className'
import { Tooltip } from 'react-tooltip'
import TranscriptionDialog from '../../common/transcription/TranscriptionDialog'
import NotFoundIcon from '../../../assets/images/not-found-icon.svg'
import { ReactSVG } from 'react-svg'
import { Link } from 'react-router-dom'
import MeetingDetailsV2 from '../../meeting/view/v2/MeetingDetailsV2'
import MeetingDetailsV1 from '../../meeting/view/v1/MeetingDetails'

export default function ViewSharedMeeting() {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [meeting, setMeeting] = useState(null)
  const [transcription, setTranscription] = useState(false)
  const [showTranscription, setShowTranscription] = useState(false)

  const { token } = useParams()

  useEffect(() => {
    MeetingAgent.getSharedMeeting(token)
      .then((data) => {
        setMeeting(data.meeting)
        setTranscription(data.transcription)
      })
      .catch((err) => {
        if (err.response.data.errorCode === 'MEETING_NOT_FOUND_ERROR') {
          setError({
            code: err.response.data.errorCode,
          })
        }
      })
      .finally(() => {
        setLoaded(true)
      })
  }, [])

  return (
    <SharedLayout>
      {loaded && error && (
        <div className={'max-w-2xl mx-auto text-center pt-16'}>
          {error.code === 'MEETING_NOT_FOUND_ERROR' && (
            <div>
              <div className={'w-full text-center justify-center flex items-center py-8'}>
                <ReactSVG
                  src={NotFoundIcon}
                  beforeInjection={(svg) => {
                    svg.setAttribute('style', 'height: 40px; width: 40px;')
                  }}
                />
              </div>
              <div className={'text-sm text-gray-700'}>
                The shared meeting analysis could not be located. It may have been removed or the link is incorrect.
                Please check the link one more time.
              </div>
              <div className={'text-sm text-gray-700'}>
                <Link
                  to={'/sign-in'}
                  className={`mx-auto mt-12 w-fit px-8 flex text-sm items-center justify-center rounded-md py-1 text-indigo-600 hover:text-indigo-600 hover:bg-indigo-100 ring-2 ring-indigo-500`}
                >
                  Sign In to Your workspace
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
      {!loaded && <Loader fullSize={true} />}
      {loaded && !error && (
        <div>
          {meeting && meeting.uuid && (
            <div className="relative pt-4">
              <div className={'px-20 pt-16 pb-2 flex flex-row items-center justify-between'}>
                <h3 className="text-2xl font-bold leading-6 text-gray-700">{meeting.name}</h3>
                {meeting.status === MeetingStatuses.AI_PROCESSING ||
                  (meeting.status === MeetingStatuses.READY && (
                    <button
                      onClick={() => setShowTranscription(true)}
                      className={
                        'record-tooltip-el p-2 flex flex-row items-center text-gray-500 transition-colors duration-200 hover:text-indigo-400 hover:bg-gray-50 rounded-md'
                      }
                    >
                      <ChatBubbleLeftIcon className={classNames(`h-5 w-5`)} />
                      <Tooltip anchorSelect=".record-tooltip-el" place="bottom">
                        Record & transcription
                      </Tooltip>
                    </button>
                  ))}
              </div>
              <div className="px-20 pt-4 lg:flex lg:gap-x-16">
                {meeting && meeting.type && <MeetingDetailsV1 meeting={meeting} />}
                {meeting && meeting.typeV2 && <MeetingDetailsV2 meeting={meeting} />}
              </div>
            </div>
          )}
          <TranscriptionDialog
            showTransaction={showTranscription}
            setShowTransaction={setShowTranscription}
            transcription={transcription}
          />
        </div>
      )}
    </SharedLayout>
  )
}
