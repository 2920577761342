import React, { memo } from 'react'
import { Outlet } from 'react-router-dom'
import { FingerPrintIcon, HomeModernIcon, KeyIcon, WalletIcon } from '@heroicons/react/24/solid'
import Sidebar from '../../common/sidebar/Sidebar'
import MainLayout from '../../common/protected/main-layout/MainLayout'

const secondaryNavigation = [
  { name: 'General', end: true, href: '/settings', icon: HomeModernIcon },
  { name: 'Security', end: true, href: '/settings/security', icon: FingerPrintIcon },
  { name: 'API keys', end: true, href: '/settings/api-keys', icon: KeyIcon },
  { name: 'Billing & Subscription', end: false, href: '/settings/billing', icon: WalletIcon },
]

function WorkspaceSettings() {
  return (
    <MainLayout>
      <div className="xl:flex lg:gap-x-8 lg:pr-8 lg:pl-4">
        <Sidebar navigation={secondaryNavigation} />
        <main className="py-8 sm:px-6 lg:flex-auto lg:px-0">
          <Outlet />
        </main>
      </div>
    </MainLayout>
  )
}

export default memo(WorkspaceSettings)
