import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeTaskAgent } from '../../../../../../api/meetingTypeTaskAgent'

export const updateTaskContent = createAsyncThunk(
  'meeting-type-tasks/update-content',
  async ({ taskUuid, typeUuid, content }, thunkApi) => {
    try {
      await MeetingTypeTaskAgent.updateTaskContent(taskUuid, typeUuid, content)

      return { content }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
