import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../../reducer-helper'
import { getAllGroups } from '../../../components/groups/slice'
import { createGroup } from '../../../components/groups/create/slice'

const initialState = {
  groups: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'workspaceGroupList',
  initialState,
  extraReducers(builder) {
    builder.addCase(createGroup.fulfilled, (state, action) => {
      state.groups.push(action.payload.group)
    })
    builder.addCase(getAllGroups.fulfilled, (state, action) => {
      state.groups = action.payload.groups
      state.status = REQUEST_STATUSES.DONE
      state.error = null
    })
    builder.addCase(getAllGroups.rejected, failed)
  },
  reducers: {
    groupsUnloaded: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.workspaceGroupList
export const selectAllGroups = (state) => selectSlice(state).groups
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, groupsUnloaded } = actions

export default reducer
