import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../../api/workspaceAgent'

export const getAllApiKeys = createAsyncThunk('workspaces/api-keys/list', async (undefined, thunkApi) => {
  try {
    const apiKeys = await WorkspaceAgent.getAllApiKeys()

    return { apiKeys }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})

export const resetApiKey = createAsyncThunk('workspaces/api-keys/reset', async (undefined, thunkApi) => {
  try {
    const apiKey = await WorkspaceAgent.resetApiKey()

    return { apiKey }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
