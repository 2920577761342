import React, { memo, useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import {
  setShowPopupState,
  selectShowPopup,
  selectRequestStatus,
  changeRequestStatus,
  selectError,
} from '../../../../../reducers/workspace/settings/revoke-subscription-cancellation-form'
import { REQUEST_STATUSES } from '../../../../../reducers/reducer-helper'
import Loader from '../../../../common/loader/Loader'
import { cancelSubscription, revokeCancellation } from './slice'
import NotificationPopup from '../../../../common/notification-popup/NotificationPopup'

function RevokeCancellation() {
  const dispatch = useDispatch()

  const showPopup = useSelector(selectShowPopup)
  const requestStatus = useSelector(selectRequestStatus)
  const requestError = useSelector(selectError)

  const [error, setError] = useState(null)

  const closeFormHandler = () => {
    dispatch(setShowPopupState({ showPopup: false }))
  }

  const revokeCancellationProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(revokeCancellation({}))
  }

  useEffect(() => {
    if (requestError) {
      setError('Revoke subscription cancellation failed.')
    }
  }, [requestError])

  return (
    <Transition.Root show={showPopup} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={closeFormHandler}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg max-w-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full">
                <div className="bg-gray-50 px-6 py-4 rounded-t-lg">
                  <div className="flex items-start justify-between space-x-3">
                    <div className="space-y-1">
                      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                        Revoke subscription cancellation
                      </Dialog.Title>
                      <p className="text-sm text-gray-500">
                        Keep workspace on current plan and revoke subscription cancellation
                      </p>
                    </div>
                    <div className="flex h-7 items-center">
                      <button
                        type="button"
                        className="relative text-gray-400 hover:text-gray-500"
                        onClick={() => closeFormHandler()}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                {!!error && (
                  <div className={'p-6'}>
                    <NotificationPopup message={error} />
                  </div>
                )}
                {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
                {requestStatus === REQUEST_STATUSES.DONE && (
                  <form className={'px-6 py-4'} onSubmit={(event) => revokeCancellationProcess(event)}>
                    <div className={'flex flex-col space-y-4'}>
                      <div className={'text-gray-700 text-sm py-6'}>
                        Please confirm revoke subscription cancellation and keep workspace on the current plan.
                      </div>
                    </div>
                    <div className="flex flex-row justify-center space-x-4 pb-6 mt-5 sm:mt-6">
                      <button
                        type={'button'}
                        onClick={(event) => {
                          event.preventDefault()
                          closeFormHandler()
                        }}
                        className={`ml-auto flex text-sm items-center justify-center rounded-md bg-white px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-gray-50 ring-2 ring-indigo-500`}
                      >
                        Cancel
                      </button>

                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500"
                      >
                        {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
                        Revoke cancellation
                      </button>
                    </div>
                  </form>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default memo(RevokeCancellation)
