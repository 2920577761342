import React, { memo } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { classNames } from '../../../../utils/className'

function Billing() {
  const tabs = [
    { name: 'Overview', href: `/settings/billing` },
    { name: 'Payment methods', href: `/settings/billing/payment-methods` },
    { name: 'Charges & Invoices', href: `/settings/billing/history` },
  ]

  return (
    <div className="bg-whiteX">
      <div>
        <header className="border-b pb-6 border-black/10">
          <div className="mx-auto flex">
            <div className={'flex flex-col'}>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Billing & Subscription</h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">Workspace billing and subscription settings</p>
            </div>
            <div className={'hidden md:block sm:ml-auto'}>
              <div className="sm:block mt-4 sm:mt-0">
                <nav className="flex gap-x-2 flex-col sm:flex-row" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <NavLink key={tab.name} end to={tab.href}>
                      {({ isActive }) => {
                        return (
                          <div
                            className={classNames(
                              isActive ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                              'rounded-md px-3 py-1.5 text-sm font-medium',
                            )}
                          >
                            {tab.name}
                          </div>
                        )
                      }}
                    </NavLink>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className={'md:hidden md:ml-auto'}>
            <div className="md:block mt-4 md:mt-0">
              <nav className="flex gap-x-2 flex-col sm:flex-row" aria-label="Tabs">
                {tabs.map((tab) => (
                  <NavLink key={tab.name} end to={tab.href}>
                    {({ isActive }) => {
                      return (
                        <div
                          className={classNames(
                            isActive ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                            'rounded-md px-3 py-1.5 text-sm font-medium',
                          )}
                        >
                          {tab.name}
                        </div>
                      )
                    }}
                  </NavLink>
                ))}
              </nav>
            </div>
          </div>
        </header>
      </div>
      <div className="w-full">
        <Outlet />
      </div>
    </div>
  )
}

export default memo(Billing)
