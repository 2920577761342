import React, { memo } from 'react'
import { Fragment } from 'react'
import { Listbox, Transition, Switch } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setShowPopupState,
  selectRequestStatus,
  selectData,
  changeName,
  changeAddressLine1,
  changeAddressLine2,
  changeState,
  changeCity,
  changePostalCode,
  changeAddressCountry,
  changeIsDefault,
  changeRequestStatus,
} from '../../../../../../reducers/workspace/settings/billing/add-payment-method-form'
import { REQUEST_STATUSES } from '../../../../../../reducers/reducer-helper'
import Loader from '../../../../../common/loader/Loader'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Countries } from '../../../../../../countries'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { classNames } from '../../../../../../utils/className'
import { PRICE } from '../../../../../../constants'

function AddPaymentMethodForm({ addMethodHandler, cancelHandler, createWorkspaceFlow = false }) {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const formData = useSelector(selectData)
  const elements = useElements()
  const stripe = useStripe()

  const createPaymentMethodProcess = (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))

    const card = elements.getElement('card')

    stripe
      .createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: formData.name,
          address: {
            city: formData.address.city,
            country: formData.address.country,
            state: formData.address.state,
            line2: formData.address.addressLine2,
            line1: formData.address.addressLine1,
            postal_code: formData.address.postalCode,
          },
        },
      })
      .then((res) => {
        if (!res.error) {
          addMethodHandler(res.paymentMethod, formData.isDefault)
        } else {
          dispatch(changeRequestStatus({ status: REQUEST_STATUSES.NOT_TRIGGERED }))
        }
      })
  }

  const changeNameProcess = (event) => {
    dispatch(changeName({ name: event.target.value }))
  }

  const changeAddressLine1Process = (event) => {
    dispatch(changeAddressLine1({ addressLine1: event.target.value }))
  }

  const changeAddressLine2Process = (event) => {
    dispatch(changeAddressLine2({ addressLine2: event.target.value }))
  }

  const changeStateProcess = (event) => {
    dispatch(changeState({ state: event.target.value }))
  }

  const changeCityProcess = (event) => {
    dispatch(changeCity({ city: event.target.value }))
  }

  const changeCountryProcess = (value) => {
    dispatch(changeAddressCountry({ country: value }))
  }

  const changePostalCodeProcess = (event) => {
    dispatch(changePostalCode({ postalCode: event.target.value }))
  }

  const changeIsDefaultProcess = (value) => {
    dispatch(changeIsDefault({ isDefault: value }))
  }

  return (
    <form className={'px-6 py-4'} onSubmit={createPaymentMethodProcess}>
      <div className={'flex flex-col space-y-4'}>
        <fieldset>
          <legend className="block text-sm font-medium leading-6 text-gray-900">Card Details</legend>
          <div className="mt-2 py-2.5 px-3 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
            <CardElement options={{ disableLink: true, hidePostalCode: true }} />
          </div>
        </fieldset>
        <fieldset>
          <legend className="block text-sm font-medium leading-6 text-gray-900">Name on the card</legend>
          <div
            className={
              'mt-2 flex bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600'
            }
          >
            <input
              type="text"
              name="name"
              id="name"
              autoComplete="name"
              required={true}
              value={formData.name}
              onChange={changeNameProcess}
              className={
                'block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
              }
              placeholder="Name on the card"
            />
          </div>
        </fieldset>
        <fieldset>
          <legend className="block text-sm font-medium leading-6 text-gray-900">Billing address</legend>
          <Listbox value={formData.address.country} onChange={changeCountryProcess}>
            {({ open }) => (
              <>
                <div className="relative mt-2">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <span className="block truncate">
                      {formData.address.country
                        ? Countries.find((c) => c.code === formData.address.country).name
                        : 'Country'}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {Countries.map((country) => (
                        <Listbox.Option
                          key={country.code}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                            )
                          }
                          value={country.code}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}
                              >
                                {country.name}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          <div
            className={
              'mt-2 flex bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600'
            }
          >
            <input
              type="text"
              name="addressLine1"
              id="addressLine1"
              required={true}
              autoComplete="addressLine1"
              value={formData.addressLine1}
              onChange={changeAddressLine1Process}
              className={
                'block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
              }
              placeholder="Address line 1"
            />
          </div>
          <div
            className={
              'mt-2 flex bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600'
            }
          >
            <input
              type="text"
              name="addressLine2"
              id="addressLine2"
              autoComplete="addressLine2"
              value={formData.addressLine2}
              onChange={changeAddressLine2Process}
              className={
                'block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
              }
              placeholder="Address line 2"
            />
          </div>
          <div className="flex -space-x-px mt-2">
            <div className="w-1/2 min-w-0 flex-1">
              <label htmlFor="card-expiration-date" className="sr-only">
                City
              </label>
              <input
                type="text"
                value={formData.address.city}
                required={true}
                onChange={changeCityProcess}
                name="city"
                id="city"
                className="relative block w-full rounded-none rounded-l-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="City"
              />
            </div>
            <div className="min-w-0 flex-1">
              <label htmlFor="card-cvc" className="sr-only">
                Postal code
              </label>
              <input
                type="text"
                value={formData.address.postalCode}
                required={true}
                onChange={changePostalCodeProcess}
                name="postalCode"
                id="postalCode"
                className="relative block w-full rounded-none rounded-r-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Postal code"
              />
            </div>
          </div>
          <div
            className={
              'mt-2 flex bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600'
            }
          >
            <input
              type="text"
              name="state"
              id="state"
              required={true}
              autoComplete="state"
              value={formData.address.state}
              onChange={changeStateProcess}
              className={
                'block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
              }
              placeholder="State, county, province, or region"
            />
          </div>
          {!createWorkspaceFlow && (
            <div className={'mt-4 flex bg-white'}>
              <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={formData.isDefault}
                  onChange={changeIsDefaultProcess}
                  className={classNames(
                    formData.isDefault ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      formData.isDefault ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3 text-sm">
                  Is default
                </Switch.Label>
              </Switch.Group>
            </div>
          )}
        </fieldset>
      </div>

      <div className={'border-t border-gray-200 py-4 mt-4'}>
        {createWorkspaceFlow && (
          <p className={'text-xs text-gray-500 text-right'}>
            You will be charged for <span className={'font-semibold text-indigo-600'}>${PRICE.SEAT_PRICE}</span> for one
            seat.
          </p>
        )}
        <div
          className={classNames(
            'flex flex-row space-x-4 mt-5 sm:mt-6',
            createWorkspaceFlow ? 'justify-end' : 'justify-center',
          )}
        >
          <button
            type={'button'}
            onClick={cancelHandler}
            className={`flex text-sm items-center justify-center rounded-md bg-white px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-gray-50 ring-2 ring-indigo-500`}
          >
            {createWorkspaceFlow ? 'Back' : 'Cancel'}
          </button>

          <button
            type="submit"
            className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500"
          >
            {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
            {createWorkspaceFlow ? 'Create workspace' : 'Add payment method'}
          </button>
        </div>
      </div>
    </form>
  )
}

export default memo(AddPaymentMethodForm)
