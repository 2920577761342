import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { getAllTypes } from '../../components/meeting/list/types/list/slice'
import { createNewMeetingType } from '../../components/meeting/list/types/create-form-popup/slice'
import { deleteMeetingType } from '../../components/meeting/list/types/confirm-delete-popup/slice'
import { duplicateMeetingType } from '../../components/meeting/list/types/duplicate-form-popup/slice'

const initialState = {
  types: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'typeList',
  initialState,
  extraReducers(builder) {
    builder.addCase(getAllTypes.fulfilled, (state, action) => {
      state.types = action.payload.types
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(createNewMeetingType.fulfilled, (state, action) => {
      if (state.types) {
        state.types.push(action.payload.type)
      }
    })
    builder.addCase(duplicateMeetingType.fulfilled, (state, action) => {
      if (state.types) {
        state.types.push(action.payload.type)
      }
    })
    builder.addCase(getAllTypes.rejected, failed)
    builder.addCase(deleteMeetingType.fulfilled, (state, actions) => {
      if (state.types) {
        const deletedIndex = state.types.findIndex((type) => type.uuid === actions.payload.deletedUuid)
        state.types.splice(deletedIndex, 1)
      }
    })
  },
  reducers: {
    typesUnloaded: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.typeList
export const selectAllTypes = (state) => selectSlice(state).types
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, typesUnloaded } = actions

export default reducer
