import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../api/workspaceAgent'

export const getGroup = createAsyncThunk('workspaces/groups/details', async ({ uuid }, thunkApi) => {
  try {
    const group = await WorkspaceAgent.getGroupDetails(uuid)

    return { group }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
