import { createSlice } from '@reduxjs/toolkit'
import { createApiKey } from '../../../components/workspace/settings/api-keys/create-popup/slice'

const initialState = {
  data: {
    name: '',
    value: '',
  },
  openForm: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'createApiKey',
  initialState,
  extraReducers(builder) {
    builder.addCase(createApiKey.fulfilled, (state, action) => {
      state.data.value = action.payload.apiKey.fullValue
    })
  },
  reducers: {
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    setOpenForm: (state, action) => {
      state.openForm = action.payload.openForm
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.createApiKey
export const selectFormData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status
export const selectOpenForm = (state) => selectSlice(state).openForm

const { reducer, actions } = slice

export const { changeRequestStatus, resetForm, changeName, setOpenForm } = actions

export default reducer
