import { createSlice } from '@reduxjs/toolkit'
import { failed } from '../../../reducer-helper'
import { deposit } from '../../../../components/workspace/settings/billing/deposit/slice'

const initialState = {
  data: {
    amount: 10,
    paymentMethodId: null,
  },
  showPopup: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'depositForm',
  initialState,
  reducers: {
    setShowPopupState(state, action) {
      state.showPopup = action.payload.showPopup
    },
    changeAmount: (state, action) => {
      state.data.amount = action.payload.amount
    },
    changePaymentMethod: (state, action) => {
      state.data.paymentMethodId = action.payload.paymentMethodId
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetForm: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(deposit.fulfilled, () => initialState)
    builder.addCase(deposit.rejected, failed)
  },
})

const selectSlice = (state) => state.depositForm
export const selectData = (state) => selectSlice(state).data
export const selectShowPopup = (state) => selectSlice(state).showPopup
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeAmount, changePaymentMethod, resetForm, changeRequestStatus, setShowPopupState } = actions

export default reducer
