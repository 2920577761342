import React, { memo, useEffect, useState } from 'react'
import { PlusIcon } from '@heroicons/react/20/solid'
import { setShowPopupState } from '../../../../../reducers/workspace/settings/billing/add-payment-method-form'
import { setShowPopupState as setShowDeletePopupState } from '../../../../../reducers/workspace/settings/billing/delete-payment-method-confirmation'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeRequestStatus,
  methodsUnloaded,
  selectDefaultPaymentMethod,
  selectMethods,
  selectError,
  selectRequestStatus,
} from '../../../../../reducers/workspace/settings/billing/payment-methods'
import { REQUEST_STATUSES } from '../../../../../reducers/reducer-helper'
import Loader from '../../../../common/loader/Loader'
import DeleteConfirmationDialog from './DeleteConfirmationDialog'
import { classNames } from '../../../../../utils/className'
import NotificationPopup from '../../../../common/notification-popup/NotificationPopup'
import { getPaymentMethods, setDefaultPaymentMethod as setDefaultPaymentMethodRequest } from './slice'

function PaymentMethods() {
  const dispatch = useDispatch()

  const paymentMethods = useSelector(selectMethods)
  const defaultPaymentMethod = useSelector(selectDefaultPaymentMethod)
  const paymentMethodRequestStatus = useSelector(selectRequestStatus)
  const requestError = useSelector(selectError)

  const [error, setError] = useState(null)

  const showDeleteMethodConfirmation = (event, paymentMethodId) => {
    event.preventDefault()
    dispatch(setShowDeletePopupState({ paymentMethodId, showPopup: true }))
  }

  const showAddPaymentMethodForm = () => {
    dispatch(setShowPopupState({ showPopup: true }))
  }

  const setDefaultPaymentMethodProcess = (event, paymentMethodId) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(setDefaultPaymentMethodRequest({ paymentMethodId }))
  }

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchMethods = dispatch(getPaymentMethods())

    return () => {
      dispatch(methodsUnloaded())
      fetchMethods.abort()
    }
  }, [])

  useEffect(() => {
    if (requestError) {
      setError('Something went wrong, please try again later.')
    }
  }, [requestError])

  return (
    <div className={'mt-8 px-6 md:px-0'}>
      {!!error && (
        <div className={'p-6'}>
          <NotificationPopup message={error} />
        </div>
      )}
      {paymentMethodRequestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
      {paymentMethodRequestStatus === REQUEST_STATUSES.DONE && (
        <>
          <div className={'mb-8'}>
            <button
              onClick={showAddPaymentMethodForm}
              className="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Add payment method
            </button>
          </div>
          {paymentMethods?.length === 0 && (
            <div className={'text-sm text-gray-700'}>There are no available payment methods</div>
          )}
          {paymentMethods?.length > 0 && (
            <>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                {paymentMethods.map((method) => (
                  <div
                    key={method.id}
                    className={classNames(
                      'relative flex items-center space-x-3 rounded-lg p-6 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400',
                      method.id === defaultPaymentMethod ? 'bg-indigo-50' : 'bg-gray-50',
                    )}
                  >
                    <div className="min-w-0 flex-1">
                      <div className={'flex flex-col w-full text-sm text-gray-700 text-left'}>
                        <div>•••• {method.card.last4}</div>
                        <div className={'text-gray-400 pt-1'}>
                          Expires {method.card.expMonth}/{method.card.expYear}
                        </div>
                        <div className={'flex flex-row gap-x-4 pt-8 text-xs font-semibold'}>
                          {method.id === defaultPaymentMethod && (
                            <p
                              className={`flex text-sm font-normal items-center justify-center rounded-md px-3 py-1 text-gray-500 ring-2 ring-gray-500`}
                            >
                              Is default
                            </p>
                          )}
                          {method.id !== defaultPaymentMethod && (
                            <button
                              className={`flex text-sm font-normal items-center justify-center rounded-md px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-indigo-100 ring-2 ring-indigo-500`}
                              onClick={(event) => setDefaultPaymentMethodProcess(event, method.id)}
                            >
                              Set as default
                            </button>
                          )}
                          <button
                            onClick={(event) => showDeleteMethodConfirmation(event, method.id)}
                            className={'text-red-800 hover:text-red-500'}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <DeleteConfirmationDialog />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default memo(PaymentMethods)
