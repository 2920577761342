import React from 'react'
import { classNames } from '../../../../utils/className'

const ToggleTableView = ({ isTableTemplate, setTableTemplate }) => {
  const handleIconClick = () => {
    setTableTemplate(!isTableTemplate)
  }

  const commonStyles = 'text-gray-700 rounded-md text-sm px-8 py-2 border border-gray-100 font-semibold'
  const activeStyles = 'border-gray-200 bg-white'

  return (
    <div className={'flex flex-row bg-gray-100 w-fit gap-x-2 rounded-md'}>
      <button
        onClick={handleIconClick}
        className={classNames(commonStyles, !isTableTemplate ? activeStyles : undefined)}
      >
        List view
      </button>
      <button
        onClick={handleIconClick}
        className={classNames(commonStyles, isTableTemplate ? activeStyles : undefined)}
      >
        Table view
      </button>
    </div>
  )
}

export default ToggleTableView
