import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeAgent } from '../../../../../api/meetingTypeAgent'

export const getMeetingTypeDetails = createAsyncThunk(
  'meeting-types-v2/get-meeting-type',
  async ({ uuid }, thunkApi) => {
    try {
      const type = await MeetingTypeAgent.getMeetingTypeDetails(uuid)

      return { type }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
