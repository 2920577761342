import { CheckIcon } from '@heroicons/react/20/solid'
import MainLayout from '../../common/protected/main-layout/MainLayout'
import React from 'react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { changeShowNavigationSidebar } from '../../../reducers/common'
import { useDispatch } from 'react-redux'
import { setShowPopupState } from '../../../reducers/workspace/upgrade-form'
import { PRICE } from '../../../constants'

const includedFeatures = [
  'Unlimited Meetings & Viewers',
  'Transcribe in 100+ Languages',
  'Automatic Speaker Recognition',
  'Video/Audio Recording',
  'Records management',
  'Team members management',
  'Groups management',
  'Group notifications system',
]

export default function Upgrade() {
  const dispatch = useDispatch()

  const showUpgradeForm = () => {
    dispatch(setShowPopupState({ showPopup: true }))
  }

  const changeShowNavigationSidebarProcess = (status) => {
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: status }))
  }

  return (
    <MainLayout>
      <button
        type="button"
        className="mr-4 p-2.5 text-black lg:hidden"
        onClick={() => changeShowNavigationSidebarProcess(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-5 w-5" aria-hidden="true" />
      </button>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Do more with <span className={'text-indigo-600'}>Team</span> workspace
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Revolutionize the way your team works by sharing access to meetings and letting them stay connected and
              productive from anywhere.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl bg-gray-50 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">Work together more effectively</h3>
              <p className="mt-6 text-base leading-7 text-gray-600">
                With Team workspace, every meeting and interview your team needs is right at their fingertips, helping
                them work smarter, not harder. Experience the perks of seamless teamwork firsthand!
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
              >
                {includedFeatures.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-white py-10 text-center lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto px-8">
                  <p className="text-base font-semibold text-gray-600">Monthly price is just</p>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-6xl font-light tracking-tight text-gray-900">${PRICE.SEAT_PRICE}</span>
                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">/seat</span>
                  </p>
                  <div className={'bg-indigo-50 rounded-xl'}>
                    <p className="mt-6 pt-4 text-sm leading-6 text-indigo-600 font-semibold">+ Analysis pricing</p>
                    <ul role="list" className="p-4 min-w-80 space-y-4 text-sm leading-6 text-gray-600 ">
                      <li className="flex justify-between w-full">
                        <div>One meeting analysis</div>
                        <div className={'text-right'}>
                          <div className={'font-semibold'}>$0.50/meeting</div>
                          <div className={'text-xs'}>+ GPT costs</div>
                        </div>
                      </li>
                      <li className="flex justify-between w-full">
                        <div>Transcription</div>
                        <div className={'font-semibold'}>$0.60/hour</div>
                      </li>
                    </ul>
                  </div>

                  <button
                    onClick={showUpgradeForm}
                    className="mt-8 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Upgrade plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
