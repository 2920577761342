import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  changeFullName,
  changePassword,
  changeRequestStatus,
  selectError,
  selectRequestStatus,
  selectSignupFormData,
} from '../../../reducers/user/sign-up-form'
import { createProfile } from './slice'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { Link } from 'react-router-dom'
import Loader from '../../common/loader/Loader'
import NotificationPopup from '../../common/notification-popup/NotificationPopup'

function ProfileDataForm() {
  const dispatch = useDispatch()

  const { fullName, password, token } = useSelector(selectSignupFormData)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  const createUserProfile = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(createProfile({ fullName, password, token }))
  }

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeFullName({ fullName: event.target.value }))
  }

  const changePasswordProcess = (event) => {
    event.preventDefault()
    dispatch(changePassword({ password: event.target.value }))
  }

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Create account</h2>
        <div className="mt-4 text-center text-sm text-gray-500">Please provide your profile information</div>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-4" onSubmit={createUserProfile}>
          {error && <NotificationPopup message={error.message} />}
          <div className="relative -space-y-px rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="full-name"
                name="fullName"
                type="text"
                autoComplete="full-name"
                onChange={(e) => {
                  changeNameProcess(e)
                }}
                value={fullName}
                required
                className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Full name"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                onChange={(e) => {
                  changePasswordProcess(e)
                }}
                value={password}
                autoComplete="current-password"
                required
                className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Password"
              />
            </div>
          </div>

          <div>
            <p className="text-center text-sm text-gray-500">
              By clicking you accept our{' '}
              <Link
                to={'https://asistme.ai/privacy-policy/'}
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              >
                Privacy policy
              </Link>{' '}
              and{' '}
              <Link
                to={'https://asistme.ai/terms-of-service/'}
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              >
                Terms of service
              </Link>
            </p>
          </div>

          <div>
            <button
              type="submit"
              disabled={requestStatus === REQUEST_STATUSES.PENDING}
              className="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
              <span>Create account</span>
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default memo(ProfileDataForm)
