'use client'

import React, { memo } from 'react'
import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ArrowPathIcon, CheckIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LinkIcon } from '@heroicons/react/24/solid'
import { APP_URL } from '../../../configs'
import { useDispatch, useSelector } from 'react-redux'
import { reset, selectSharedItemUuid, selectToken, selectType } from '../../../reducers/share-popup'
import { deleteShareMeetingToken, deleteShareRecordToken, resetShareMeetingToken, resetShareRecordToken } from './slice'
import { Tooltip } from 'react-tooltip'

function SharePopup({ open }) {
  const dispatch = useDispatch()

  const [copied, setCopied] = useState(false)

  const type = useSelector(selectType)
  const token = useSelector(selectToken)
  const uuid = useSelector(selectSharedItemUuid)

  const resetToken = () => {
    switch (type) {
      case 'meeting':
        dispatch(resetShareMeetingToken({ meetingUuid: uuid }))
        break

      case 'record':
        dispatch(resetShareRecordToken({ recordUuid: uuid }))
        break
    }

    setCopied(false)
  }

  const deleteToken = (event) => {
    event.stopPropagation()

    switch (type) {
      case 'meeting':
        dispatch(deleteShareMeetingToken({ meetingUuid: uuid }))
        break

      case 'record':
        dispatch(deleteShareRecordToken({ recordUuid: uuid }))
        break
    }

    setCopied(false)
  }

  const closePopup = () => {
    dispatch(reset())
  }

  const copyLink = () => {
    setCopied(true)
    navigator.clipboard.writeText(`${APP_URL}/shared/${type}/${token}`)
  }

  return (
    <Dialog open={open} onClose={closePopup} className="relativex">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in z-30"
      />

      <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  Share {type}
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {token
                      ? 'Use the link below to instantly give access to the processed information.'
                      : `It seems the sharing link was not generated or has been deleted. Please generate a new link to share the ${type === 'meeting' ? 'processing results' : 'record'}.`}
                  </p>
                </div>
                <div className={'my-8'}>
                  {token && (
                    <>
                      <div className={'flex flex-row items-center'}>
                        <div
                          onClick={copyLink}
                          className={
                            'flex flex-row w-full items-center rounded-md px-2 bg-indigo-50 hover:bg-indigo-100 transition-all cursor-pointer'
                          }
                        >
                          <div className={'mr-2'}>
                            <LinkIcon className={'w-4 h-4 text-gray-500'} />
                          </div>
                          <p className={'text-sm text-gray-700 text-ellipsis text-nowrap overflow-hidden w-full'}>
                            {APP_URL}/shared/{type}/{token}
                          </p>
                          <button
                            onClick={deleteToken}
                            className={
                              'delete-link-el p-2 ml-2 cursor-pointer hover:text-gray-700 rounded-md text-gray-400'
                            }
                          >
                            <TrashIcon className={'w-5 h-5'} />
                            <Tooltip anchorSelect=".delete-link-el" place="bottom">
                              Delete link
                            </Tooltip>
                          </button>
                          {copied && <CheckIcon className={'w-5 h-5 text-green-700'} />}
                        </div>
                      </div>
                      <p className={'text-right text-xs text-gray-400 pt-1'}>{copied ? 'Copied' : 'Click to copy'}</p>
                    </>
                  )}
                  {!token && (
                    <div>
                      <button
                        onClick={resetToken}
                        className={`mx-auto flex items-center justify-center rounded-md px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-indigo-100 ring-2 ring-indigo-500`}
                      >
                        <ArrowPathIcon className={'w-4 h-4 mr-2'} />
                        Generate sharing link
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row">
              {token && (
                <button
                  type="button"
                  onClick={resetToken}
                  className="flex flex-row justify-center items-center px-3 py-2 text-sm font-semibold text-indigo-600 hover:bg-gray-50 rounded-md"
                >
                  <ArrowPathIcon className={'w-4 h-4 mr-2'} />
                  Reset link
                </button>
              )}
              <button
                type="button"
                onClick={closePopup}
                className="mt-3 ml-auto inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

export default memo(SharePopup)
