import { jsonrepair } from 'jsonrepair'

export const isJsonString = (str) => {
  try {
    JSON.parse(str)

    return true
  } catch (e) {
    return false
  }
}

export const cleanJsonString = (str) => {
  // Remove extra characters like newlines or extra brackets if necessary
  str = str.trim()

  // Unescape the string by replacing escaped quotes (\" -> ")
  str = str.replace(/\\"/g, '"')

  // Parse the cleaned-up string
  return str
}
