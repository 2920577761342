import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { PlusIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { REQUEST_STATUSES } from '../../../../../reducers/reducer-helper'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeName,
  changeDescription,
  changeContext,
  addVariable,
  deleteVariable,
  changeVariable,
  changeTemplate,
  selectFormData,
  changeRequestStatus,
} from '../../../../../reducers/meeting-type/create-type'
import { createNewMeetingType } from './slice'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { classNames } from '../../../../../utils/className'
import { ChevronDownIcon } from '@heroicons/react/16/solid'
import { selectAllTemplates } from '../../../../../reducers/meeting-template/template-list'

export default function CreateMeetingType({ open, setOpen }) {
  const dispatch = useDispatch()

  const formData = useSelector(selectFormData)
  const templates = useSelector(selectAllTemplates)

  const [formError, setFormError] = useState(null)
  const [selectedTemplate, setSelectedTemplate] = useState(null)

  useEffect(() => {
    if (formData.templateUuid) {
      const templateObj = templates.find((template) => template.uuid === formData.templateUuid)
      setSelectedTemplate(templateObj)
    }
  }, [formData])

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const changeTemplateProcess = (templateUuid) => {
    dispatch(changeTemplate({ templateUuid }))
  }
  const changeDescriptionProcess = (event) => {
    event.preventDefault()
    dispatch(changeDescription({ description: event.target.value }))
  }
  const changeContextProcess = (event) => {
    dispatch(changeContext({ context: event.target.value }))
  }
  const addVariableProcess = (event) => {
    event.preventDefault()
    dispatch(addVariable())
  }
  const deleteVariableProcess = (indexForDelete) => {
    dispatch(deleteVariable({ indexForDelete }))
  }
  const changeVariableNameProcess = (name, index) => {
    dispatch(changeVariable({ index, variable: { name: name.toUpperCase(), value: formData.variables[index].value } }))
  }
  const changeVariableValueProcess = (value, index) => {
    dispatch(changeVariable({ index, variable: { name: formData.variables[index].name, value } }))
  }

  const createMeetingTypeProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      createNewMeetingType({
        templateUuid: formData.templateUuid,
        name: formData.name,
        description: formData.description,
        context: formData.context,
        variables: formData.variables,
      }),
    )
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form
                    onSubmit={createMeetingTypeProcess}
                    className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                  >
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Create new meeting type
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              Please fill the form below to create a new meeting type
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-gray-400 hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Meeting type template
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <Menu as="div" className="relative inline-block gap-x-4 w-full text-left hover:bg-gray-50">
                              {({ open }) => (
                                <>
                                  <Menu.Button
                                    className={classNames(
                                      'w-full  hover:bg-gray-50 bg-white justify-between flex items-center gap-x-2 px-4 rounded-md ring-1  py-2 text-sm leading-6',
                                      formError && formError.field === 'meeting-type-template'
                                        ? 'ring-red-600'
                                        : 'ring-gray-200',
                                    )}
                                  >
                                    <span className="sr-only">Open user menu</span>
                                    <span
                                      className={classNames(
                                        'text-sm ',
                                        selectedTemplate ? 'text-gray-700 font-medium' : 'text-gray-500',
                                      )}
                                      aria-hidden="true"
                                    >
                                      {selectedTemplate ? selectedTemplate.name : 'Select meeting type template'}
                                    </span>
                                    <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </Menu.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute w-full max-h-64 overflow-y-scroll z-10 mb-0 py-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      {templates &&
                                        templates.map((template) => (
                                          <Menu.Item key={`meeting-type-template-${template.uuid}`}>
                                            {({ focus }) => {
                                              return (
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    changeTemplateProcess(template.uuid)
                                                  }}
                                                  className={classNames(
                                                    formData.templateUuid === template.uuid
                                                      ? 'bg-gray-50 font-semibold'
                                                      : '',
                                                    focus ? 'bg-gray-50' : '',
                                                    'w-full border-t border-1 border-gray-100 py-4 text-left block px-6 text-sm leading-6 text-gray-700',
                                                  )}
                                                >
                                                  <div>{template.name}</div>
                                                  <div className={'text-xs text-gray-500'}>{template.description}</div>
                                                </button>
                                              )
                                            }}
                                          </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Name
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              onChange={changeNameProcess}
                              value={formData.name}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Description
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <textarea
                              id="description"
                              name="description"
                              onChange={changeDescriptionProcess}
                              value={formData.description}
                              rows={6}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Meeting type context
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <textarea
                              id="type-context"
                              name="type-context"
                              onChange={changeContextProcess}
                              value={formData.context}
                              rows={6}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div className={'text-sm font-medium leading-6 text-gray-900'}>Variables</div>
                          <div className={'flex flex-col gap-y-4 pt-4'}>
                            {formData.variables.length === 0 && (
                              <div className={'text-gray-500 text-sm'}>There are no variables yet.</div>
                            )}
                            {formData.variables.map((variable, index) => (
                              <div key={`variable-${index}`} className={'p-4 rounded-md bg-gray-50'}>
                                <div className={'space-y-2'}>
                                  <div className={'relative'}>
                                    <input
                                      type="text"
                                      name={`variable-${index}-name`}
                                      placeholder={'Name'}
                                      id={`variable-${index}-name`}
                                      onChange={(event) => {
                                        changeVariableNameProcess(event.target.value, index)
                                      }}
                                      value={formData.variables[index].name}
                                      className="block w-full uppercase rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <div
                                      className={
                                        'absolute top-2 right-2 text-gray-500 hover:text-gray-700 cursor-pointer'
                                      }
                                    >
                                      <InformationCircleIcon className={'w-5 h-5'} />
                                    </div>
                                  </div>
                                  <div>
                                    <textarea
                                      id={`variable-${index}-value`}
                                      placeholder={'Value'}
                                      name={`variable-${index}-value`}
                                      onChange={(event) => {
                                        changeVariableValueProcess(event.target.value, index)
                                      }}
                                      value={formData.variables[index].value}
                                      rows={3}
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                </div>
                                <div className={'pt-4 w-full flex justify-end'}>
                                  <button
                                    type="button"
                                    className="rounded-md flex flex-row items-center bg-white px-3 py-2 text-sm font-semibold text-gray-500 hover:text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    onClick={() => deleteVariableProcess(index)}
                                  >
                                    <TrashIcon className={'w-5 h-5 mr-2'} />
                                    Delete variable
                                  </button>
                                </div>
                              </div>
                            ))}
                            <div className={'pb-4'}>
                              <button
                                onClick={addVariableProcess}
                                type={'button'}
                                className="flex flex-row items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              >
                                <PlusIcon className={'w-5 h-5 mr-2'} />
                                Add variable
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
