import { createSlice } from '@reduxjs/toolkit'
import { getAllUsers, updateRole } from '../../../components/users/slice'
import { failed, REQUEST_STATUSES } from '../../reducer-helper'
import { inviteUsers } from '../../../components/users/invite/slice'
import { removeUser } from '../../../components/users/remove/slice'
import { WORKSPACE_USER_STATUSES } from '../../../constants'

const initialState = {
  data: {
    users: null,
    availableSeats: null,
  },
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'workspaceUserList',
  initialState,
  extraReducers(builder) {
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.data.users = action.payload.users
      state.data.availableSeats = action.payload.availableSeats
      state.status = REQUEST_STATUSES.DONE
      state.error = null
    })
    builder.addCase(getAllUsers.rejected, failed)
    builder.addCase(inviteUsers.fulfilled, (state, action) => {
      action.payload.invitedUsers.map((user) =>
          state.data.users.push(user)
      )
      state.data.availableSeats =
        state.data.availableSeats > 0 ? state.data.availableSeats - 1 : state.data.availableSeats
    })
    builder.addCase(updateRole.fulfilled, (state, action) => {
      const userIndex = state.data.users.findIndex((user) => user.uuid === action.payload.uuid)
      if (userIndex !== -1) {
        state.data.users[userIndex].role = action.payload.role
      }
    })
    builder.addCase(removeUser.fulfilled, (state, action) => {
      const userIndex = state.data.users.findIndex((user) => user.uuid === action.payload.uuid)
      if (userIndex !== -1) {
        if (state.data.users[userIndex].status === WORKSPACE_USER_STATUSES.ACTIVE) {
          state.data.users[userIndex].status = WORKSPACE_USER_STATUSES.DEACTIVATED
        }
        if (state.data.users[userIndex].status === WORKSPACE_USER_STATUSES.INVITED) {
          state.data.users.splice(userIndex, 1)
        }
        state.data.availableSeats = state.data.availableSeats + 1
      }
    })
  },
  reducers: {
    usersUnloaded: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.workspaceUserList
export const selectAllUsers = (state) => selectSlice(state).data.users
export const selectAvailableSeats = (state) => selectSlice(state).data.availableSeats
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, usersUnloaded } = actions

export default reducer
