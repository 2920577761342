import { createSlice } from '@reduxjs/toolkit'
import { initApplication } from '../../components/app/slice'
import { getDashboardData } from '../../components/dashboard/slice'
import { payLastInvoice } from '../../components/workspace/settings/billing/pay-for-subscription/slice'
import { inviteUsers } from '../../components/users/invite/slice'
import { cancelSubscription } from '../../components/workspace/settings/billing/cancel-subscription/slice'
import { revokeCancellation } from '../../components/workspace/settings/billing/revoke-cancellation/slice'
import { getSubscription } from '../../components/workspace/settings/billing/overview/slice'
import { REQUEST_STATUSES } from '../reducer-helper'
import { acceptLegal } from '../../components/legal/accept/slice'

const initialState = {
  subscription: null,
  error: null,
  status: null,
}

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
  extraReducers(builder) {
    builder.addCase(initApplication.fulfilled, (state, action) => {
      state.subscription = action.payload.subscription
    })
    builder.addCase(acceptLegal.fulfilled, (state, action) => {
      state.subscription = action.payload.subscription
    })
    builder.addCase(getDashboardData.fulfilled, (state, action) => {
      state.subscription = action.payload.subscription
    })
    builder.addCase(payLastInvoice.fulfilled, (state, action) => {
      state.subscription = action.payload.subscription
    })
    builder.addCase(inviteUsers.fulfilled, (state, action) => {
      state.subscription = action.payload.subscription
    })
    builder.addCase(cancelSubscription.fulfilled, (state, action) => {
      state.subscription = action.payload.subscription
    })
    builder.addCase(revokeCancellation.fulfilled, (state, action) => {
      state.subscription = action.payload.subscription
    })
    builder.addCase(getSubscription.fulfilled, (state, action) => {
      state.subscription = action.payload.subscription
      state.status = REQUEST_STATUSES.DONE
      state.error = null
    })
  },
})

const selectSlice = (state) => state.subscription
export const selectSubscription = (state) => selectSlice(state).subscription
export const selectRequestStatus = (state) => selectSlice(state).status
export const selectError = (state) => selectSlice(state).error

const { reducer, actions } = subscriptionSlice

export const { changeRequestStatus } = actions

export default reducer
