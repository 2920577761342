import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  MeetingStatuses,
  MeetingStatusesLabels,
  RECORD_SOURCE_TYPES,
  RecordLanguagesSelectboxOptions,
  TranscriptionStatuses,
} from '../../../constants'
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs'
import MainLayout from '../../common/protected/main-layout/MainLayout'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  EllipsisVerticalIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  ShareIcon,
  StarIcon,
} from '@heroicons/react/24/outline'
import Loader from '../../common/loader/Loader'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { Tooltip } from 'react-tooltip'
import { classNames } from '../../../utils/className'
import { changeName, selectFormData } from '../../../reducers/records/rename-record-form'
import { getRecord, newTranscription, renameRecord, switchTranscription } from './slice'
import {
  recordUnloaded,
  selectRecord,
  selectRequestStatus,
  changeRequestStatus,
  selectTranscription,
} from '../../../reducers/records/record-details'
import { deleteRecord } from '../list/slice'
import Transcription from '../../common/transcription/Transcription'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { setShowPopupState } from '../../../reducers/records/user-for-meeting-form'
import { Link } from 'react-router-dom'
import { MeetingStatusClasses } from '../../meeting/statuses'
import { showPopup } from '../../../reducers/share-popup'

const statusesMessages = {
  [TranscriptionStatuses.READY_FOR_PROCESS]: {
    title: 'This record has been uploaded and will be transcribed shortly.',
    subtitle: 'Please wait, it can takes a few minutes.',
  },
  [TranscriptionStatuses.IN_PROGRESS]: {
    title: 'This record is currently transcribing.',
    subtitle: 'Please wait, it can takes a few minutes.',
  },
  [TranscriptionStatuses.FAILED]: {
    title: 'Transcription failed.',
    subtitle: 'Please contact our support team for help.',
  },
  [TranscriptionStatuses.CLEARED]: {
    title: 'This transcription has been cleared.',
  },
}

export default function ViewRecord() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const renameFormData = useSelector(selectFormData)
  const record = useSelector(selectRecord)
  const transcription = useSelector(selectTranscription)

  const [showRenameForm, setShowRenameForm] = useState(false)
  const [emptyName, setEmptyName] = useState(false)

  const { recordUuid } = useParams()

  const cancelButtonRef = useRef(null)

  const [recordUuidForDelete, setRecordUuidForDelete] = useState(null)

  const renameHandler = () => {
    const withoutSpaces = renameFormData.name.replace(/\s+/g, '')
    setEmptyName(withoutSpaces.length === 0)
    if (withoutSpaces.length === 0 && renameFormData.name.length > 0) {
      dispatch(changeName({ name: '' }))
    } else if (withoutSpaces.length > 0) {
      setShowRenameForm(false)
      if (renameFormData.name !== record.name) {
        dispatch(renameRecord({ recordUuid, name: renameFormData.name }))
      }
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      renameHandler(event)
    }
  }

  const handleNameFieldFocus = (event) => {
    return event.target.select()
  }

  const changeNameProcess = (event) => {
    event.preventDefault()

    dispatch(changeName({ name: event.target.value }))
  }

  const newTranscriptionProcess = (language) => {
    dispatch(newTranscription({ language, recordUuid }))
  }

  const switchTranscriptionProcess = (transcriptionUuid) => {
    dispatch(switchTranscription({ transcriptionUuid, recordUuid }))
  }

  const showUseForMeetingForm = () => {
    dispatch(setShowPopupState({ showPopup: true }))
  }

  const pages = [
    { name: 'Records', href: '/records', current: true },
    { name: record ? record.name : '', href: '#', current: true },
  ]

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetch = dispatch(getRecord({ recordUuid }))

    return () => {
      dispatch(recordUnloaded())
      fetch.abort()
    }
  }, [])

  useEffect(() => {
    if (record) {
      dispatch(changeName({ name: record.name }))
    }
  }, [record])

  const deleteRecordProcess = (recordUuid) => {
    dispatch(deleteRecord({ recordUuid }))
  }

  const showSharePopup = () => {
    dispatch(showPopup({ type: 'record', token: record.sharingToken, sharedItemUuid: recordUuid }))
  }

  const processingMeeting =
    record &&
    record.existingMeetings &&
    record.existingMeetings.length > 0 &&
    record.existingMeetings.find((meeting) => {
      return (
        meeting.status === MeetingStatuses.READY_FOR_AI_PROCESSING ||
        meeting.status === MeetingStatuses.AI_PROCESSING ||
        meeting.status === MeetingStatuses.WAITING_FOR_TRANSCRIPTION ||
        meeting.status === MeetingStatuses.IN_AI_PROCESSING_QUEUE
      )
    })

  return (
    <MainLayout>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
      {requestStatus === REQUEST_STATUSES.DONE && (
        <div>
          {record && record.uuid && (
            <>
              <Breadcrumbs pages={pages} />
              <div className="w-full py-8 px-8 flex flex-col">
                <div className="w-full border-b border-gray-200 bg-white pb-4">
                  <div className="flex flex-wrap flex-col justify-between sm:flex-nowrap">
                    <div className="flex w-full justify-between">
                      <div className={'flex flex-row items-center w-full'}>
                        {!showRenameForm && (
                          <>
                            <h3 className="text-2xl font-bold leading-6 text-indigo-600">{record.name}</h3>
                            {record.source === RECORD_SOURCE_TYPES.ACCOUNT && (
                              <button
                                onClick={() => {
                                  setShowRenameForm(true)
                                }}
                                className={
                                  'p-2 ml-2 flex flex-row items-center text-gray-300 transition-colors duration-200 hover:text-indigo-400 hover:bg-gray-50 rounded-md'
                                }
                              >
                                <PencilIcon className={'w-5 h-5'} />
                              </button>
                            )}
                          </>
                        )}
                        {showRenameForm && record.source === RECORD_SOURCE_TYPES.ACCOUNT && (
                          <>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              onChange={(e) => {
                                changeNameProcess(e)
                              }}
                              onFocus={handleNameFieldFocus}
                              onKeyUp={handleKeyPress}
                              autoFocus
                              onBlur={renameHandler}
                              value={renameFormData.name}
                              className={classNames(
                                'max-w-2xl p-0 text-2xl font-bold leading-6 text-gray-700 block flex-1 border-0 bg-transparent  focus:ring-0',
                                emptyName ? 'placeholder:text-red-400' : 'placeholder:text-gray-400',
                              )}
                              placeholder="Record name"
                            />
                          </>
                        )}
                      </div>
                      <div className={'flex flex-row items-center'}>
                        <div className={'flex flex-row items-center mr-6 gap-x-2'}>
                          {record.activeTranscription.status === TranscriptionStatuses.DONE && (
                            <>
                              <Menu as="div" className="relative inline-block gap-x-4 w-48 text-left">
                                {({ open }) => (
                                  <>
                                    <Menu.Button
                                      className={classNames(
                                        'w-full text-nowrap bg-white ring-inset ring-1 ring-gray-200 hover:bg-gray-50 justify-between flex items-center gap-x-2 px-4 rounded-md py-2 text-sm leading-6',
                                      )}
                                    >
                                      <span className="sr-only">Open user menu</span>
                                      <span
                                        className=" text-sm font-medium capitalize text-gray-500"
                                        aria-hidden="true"
                                      >
                                        {record.activeTranscription.language}
                                      </span>
                                      <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </Menu.Button>

                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="absolute w-full max-h-64 overflow-y-scroll z-10 mb-0 py-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div
                                          className={'px-4 text-xs text-gray-500 border-b border-gray-100 mb-2 pb-2'}
                                        >
                                          You will be charged for the new new transcription if it is not ready.
                                        </div>
                                        {RecordLanguagesSelectboxOptions.map((language, languageIndex) => {
                                          const readyTranscription = record.transcriptions.find(
                                            (transcription) => transcription.language === language.index,
                                          )
                                          return (
                                            <Menu.Item key={`language-${languageIndex}`}>
                                              {({ active }) => {
                                                return (
                                                  <button
                                                    type={'button'}
                                                    onClick={() => {
                                                      if (readyTranscription) {
                                                        switchTranscriptionProcess(readyTranscription.uuid)
                                                      } else {
                                                        newTranscriptionProcess(language.index)
                                                      }
                                                    }}
                                                    className={classNames(
                                                      record.activeTranscription.language === language.index
                                                        ? 'bg-gray-50 font-semibold'
                                                        : '',
                                                      active ? 'bg-gray-50' : '',
                                                      'flex justify-between items-center w-full text-left px-6 py-1 text-sm leading-6 text-gray-700',
                                                    )}
                                                  >
                                                    <span>{language.title}</span>
                                                    {readyTranscription && (
                                                      <span className={'text-xs font-normal text-gray-500'}>Ready</span>
                                                    )}
                                                  </button>
                                                )
                                              }}
                                            </Menu.Item>
                                          )
                                        })}
                                      </Menu.Items>
                                    </Transition>
                                  </>
                                )}
                              </Menu>

                              <div
                                className={
                                  'flex text-nowrap text-sm text-gray-500 rounded-md bg-white hover:text-gray-600 hover:bg-gray-50 font-medium ring-inset ring-1 ring-gray-200'
                                }
                              >
                                <button
                                  className={classNames(
                                    'px-3 py-2 flex flex-row justify-center items-center',
                                    record.existingMeetings && record.existingMeetings.length > 0
                                      ? 'border-r border-1 border-gray-200'
                                      : '',
                                  )}
                                  onClick={showUseForMeetingForm}
                                >
                                  {processingMeeting && <Loader />}
                                  New analysis
                                </button>
                                {record.existingMeetings && record.existingMeetings.length > 0 && (
                                  <Menu as="div" className="relative inline-block text-left">
                                    {({ open }) => (
                                      <>
                                        <Menu.Button
                                          className={classNames('flex px-3 py-2 justify-center text-nowrap')}
                                        >
                                          <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </Menu.Button>

                                        <Transition
                                          show={open}
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <Menu.Items className="absolute right-0 min-w-56 max-h-64 overflow-y-scroll z-10 mb-0 py-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {record.existingMeetings.map((meeting, meetingIndex) => {
                                              return (
                                                <Menu.Item key={`existing-meeting-${meetingIndex}`}>
                                                  {({ active }) => {
                                                    return (
                                                      <Link
                                                        to={`/meetings/${meeting.uuid}`}
                                                        className={classNames(
                                                          active ? 'bg-gray-50' : '',
                                                          'flex font-normal justify-between items-center w-full text-left px-6 py-1 text-sm leading-6 text-gray-700',
                                                        )}
                                                      >
                                                        <span className={'pr-4'}>{meeting.name}</span>
                                                        <span className={MeetingStatusClasses[meeting.status]}>
                                                          {MeetingStatusesLabels[meeting.status]}
                                                        </span>
                                                      </Link>
                                                    )
                                                  }}
                                                </Menu.Item>
                                              )
                                            })}
                                          </Menu.Items>
                                        </Transition>
                                      </>
                                    )}
                                  </Menu>
                                )}
                              </div>
                            </>
                          )}

                          <button
                            onClick={showSharePopup}
                            className={
                              'share-tooltip-el p-2 flex flex-row items-center text-gray-500 transition-colors duration-200 hover:text-indigo-400 hover:bg-gray-50 rounded-md'
                            }
                          >
                            <ShareIcon className={'h-5 w-5'} />
                            <Tooltip anchorSelect=".share-tooltip-el" place="bottom">
                              Share
                            </Tooltip>
                          </button>

                          <button
                            className={
                              'favorite-tooltip-el p-2 flex flex-row items-center text-gray-500 transition-colors duration-200 hover:text-indigo-400 hover:bg-gray-50 rounded-md'
                            }
                          >
                            <StarIcon className={classNames(`h-5 w-5`)} />
                            <Tooltip anchorSelect=".favorite-tooltip-el" place="bottom">
                              Favorite
                            </Tooltip>
                          </button>
                        </div>

                        {record.activeTranscription.status === TranscriptionStatuses.DONE && (
                          <Menu as="div" className="relative inline-block text-left">
                            <div className={'border-l pl-4 flex flex-row items-center'}>
                              <div className={'text-xs text-gray-500 text-nowrap'}>Other options</div>
                              <Menu.Button className="flex items-center bg-white p-2 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                                <span className="sr-only">Open options</span>
                                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault()
                                          setRecordUuidForDelete(record.uuid)
                                        }}
                                        className={classNames(
                                          active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                          'flex w-full text-left justify-between px-4 py-2 text-sm',
                                        )}
                                      >
                                        <span>Delete</span>
                                      </button>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative pt-4 lg:flex lg:gap-x-16">
                  {record.activeTranscription.status === TranscriptionStatuses.DONE && (
                    <>
                      <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-8">
                        <div className="text-gray-500 text-sm whitespace-pre-line">
                          <Transcription transcription={transcription} />
                        </div>
                      </div>
                    </>
                  )}
                  {record.activeTranscription.status !== TranscriptionStatuses.DONE && (
                    <div className="mt-12 w-full text-center">
                      {(record.activeTranscription.status === TranscriptionStatuses.READY_FOR_PROCESS ||
                        record.activeTranscription.status === TranscriptionStatuses.IN_PROGRESS) && (
                        <div className={'mx-auto w-12 mb-4'}>
                          <Loader size={12} />
                        </div>
                      )}
                      <h3 className="mt-2 text-sm font-semibold text-gray-900">
                        {statusesMessages[record.activeTranscription.status]?.title}
                      </h3>
                      {statusesMessages[record.activeTranscription.status].subtitle && (
                        <p className="mt-1 text-sm text-gray-500">
                          {statusesMessages[record.activeTranscription.status].subtitle}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <Transition.Root show={recordUuidForDelete !== null} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={() => setRecordUuidForDelete(null)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Delete record
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this record? All meeting meetings connected to this record
                          will be deleted as well.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"
                      onClick={() => {
                        deleteRecordProcess(recordUuidForDelete)
                        setRecordUuidForDelete(null)
                      }}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                      onClick={() => setRecordUuidForDelete(null)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </MainLayout>
  )
}
