import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../../../api/workspaceAgent'

export const revokeCancellation = createAsyncThunk(
  'workspaces/subscription/revoke-cancellation',
  async ({}, thunkApi) => {
    try {
      const response = await WorkspaceAgent.revokeCancellation()

      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)
