import React, { memo } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'
import { selectMeeting } from '../../../reducers/meetings/meeting-details'

function UsageDialog({ showUsage, setShowUsage }) {
  const meeting = useSelector(selectMeeting)

  return (
    <Transition.Root show={showUsage} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setShowUsage(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-auto">
          <div className="absolute inset-0  overflow-auto">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto overflow-auto w-screen bg-white max-w-2xl">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="w-full bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">Usage</Dialog.Title>
                          <p className="text-sm text-gray-500">Usage information</p>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="relative text-gray-400 hover:text-gray-500"
                            onClick={() => {
                              setShowUsage(false)
                            }}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {meeting && (
                      <div className={'px-6 space-y-4'}>
                        <div className={'grid grid-cols-2 gap-x-4'}>
                          <div className={'bg-indigo-50 rounded-md p-6'}>
                            <div className={'text-4xl font-light text-gray-900'}>
                              ${parseFloat(meeting.usage.totalCost) + parseFloat(meeting.usage.singleRequestLLMPrice)}
                            </div>
                            <div className={'text-gray-700 font-semibold pt-2 text-xs'}>Total meeting cost</div>
                          </div>
                          <div className={'bg-indigo-50 rounded-md p-6'}>
                            <div className={'text-4xl font-light text-gray-900'}>{meeting.usage.requestCount}</div>
                            <div className={'text-gray-700 font-semibold pt-2 text-xs'}>
                              AI request{meeting.usage.requestCount > 1 ? 's' : ''}
                            </div>
                            <div className={'text-gray-500 pt-2 text-xs'}>
                              ${meeting.usage.singleRequestLLMPrice} / request
                            </div>
                          </div>
                        </div>
                        <div className={'bg-indigo-50 rounded-md p-6'}>
                          <div className={'text-4xl font-light text-gray-900'}>{meeting.usage.llmVersion}</div>
                          <div className={'text-gray-700 font-semibold pt-2 text-xs'}>AI Model</div>
                        </div>
                        <div className={'grid grid-cols-2 gap-x-4'}>
                          <div className={'bg-indigo-50 rounded-md p-6'}>
                            <div className={'text-4xl font-light text-gray-900'}>{meeting.usage.promptTokens}</div>
                            <div className={'text-gray-700 font-semibold pt-2 text-xs'}>Prompt tokens</div>
                            <div className={'text-gray-500 pt-2 text-xs'}>${meeting.usage.promptPrice} / 1k tokens</div>
                          </div>
                          <div className={'bg-indigo-50 rounded-md p-6'}>
                            <div className={'text-4xl font-light text-gray-900'}>{meeting.usage.completionTokens}</div>
                            <div className={'text-gray-700 font-semibold pt-2 text-xs'}>Completion tokens</div>
                            <div className={'text-gray-500 pt-2 text-xs'}>
                              ${meeting.usage.completionPrice} / 1k tokens
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default memo(UsageDialog)
