import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../../reducer-helper'
import { createApiKey } from '../../../components/workspace/settings/api-keys/create-popup/slice'
import { getAllApiKeys } from '../../../components/workspace/settings/api-keys/slice'
import { deleteApiKey } from '../../../components/workspace/settings/api-keys/confirm-delete-popup/slice'

const initialState = {
  apiKeys: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'workspaceApiKeyList',
  initialState,
  extraReducers(builder) {
    builder.addCase(createApiKey.fulfilled, (state, action) => {
      const apiKey = { ...action.payload.apiKey }
      delete apiKey.fullValue

      state.apiKeys.push(apiKey)
    })
    builder.addCase(deleteApiKey.fulfilled, (state, action) => {
      const deletedKeyIndex = state.apiKeys.findIndex((key) => key.uuid === action.payload.apiKeyUuid)
      if (deletedKeyIndex !== -1) {
        state.apiKeys.splice(deletedKeyIndex, 1)
      }
    })
    builder.addCase(getAllApiKeys.fulfilled, (state, action) => {
      state.apiKeys = action.payload.apiKeys
      state.status = REQUEST_STATUSES.DONE
      state.error = null
    })
    builder.addCase(getAllApiKeys.rejected, failed)
  },
  reducers: {
    apiKeysUnloaded: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.workspaceApiKeyList
export const selectAllApiKeys = (state) => selectSlice(state).apiKeys
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, apiKeysUnloaded } = actions

export default reducer
