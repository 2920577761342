import { createAsyncThunk } from '@reduxjs/toolkit'
import { BillingAgent } from '../../../../../api/billingAgent'

export const deposit = createAsyncThunk('workspaces/billing/deposit', async ({ amount, paymentMethodId }, thunkApi) => {
  try {
    await BillingAgent.deposit(amount, paymentMethodId)

    return { amount }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
