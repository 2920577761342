import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetForm, selectStep } from '../../../reducers/user/sign-up-form'
import EmailForm from './EmailForm'
import ConfirmEmailForm from './ConfirmEmailForm'
import ProfileDataForm from './ProfileDataForm'
import SingleFormWrapper from '../../common/singl-form-wrapper/SingleFormWrapper'
import { selectUser } from '../../../reducers/user/user'
import { useNavigate } from 'react-router'

function SignUp() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const step = useSelector(selectStep)
  const user = useSelector(selectUser)

  useEffect(() => {
    if (user) {
      navigate('/dashboard')
    }

    return () => {
      dispatch(resetForm())
    }
  }, [])

  return (
    <SingleFormWrapper>
      {step === 1 && <EmailForm />}
      {step === 2 && <ConfirmEmailForm />}
      {step === 3 && <ProfileDataForm />}
    </SingleFormWrapper>
  )
}

export default memo(SignUp)
