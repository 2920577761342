import React, { memo, useEffect, useState } from 'react'
import { PlusIcon } from '@heroicons/react/20/solid'
import { useDispatch, useSelector } from 'react-redux'
import { changeShowNavigationSidebar } from '../../../reducers/common'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import {
  changeRequestStatus,
  selectAllWorkspaces,
  setSidebarState as setListSidebarState,
  workspacesUnloaded,
} from '../../../reducers/workspace/workspace-list'
import { acceptInvitation, getAllWorkspaces, switchWorkspace } from './slice'
import { selectRequestStatus } from '../../../reducers/workspace/workspace-list'
import Loader from '../../common/loader/Loader'
import { WORKSPACE_USER_ROLES, WORKSPACE_USER_STATUSES } from '../../../constants'
import { selectWorkspace } from '../../../reducers/workspace/workspace'
import { setSidebarState as setCreateWorkspaceSidebarState } from '../../../reducers/workspace/create-form'
import SingleFormWrapper from '../../common/singl-form-wrapper/SingleFormWrapper'
import CreateWorkspace from '../create/Create'
import { classNames } from '../../../utils/className'

const tabsKeys = {
  ALL: 'all',
  OWN: 'own-workspace',
  INVITED: 'invited',
}

const tabs = [
  { key: tabsKeys.ALL, name: 'All' },
  { key: tabsKeys.OWN, name: 'Own' },
  { key: tabsKeys.INVITED, name: 'Invited' },
]

function WorkspaceList() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const workspaces = useSelector(selectAllWorkspaces)
  const currentWorkspace = useSelector(selectWorkspace)

  const showCreateWorkspaceSidebarProcess = () => {
    dispatch(setCreateWorkspaceSidebarState({ showSidebar: true }))
  }

  const closeAllPopups = () => {
    dispatch(setListSidebarState({ showSidebar: false }))
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: false }))
  }

  const [filteredWorkspaces, setFilteredWorkspaces] = useState(null)
  const [currentTab, setCurrentTab] = useState(tabs[0].key)

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchWorkspaces = dispatch(getAllWorkspaces())

    return () => {
      dispatch(workspacesUnloaded())
      fetchWorkspaces.abort()
    }
  }, [])

  useEffect(() => {
    if (workspaces) {
      let filtered

      switch (currentTab) {
        case tabsKeys.ALL:
          filtered = [...workspaces]
          break
        case tabsKeys.OWN:
          filtered = [...workspaces.filter((workspace) => workspace.user.role === WORKSPACE_USER_ROLES.OWNER)]
          break
        case tabsKeys.INVITED:
          filtered = [...workspaces.filter((workspace) => workspace.user.role !== WORKSPACE_USER_ROLES.OWNER)]
          break
      }

      filtered.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))
      setFilteredWorkspaces(filtered)
    }
  }, [currentTab, workspaces])

  const switchWorkspaceProcess = (workspaceUuid) => {
    dispatch(switchWorkspace({ workspaceUuid }))
    closeAllPopups()
  }

  const acceptInvitationProcess = (workspaceUuid) => {
    dispatch(acceptInvitation({ workspaceUuid }))
    closeAllPopups()
  }

  return (
    <SingleFormWrapper>
      <div className={'sm:mx-auto sm:w-full sm:max-w-sm'}>
        {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
        {requestStatus === REQUEST_STATUSES.DONE && filteredWorkspaces && (
          <div>
            <ul role="list" className="">
              <li className="flex items-center justify-between mb-8 py-5">
                <button
                  type="button"
                  onClick={() => showCreateWorkspaceSidebarProcess()}
                  className="flex mx-auto items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Create new workspace
                </button>
              </li>
              <li>
                <div className="pb-4 sm:flex sm:justify-start">
                  <div className="sm:hidden mx-auto">
                    <label htmlFor="tabs" className="sr-only">
                      Select a tab
                    </label>
                    <select
                      id="tabs"
                      name="tabs"
                      className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                      onChange={(event) => {
                        setCurrentTab(event.target.value)
                      }}
                      defaultValue={tabs.find((tab) => tab.key === currentTab).name}
                    >
                      {tabs.map((tab) => (
                        <option key={tab.key} value={tab.key}>
                          {tab.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden w-full sm:block pt-4">
                    <nav className="flex items-center justify-center" aria-label="Tabs">
                      {tabs.map((tab) => (
                        <button
                          key={tab.key}
                          onClick={() => setCurrentTab(tab.key)}
                          className={classNames(
                            tab.key === currentTab ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                            'rounded-md px-3 py-1.5 text-sm font-medium',
                          )}
                          aria-current={tab.key === currentTab ? 'page' : undefined}
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>
              </li>
              <li>
                {filteredWorkspaces && filteredWorkspaces.length === 0 && (
                  <div className={'text-sm text-gray-700 text-center pt-8'}>There are no workspaces yet...</div>
                )}
              </li>

              {filteredWorkspaces.map((workspace) => (
                <li
                  key={workspace.uuid}
                  className="flex border-t border-gray-50 items-center justify-between gap-x-6 py-5"
                >
                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">{workspace.name}</p>
                    </div>
                  </div>
                  <button
                    key={workspace.uuid}
                    onClick={() => {
                      if (workspace.user.status === WORKSPACE_USER_STATUSES.ACTIVE) {
                        switchWorkspaceProcess(workspace.uuid)
                      }
                      if (workspace.user.status === WORKSPACE_USER_STATUSES.INVITED) {
                        acceptInvitationProcess(workspace.uuid)
                      }
                    }}
                    className={classNames([
                      workspace.user.status === WORKSPACE_USER_STATUSES.INVITED
                        ? 'text-indigo-500 focus-visible:outline-indigo-600'
                        : 'text-gray-900 ',
                      'hidden rounded-md ring-1 ring-inset bg-white  ring-gray-300 hover:bg-gray-50 px-3 py-1.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ',
                    ])}
                    aria-current={currentWorkspace && currentWorkspace.uuid ? 'page' : undefined}
                  >
                    {workspace.user.status !== WORKSPACE_USER_STATUSES.INVITED && <div>Switch</div>}
                    {workspace.user.status === WORKSPACE_USER_STATUSES.INVITED && <div>Accept invitation</div>}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <CreateWorkspace />
    </SingleFormWrapper>
  )
}

export default memo(WorkspaceList)
