import { createSlice } from '@reduxjs/toolkit'
import { failed, resetErrors, resetStatus } from '../../reducer-helper'
import { updateGroup } from '../../../components/groups/details/settings/slice'

const initialState = {
  form: {
    name: '',
    openMeetings: true,
  },
  formLoaded: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'updateWorkspaceGroup',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateGroup.fulfilled, (state) => {
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(updateGroup.rejected, failed)
  },
  reducers: {
    setSettings: (state, action) => {
      state.form.name = action.payload.name
      state.form.openMeetings = action.payload.openMeetings

      state.formLoaded = true
    },
    changeName: (state, action) => {
      state.form.name = action.payload.name
    },
    changeOpenMeetings: (state, action) => {
      state.form.openMeetings = action.payload.openMeetings
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.updateWorkspaceGroup
export const selectFormData = (state) => selectSlice(state).form
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice
export const { changeRequestStatus, resetForm, changeName, setSettings, changeOpenMeetings, setSidebarState } = actions

export default reducer
