import { createSlice } from '@reduxjs/toolkit'
import { getUsageData } from '../../components/usage/slice'
import { failed } from '../reducer-helper'

const dateObj = new Date()
const month = dateObj.getUTCMonth() + 1 // months from 1-12

const initialState = {
  usage: null,
  year: dateObj.getUTCFullYear(),
  month: month.toString().padStart(2, '0'),
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'workspaceUsage',
  initialState,
  extraReducers(builder) {
    builder.addCase(getUsageData.fulfilled, (state, action) => {
      state.usage = action.payload.usage
    })
    builder.addCase(getUsageData.rejected, failed)
  },
  reducers: {
    usageUnloaded: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },

    changeDateDown: (state) => {
      let newYear = state.year,
        newMonth = state.month

      if (parseInt(state.month) === 1) {
        newYear = parseInt(state.year) - 1
        newMonth = 12
      } else {
        newMonth = parseInt(state.month) - 1
      }

      state.year = newYear
      state.month = newMonth.toString().padStart(2, '0')
    },

    changeDateUp: (state) => {
      let newYear = state.year,
        newMonth = state.month

      if (parseInt(state.month) === 12) {
        newYear = parseInt(state.year) + 1
        newMonth = 1
      } else {
        newMonth = parseInt(state.month) + 1
      }

      state.year = newYear
      state.month = newMonth.toString().padStart(2, '0')
    },
  },
})

const selectSlice = (state) => state.workspaceUsage
export const selectUsage = (state) => selectSlice(state).usage
export const selectUsageMonth = (state) => selectSlice(state).month
export const selectUsageYear = (state) => selectSlice(state).year
export const selectError = (state) => selectSlice(state).error

const { reducer, actions } = slice

export const { changeRequestStatus, usageUnloaded, changeDateDown, changeDateUp } = actions

export default reducer
