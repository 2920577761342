import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeRequestStatus,
  invoicesUnloaded,
  selectInvoices,
  selectError,
  selectRequestStatus,
} from '../../../../../reducers/workspace/settings/billing/invoices'
import { REQUEST_STATUSES } from '../../../../../reducers/reducer-helper'
import { getInvoices } from './slice'
import NotificationPopup from '../../../../common/notification-popup/NotificationPopup'
import Loader from '../../../../common/loader/Loader'
import { format } from 'date-fns'

function History() {
  const dispatch = useDispatch()

  const charges = useSelector(selectInvoices)
  const requestStatus = useSelector(selectRequestStatus)
  const requestError = useSelector(selectError)

  const [error, setError] = useState(null)

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const data = dispatch(getInvoices({}))

    return () => {
      dispatch(invoicesUnloaded())
      data.abort()
    }
  }, [])

  useEffect(() => {
    if (requestError) {
      setError('Something went wrong, please try again later.')
    }
  }, [requestError])

  return (
    <div className={'mt-8 px-6 md:px-0'}>
      <div>
        {!!error && (
          <div className={'p-6'}>
            <NotificationPopup message={error} />
          </div>
        )}
        {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
        {requestStatus === REQUEST_STATUSES.DONE && charges !== null && (
          <div className="mt-8 flow-root sm:mx-0 overflow-x-auto">
            {charges.length === 0 && (
              <div className={'text-sm text-gray-500'}>There are no any charges or invoices yet.</div>
            )}
            {charges.length > 0 && (
              <table className="min-w-full">
                <colgroup>
                  <col className="sm:w-1/6" />
                  <col className="w-full sm:w-1/4" />
                  <col className="sm:w-1/6" />
                  <col className="sm:w-1/6" />
                </colgroup>
                <thead className="border-b border-gray-300 text-gray-900">
                  <tr>
                    <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Date
                    </th>
                    <th
                      scope="col"
                      className="hidden text-left py-3.5 text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Description
                    </th>
                    <th scope="col" className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                      Invoice Total
                    </th>
                    <th scope="col" className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {charges.map((charge) => {
                    const invoice = charge?.invoice

                    return (
                      <tr key={charge.id} className="border-b border-gray-200">
                        <td className="max-w-0 text-sm">
                          <div className="text-gray-900">
                            {format(new Date(charge.created * 1000), 'MMMM do, yyyy')}
                          </div>
                        </td>
                        <td className={'max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0 text-gray-700'}>
                          {invoice && (
                            <div>
                              {invoice.items.map((item) => (
                                <div key={`${invoice.id}-${item.id}`}>
                                  {item.id} - {item.description}
                                </div>
                              ))}
                            </div>
                          )}
                          {!invoice && <div>{charge.description}</div>}
                        </td>
                        <td className="hidden py-5 text-right text-sm text-gray-700 sm:table-cell">
                          ${charge.amount / 100}
                        </td>
                        <td className="py-5 pl-3 pr-4 text-right text-sm text-gray-700 sm:pr-0">
                          <p className={'capitalize'}>{charge.status}</p>
                          <div>
                            {invoice && (
                              <a href={invoice?.pdfUrl} className={'text-indigo-500 text-xs hover:text-indigo-700'}>
                                View invoice
                              </a>
                            )}
                            {!invoice && (
                              <a href={charge.receiptUrl} className={'text-indigo-500 text-xs hover:text-indigo-700'}>
                                View receipt
                              </a>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(History)
