import { createSlice } from '@reduxjs/toolkit'
import {
  deleteShareMeetingToken,
  deleteShareRecordToken,
  resetShareMeetingToken,
  resetShareRecordToken,
} from '../components/common/share-popup/slice'

const initialState = {
  showPopup: false,
  type: '',
  sharedItemUuid: '',
  token: '',
}

const slice = createSlice({
  name: 'sharePopup',
  initialState,
  reducers: {
    showPopup(state, action) {
      state.showPopup = true
      state.type = action.payload.type
      state.token = action.payload.token
      state.sharedItemUuid = action.payload.sharedItemUuid
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(resetShareMeetingToken.fulfilled, (state, action) => {
      state.token = action.payload.sharedToken
    })
    builder.addCase(resetShareRecordToken.fulfilled, (state, action) => {
      state.token = action.payload.sharedToken
    })
    builder.addCase(deleteShareMeetingToken.fulfilled, (state, action) => {
      state.token = ''
    })
    builder.addCase(deleteShareRecordToken.fulfilled, (state, action) => {
      state.token = ''
    })
  },
})

const selectSlice = (state) => state.sharePopup
export const selectShowPopup = (state) => selectSlice(state).showPopup
export const selectType = (state) => selectSlice(state).type
export const selectSharedItemUuid = (state) => selectSlice(state).sharedItemUuid
export const selectToken = (state) => selectSlice(state).token

const { reducer, actions } = slice

export const { showPopup, reset } = actions

export default reducer
