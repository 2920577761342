import React, { Fragment, memo, useEffect, useRef, useState } from 'react'
import { Combobox, Dialog, Switch, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { CheckIcon, ChevronUpDownIcon, PlusIcon } from '@heroicons/react/20/solid'
import { useDispatch, useSelector } from 'react-redux'
import {
  addEmail,
  addToGroup,
  changeEmail,
  changeIsAdmin,
  changeIsManager,
  changeRequestStatus,
  removeEmail,
  removeFromGroup,
  resetData,
  selectError,
  selectFormData,
  selectRequestStatus,
  selectShowSidebar,
  setSidebarState,
} from '../../../reducers/workspace/user/invite'
import { inviteUsers } from './slice'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loader from '../../common/loader/Loader'
import NotificationPopup from '../../common/notification-popup/NotificationPopup'
import { WorkspaceAgent } from '../../../api/workspaceAgent'
import { selectAvailableSeats } from '../../../reducers/workspace/user/user-list'
import { format } from 'date-fns'
import { selectSubscription } from '../../../reducers/workspace/subscription'
import { BillingAgent } from '../../../api/billingAgent'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Invite() {
  const dispatch = useDispatch()

  const moreBtn = useRef()

  const form = useSelector(selectFormData)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)
  const showInviteUserSidebar = useSelector(selectShowSidebar)
  const availableSeats = useSelector(selectAvailableSeats)
  const subscription = useSelector(selectSubscription)

  const [newSeatProratedPrice, setNewSeatProratedPrice] = useState(null)
  const [groups, setGroups] = useState(null)
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (showInviteUserSidebar) {
      WorkspaceAgent.getAllGroups().then((groups) => {
        setGroups(groups)
      })
      BillingAgent.getNewSeatProratedPrice().then((price) => {
        setNewSeatProratedPrice(price)
      })
    }

    return () => {
      if (showInviteUserSidebar) {
        setTimeout(() => {
          dispatch(resetData())
        }, 300)
      }
    }
  }, [showInviteUserSidebar])

  const inviteProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(inviteUsers({ emails: form.emails, groups: form.groups }))
  }

  const hideInviteUserSidebarProcess = () => {
    dispatch(setSidebarState({ showSidebar: false }))
  }

  const changeGroupUuidProcess = (value) => {
    dispatch(addToGroup({ groupUuid: value }))
    setQuery('')
  }

  const changeIsManagerProcess = (groupUuid, value) => {
    dispatch(changeIsManager({ groupUuid, isManager: value }))
  }

  const removeProcess = (groupUuid) => {
    dispatch(removeFromGroup({ groupUuid }))
  }

  const filteredGroups = !groups
    ? []
    : groups.filter((group) => {
        const addedUuids = form.groups.map((group) => group.uuid)

        return !addedUuids.includes(group.uuid) && group.name.toLowerCase().includes(query.toLowerCase())
      })

  const addEmailAction = (event) => {
    event.preventDefault()
    dispatch(addEmail({ email: '', isAdmin: false }))
  }

  const removeEmailAction = (index) => {
    dispatch(removeEmail({ index }))
  }

  const changeEmailProcess = (index, event) => {
    dispatch(changeEmail({ email: event.target.value, index }))
  }

  const changeIsAdminProcess = (index, value) => {
    dispatch(changeIsAdmin({ isAdmin: value, index }))
  }

  return (
    <Transition.Root show={showInviteUserSidebar} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => hideInviteUserSidebarProcess()}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form onSubmit={inviteProcess} className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Invite new member
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              Get started by filling in the information below to invite new workspace member.
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-gray-400 hover:text-gray-500"
                              onClick={() => hideInviteUserSidebarProcess()}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {error && (
                        <div className={'px-6'}>
                          <NotificationPopup message={error.message} />
                        </div>
                      )}

                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              User email
                            </label>
                          </div>
                          <div className={'sm:col-span-2'}>
                            {form.emails.map((entry, index) => (
                              <div key={index} className={'flex flex-row items-center mt-3'}>
                                <div className="w-full pr-4">
                                  <input
                                    type="text"
                                    placeholder="newuser@email.com"
                                    value={entry.email}
                                    onChange={(event) => changeEmailProcess(index, event)}
                                    name="email"
                                    id="email"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>

                                <div>
                                  <Switch.Group as="div" className="flex items-center">
                                    <Switch
                                      checked={entry.isAdmin}
                                      onChange={(event) => changeIsAdminProcess(index, event)}
                                      className={classNames(
                                        entry.isAdmin ? 'bg-indigo-600' : 'bg-gray-200',
                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                                      )}
                                    >
                                      <span
                                        aria-hidden="true"
                                        className={classNames(
                                          entry.isAdmin ? 'translate-x-5' : 'translate-x-0',
                                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                        )}
                                      />
                                    </Switch>
                                    <Switch.Label as="span" className="ml-3 text-xs">
                                      <span className="font-normal text-gray-500">Admin</span>
                                    </Switch.Label>
                                  </Switch.Group>
                                </div>
                                {index !== 0 && (
                                  <div className={'ml-4'}>
                                    <XMarkIcon
                                      onClick={() => removeEmailAction(index)}
                                      className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-700"
                                      aria-hidden="true"
                                    />
                                  </div>
                                )}
                              </div>
                            ))}

                            <button
                              type="button"
                              onClick={addEmailAction}
                              className="mt-3 ml-auto flex text-sm items-center justify-center px-3 py-1 text-indigo-600 hover:text-indigo-400"
                            >
                              <PlusIcon className="-ml-0.5 h-5 w-5 mr-1" aria-hidden="true" />
                              Add one more
                            </button>
                          </div>
                        </div>
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Add to groups
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <Combobox
                              as="div"
                              value={null}
                              onChange={(value) => {
                                changeGroupUuidProcess(value)
                              }}
                            >
                              <div className="relative">
                                <Combobox.Input
                                  className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  onChange={(event) => setQuery(event.target.value)}
                                  placeholder={`Find group...`}
                                  onClick={(e) => {
                                    moreBtn.current.click()
                                  }}
                                />
                                <Combobox.Button
                                  onClick={(e) => {
                                    moreBtn.current.click()
                                  }}
                                  ref={moreBtn}
                                  className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                                >
                                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Combobox.Button>

                                {filteredGroups.length > 0 && (
                                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {filteredGroups.map((group) => (
                                      <Combobox.Option
                                        key={group.uuid}
                                        value={group.uuid}
                                        className={({ active }) =>
                                          classNames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                          )
                                        }
                                      >
                                        {({ active, selected }) => (
                                          <>
                                            <p className="text-sm">{group.name}</p>

                                            {selected && (
                                              <span
                                                className={classNames(
                                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                                  active ? 'text-white' : 'text-indigo-600',
                                                )}
                                              >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </Combobox.Option>
                                    ))}
                                  </Combobox.Options>
                                )}
                              </div>
                            </Combobox>

                            <ul role="list" className="mt-4 divide-y divide-gray-100">
                              {form.groups.map((group) => {
                                const groupData = groups.find((groupObj) => groupObj.uuid === group.uuid)

                                return groupData ? (
                                  <li key={`added-group-${groupData.uuid}`} className="py-4">
                                    <div className="flex items-center gap-x-3">
                                      <h3 className="flex-auto truncate text-sm font-semibold leading-6 text-gray-900">
                                        {groupData.name}
                                      </h3>
                                      <div>
                                        <Switch.Group as="div" className="flex items-center">
                                          <Switch
                                            checked={group.isManager}
                                            onChange={(value) => changeIsManagerProcess(group.uuid, value)}
                                            className={classNames(
                                              group.isManager ? 'bg-indigo-600' : 'bg-gray-200',
                                              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                                            )}
                                          >
                                            <span
                                              aria-hidden="true"
                                              className={classNames(
                                                group.isManager ? 'translate-x-5' : 'translate-x-0',
                                                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                              )}
                                            />
                                          </Switch>
                                          <Switch.Label as="span" className="ml-3 text-xs">
                                            <span className="font-normal text-gray-500">Manager</span>
                                          </Switch.Label>
                                        </Switch.Group>
                                      </div>

                                      <button
                                        type="button"
                                        className="relative text-gray-400 hover:text-gray-500"
                                        onClick={() => removeProcess(group.uuid)}
                                      >
                                        <span className="absolute -inset-2.5" />
                                        <span className="sr-only">Delete manager</span>
                                        <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                                      </button>
                                    </div>
                                  </li>
                                ) : null
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    {availableSeats > 0 && (
                      <div className={'w-full px-6 mb-6 text-right'}>
                        <div className={'w-full rounded-md bg-indigo-50 px-6 py-8 text-sm text-gray-700'}>
                          <div className={'font-light text-4xl'}>{availableSeats}</div>
                          <span className={'font-semibold text-gray-500'}>
                            Paid {availableSeats > 1 ? 'seats ' : 'seat '}
                          </span>
                          <div className={'pt-2 text-gray-500'}>
                            This {availableSeats > 1 ? 'seats are' : 'seat is'} available until the end of the current
                            billing period -{' '}
                            <span className={'font-bold'}>
                              {format(new Date(subscription.currentPeriodEnd * 1000), 'MMMM do, yyyy')}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {availableSeats === 0 && (
                      <div className={'w-full px-6 mb-6 text-right'}>
                        <div className={'w-full rounded-md bg-indigo-50 px-6 py-8 text-sm text-gray-700'}>
                          <div className={'font-light text-4xl'}>${newSeatProratedPrice / 100}</div>
                          <div className={'pt-2 text-gray-500'}>You will be charged for one more user.</div>
                        </div>
                      </div>
                    )}
                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className={`ml-auto flex text-sm items-center justify-center rounded-md bg-white px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-gray-50 ring-2 ring-indigo-500`}
                          onClick={() => hideInviteUserSidebarProcess()}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          disabled={form.emails.length === 0}
                          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500"
                        >
                          {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
                          Invite
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default memo(Invite)
