import { createSlice } from '@reduxjs/toolkit'
import { updateDescriptionTask } from '../../components/meeting/list/types/task/details/slice'

const initialState = {
  data: {
    description: '',
  },
  openForm: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'updateMeetingTypeTaskDescription',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateDescriptionTask.fulfilled, () => initialState)
  },
  reducers: {
    formInit: (state, action) => {
      state.data.description = action.payload.description
    },
    changeDescription: (state, action) => {
      state.data.description = action.payload.description
    },
    setOpenForm: (state, action) => {
      state.openForm = action.payload.openForm
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.updateMeetingTypeTaskDescription
export const selectFormData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status
export const selectOpenForm = (state) => selectSlice(state).openForm

const { reducer, actions } = slice

export const { changeRequestStatus, formInit, resetForm, changeDescription, setOpenForm } = actions

export default reducer
