import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../../api/workspaceAgent'

export const getGroupUsageData = createAsyncThunk('groups/usage/data', async ({ groupUuid, year, month }, thunkApi) => {
  try {
    const usage = await WorkspaceAgent.getGroupUsageData(groupUuid, year, month)

    return usage
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
