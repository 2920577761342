import React, { memo } from 'react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { NavLink } from 'react-router-dom'
import { changeShowNavigationSidebar } from '../../../reducers/common'
import { useDispatch } from 'react-redux'
import { classNames } from '../../../utils/className'

function Sidebar({ navigation }) {
  const dispatch = useDispatch()

  const changeShowNavigationSidebarProcess = (status) => {
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: status }))
  }

  return (
    <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 xl:block xl:w-64 xl:flex-none xl:border-0 lg:py-8">
      <nav className="flex-none px-4 sm:px-6 lg:px-0">
        <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap xl:flex-col">
          <li>
            <button
              type="button"
              className="mr-4 p-2.5 text-black lg:hidden"
              onClick={() => changeShowNavigationSidebarProcess(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-5 w-5" aria-hidden="true" />
            </button>
          </li>
          {navigation.map((item) => (
            <li key={item.name}>
              {item.type === 'divider' && (
                <div className="relative py-4">
                  <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-100" />
                  </div>
                </div>
              )}
              {item.type === 'button' && (
                <div className="relative pb-4">
                  <button
                    onClick={item.action}
                    className="w-full flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {item.icon && <item.icon className={' text-white h-6 w-6 shrink-0'} aria-hidden="true" />}
                    {item.name}
                  </button>
                </div>
              )}
              {(!item.type || item.type === 'link') && (
                <NavLink end={item.end} to={item.href}>
                  {({ isActive }) => {
                    return (
                      <div
                        className={classNames(
                          isActive
                            ? 'bg-gray-50 text-indigo-600'
                            : 'text-gray-500 hover:text-indigo-600 hover:bg-gray-50',
                          'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-normal items-center',
                        )}
                      >
                        {item.icon && (
                          <item.icon
                            className={classNames(
                              isActive ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                              'h-5 w-5 shrink-0',
                            )}
                            aria-hidden="true"
                          />
                        )}
                        <span className={'font-semibold'}>{item.name}</span>
                      </div>
                    )
                  }}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  )
}

export default memo(Sidebar)
