import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeAgent } from '../../../../../api/meetingTypeAgent'

export const getAllTypes = createAsyncThunk('meeting-type/get-all-types', async ({}, thunkApi) => {
  try {
    const types = await MeetingTypeAgent.getAllTypes()

    return { types }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
