import React, { memo } from 'react'
import { Outlet } from 'react-router-dom'
import MainLayout from '../../common/protected/main-layout/MainLayout'
import Sidebar from '../../common/sidebar/Sidebar'
import { FingerPrintIcon, UserCircleIcon } from '@heroicons/react/24/outline'

const secondaryNavigation = [
  { name: 'Profile', end: true, href: '/account/settings/general', icon: UserCircleIcon },
  { name: 'Security', end: true, href: '/account/settings/security', icon: FingerPrintIcon },
]

function UserSettings() {
  return (
    <MainLayout>
      <div className="max-w-6xl xl:flex lg:gap-x-8 lg:pr-8 lg:pl-4">
        <Sidebar navigation={secondaryNavigation} />

        <main className="py-8 sm:px-6 lg:flex-auto lg:px-0">
          <Outlet />
        </main>
      </div>
    </MainLayout>
  )
}

export default memo(UserSettings)
