import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../../../api/userAgent'

export const updateProfileInformation = createAsyncThunk(
  'user/update',
  async ({ fullName, email, password }, thunkApi) => {
    try {
      await UserAgent.update(fullName, email, password)

      return { fullName, emailChanged: !!password }
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const confirmNewEmail = createAsyncThunk(
  'user/account/confirm-new-email',
  async ({ token, email }, thunkApi) => {
    try {
      await UserAgent.confirmNewEmail(token)

      return { email }
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const removeProfileImage = createAsyncThunk('user/image/remove', async ({}, thunkApi) => {
  try {
    await UserAgent.removeProfileImage()
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const addProfileImage = createAsyncThunk('user/image/add', async ({ file }, thunkApi) => {
  try {
    return await UserAgent.addProfileImage(file)
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})
