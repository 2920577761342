import React, { Fragment, useEffect, useState } from 'react'
import ViewSidebar from './Sidebar'
import { useSelector } from 'react-redux'
import { selectMeeting } from '../../../../reducers/meetings/meeting-details'
import { MeetingStatuses } from '../../../../constants'
import Loader from '../../../common/loader/Loader'
import { flattenDataStructure } from '../../../../utils/flattenDataStructure'
import TableTemplate from './tag-templates/table-template'
import TitleValueTemplate from './tag-templates/title-value-template'
import TextTemplate from './tag-templates/text-template'
import Accordion from './tag-templates/toggle-section-template'
import ToggleTableView from './ToggleTableView'

const statusesMessages = {
  [MeetingStatuses.AI_PROCESSING]: {
    title: 'This meeting is currently processing by our AI.',
    subtitle: 'Please wait, it can takes a few minutes.',
  },
  [MeetingStatuses.TRANSCRIPTION_FAILED]: {
    title: 'Transcription process failed',
    subtitle: 'Please contact our support team.',
  },
  [MeetingStatuses.WAITING_FOR_TRANSCRIPTION]: {
    title: 'This meeting will start processing soon',
    subtitle: 'Please wait, it can takes a few minutes.',
  },
  [MeetingStatuses.AI_PROCESSING_FAILED]: {
    title: 'AI processing failed',
    subtitle: 'Please contact our support team for help.',
  },
}

export default function MeetingDetails({ meeting }) {
  const storedMeeting = useSelector(selectMeeting)

  if (!meeting) {
    meeting = storedMeeting
  }

  const [selectedTagUuid, selectTagUuid] = useState(null)
  const [selectedTag, selectTag] = useState(null)
  const [isTableTemplate, setTableTemplate] = useState(false)

  const selectTemplate = (data) => {
    if (isTableTemplate && typeof data !== 'string') {
      const flattenData = flattenDataStructure(data)
      if (flattenData) {
        return (
          <TableTemplate
            data={flattenData}
            // columnsToDisplay={['title', 'value', 'details_additional_info']}
          />
        )
      } else {
        return ''
      }
    }

    if (Array.isArray(data) && data?.[0]?.items) {
      return <Accordion data={data} />
    }

    if (Array.isArray(data)) {
      return data.map((item, index) => <TitleValueTemplate key={`array-item-${index}`} data={item} />)
    }

    if (typeof data === 'string') {
      const tagName = meeting.processingResults.find((result) => {
        return result.tag.uuid === selectedTag
      })?.tag?.name
      return <TextTemplate title={tagName} text={data} />
    }
  }

  let result = ''
  if (meeting && meeting.uuid && selectedTagUuid) {
    const json = meeting.processingResults.find((result) => result.tag.uuid === selectedTagUuid)

    if (json && json.result) {
      const data = JSON.parse(json.result).data
      result = selectTemplate(data)
    }
  }

  if (!selectedTagUuid && meeting && meeting.uuid && meeting.processingResults.length > 0) {
    selectTagUuid(meeting.processingResults[0].tag.uuid)
  }

  useEffect(() => {
    if (selectedTagUuid && meeting) {
      const selectedResult = meeting.processingResults.find((result) => result.tag.uuid === selectedTagUuid)
      selectTag(selectedResult.tag)
    }
  }, [selectedTagUuid])

  return (
    <div>
      <div>
        {meeting.type && (
          <div>
            <p className="mt-1 text-sm text-gray-500">{meeting.type.name}</p>
            <div className="mt-6 text-sm text-gray-700 sm:col-span-2 whitespace-pre-line">{meeting.description}</div>
          </div>
        )}
      </div>
      <div className="relative pt-4 lg:flex lg:gap-x-16">
        {meeting.status === MeetingStatuses.READY && (
          <>
            <ViewSidebar selectedTagUuid={selectedTagUuid} selectTag={selectTagUuid} meeting={meeting} />
            <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-8">
              <div className={'flex flex-row justify-between content-center items-center pb-8'}>
                <div className={'flex flex-col'}>
                  <div className={'text-gray-700 text-xl font-bold'}>{selectedTag?.name}</div>
                  <div className={'text-gray-500 text-sm font-normal'}>{selectedTag?.description}</div>
                </div>
                <ToggleTableView isTableTemplate={isTableTemplate} setTableTemplate={setTableTemplate} />
              </div>
              <div className="text-gray-500 text-sm whitespace-pre-line">{result}</div>
            </div>
          </>
        )}
        {meeting.status !== MeetingStatuses.READY && (
          <div className="mt-12 w-full text-center">
            {(meeting.status === MeetingStatuses.AI_PROCESSING ||
              meeting.status === MeetingStatuses.WAITING_FOR_TRANSCRIPTION) && (
              <div className={'mx-auto w-12 mb-4'}>
                <Loader size={12} />
              </div>
            )}
            <h3 className="mt-2 text-sm font-semibold text-gray-900">{statusesMessages[meeting.status]?.title}</h3>
            <p className="mt-1 text-sm text-gray-500">{statusesMessages[meeting.status].subtitle}</p>
          </div>
        )}
      </div>
    </div>
  )
}
