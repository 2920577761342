import React, { memo, useEffect } from 'react'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import Chart from 'react-apexcharts'
import { format } from 'date-fns'
import { chartSeries, secondsToDuration } from '../../../../utils/helper'
import {
  changeDateDown,
  changeDateUp,
  selectUsage,
  selectUsageMonth,
  selectUsageYear,
  changeRequestStatus,
  usageUnloaded,
} from '../../../../reducers/workspace/group/usage'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { useParams } from 'react-router'
import { getGroupUsageData } from './slice'
import { WorkspaceStatisticTypes } from '../../../../constants'

function Usage() {
  const chartOptions = {
    chart: {
      type: 'line',
      height: 240,
      toolbar: {
        show: false,
      },
    },
    title: {
      show: '',
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#6466F1'],
    stroke: {
      lineCap: 'round',
      curve: 'smooth',
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#616161',
          fontSize: '12px',
          fontFamily: 'inherit',
          fontWeight: 400,
        },
      },
    },
    grid: {
      show: true,
      borderColor: '#dddddd',
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 5,
        right: 20,
      },
    },
    fill: {
      opacity: 0.8,
    },
    tooltip: {
      theme: 'dark',
    },
  }

  const chartConfigs = (customConfig = {}) => {
    return { ...chartOptions, ...customConfig }
  }

  const dispatch = useDispatch()

  const { groupUuid } = useParams('groupUuid')

  const usage = useSelector(selectUsage)
  const month = useSelector(selectUsageMonth)
  const year = useSelector(selectUsageYear)

  useEffect(() => {
    if (year && month) {
      dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      const usageData = dispatch(getGroupUsageData({ groupUuid, year, month }))

      return () => {
        usageData.abort()
      }
    }
  }, [month, year])

  useEffect(() => {
    return () => {
      dispatch(usageUnloaded())
    }
  }, [])

  return (
    <>
      <div className={'py-8 flex gap-x-4 items-center justify-end'}>
        <span className="isolate inline-flex rounded-md shadow-sm">
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault()
              dispatch(changeDateDown())
            }}
            className="relative inline-flex items-center rounded-l-md bg-white px-1.5 py-1.5 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            className="relative -ml-px inline-flex items-center bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          >
            {format(new Date(`${year}/${month}`), 'MMMM')} {year !== new Date().getUTCFullYear() && year}
          </button>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault()
              dispatch(changeDateUp())
            }}
            className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-1.5 py-1.5 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </span>
        {/*<button*/}
        {/*  type="button"*/}
        {/*  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"*/}
        {/*>*/}
        {/*  Export*/}
        {/*</button>*/}
      </div>
      <div>
        <div className="relative flex flex-col gap-4 overflow-hidden rounded-none bg-transparent bg-clip-border text-gray-700 shadow-none md:flex-row md:items-center">
          <div>
            <h6 className="block font-sans text-base font-semibold leading-relaxed tracking-normal text-blue-gray-900 antialiased">
              Meeting processed
            </h6>
            <p className="block max-w-sm font-sans text-sm font-normal leading-normal text-gray-700 antialiased">
              Amount of the meetings that were processed this month
            </p>
          </div>
        </div>
        <div className="pb-0">
          <Chart
            options={chartConfigs()}
            series={chartSeries(usage, WorkspaceStatisticTypes.MEETINGS_PROCESSED)}
            type="bar"
            width={'100%'}
            height={320}
          />
        </div>
      </div>
      <div>
        <div className="relative mt-8 flex flex-col gap-4 overflow-hidden rounded-none bg-transparent bg-clip-border text-gray-700 shadow-none md:flex-row md:items-center">
          <div>
            <h6 className="block font-sans text-base font-semibold leading-relaxed tracking-normal text-blue-gray-900 antialiased">
              Time transcribed
            </h6>
            <p className="block max-w-sm font-sans text-sm font-normal leading-normal text-gray-700 antialiased">
              Amount of the time of the meetings transcribed
            </p>
          </div>
        </div>
        <div className="pb-0">
          <Chart
            options={chartConfigs({
              yaxis: {
                labels: {
                  formatter: (val) => {
                    return secondsToDuration(val)
                  },
                },
              },
            })}
            series={chartSeries(usage, WorkspaceStatisticTypes.TOTAL_TRANSCRIPTION_TIME, 'Total time')}
            type="bar"
            width={'100%'}
            height={320}
          />
        </div>
      </div>
      {/*<div>*/}
      {/*  <div className="relative mt-8 flex flex-col gap-4 overflow-hidden rounded-none bg-transparent bg-clip-border text-gray-700 shadow-none md:flex-row md:items-center">*/}
      {/*    <div>*/}
      {/*      <h6 className="block font-sans text-base font-semibold leading-relaxed tracking-normal text-blue-gray-900 antialiased">*/}
      {/*        Costs*/}
      {/*      </h6>*/}
      {/*      <p className="block max-w-sm font-sans text-sm font-normal leading-normal text-gray-700 antialiased">*/}
      {/*        Costs for meeting processing*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="pb-0">*/}
      {/*    <Chart*/}
      {/*      options={chartConfigs({*/}
      {/*        yaxis: {*/}
      {/*          labels: {*/}
      {/*            formatter: (val) => {*/}
      {/*              return `$111${val}`*/}
      {/*            },*/}
      {/*          },*/}
      {/*        },*/}
      {/*      })}*/}
      {/*      series={chartSeries(1, 'Cost')}*/}
      {/*      type="bar"*/}
      {/*      width={'100%'}*/}
      {/*      height={320}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  )
}

export default memo(Usage)
