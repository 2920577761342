import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeAgent } from '../../../../../api/meetingTypeAgent'

export const deleteMeetingType = createAsyncThunk('meeting-type-v2/delete', async ({ uuid }, thunkApi) => {
  try {
    await MeetingTypeAgent.deleteMeetingType(uuid)

    return { deletedUuid: uuid }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
