import { Fragment, useEffect, useState } from 'react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PlusIcon } from '@heroicons/react/24/outline'
import CreateMeetingType from '../create-form-popup/CreateMeetingType'
import {
  selectAllTypes,
  selectRequestStatus,
  changeRequestStatus,
} from '../../../../../reducers/meeting-type/type-list'
import { getAllTypes } from './slice'
import { typesUnloaded } from '../../../../../reducers/meeting-type/type-list'
import { structuredMeetingTypes } from '../../../../../components-helpers/structured-meeting-types'
import {
  selectOpenForm as selectOpenNewMeetingTypeForm,
  setOpenForm as setOpenNewMeetingTypeForm,
} from '../../../../../reducers/meeting-type/create-type'
import ConfirmDeleteTypePopup from '../confirm-delete-popup/ConfirmDeletePopup'
import DuplicateMeetingType from '../duplicate-form-popup/DuplicateMeetingType'
import { REQUEST_STATUSES } from '../../../../../reducers/reducer-helper'
import Loader from '../../../../common/loader/Loader'
import MeetingTypeListItem from './MeetingTypeListItem'

export default function MeetingTypeList() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const types = useSelector(selectAllTypes)

  const [commonTypes, setCommonTypes] = useState(null)
  const [customTypes, setCustomTypes] = useState(null)

  const openNewMeetingTypeForm = useSelector(selectOpenNewMeetingTypeForm)
  const setOpenNewMeetingTypeFormProcess = (status) => {
    dispatch(setOpenNewMeetingTypeForm({ openForm: status }))
  }

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchTypes = dispatch(getAllTypes({}))

    return () => {
      dispatch(typesUnloaded())
      fetchTypes.abort()
    }
  }, [])

  useEffect(() => {
    if (types && types.length > 0) {
      structuredMeetingTypes(types).then(({ structuredTypes }) => {
        structuredTypes.forEach((types) => {
          types.type === 'common' ? setCommonTypes(types) : setCustomTypes(types)
        })
      })
    }
  }, [types])

  return (
    <>
      {types && requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
      {requestStatus === REQUEST_STATUSES.DONE && (
        <div className={'space-y-12'}>
          {commonTypes !== null && (
            <div>
              <h3 className={'text-sm font-medium text-gray-700 pb-4'}>Asistme meeting types</h3>
              <dl className={'grid grid-cols-2 gap-4 '}>
                {commonTypes &&
                  commonTypes.items.map((type) => (
                    <MeetingTypeListItem key={`common-type-${type.uuid}`} type={type} isCommon={true} />
                  ))}
              </dl>
            </div>
          )}

          <div>
            <h3 className={'text-sm font-medium text-gray-700 pb-4'}>Your own meeting types</h3>
            <dl className={'space-y-4'}>
              <button
                type={'button'}
                className={
                  'w-full p-4 rounded-md bg-gray-50 hover:bg-indigo-50 ring-2 ring-indigo-100 transition-all text-left'
                }
                onClick={() => {
                  setOpenNewMeetingTypeFormProcess(true)
                }}
              >
                <div className={'flex flex-row items-center'}>
                  <div className={'w-10 h-10 flex items-center justify-center rounded-md mr-4'}>
                    <PlusIcon className={'w-6 h-6 text-gray-500'} />
                  </div>
                  <div className={'flex flex-col'}>
                    <div className="text-sm font-medium leading-6 text-gray-500">Create new meeting type</div>
                    <div className="text-xs text-gray-400">
                      Here you can create a new meeting type for your own needs
                    </div>
                  </div>
                </div>
              </button>
              {customTypes === null && (
                <div className={'text-xs text-gray-500 py-8'}>There are no custom types yet.</div>
              )}
              {customTypes !== null && (
                <>
                  {customTypes.items.map((type) => (
                    <MeetingTypeListItem key={`own-type-${type.uuid}`} type={type} />
                  ))}
                </>
              )}
            </dl>
          </div>
        </div>
      )}

      <div className="z-30">
        <CreateMeetingType open={openNewMeetingTypeForm} setOpen={setOpenNewMeetingTypeFormProcess} />
        <ConfirmDeleteTypePopup />
        <DuplicateMeetingType />
      </div>
    </>
  )
}
