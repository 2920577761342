import agent from '../api/agent'
import { signIn } from '../components/user/sign-in/slice'
import { createProfile } from '../components/user/sign-up/slice'
import { logout } from '../components/common/protected/main-layout/slice'
import { acceptWithSignIn, acceptWithSignUp } from '../components/workspace/accept-invitation/slice'
import { HttpStatusCode } from 'axios'
import { Errors } from '../errors'
import { redirect } from '../reducers/common'

const localStorageMiddleware = (store) => (next) => (action) => {
  if (action.payload?.statusCode === HttpStatusCode.InternalServerError) {
    if (action.payload.errorCode === Errors.LEGAL_ACCEPTANCE_REQUIRED_ERROR.code) {
      action.asyncDispatch(redirect({ redirectTo: '/legal/accept' }))
    }
    if (action.payload.errorCode === Errors.SUBSCRIPTION_IS_NOT_ACTIVATED_ERROR.code) {
      action.asyncDispatch(redirect({ redirectTo: '/subscription/activate' }))
    }
  }

  switch (action.type) {
    case createProfile.fulfilled.type:
    case signIn.fulfilled.type:
    case acceptWithSignUp.fulfilled.type:
    case acceptWithSignIn.fulfilled.type:
      window.localStorage.setItem('api-token', action.payload.apiToken)
      agent.setApiToken(action.payload.apiToken)
      break

    case logout.fulfilled.type:
      window.localStorage.removeItem('api-token')
      agent.setApiToken(undefined)
      break
  }

  return next(action)
}

export { localStorageMiddleware }
