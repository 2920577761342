import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../api/workspaceAgent'

export const createNewWorkspace = createAsyncThunk(
  'workspaces/create',
  async ({ name, plan, paymentMethodId }, thunkApi) => {
    try {
      const workspace = await WorkspaceAgent.createWorkspaces(name, plan, paymentMethodId)

      return { workspace }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)
