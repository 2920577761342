import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../../api/workspaceAgent'

export const updateGroup = createAsyncThunk(
  'workspaces/groups/update',
  async ({ uuid, name, openMeetings }, thunkApi) => {
    try {
      const group = await WorkspaceAgent.updateGroup(uuid, name, openMeetings)

      return { group }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)
