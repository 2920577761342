import React, { memo, useEffect, useState } from 'react'
import { classNames } from '../../../../utils/className'

function SidebarV2({ selectedTaskUuid, selectTask, meeting }) {
  const [processingResults, setProcessingResults] = useState([])

  useEffect(() => {
    if (meeting.uuid !== '') {
      const orderMap = new Map()
      meeting?.typeV2?.tasksOrdering.forEach((uuid, index) => {
        orderMap[uuid] = index
      })

      const processingResultsCopy = [...meeting.processingResults]

      processingResultsCopy.sort((a, b) => {
        const indexA = orderMap[a.task.uuid] !== undefined ? orderMap[a.task.uuid] : -1
        const indexB = orderMap[b.task.uuid] !== undefined ? orderMap[b.task.uuid] : -1

        return indexA - indexB
      })

      setProcessingResults(processingResultsCopy)
    }
  }, [meeting])

  useEffect(() => {
    if (!selectedTaskUuid && processingResults.length > 0) {
      selectTask(processingResults[0]?.task.uuid)
    }
  }, [processingResults])

  return (
    <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-48 lg:flex-none lg:border-0 lg:py-8">
      {meeting.uuid && (
        <nav className="flex-none px-4 sm:px-6 lg:px-0">
          <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
            {processingResults.map((result) => (
              <li key={result.task.uuid}>
                <button
                  type="button"
                  onClick={() => selectTask(result.task.uuid)}
                  className={classNames(
                    result.task.uuid === selectedTaskUuid
                      ? 'bg-gray-50 text-indigo-600'
                      : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                    'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold',
                  )}
                >
                  {result.task.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </aside>
  )
}

export default memo(SidebarV2)
