import React from 'react'
import Logo from '../../../assets/images/as_new_logo_full.svg'
import { ReactSVG } from 'react-svg'
import { NavLink } from 'react-router-dom'

export default function SharedLayout({ children }) {
  return (
    <div className="absolute top-0 bottom-0 left-0 w-full">
      <header className={'flex flex-col'}>
        <div className={'h-16 flex items-center'}>
          <NavLink to={'/sign-up'} className={'p-4'}>
            <ReactSVG
              src={Logo}
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'height: 16px;')
              }}
            />
          </NavLink>
        </div>
      </header>
      <div className="absolute right-0 w-full top-16 bottom-0 overflow-auto">
        <main className="relative min-h-full ">{children}</main>
      </div>
    </div>
  )
}
