import React, { memo } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import {
  setShowPopupState,
  selectShowPopup,
} from '../../../../../../reducers/workspace/settings/billing/add-payment-method-form'
import AddPaymentMethodForm from './AddPaymentMethodForm'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from '../../../../../../configs'
import { Elements } from '@stripe/react-stripe-js'
import { attachPaymentMethod } from './slice'

const stripePromise = loadStripe(STRIPE_KEY)

function AddPaymentMethod() {
  const dispatch = useDispatch()

  const closeFormHandler = () => {
    dispatch(setShowPopupState({ showPopup: false }))
  }

  const addMethodHandler = (paymentMethod, isDefault) => {
    dispatch(attachPaymentMethod({ paymentMethod, isDefault }))
  }
  const cancelHandler = (event) => {
    event.preventDefault()
    closeFormHandler()
  }

  const showPopup = useSelector(selectShowPopup)

  return (
    <Transition.Root show={showPopup} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={closeFormHandler}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg max-w-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full">
                <div className="bg-gray-50 px-6 py-4 rounded-t-lg">
                  <div className="flex items-start justify-between space-x-3">
                    <div className="space-y-1">
                      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                        Add payment method
                      </Dialog.Title>
                      <p className="text-sm text-gray-500">
                        Add your credit card details below. This card will be saved to your workspace and can be removed
                        at any time.
                      </p>
                    </div>
                    <div className="flex h-7 items-center">
                      <button
                        type="button"
                        className="relative text-gray-400 hover:text-gray-500"
                        onClick={() => closeFormHandler()}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <Elements stripe={stripePromise}>
                  <AddPaymentMethodForm addMethodHandler={addMethodHandler} cancelHandler={cancelHandler} />
                </Elements>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default memo(AddPaymentMethod)
