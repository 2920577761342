import React, { memo, useEffect } from 'react'
import { Fragment } from 'react'
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/20/solid'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAllApiKeys,
  selectRequestStatus,
  changeRequestStatus,
  apiKeysUnloaded,
} from '../../../../reducers/workspace/api-keys/list'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import Loader from '../../../common/loader/Loader'
import { getAllApiKeys } from './slice'
import { setOpenForm as setOpenNewApiKeyForm } from '../../../../reducers/workspace/api-keys/create'
import { openForm as openDeleteApiKeyForm } from '../../../../reducers/workspace/api-keys/delete'
import CreateApiKey from './create-popup/CreatePopup'
import { Menu, Transition } from '@headlessui/react'
import { classNames } from '../../../../utils/className'
import ConfirmDeleteApiKeyPopup from './confirm-delete-popup/ConfirmDeletePopup'

function ApiKeys() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const apiKeys = useSelector(selectAllApiKeys)

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchApiKeys = dispatch(getAllApiKeys())

    return () => {
      dispatch(apiKeysUnloaded())
      fetchApiKeys.abort()
    }
  }, [])

  const setOpenNewApiKeyFormProcess = (status) => {
    dispatch(setOpenNewApiKeyForm({ openForm: status }))
  }

  const setOpenDeleteApiKeyFormProcess = (uuid, name) => {
    dispatch(openDeleteApiKeyForm({ uuid, name }))
  }

  return (
    <>
      <div className="mx-auto space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <div className={'flex flex-col pb-6 border-b border-gray-200'}>
            <h2 className="text-base font-semibold leading-7 text-gray-900">API Keys</h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">Your workspace API Keys</p>
          </div>
          <dl className="max-w-4xl text-sm leading-6">
            <div className={'pt-4 text-gray-700'}>Your secret API keys are listed below.</div>
            <div className={'pt-4 text-gray-700'}>
              Do not share your API key with others, or expose it in the browser or other client-side code. In order to
              protect the security of your account, OpenAI may also automatically disable any API key that we've found
              has leaked publicly.
            </div>
            <div className="mt-8 flow-root">
              <div>
                <button
                  onClick={() => {
                    setOpenNewApiKeyFormProcess(true)
                  }}
                  type={'button'}
                  className="flex ml-auto items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Create new API key
                </button>
                <div className="inline-block min-w-full py-2 align-middle">
                  {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
                  {requestStatus === REQUEST_STATUSES.DONE && (!apiKeys || apiKeys.length === 0) && (
                    <div className={'text-sm text-gray-700 py-4'}>There are no API keys yet.</div>
                  )}
                  {requestStatus === REQUEST_STATUSES.DONE && apiKeys && apiKeys.length > 0 && (
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Name
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Secret key
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Created date
                          </th>
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                            <span className="sr-only">Delete</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {apiKeys.map((key) => (
                          <tr key={key.uuid}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                              {key.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{key.value}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{key.createdTime}</td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 sm:pr-0">
                              <Menu as="div" className="relative flex-none">
                                <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                  <span className="sr-only">Open options</span>
                                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                    <Menu.Item>
                                      {({ focus }) => (
                                        <button
                                          type={'button'}
                                          onClick={() => {
                                            setOpenDeleteApiKeyFormProcess(key.uuid, key.name)
                                          }}
                                          className={classNames(
                                            focus ? 'bg-gray-50' : '',
                                            'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-700',
                                          )}
                                        >
                                          Delete API key
                                        </button>
                                      )}
                                    </Menu.Item>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </dl>
        </div>
      </div>
      <CreateApiKey />
      <ConfirmDeleteApiKeyPopup />
    </>
  )
}

export default memo(ApiKeys)
