import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../api/workspaceAgent'

export const upgrade = createAsyncThunk('workspaces/upgrade', async ({ paymentMethodId }, thunkApi) => {
  try {
    const workspace = await WorkspaceAgent.upgrade(paymentMethodId)

    return { workspace }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
