import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTemplates } from './slice'
import {
  changeRequestStatus,
  templatesUnloaded,
  selectRequestStatus as selectTemplateRequestStatus,
  selectAllTemplates,
} from '../../../../reducers/meeting-template/template-list'
import {
  changeRequestStatus as changeGetTypeRequestStatus,
  reset as resetTypeDetails,
  selectRequestStatus as selectTypeRequestStatus,
  selectTypeDetails,
} from '../../../../reducers/meeting-type/type-details'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import Loader from '../../../common/loader/Loader'
import { useParams } from 'react-router'
import { getMeetingTypeDetails } from './details/slice'

export default function Types() {
  const dispatch = useDispatch()

  const getTemplateRequestStatus = useSelector(selectTemplateRequestStatus)
  const getTypeRequestStatus = useSelector(selectTypeRequestStatus)
  const templates = useSelector(selectAllTemplates)
  const type = useSelector(selectTypeDetails)

  const { typeUuid } = useParams()

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchTemplates = dispatch(getAllTemplates({}))

    return () => {
      dispatch(templatesUnloaded())
      fetchTemplates.abort()
    }
  }, [])

  useEffect(() => {
    if (typeUuid) {
      dispatch(changeGetTypeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      const fetchType = dispatch(getMeetingTypeDetails({ uuid: typeUuid }))

      return () => {
        dispatch(resetTypeDetails())
        fetchType.abort()
      }
    }
  }, [typeUuid])

  return (
    <div className="py-8 pb-32 sm:px-6 lg:flex-auto lg:px-0">
      {!templates &&
        getTemplateRequestStatus === REQUEST_STATUSES.PENDING &&
        (!typeUuid || (type && getTypeRequestStatus === REQUEST_STATUSES.PENDING)) && <Loader fullSize={true} />}
      {templates &&
        getTemplateRequestStatus === REQUEST_STATUSES.DONE &&
        (!typeUuid || getTypeRequestStatus === REQUEST_STATUSES.DONE) && <Outlet />}
    </div>
  )
}
