import React, { useEffect, useState, Fragment, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeDescription,
  changeName,
  changeRequestStatus,
  changeTypeUuid,
  selectFormData,
  selectRequestStatus,
  selectShowPopup,
  setShowPopupState,
  resetForm,
} from '../../../../reducers/records/user-for-meeting-form'
import { selectShowPopup as selectAddPaymentMethodPopup } from '../../../../reducers/workspace/settings/billing/add-payment-method-form'
import {
  selectShowPopup as selectShowDepositPopup,
  setShowPopupState as setShowDepositForm,
} from '../../../../reducers/workspace/settings/billing/deposit-form'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { selectWorkspace } from '../../../../reducers/workspace/workspace'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { PRICE, WORKSPACE_PLANS, WORKSPACE_USER_ROLES } from '../../../../constants'
import Loader from '../../../common/loader/Loader'
import { classNames } from '../../../../utils/className'
import { getAllGroups } from '../../../groups/slice'

import { structuredMeetingTypes } from '../../../../components-helpers/structured-meeting-types'
import { createNewMeeting } from '../../../meeting/create-dialog/slice'
import { useParams } from 'react-router'
import { selectRecord } from '../../../../reducers/records/record-details'

export default function UseForMeeting() {
  const dispatch = useDispatch()

  const { recordUuid } = useParams()

  const record = useSelector(selectRecord)

  const requestStatus = useSelector(selectRequestStatus)
  const formData = useSelector(selectFormData)
  const workspace = useSelector(selectWorkspace)
  const showPopup = useSelector(selectShowPopup)
  const showDepositPopup = useSelector(selectShowDepositPopup)
  const showAddPaymentMethodPopup = useSelector(selectAddPaymentMethodPopup)

  const [types, setTypes] = useState(null)
  const [showMoreConfigs, setShowMoreConfigs] = useState(false)
  const [filteredTypes, setFilteredTypes] = useState(null)
  const [formError, setFormError] = useState(null)

  useEffect(() => {
    if (showPopup) {
      structuredMeetingTypes().then(({ structuredTypes, allTypes }) => {
        setTypes(allTypes)
        setFilteredTypes(structuredTypes)
      })

      let fetchGroups
      if (workspace.plan !== WORKSPACE_PLANS.PERSONAL) {
        fetchGroups = dispatch(getAllGroups())
      }

      return () => {
        setTimeout(() => {
          dispatch(resetForm())
          setTypes(null)
          setFilteredTypes(null)
          setFormError(null)
          if (fetchGroups) {
            fetchGroups.abort()
          }
        }, 200)
      }
    }
  }, [showPopup])

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const changeDescriptionProcess = (event) => {
    event.preventDefault()
    dispatch(changeDescription({ description: event.target.value }))
  }

  const changeTypeUuidProcess = (typeUuid) => {
    dispatch(changeTypeUuid({ typeUuid }))
    if (formError && formError.field === 'meeting-type') {
      setFormError(null)
    }
  }

  const showDepositForm = () => {
    dispatch(setShowDepositForm({ showPopup: true }))
  }

  const createMeeting = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))

    if (!formData.typeUuid) {
      setFormError({
        field: 'meeting-type',
        message: 'Meeting type is required',
      })
      dispatch(changeRequestStatus({ status: REQUEST_STATUSES.NOT_TRIGGERED }))

      return
    }

    dispatch(
      createNewMeeting({
        recordUuid: recordUuid,
        groupUuid: record.groupUuid,
        typeUuid: formData.typeUuid,
        name: formData.name,
        description: formData.description,
        text: null,
      }),
    )
  }

  const closeFormHandler = () => {
    dispatch(setShowPopupState({ showPopup: false }))
  }

  const selectedType = formData.typeUuid && types ? types.find((type) => type.uuid === formData.typeUuid) : null

  const ErrorMessage = (message) => {
    return <div className={'text-xs text-red-400 pt-1 pl-2'}>{message}</div>
  }

  return (
    <Transition.Root show={showPopup && !showDepositPopup && !showAddPaymentMethodPopup} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={closeFormHandler}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {record && (
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg max-w-xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full">
                  <div className="bg-gray-50 px-6 py-4 rounded-t-lg">
                    <div className="flex items-start justify-between space-x-3">
                      <div className="py-4 space-y-2">
                        <Dialog.Title className="text-2xl font-semibold leading-6 text-gray-700">
                          Use this record for new meeting
                        </Dialog.Title>
                        <p className="text-sm text-gray-500">
                          {workspace.balance < 0
                            ? 'Not enough credits'
                            : 'Get started by filling in the information below to create new meeting.'}
                        </p>
                      </div>
                      <div className="flex h-7 items-center">
                        <button
                          type="button"
                          className="relative text-gray-400 hover:text-gray-500"
                          onClick={() => closeFormHandler()}
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  {workspace.balance < PRICE.FIRST_LLM_REQUEST && (
                    <div className="px-6 py-8 text-center">
                      <p className={'text-sm text-gray-700'}>Not enough credits for processing more meetings</p>
                      {(workspace.user.role === WORKSPACE_USER_ROLES.OWNER ||
                        workspace.user.role === WORKSPACE_USER_ROLES.ADMIN) && (
                        <div>
                          <button
                            type="button"
                            onClick={showDepositForm}
                            className="mt-4 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Deposit
                          </button>
                        </div>
                      )}
                      {workspace.user.role === WORKSPACE_USER_ROLES.MEMBER && (
                        <p>Please ask your manager to replenish Workspace credits balance</p>
                      )}
                    </div>
                  )}

                  {workspace.balance >= PRICE.FIRST_LLM_REQUEST && (
                    <form onSubmit={createMeeting}>
                      <div className={'px-6 py-4'}>
                        {!record.groupUuid && !workspace.allowPrivateMeetings && (
                          <div className={'text-sm px-2 pb-2 text-gray-500'}>
                            You can not use this record for new meeting, as private records & meetings are not allowed
                            in your workspace.
                          </div>
                        )}
                        <div className={' mt-8'}>
                          <div>
                            <Menu as="div" className="relative inline-block gap-x-4 w-full text-left hover:bg-gray-50">
                              {({ open }) => (
                                <>
                                  <Menu.Button
                                    className={classNames(
                                      'w-full  hover:bg-gray-50 bg-white justify-between flex items-center gap-x-2 px-4 rounded-md ring-1  py-2 text-sm leading-6',
                                      formError && formError.field === 'meeting-type'
                                        ? 'ring-red-600'
                                        : 'ring-gray-200',
                                    )}
                                  >
                                    <span className="sr-only">Open user menu</span>
                                    <span
                                      className={classNames(
                                        'text-sm ',
                                        selectedType ? 'text-gray-700 font-medium' : 'text-gray-500',
                                      )}
                                      aria-hidden="true"
                                    >
                                      {selectedType ? selectedType.name : 'Select meeting type'}
                                    </span>
                                    <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </Menu.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute w-full max-h-64 overflow-y-scroll z-10 mb-0 py-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      {filteredTypes &&
                                        filteredTypes.map((type, typeIndex) => (
                                          <div key={`meeting-type-${typeIndex}`}>
                                            {type.title && (
                                              <div
                                                className={classNames(
                                                  'text-xs text-gray-400 py-1 px-4 ',
                                                  typeIndex > 0 ? 'pt-2 mt-2 border-t border-gray-100' : '',
                                                )}
                                              >
                                                {type.title}
                                              </div>
                                            )}
                                            {type.items &&
                                              type.items.map((item) => (
                                                <Menu.Item key={item.name}>
                                                  {({ active }) => {
                                                    return (
                                                      <button
                                                        type="button"
                                                        onClick={() => {
                                                          changeTypeUuidProcess(item.uuid)
                                                        }}
                                                        className={classNames(
                                                          selectedType && selectedType.uuid === item.uuid
                                                            ? 'bg-gray-50 font-semibold'
                                                            : '',
                                                          active ? 'bg-gray-50' : '',
                                                          'w-full text-left block px-6 py-1 text-sm leading-6 text-gray-700',
                                                        )}
                                                      >
                                                        <div>{item.name}</div>
                                                        <div className={'text-xs text-gray-500'}>
                                                          {item.description}
                                                        </div>
                                                      </button>
                                                    )
                                                  }}
                                                </Menu.Item>
                                              ))}
                                          </div>
                                        ))}
                                    </Menu.Items>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                            {formError && formError.field === 'meeting-type' && ErrorMessage(formError.message)}
                          </div>
                          <div className="w-full my-8" />
                        </div>
                        <div className={'text-gray-700 mt-4'}>
                          <button
                            type={'button'}
                            onClick={(e) => {
                              e.preventDefault()
                              setShowMoreConfigs(!showMoreConfigs)
                            }}
                            className={classNames(
                              showMoreConfigs ? 'bg-gray-50 rounded-b-none' : '',
                              'flex flex-row ml-auto py-2 px-4 rounded-md justify-between items-center hover:bg-gray-50',
                            )}
                          >
                            <div className={'text-sm font-semibold mr-4'}>More configs</div>
                            {!showMoreConfigs && <ChevronDownIcon className={'w-6 h-6'} />}
                            {showMoreConfigs && <ChevronUpIcon className={'w-6 h-6'} />}
                          </button>
                        </div>
                        {showMoreConfigs && (
                          <div className={'bg-gray-50 pl-8 pr-4 py-2 rounded-md'}>
                            <div className={'mt-4'}>
                              <div
                                className={
                                  'flex bg-white rounded-md ring-1 ring-inset ring-gray-200 focus-within:ring-inset focus-within:ring-indigo-600'
                                }
                              >
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  autoComplete="name"
                                  onChange={changeNameProcess}
                                  value={formData.name}
                                  className={
                                    'block flex-1 border-0 bg-transparent py-1.5 px-3 text-gray-700 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
                                  }
                                  placeholder="Meeting name..."
                                />
                              </div>
                            </div>
                            <div className={'mt-8'}>
                              <textarea
                                id="description"
                                name="description"
                                rows={3}
                                onChange={changeDescriptionProcess}
                                value={formData.description}
                                className="block w-full max-w-2xl rounded-md border-0 py-1.5 text-gray-700 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder={'Additional context...'}
                              />
                              <p className=" leading-6 text-xs text-gray-400">
                                This information will be used by AI as additional context for analysis
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="grid grid-cols-2 justify-center space-x-4 py-6 mt-8 sm:mt-6">
                          <button
                            type={'button'}
                            onClick={(event) => {
                              event.preventDefault()
                              closeFormHandler()
                            }}
                            className={`text-sm rounded-md bg-white px-3 py-2 font-medium text-gray-700 hover:text-gray-600 hover:bg-gray-50 ring-1 ring-gray-200`}
                          >
                            Cancel
                          </button>

                          <button
                            type="submit"
                            className="flex flex-row justify-center items-center text-sm text-white rounded-md bg-indigo-600 px-3 py-2 font-medium hover:bg-indigo-500 ring-1 ring-indigo-600 hover:ring-indigo-500"
                          >
                            {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
                            Send to process
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        )}
      </Dialog>
    </Transition.Root>
  )
}
