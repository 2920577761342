import { Fragment, useEffect, useState } from 'react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import UpdateMeetingType from '../update-form-popup/UpdateMeetingType'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import ConfirmDeleteTypePopup from '../confirm-delete-popup/ConfirmDeletePopup'
import { openForm as openUpdateForm } from '../../../../../reducers/meeting-type/update-type'
import { classNames } from '../../../../../utils/className'
import { openForm as openDeleteForm } from '../../../../../reducers/meeting-type/delete-type'
import { selectTypeDetails } from '../../../../../reducers/meeting-type/type-details'
import { selectAllTemplates } from '../../../../../reducers/meeting-template/template-list'

export default function MeetingTypeDetails() {
  const dispatch = useDispatch()

  const type = useSelector(selectTypeDetails)
  const templates = useSelector(selectAllTemplates)

  const [template, setTemplate] = useState(null)

  const openDeleteConfirmationFormProcess = (event) => {
    event.preventDefault()

    dispatch(openDeleteForm({ uuid: type.uuid, name: type.name }))
  }

  useEffect(() => {
    if (type && type.templateUuid) {
      const template = templates.find((templateItem) => templateItem.uuid === type.templateUuid)
      if (template) {
        setTemplate(template)
      }
    }
  }, [type])

  const openUpdateTypeFormProcess = (event) => {
    event.preventDefault()

    if (type) {
      dispatch(
        openUpdateForm({
          uuid: type.uuid,
          name: type.name,
          templateUuid: type.templateUuid,
          description: type.description,
          context: type.context,
          variables: type.variables,
        }),
      )
    }
  }

  return (
    <>
      {type && (
        <div>
          <div>
            <div className={'flex flex-row justify-between items-center'}>
              <div className="flex flex-row items-center px-4 sm:px-0">
                <NavLink
                  className={'p-4 rounded-md bg-gray-50 hover:bg-indigo-50 mr-6 cursor-pointer'}
                  to={`/meetings/types`}
                >
                  <ArrowLeftIcon className={'w-5 h-5'} />
                </NavLink>
                <div>
                  <h3 className="text-base font-semibold leading-7 text-gray-900">{type.name}</h3>
                  <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                    Meeting type details and configurations
                  </p>
                </div>
              </div>
              <div className={'flex flex-row gap-x-4 items-center'}>
                <div>
                  <NavLink
                    to={`/meetings/types/${type.uuid}/tasks`}
                    className="flex items-center rounded-md bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    <span>Meeting type AI tasks</span>
                    <ArrowRightIcon className={'ml-2 w-4 h-4'} />
                  </NavLink>
                </div>
                <Menu as="div" className="relative flex-none">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ focus }) => (
                          <button
                            onClick={openUpdateTypeFormProcess}
                            className={classNames(
                              focus ? 'bg-gray-50' : '',
                              'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900',
                            )}
                          >
                            Update type
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ focus }) => (
                          <button
                            onClick={openDeleteConfirmationFormProcess}
                            className={classNames(
                              focus ? 'bg-gray-50' : '',
                              'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900',
                            )}
                          >
                            Delete type
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-normal leading-6 col-span-1 text-gray-500">Uuid</dt>
                  <dd className="col-span-3 mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{type.uuid}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-normal leading-6 col-span-1 text-gray-500">Name</dt>
                  <dd className="col-span-3 mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{type.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-normal leading-6 col-span-1 text-gray-500">Description</dt>
                  <dd className="col-span-3 mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {type.description}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-normal leading-6 col-span-1 text-gray-500">Meeting type template</dt>
                  <dd className="col-span-3 mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {template?.name}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-normal leading-6 col-span-1 text-gray-500">Context</dt>
                  <dd className="col-span-3 mt-1 text-sm leading-6 whitespace-pre-line text-gray-700 sm:col-span-2 sm:mt-0">
                    {type.context}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-normal leading-6 col-span-1 w-64 text-gray-500">Variables</dt>
                  {!type.variables ||
                    (type.variables.length === 0 && (
                      <div className={'text-sm text-gray-500 py-4 col-span-3'}>The are no variables yet.</div>
                    ))}
                  {type.variables && type.variables.length > 0 && (
                    <dd className="mt-1 col-span-3 flex flex-col gap-y-4">
                      {type.variables.map((variable, index) => (
                        <div key={`variable-${variable}-${index}`} className={'w-full p-4 rounded-md bg-gray-50'}>
                          <div className={'text-gray-700 font-semibold text-sm leading-4 uppercase'}>
                            {'<'} {variable.name} {'>'}
                          </div>
                          <div className={'text-gray-500 text-sm pt-4 font-normal leading-4'}>
                            <span className={'font-semibold text-gray-700'}></span>
                            <div className={'py-2 whitespace-pre-wrap leading-6'}>{variable.value}</div>
                            <span className={'font-semibold text-gray-700'}></span>
                          </div>
                        </div>
                      ))}
                    </dd>
                  )}
                </div>
              </dl>
            </div>
          </div>
        </div>
      )}
      <ConfirmDeleteTypePopup />
      <UpdateMeetingType />
    </>
  )
}
