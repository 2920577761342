import { createSlice } from '@reduxjs/toolkit'
import { failed } from '../../reducer-helper'
import { removeUser } from '../../../components/users/remove/slice'

const initialState = {
  userUuid: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'removeWorkspaceUser',
  initialState,
  extraReducers(builder) {
    builder.addCase(removeUser.fulfilled, () => initialState)
    builder.addCase(removeUser.rejected, failed)
  },
  reducers: {
    setUserUuidForRemove: (state, action) => {
      state.userUuid = action.payload.userUuid
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetData: () => initialState,
  },
})

const selectSlice = (state) => state.removeWorkspaceUser
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status
export const selectUserUuid = (state) => selectSlice(state).userUuid

const { reducer, actions } = slice
export const { changeRequestStatus, setUserUuidForRemove } = actions

export default reducer
