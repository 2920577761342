import React, { Suspense } from 'react'
import { memo } from 'react'
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'
import getRoutes from './Routes'

function App() {
  const router = createBrowserRouter(createRoutesFromElements(getRoutes()))

  return (
    <Suspense fallback={<p>Loading...</p>}>
      <RouterProvider router={router} />
    </Suspense>
  )
}

export default memo(App)
