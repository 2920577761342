import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../api/workspaceAgent'

export const inviteUsers = createAsyncThunk('workspaces/users/invite', async ({ emails, groups }, thunkApi) => {
  try {
    const response = await WorkspaceAgent.inviteUsers(emails, groups)

    return response
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
