import { requests } from './agent'

export const BillingAgent = {
  deposit: (amount, paymentMethodId) => {
    return requests.post('/1_0/payments/replenish', { amount, paymentMethodId })
  },

  getPaymentMethods: () => {
    return requests.get('/1_0/payment-methods/list')
  },

  getLastInvoice: () => {
    return requests.get('/1_0/payments/last-invoice')
  },

  attachPaymentMethod: (paymentMethodId, isDefault) => {
    return requests.post('/1_0/payment-methods/attach', {
      paymentMethodId,
      isDefault,
    })
  },

  deletePaymentMethod: (paymentMethodId) => {
    return requests.post(`/1_0/payment-methods/${paymentMethodId}/delete`)
  },

  setDefaultPaymentMethod: (paymentMethodId) => {
    return requests.post(`/1_0/payment-methods/${paymentMethodId}/set-default`)
  },

  payLastInvoice: (paymentMethodId) => {
    return requests.post(`/1_0/payments/pay-last-invoice`, { paymentMethodId })
  },

  getNewSeatProratedPrice: () => {
    return requests.get(`/1_0/payments/subscriptions/new-seat-prorated-price`)
  },

  getInvoices: () => {
    return requests.get(`/1_0/payments/invoices/list`)
  },

  rechargeUpdate: (enabled, amountAt, amountTo) => {
    return requests.post(`/1_0/workspaces/payments/recharge-update`, { enabled, amountAt, amountTo })
  },
}
