import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectWorkspace } from '../../../../../reducers/workspace/workspace'
import { setShowPopupState } from '../../../../../reducers/workspace/settings/billing/deposit-form'
import { setShowPopupState as setShowRechargePopupState } from '../../../../../reducers/workspace/settings/billing/auto-recharge-form'
import { PRICE, WORKSPACE_PLANS, WORKSPACE_PLANS_NAMES } from '../../../../../constants'
import { setShowPopupState as setShowCancelSubscriptionPopupState } from '../../../../../reducers/workspace/settings/cancel-subscription-form'
import { Link } from 'react-router-dom'
import SubscriptionWarnings from '../../../../common/subscription-warnings/SubscriptionWarnings'
import { format } from 'date-fns'
import {
  selectSubscription,
  changeRequestStatus,
  selectRequestStatus,
} from '../../../../../reducers/workspace/subscription'
import { REQUEST_STATUSES } from '../../../../../reducers/reducer-helper'
import { getSubscription } from './slice'
import Loader from '../../../../common/loader/Loader'
import { classNames } from '../../../../../utils/className'

function Overview() {
  const workspace = useSelector(selectWorkspace)
  const subscription = useSelector(selectSubscription)
  const requestStatus = useSelector(selectRequestStatus)
  const dispatch = useDispatch()

  const showDepositForm = () => {
    dispatch(setShowPopupState({ showPopup: true }))
  }

  const showRechargeForm = () => {
    dispatch(
      setShowRechargePopupState({
        enabled: workspace.autoRechargeConfig.enable,
        rechargeAt: workspace.autoRechargeConfig.amountAt,
        rechargeTo: workspace.autoRechargeConfig.amountTo,
        showPopup: true,
      }),
    )
  }

  const showCancelSubscriptionFormHandler = () => {
    dispatch(setShowCancelSubscriptionPopupState({ showPopup: true }))
  }

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchSubscription = dispatch(getSubscription({}))

    return () => {
      fetchSubscription.abort()
    }
  }, [])

  const usingSeats = workspace.users.active + workspace.users.invited
  const autoRecharge = workspace.autoRechargeConfig

  return (
    <div className={'mt-8 px-6 md:px-0'}>
      <SubscriptionWarnings />

      {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
      {requestStatus === REQUEST_STATUSES.DONE && (
        <div className={'flex flex-col text-sm space-y-4'}>
          {workspace.plan === WORKSPACE_PLANS.PERSONAL && (
            <div className={'w-full'}>
              <div className={'bg-indigo-50 rounded-md p-6'}>
                <div className={'text-4xl font-light text-gray-900'}>{WORKSPACE_PLANS_NAMES[workspace.plan]}</div>
                <div className={'text-gray-700 font-semibold pt-2'}>Your current workspace plan</div>
                <div className={'pt-4'}>
                  <Link
                    to={'/upgrade'}
                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500"
                  >
                    Upgrade workspace
                  </Link>
                </div>
              </div>
            </div>
          )}

          {workspace.plan === WORKSPACE_PLANS.TEAM && (
            <div className={'w-full'}>
              <div
                className={classNames(
                  'grid grid-cols-1 sm:space-x-4 space-y-4 sm:space-y-0',
                  subscription ? 'sm:grid-cols-2' : '',
                )}
              >
                <div className={'bg-indigo-50 rounded-md p-6'}>
                  <div className={'text-4xl font-light text-gray-900'}>{WORKSPACE_PLANS_NAMES[workspace.plan]}</div>
                  <div className={'text-gray-700 font-semibold pt-2'}>Your current workspace plan</div>
                  {subscription && !subscription.cancelAt && (
                    <div className={'text-gray-500 pt-2 text-xs'}>
                      Next renewal in{' '}
                      <span className={'text-gray-700 font-semibold'}>
                        {format(new Date(subscription.currentPeriodEnd * 1000), 'MMMM do, yyyy')}
                      </span>
                    </div>
                  )}
                  {workspace.plan === WORKSPACE_PLANS.TEAM && !subscription && (
                    <div className={'text-gray-500 pt-2 text-xs'}>Your subscription is not active</div>
                  )}
                  <div className={'pt-4'}>
                    {subscription && !subscription.cancelAt && (
                      <button
                        type={'button'}
                        onClick={showCancelSubscriptionFormHandler}
                        className={`ml-auto sm:ml-0 flex items-center justify-center rounded-md px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-indigo-100 ring-2 ring-indigo-500`}
                      >
                        Cancel subscription
                      </button>
                    )}
                    {subscription && subscription.cancelAt && (
                      <p
                        className={`ml-auto w-fit sm:ml-0 flex items-center justify-center rounded-md px-3 py-1 text-gray-500 ring-2 ring-gray-300`}
                      >
                        Cancelled
                      </p>
                    )}
                    {!subscription && (
                      <Link
                        to={'/subscription/activate'}
                        className={`ml-auto w-fit sm:ml-0 flex items-center justify-center rounded-md px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-indigo-100 ring-2 ring-indigo-500`}
                      >
                        Activate subscription
                      </Link>
                    )}
                  </div>
                </div>
                {subscription && (
                  <div className={'sm:text-right bg-indigo-50 rounded-md p-6'}>
                    <div>
                      <div className={'text-4xl font-light text-gray-900'}>
                        {subscription && subscription.quantity} / <span className={'text-gray-500'}>{usingSeats}</span>
                      </div>
                      <div className={'text-gray-700 font-semibold pt-2'}>Available seats / active users</div>
                      <div className={'pt-2 text-xs text-gray-500'}>
                        Next subscription price estimation
                        <span className={'font-semibold text-gray-700'}> ${usingSeats * PRICE.SEAT_PRICE}</span>
                      </div>
                      <div className={'pt-4'}>
                        <Link
                          to={'/users'}
                          className={`w-fit ml-auto flex items-center justify-center rounded-md px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-indigo-100 ring-2 ring-indigo-500`}
                        >
                          Manage workspace users
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={'w-full bg-gray-50 rounded-md p-6'}>
            <div className={'grid grid-cols-1 sm:grid-cols-2'}>
              <div>
                <div className={'text-4xl font-light text-gray-900'}>${workspace.balance}</div>
                <div className={'text-gray-700 font-semibold pt-2'}>Credit balance</div>
                <div className={'pt-4'}>
                  <button
                    type={'button'}
                    onClick={showDepositForm}
                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500"
                  >
                    Add to credit balance
                  </button>
                </div>
              </div>
              <div>
                <ul role="list" className="sm:text-right pt-4 sm:pt-0 space-y-4 text-sm leading-6 text-gray-600 ">
                  <li>
                    <div>One meeting analysis</div>
                    <div className={''}>
                      <div className={'font-semibold'}>$0.50/meeting</div>
                      <div className={'text-xs text-gray-400'}>+ GPT costs</div>
                    </div>
                  </li>
                  <li>
                    <div>Transcription</div>
                    <div className={'font-semibold'}>$0.60/hour</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={'w-full bg-gray-50 rounded-md p-6'}>
            <div>
              <div className={'text-md font-semibold text-gray-900'}>Auto recharge</div>
              <div className={'text-gray-700 font-normal pt-2'}>
                {autoRecharge.enable
                  ? `When your credit balance reaches $${autoRecharge.amountAt}.00, your payment method will be charged to bring the balance up to $${autoRecharge.amountTo}.00.`
                  : "When your credit balance reaches $0, your won't be able to analyse meetings/chats and works with transcriptions or translations. Enable automatic recharge to automatically keep your credit balance topped up."}
              </div>
              <div className={'pt-4'}>
                <button
                  type={'button'}
                  onClick={showRechargeForm}
                  className={`w-fit flex items-center justify-center rounded-md px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-indigo-100 ring-2 ring-indigo-500`}
                >
                  {autoRecharge.enable ? 'Modify' : 'Enable auto recharge'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(Overview)
