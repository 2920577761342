import React from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeShowNavigationSidebar,
  selectShowGroupsSidebar,
  selectShowNavigationSidebar,
} from '../../../../reducers/common'
import Groups from '../../../groups/Groups'
import CreateGroup from '../../../groups/create/Create'
import CreateWorkspace from '../../../workspace/create/Create'
import { selectShowSidebar as selectShowCreateGroupSidebar } from '../../../../reducers/workspace/group/create'
import CreateDialog from '../../../meeting/create-dialog/CreateMeeting'
import WorkspaceListDialog from '../../../workspace/list-dialog/WorkspaceListDialog'
import Deposit from '../../../workspace/settings/billing/deposit/Deposit'
import AddPaymentMethod from '../../../workspace/settings/billing/payment-methods/add/AddPaymentMethod'
import SidebarSlim from './SidebarSlim'
import { selectShowSidebar as selectShowWorkspacesSidebar } from '../../../../reducers/workspace/workspace-list'
import UpgradeForm from '../../../workspace/upgrade/UpgradeForm'
import PayForSubscription from '../../../workspace/settings/billing/pay-for-subscription/PayForSubscription'
import CancelSubscription from '../../../workspace/settings/billing/cancel-subscription/CancelSubscription'
import RevokeCancellation from '../../../workspace/settings/billing/revoke-cancellation/RevokeCancellation'
import UploadRecord from '../../../records/upload-dialog/UploadRecord'
import UseForMeeting from '../../../records/view/use-for-meeting-form/UseForMeeting'
import SharePopup from '../../share-popup/SharePopup'
import { selectShowPopup as selectShowSharePopup } from '../../../../reducers/share-popup'
import AutoRecharge from '../../../workspace/settings/billing/auto-recharge/AutoRecharge'

export default function MainLayout({ children }) {
  const dispatch = useDispatch()

  const showNavigationSidebar = useSelector(selectShowNavigationSidebar)
  const showGroupsSidebar = useSelector(selectShowGroupsSidebar)
  const showCreateGroupSidebar = useSelector(selectShowCreateGroupSidebar)
  const showWorkspacesSidebar = useSelector(selectShowWorkspacesSidebar)
  const selectShowPopup = useSelector(selectShowSharePopup)

  const changeShowNavigationSidebarProcess = (status) => {
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: status }))
  }

  return (
    <div className="absolute top-0 bottom-0 left-0 w-full">
      <Transition.Root
        show={showNavigationSidebar && !showGroupsSidebar && !showCreateGroupSidebar && !showWorkspacesSidebar}
        as={Fragment}
      >
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={changeShowNavigationSidebarProcess}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => changeShowNavigationSidebarProcess(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <SidebarSlim openedDefault={true} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-30 lg:flex">
        <SidebarSlim />
      </div>

      <div className="absolute right-0 w-full top-0 bottom-0 lg:pl-20 overflow-auto">
        <main className="relative min-h-full ">{children}</main>
      </div>

      <AddPaymentMethod />
      <AutoRecharge />
      <Deposit />
      <PayForSubscription />
      <UpgradeForm />
      <WorkspaceListDialog />
      <CreateWorkspace />
      <CreateDialog />
      <UseForMeeting />
      <UploadRecord />
      <Groups />
      <CreateGroup />
      <CancelSubscription />
      <RevokeCancellation />
      <SharePopup open={selectShowPopup} />
    </div>
  )
}
