'use client'

import React, { memo } from 'react'
import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { LinkIcon } from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeName,
  changeRequestStatus as changeCreateApiKeyRequestStatus,
  selectFormData as selectCreateApiKeyFormData,
  selectOpenForm,
  resetForm,
} from '../../../../../reducers/workspace/api-keys/create'
import { REQUEST_STATUSES } from '../../../../../reducers/reducer-helper'
import { createApiKey } from './slice'

function CreateApiKey({ open }) {
  const dispatch = useDispatch()

  const [copied, setCopied] = useState(false)

  const formData = useSelector(selectCreateApiKeyFormData)
  const openNewApiKeyForm = useSelector(selectOpenForm)

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const createNewApiKeyProcess = (event) => {
    event.preventDefault()
    event.stopPropagation()

    dispatch(changeCreateApiKeyRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      createApiKey({
        name: formData.name,
      }),
    )
  }

  const closePopup = () => {
    setCopied(false)
    dispatch(resetForm())
  }

  const copyKey = () => {
    setCopied(true)
    navigator.clipboard.writeText(formData.value)
  }

  return (
    <Dialog open={openNewApiKeyForm} onClose={closePopup} className="relativex">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in z-30"
      />

      <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  Create new API key
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Please fill the form below to create new API key.
                    {formData.value && (
                      <p className={'pt-4 text-red-400 '}>
                        Please note that we do not display your secret API keys again after you close this popup.
                      </p>
                    )}
                  </p>
                </div>
                <div className={'my-8'}>
                  {formData.value && (
                    <>
                      <div className={'flex flex-row items-center'}>
                        <div
                          onClick={copyKey}
                          className={
                            'flex flex-row w-full items-center rounded-md px-2 bg-indigo-50 hover:bg-indigo-100 transition-all cursor-pointer'
                          }
                        >
                          <div className={'mr-2'}>
                            <LinkIcon className={'w-4 h-4 text-gray-500'} />
                          </div>
                          <p className={'text-sm text-gray-700  py-2 text-ellipsis text-nowrap overflow-hidden w-full'}>
                            {formData.value}
                          </p>
                          {copied && <CheckIcon className={'w-5 h-5 text-green-700'} />}
                        </div>
                      </div>
                      <p className={'text-right text-xs text-gray-400 pt-1'}>{copied ? 'Copied' : 'Click to copy'}</p>
                    </>
                  )}
                  {!formData.value && (
                    <form onSubmit={createNewApiKeyProcess} className={'relative flex flex-row gap-2'}>
                      <input
                        type={'text'}
                        placeholder={'API key name'}
                        autoFocus={true}
                        onChange={changeNameProcess}
                        value={formData.name}
                        className={'w-full rounded-md border-0 ring-1 text-sm text-gray-700'}
                        name={'api-key-name'}
                      />
                      <button
                        className="text-nowrap rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        type={'submit'}
                      >
                        Create API key
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row">
              <button
                type="button"
                onClick={closePopup}
                className="mt-3 ml-auto inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                {formData.value ? 'Done' : 'Cancel'}
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

export default memo(CreateApiKey)
