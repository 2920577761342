import { Link, NavLink } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import Logo from '../../../../assets/images/as_new_logo.svg'
import {
  STRIPE_SUBSCRIPTION_STATUSES,
  WORKSPACE_PLANS,
  WORKSPACE_PLANS_NAMES,
  WORKSPACE_USER_ROLES,
} from '../../../../constants'
import {
  ArrowUpIcon,
  ChartBarIcon,
  ChevronRightIcon,
  Cog6ToothIcon,
  HomeIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { Menu, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { changeShowGroupsSidebar, selectShowGroupsSidebar } from '../../../../reducers/common'
import { setSidebarState as setWorkspaceListSidebarState } from '../../../../reducers/workspace/workspace-list'
import { logout } from './slice'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../../../reducers/user/user'
import { selectWorkspace } from '../../../../reducers/workspace/workspace'
import { classNames } from '../../../../utils/className'
import { ChevronDownIcon } from '@heroicons/react/16/solid'
import { selectSubscription } from '../../../../reducers/workspace/subscription'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { ChatBubbleLeftRightIcon, MicrophoneIcon } from '@heroicons/react/24/outline/index'
import { CND_URL } from '../../../../configs'

export default function SidebarSlim({ openedDefault = false }) {
  const dispatch = useDispatch()

  const { groupUuid } = useParams('groupUuid')

  const user = useSelector(selectUser)
  const workspace = useSelector(selectWorkspace)
  const subscription = useSelector(selectSubscription)
  const showGroupsSidebar = useSelector(selectShowGroupsSidebar)

  const [isHover, setIsHover] = useState(openedDefault)

  const changeShowGroupSidebarProcess = (status) => {
    dispatch(changeShowGroupsSidebar({ showGroupsSidebar: status }))
  }

  const showWorkspaceSidebar = () => {
    dispatch(setWorkspaceListSidebarState({ showSidebar: true }))
  }

  const logOutProcess = () => {
    dispatch(logout())
  }

  const userNavigation = [
    [
      { name: 'Privacy policy', href: '/privacy-policy' },
      { name: 'Terms of service', href: '/terms-of-service' },
    ],
    [{ name: 'Account Settings', href: '/account/settings/general' }],
    [{ name: 'Sign out', isButton: true, handler: logOutProcess }],
  ]

  const navigation = [
    { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, id: 'dashboard' },
    { name: 'Meetings analysis', href: '/meetings', icon: ChatBubbleLeftRightIcon },
    { name: 'Records & Transcriptions', href: '/records', icon: MicrophoneIcon },
    ...(workspace.plan === WORKSPACE_PLANS.TEAM && workspace.user.role !== WORKSPACE_USER_ROLES.MEMBER
      ? [
          { name: 'Users', href: '/users', icon: UsersIcon },
          {
            name: 'Groups',
            handler: changeShowGroupSidebarProcess,
            icon: UserGroupIcon,
            active: showGroupsSidebar || groupUuid,
          },
          { name: 'Usage', href: '/usage', icon: ChartBarIcon },
          { name: 'Workspace Settings', href: '/settings', icon: Cog6ToothIcon },
        ]
      : []),
    ...(workspace.plan === WORKSPACE_PLANS.PERSONAL
      ? [
          { name: 'Usage', href: '/usage', icon: ChartBarIcon },
          { name: 'Workspace settings', href: '/settings', icon: Cog6ToothIcon },
        ]
      : []),
  ]

  return (
    <div
      onMouseEnter={() => {
        if (!openedDefault) {
          setIsHover(true)
        }
      }}
      onMouseLeave={() => {
        if (!openedDefault) {
          setIsHover(false)
        }
      }}
      className={classNames(
        'flex flex-col overflow-hidden transition-all ease-in-out lg:inset-y-0 border-r border-gray-200 bg-white pb-4',
        isHover ? 'w-72' : 'w-20 ',
      )}
    >
      <Link className={classNames('flex transition-all ease-in-out w-fit ml-8 mt-4 mb-12')} to={'/dashboard'}>
        <ReactSVG
          src={Logo}
          beforeInjection={(svg) => {
            svg.setAttribute('style', 'height: 16px, padding-top: 10px')
          }}
        />
      </Link>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <button
              onClick={() => showWorkspaceSidebar()}
              className="flex w-full text-gray-900 py-4 pl-5 pr-4 items-center text-sm cursor-pointer font-semibold leading-6 hover:text-indigo-600 hover:bg-gray-50"
            >
              <span className="sr-only">Open user menu</span>
              <div className={'w-full h-12 relative items-center flex'}>
                {workspace.image ? (
                  <img
                    className={'flex-shrink-0 w-10 h-10 rounded-full'}
                    src={`${CND_URL}/${workspace.image}`}
                    alt=""
                  />
                ) : (
                  <div
                    className={
                      'flex-shrink-0 content-center bg-indigo-300 text-center rounded-full text-white font-normal text-sm uppercase w-8 h-8'
                    }
                  >
                    {workspace.name[0]}
                  </div>
                )}

                {isHover && (
                  <div className={'flex flex-col pl-2 text-left'}>
                    <span className="pr-2 line-clamp-1 text-sm font-semibold" aria-hidden="true">
                      {workspace.name}
                    </span>
                    <p className={'font-normal text-xs text-gray-500'}>{WORKSPACE_PLANS_NAMES[workspace.plan]}</p>
                  </div>
                )}
              </div>
              {isHover && <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
            </button>
          </li>

          <li>
            <ul role="list" className="space-y-2">
              {workspace.plan === WORKSPACE_PLANS.PERSONAL && (
                <li className={'mb-8'}>
                  <Link className={'w-full'} to={'/upgrade'}>
                    <div
                      className={classNames(
                        'text-indigo-600 hover:text-indigo-600 hover:bg-gray-50 ring-2 ring-indigo-500',
                        isHover
                          ? 'mx-6 items-center justify-start pl-2 gap-x-3 h-8'
                          : 'content-center ml-6 items-center justify-center w-8 h-8',
                        'group flex rounded-md text-sm font-semibold',
                      )}
                    >
                      <ArrowUpIcon
                        className={classNames('text-gray-400 group-hover:text-indigo-600', 'h-4 w-4 shrink-0')}
                        aria-hidden="true"
                      />
                      {isHover && <div className={classNames('line-clamp-1')}>Upgrade plan</div>}
                    </div>
                  </Link>

                  <div
                    id="tooltip-default"
                    role="tooltip"
                    className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                  >
                    Tooltip content
                    <div className="tooltip-arrow" data-popper-arrow></div>
                  </div>
                </li>
              )}
              {navigation.map((item) => (
                <li key={item.name}>
                  {item.href && (
                    <NavLink to={item.href}>
                      {({ isActive }) => {
                        return (
                          <div
                            className={classNames(
                              isActive
                                ? 'bg-gray-50 text-indigo-600'
                                : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                              isHover
                                ? 'mx-4 items-center justify-start pl-3 gap-x-3 h-12'
                                : 'content-center ml-4 items-center justify-center w-12 h-12',
                              'group relative flex rounded-md text-sm font-medium',
                            )}
                          >
                            <item.icon
                              className={classNames(
                                isActive ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                'h-6 w-6 shrink-0',
                              )}
                              aria-hidden="true"
                            />
                            {isHover && <div className={classNames('line-clamp-1')}>{item.name}</div>}
                            {item.id &&
                              item.id === 'dashboard' &&
                              (subscription?.status === STRIPE_SUBSCRIPTION_STATUSES.INCOMPLETE ||
                                subscription?.status === STRIPE_SUBSCRIPTION_STATUSES.PAST_DUE) &&
                              (workspace.user.role === WORKSPACE_USER_ROLES.ADMIN ||
                                workspace.user.role === WORKSPACE_USER_ROLES.OWNER) && (
                                <div
                                  className={classNames(
                                    'absolute transition-all duration-300',
                                    isHover ? 'bottom-2 right-2' : '-bottom-2 -right-2',
                                  )}
                                >
                                  <ExclamationTriangleIcon
                                    className={classNames('text-red-600', isHover ? 'w-8 h-8' : 'w-6 h-6')}
                                  />
                                </div>
                              )}
                          </div>
                        )
                      }}
                    </NavLink>
                  )}
                  {item.handler && (
                    <button
                      className={'w-full'}
                      onClick={() => {
                        item.handler(true)
                      }}
                    >
                      <div
                        className={classNames(
                          item.isActive
                            ? 'bg-gray-50 text-indigo-600'
                            : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                          isHover
                            ? 'mx-4 items-center justify-start pl-3 gap-x-3 h-12'
                            : 'content-center ml-4 items-center justify-center w-12 h-12',
                          'group flex rounded-md text-sm font-medium',
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.active ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                            'h-6 w-6 shrink-0',
                          )}
                          aria-hidden="true"
                        />
                        {isHover && <div className={classNames('line-clamp-1')}>{item.name}</div>}
                      </div>
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </li>
          <li className="-mx-6 mt-auto">
            <Menu as="div" className="relative inline-block gap-x-4 w-full text-left hover:bg-gray-50">
              {({ open }) => (
                <>
                  <Menu.Button
                    className={classNames(
                      'w-full justify-start ml-6 flex items-center gap-x-2 px-6 py-3 text-sm font-semibold leading-6',
                    )}
                  >
                    <span className="sr-only">Open user menu</span>
                    {user.profile.profileImage ? (
                      <img
                        className={'flex-shrink-0 w-8 h-8 rounded-full'}
                        src={`${CND_URL}/${user.profile.profileImage}`}
                        alt=""
                      />
                    ) : (
                      <UserIcon className={'flex-shrink-0 w-6 h-6'} />
                    )}
                    {isHover && (
                      <>
                        <span className="text-sm font-semibold line-clamp-1 leading-6 text-gray-900" aria-hidden="true">
                          {user.profile.fullName}
                        </span>
                        <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                      </>
                    )}
                  </Menu.Button>

                  <Transition
                    show={open && isHover}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-8 bottom-12 z-10 mb-0 w-64 py-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((group, groupIndex) => (
                        <div key={`sidebar-account-nav-group-${groupIndex}`}>
                          {group.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => {
                                if (item.isButton) {
                                  return (
                                    <button
                                      onClick={item.handler}
                                      className={classNames(
                                        active ? 'bg-gray-50' : '',
                                        'w-full text-left block px-4 py-1 text-sm leading-6 text-gray-900',
                                      )}
                                    >
                                      {item.name}
                                    </button>
                                  )
                                } else {
                                  return (
                                    <Link
                                      to={item.href}
                                      className={classNames(
                                        active ? 'bg-gray-50' : '',
                                        'block px-4 py-1 text-sm leading-6 text-gray-900',
                                      )}
                                    >
                                      {item.name}
                                    </Link>
                                  )
                                }
                              }}
                            </Menu.Item>
                          ))}
                          {groupIndex !== userNavigation.length - 1 && (
                            <div className="w-full my-2 border-t border-gray-100" />
                          )}
                        </div>
                      ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </li>
        </ul>
      </nav>
    </div>
  )
}
