import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingAgent } from '../../../../api/meetingAgent'

export const getAllMeetings = createAsyncThunk(
  'meeting-list/get-all-meetings',
  async ({ page, pageSize }, thunkApi) => {
    try {
      const meetings = await MeetingAgent.getAllMeetings(page, pageSize)

      return { meetings }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)

export const deleteMeeting = createAsyncThunk('meeting/delete-meetings', async ({ meetingUuid }, thunkApi) => {
  try {
    await MeetingAgent.deleteMeeting(meetingUuid)

    return { meetingUuid }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
