import React from 'react'
import PropTypes from 'prop-types'

const StatusTdValue = ({ value }) => {
  const statusOutline = {
    true: 'bg-green-700/10',
    false: 'bg-rose-700/10',
  }

  const statusCenter = {
    true: 'bg-green-400',
    false: 'bg-rose-400',
  }

  return (
    <div className="flex items-start justify-end gap-x-2 sm:justify-start">
      <div className={`${statusOutline[value.toString()]} flex-none rounded-full p-1`}>
        <div className={`h-1.5 w-1.5 rounded-full ${statusCenter[value.toString()]}`} />
      </div>
      <div className="hidden sm:block">{value.toString()}</div>
    </div>
  )
}

StatusTdValue.propTypes = {
  value: PropTypes.bool.isRequired,
}

function TableTemplate({ data, columnsToDisplay }) {
  let columns = data && data.length > 0 ? Object.keys(data[0]) : []

  if (columnsToDisplay) {
    columns = columns.filter((column) => columnsToDisplay.includes(column))
  }

  const renderColumnHeaders = () =>
    columns.map((column) => {
      const cleanColumn = column.replaceAll('_', ' ').trim()
      return (
        <th key={column} scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
          {cleanColumn.charAt(0).toUpperCase() + cleanColumn.slice(1)}
        </th>
      )
    })

  const renderTableBody = () =>
    data.map((item, itemIndex) => (
      <tr key={itemIndex} className="transition duration-150 ease-in-out hover:bg-gray-100">
        {columns.map((column) => {
          const value = item[column]
          return (
            <td key={column} className="px-3 py-4 text-sm text-gray-500">
              {typeof value === 'boolean' ? <StatusTdValue value={value} /> : value}
            </td>
          )
        })}
      </tr>
    ))

  return (
    <div>
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>{renderColumnHeaders()}</tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">{renderTableBody()}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

TableTemplate.propTypes = {
  data: PropTypes.array.isRequired,
  columnsToDisplay: PropTypes.array,
}

export default TableTemplate
