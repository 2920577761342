import React, { Fragment, memo, useEffect, useRef, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Combobox, Switch } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { getAllUsers } from '../../../users/slice'
import { selectAllUsers, usersUnloaded } from '../../../../reducers/workspace/user/user-list'
import { useDispatch, useSelector } from 'react-redux'
import { selectGroup } from '../../../../reducers/workspace/group/details'
import { addGroupMember, removeGroupMember, updateGroupMemberRole } from './slice'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Members() {
  const dispatch = useDispatch()

  const moreBtn = useRef()

  const workspaceUsers = useSelector(selectAllUsers)
  const group = useSelector(selectGroup)

  const [query, setQuery] = useState('')
  const users = useSelector(selectAllUsers)

  useEffect(() => {
    const fetchUsers = dispatch(getAllUsers())

    return () => {
      dispatch(usersUnloaded())
      fetchUsers.abort()
    }
  }, [])

  const changeMemberUuidProcess = (workspaceUserUuid) => {
    dispatch(addGroupMember({ groupUuid: group.uuid, workspaceUserUuid }))
    setQuery('')
  }

  const changeIsManagerProcess = (memberUuid, value) => {
    dispatch(updateGroupMemberRole({ groupUuid: group.uuid, memberUuid, isManager: value }))
  }

  const removeProcess = (memberUuid) => {
    dispatch(removeGroupMember({ groupUuid: group.uuid, memberUuid }))
  }

  const filteredUsers = !users
    ? []
    : workspaceUsers.filter((user) => {
        const addedUuids = group.members.map((member) => member.workspaceUser.uuid)
        const fullName = user.account.profile.fullName

        return (
          !addedUuids.includes(user.uuid) &&
          ((fullName && fullName.toLowerCase().includes(query.toLowerCase())) ||
            user.account.email.toLowerCase().includes(query.toLowerCase()))
        )
      })

  return (
    <div className={'mt-8 max-w-4xl'}>
      <Combobox
        as="div"
        value={null}
        onChange={(value) => {
          changeMemberUuidProcess(value)
        }}
      >
        <div className="relative">
          <Combobox.Input
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            placeholder={'Find user...'}
            onClick={() => {
              moreBtn.current.click()
            }}
          />
          <Combobox.Button
            onClick={(e) => {
              moreBtn.current.click()
            }}
            ref={moreBtn}
            className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
          >
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>

          {filteredUsers.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredUsers.map((user) => (
                <Combobox.Option
                  key={user.uuid}
                  value={user.uuid}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <p className="text-sm">
                        {user.account.profile.fullName ? user.account.profile.fullName : 'Invited'}
                      </p>
                      <p className="whitespace-nowrap text-xs text-gray-400">{user.account.email}</p>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>

      {users && (
        <ul role="list" className="mt-4 divide-y divide-gray-100">
          {group.members.map((member) => {
            const memberUser = users.find((user) => user.uuid === member.workspaceUser.uuid)
            if (!memberUser) {
              return
            }

            return (
              <li key={`manager-${memberUser.uuid}`} className="py-4">
                <div className="flex items-center gap-x-3">
                  <div className={'w-full flex flex-col'}>
                    <p className="text-sm">
                      {memberUser.account.profile.fullName ? memberUser.account.profile.fullName : 'Invited'}
                    </p>
                    <p className="whitespace-nowrap text-xs text-gray-400">{memberUser.account.email}</p>
                  </div>
                  <div>
                    <Switch.Group as="div" className="flex items-center">
                      <Switch
                        checked={member.isManager}
                        onChange={(value) => changeIsManagerProcess(member.uuid, value)}
                        className={classNames(
                          member.isManager ? 'bg-indigo-600' : 'bg-gray-200',
                          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            member.isManager ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                          )}
                        />
                      </Switch>
                      <Switch.Label as="span" className="ml-3 text-sm">
                        <span className="font-normal text-gray-700">Manager</span>
                      </Switch.Label>
                    </Switch.Group>
                  </div>
                  <button
                    type="button"
                    className="relative text-gray-400 hover:text-gray-500"
                    onClick={() => removeProcess(member.uuid)}
                  >
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Delete manager</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default memo(Members)
