import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkspaceAgent } from '../../../../api/workspaceAgent'

export const updateWorkspaceGeneralSettings = createAsyncThunk(
  'workspaces/general-settings/update',
  async ({ name }, thunkApi) => {
    try {
      const workspace = await WorkspaceAgent.updateGeneralSettings(name)

      return { workspace }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data)
    }
  },
)

export const removeImage = createAsyncThunk('workspaces/general-settings/image/remove', async ({}, thunkApi) => {
  try {
    await WorkspaceAgent.removeImage()
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const addImage = createAsyncThunk('workspaces/general-settings/image/add', async ({ file }, thunkApi) => {
  try {
    return await WorkspaceAgent.addImage(file)
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})
