import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import {
  selectError,
  selectRequestStatus,
  selectData,
  changeRequestStatus,
  resetData,
} from '../../../reducers/workspace/user/accept-invitation'
import NotificationPopup from '../../common/notification-popup/NotificationPopup'
import Loader from '../../common/loader/Loader'
import SingleFormWrapper from '../../common/singl-form-wrapper/SingleFormWrapper'
import { checkInvitation } from './slice'
import { useParams } from 'react-router'
import SignUpForm from './SignUpForm'
import SignInForm from './SignInForm'

function AcceptInvitation() {
  const dispatch = useDispatch()

  const { token } = useParams()

  const data = useSelector(selectData)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchInvitation = dispatch(checkInvitation({ token }))

    return () => {
      dispatch(resetData())
      fetchInvitation.abort()
    }
  }, [])

  return (
    <SingleFormWrapper>
      {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
      {requestStatus === REQUEST_STATUSES.DONE && (
        <>
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Workspace invitation
            </h2>
            <div className="mt-4 text-center text-sm text-gray-500">
              You were invited to the <span className={'font-semibold text-black'}>{data.workspace.name}</span>{' '}
              workspace.
              <b />
              {data.actionRequired && data.actionRequired === 'sign-up' && (
                <div className={'pt-12'}>Please fill the form to create an account and accept this invitation</div>
              )}
              {data.actionRequired && data.actionRequired === 'sign-in' && (
                <div>Please fill the form to login and accept invitation</div>
              )}
              {!data.actionRequired && <div>Please confirm accepting of the invitation</div>}
            </div>
          </div>
        </>
      )}

      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
        {error && <NotificationPopup message={error.message} />}
        {data.actionRequired && data.actionRequired === 'sign-up' && <SignUpForm />}
        {data.actionRequired && data.actionRequired === 'sign-in' && <SignInForm />}
      </div>
    </SingleFormWrapper>
  )
}

export default memo(AcceptInvitation)
