import React, { memo } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectError,
  selectRequestStatus,
  changeRequestStatus,
  selectUserUuid,
  setUserUuidForRemove,
} from '../../../reducers/workspace/user/remove'
import { removeUser } from './slice'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loader from '../../common/loader/Loader'
import NotificationPopup from '../../common/notification-popup/NotificationPopup'
import { STRIPE_SUBSCRIPTION_STATUSES, WORKSPACE_USER_ROLES } from '../../../constants'
import { selectWorkspace } from '../../../reducers/workspace/workspace'
import { selectSubscription } from '../../../reducers/workspace/subscription'
import {
  selectShowPopup,
  setShowPopupState as setShowPayForSubscriptionPopupState,
} from '../../../reducers/workspace/settings/billing/pay-for-subscription-form'

function Remove() {
  const dispatch = useDispatch()

  const error = useSelector(selectError)
  const userUuid = useSelector(selectUserUuid)
  const requestStatus = useSelector(selectRequestStatus)
  const workspace = useSelector(selectWorkspace)
  const subscription = useSelector(selectSubscription)
  const showPayForSubscriptionPopup = useSelector(selectShowPopup)

  const removeProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(removeUser({ uuid: userUuid }))
  }

  const hideRemoveUserSidebarProcess = () => {
    dispatch(setUserUuidForRemove({ userUuid: null }))
  }

  const showPayForSubscriptionFormHandler = () => {
    dispatch(setShowPayForSubscriptionPopupState({ showPopup: true }))
  }

  return (
    <Transition.Root show={!!userUuid && !showPayForSubscriptionPopup} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={hideRemoveUserSidebarProcess}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => hideRemoveUserSidebarProcess()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {(subscription.status === STRIPE_SUBSCRIPTION_STATUSES.INCOMPLETE ||
                  subscription.status === STRIPE_SUBSCRIPTION_STATUSES.PAST_DUE) &&
                  (workspace.user.role === WORKSPACE_USER_ROLES.ADMIN ||
                    workspace.user.role === WORKSPACE_USER_ROLES.OWNER) && (
                    <div className={'p-6'}>
                      <div
                        className={
                          'text-sm flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4 justify-between rounded-md bg-red-100 px-4 py-4'
                        }
                      >
                        <div className={'flex flex-row items-center space-x-2'}>
                          <div>
                            <ExclamationTriangleIcon className={'w-6 h-6 text-red-600'} />
                          </div>
                          <div className={'text-gray-700'}>
                            Your workspace has not completed payment. Please fix it to be able to remove user.
                          </div>
                        </div>
                        <div className={'flex flex-row items-center space-x-2'}>
                          <button
                            type={'button'}
                            onClick={showPayForSubscriptionFormHandler}
                            className={`flex text-sm text-nowrap items-center justify-center rounded-md px-3 py-1 text-white bg-indigo-600 hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500`}
                          >
                            Pay now
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                {subscription.status === STRIPE_SUBSCRIPTION_STATUSES.ACTIVE && (
                  <div>
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Remove user
                        </Dialog.Title>
                        {error && <NotificationPopup message={error.message} />}
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">Are you sure you want to remove this user?</p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md bg-red-800 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-600 ring-2 ring-red-800 hover:ring-red-600 sm:ml-3 sm:w-auto"
                        onClick={(e) => removeProcess(e)}
                      >
                        {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
                        Remove
                      </button>
                      <button
                        type="button"
                        className={`ml-auto flex text-sm items-center justify-center rounded-md bg-white px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-gray-50 ring-2 ring-indigo-500`}
                        onClick={() => hideRemoveUserSidebarProcess()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default memo(Remove)
