import { createSlice } from '@reduxjs/toolkit'
import { failed } from '../../reducer-helper'
import { createGroup } from '../../../components/groups/create/slice'

const initialState = {
  form: {
    name: '',
    openMeetings: true,
    members: [],
  },
  showSidebar: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'createWorkspaceGroup',
  initialState,
  extraReducers(builder) {
    builder.addCase(createGroup.fulfilled, (state) => initialState)
    builder.addCase(createGroup.rejected, failed)
  },
  reducers: {
    setSidebarState(state, action) {
      state.showSidebar = action.payload.showSidebar
    },
    changeName: (state, action) => {
      state.form.name = action.payload.name
    },
    changeOpenMeetings: (state, action) => {
      state.form.openMeetings = action.payload.openMeetings
    },
    addGroupMember: (state, action) => {
      state.form.members.push({
        uuid: action.payload.memberUuid,
        isManager: false,
      })
    },
    changeIsManager: (state, action) => {
      const memberIndex = state.form.members.findIndex((member) => member.uuid === action.payload.memberUuid)
      if (memberIndex !== -1) {
        state.form.members[memberIndex].isManager = action.payload.isManager
      }
    },
    removeGroupMember: (state, action) => {
      const memberIndex = state.form.members.findIndex((member) => member.uuid === action.payload.memberUuid)
      if (memberIndex !== -1) {
        state.form.members.splice(memberIndex, 1)
      }
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.createWorkspaceGroup
export const selectFormData = (state) => selectSlice(state).form
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status
export const selectShowSidebar = (state) => selectSlice(state).showSidebar

const { reducer, actions } = slice
export const {
  changeRequestStatus,
  resetForm,
  changeName,
  changeOpenMeetings,
  addGroupMember,
  changeIsManager,
  removeGroupMember,
  setSidebarState,
} = actions

export default reducer
