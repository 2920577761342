import React, { memo, useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Switch } from '@headlessui/react'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { useDispatch, useSelector } from 'react-redux'
import { updateWorkspaceSecuritySettings } from './slice'
import {
  resetForm,
  changeRequestStatus,
  selectError,
  selectRequestStatus,
  selectSettings,
  setSettings,
  changeAllowPrivateMeetings,
} from '../../../../reducers/workspace/settings/security-settings-form'

import { selectWorkspace } from '../../../../reducers/workspace/workspace'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
function WorkspaceSecuritySettings() {
  const dispatch = useDispatch()

  const formData = useSelector(selectSettings)
  const workspace = useSelector(selectWorkspace)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  const changeAllowPrivateMeetingsProcess = (value) => {
    dispatch(changeAllowPrivateMeetings({ allowPrivateMeetings: value }))
  }

  const saveSettings = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      updateWorkspaceSecuritySettings({
        allowPrivateMeetings: formData.allowPrivateMeetings,
      }),
    )
  }

  useEffect(() => {
    dispatch(setSettings({ allowPrivateMeetings: workspace.allowPrivateMeetings }))
  }, [workspace])

  useEffect(() => {
    return () => {
      dispatch(resetForm())
    }
  }, [])

  return (
    <>
      <div className="mx-auto space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <div className={'flex flex-col pb-6 border-b border-gray-200'}>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Security</h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">Workspace security settings</p>
          </div>
          <form onSubmit={saveSettings} className="mt-6 max-w-4xl space-y-6 divide-y divide-gray-100 text-sm leading-6">
            <div className="sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Privacy</dt>
              <dd className="">
                <Switch.Group as="div" className="flex items-center">
                  <Switch
                    checked={formData.allowPrivateMeetings}
                    onChange={changeAllowPrivateMeetingsProcess}
                    className={classNames(
                      formData.allowPrivateMeetings ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        formData.allowPrivateMeetings ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                      )}
                    />
                  </Switch>
                  <Switch.Label as="span" className="ml-3 text-sm">
                    <span className="font-normal text-gray-700">Allow private meetings</span>
                  </Switch.Label>
                </Switch.Group>
                <div className={'pt-4 text-xs text-gray-400'}>
                  Users are able to create their private meetings. Those meetings, are not related to any group and
                  processing result can be visible only to the meeting creator.
                </div>
              </dd>
            </div>

            {formData.allowPrivateMeetings !== workspace.allowPrivateMeetings && (
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-500 sm:w-64 sm:flex-none sm:pr-6"></dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <button
                    type="submit"
                    className={`flex items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  >
                    <span>Save changes</span>
                  </button>
                </dd>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  )
}

export default memo(WorkspaceSecuritySettings)
