import { createAsyncThunk } from '@reduxjs/toolkit'
import { RecordAgent } from '../../../api/recordAgent'

export const uploadRecord = createAsyncThunk('record/upload', async ({ groupUuid, language, file, name }, thunkApi) => {
  try {
    const record = await RecordAgent.upload(file, name, groupUuid, language)

    return { record }
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data)
  }
})
