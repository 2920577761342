import { createSlice } from '@reduxjs/toolkit'
import { failed } from '../reducer-helper'
import { createNewMeeting } from '../../components/meeting/create-dialog/slice'

const initialState = {
  data: {
    typeUuid: null,
    name: '',
    description: '',
  },
  showPopup: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'useForMeetingForm',
  initialState,
  extraReducers(builder) {
    builder.addCase(createNewMeeting.fulfilled, () => initialState)
    builder.addCase(createNewMeeting.rejected, failed)
  },
  reducers: {
    setShowPopupState(state, action) {
      state.showPopup = action.payload.showPopup
    },
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    changeDescription: (state, action) => {
      state.data.description = action.payload.description
    },
    changeTypeUuid: (state, action) => {
      state.data.typeUuid = action.payload.typeUuid
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetForm: () => initialState,
  },
})

const selectSlice = (state) => state.useForMeetingForm
export const selectFormData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status
export const selectShowPopup = (state) => selectSlice(state).showPopup

const { reducer, actions } = slice
export const { changeRequestStatus, changeName, changeDescription, changeTypeUuid, resetForm, setShowPopupState } =
  actions

export default reducer
