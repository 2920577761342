import React, { memo, useEffect, useRef, useState } from 'react'
import { Fragment } from 'react'
import { Combobox, Dialog, Switch, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectError,
  selectFormData,
  selectRequestStatus,
  changeName,
  changeOpenMeetings,
  addGroupMember,
  changeIsManager,
  selectShowSidebar,
  setSidebarState,
  removeGroupMember,
} from '../../../reducers/workspace/group/create'
import NotificationPopup from '../../common/notification-popup/NotificationPopup'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import Loader from '../../common/loader/Loader'
import { selectAllUsers, usersUnloaded } from '../../../reducers/workspace/user/user-list'
import { getAllUsers } from '../../users/slice'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { changeRequestStatus } from '../../../reducers/workspace/create-form'
import { createGroup } from './slice'
import { removeFromGroup } from '../../../reducers/workspace/user/invite'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Create() {
  const dispatch = useDispatch()

  const moreBtn = useRef()

  const formData = useSelector(selectFormData)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)
  const users = useSelector(selectAllUsers)

  const showCreateGroupSidebar = useSelector(selectShowSidebar)

  const [query, setQuery] = useState('')

  useEffect(() => {
    if (!users && showCreateGroupSidebar) {
      const fetchUsers = dispatch(getAllUsers())

      return () => {
        dispatch(usersUnloaded())
        fetchUsers.abort()
      }
    }
  }, [showCreateGroupSidebar])

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const changeOpenMeetingsProcess = (value) => {
    dispatch(changeOpenMeetings({ openMeetings: value }))
  }

  const changeShowCreateGroupSidebarProcess = () => {
    dispatch(setSidebarState({ showSidebar: false }))
  }

  const changeMemberUuidProcess = (value) => {
    dispatch(addGroupMember({ memberUuid: value }))
    setQuery('')
  }

  const changeIsManagerProcess = (memberUuid, value) => {
    dispatch(changeIsManager({ memberUuid, isManager: value }))
  }

  const removeProcess = (memberUuid) => {
    dispatch(removeGroupMember({ memberUuid }))
  }

  const createGroupProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      createGroup({
        name: formData.name,
        openMeetings: formData.openMeetings,
        members: formData.members,
      }),
    )
  }

  const filteredUsers = !users
    ? []
    : users.filter((user) => {
        const addedUuids = formData.members.map((member) => member.uuid)
        const fullName = user.account.profile.fullName

        return (
          !addedUuids.includes(user.uuid) &&
          ((fullName && fullName.toLowerCase().includes(query.toLowerCase())) ||
            user.account.email.toLowerCase().includes(query.toLowerCase()))
        )
      })

  return (
    <Transition.Root show={showCreateGroupSidebar} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          changeShowCreateGroupSidebarProcess(false)
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form
                    className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                    onSubmit={createGroupProcess}
                  >
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Create new group
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              Get started by filling in the information below to create new group.
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-gray-400 hover:text-gray-500"
                              onClick={() => changeShowCreateGroupSidebarProcess(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {error && <NotificationPopup message={error.message} />}

                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <div className="flex items-center space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900">
                              Group name
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="text"
                              value={formData.name}
                              onChange={changeNameProcess}
                              placeholder="My group"
                              name="group-name"
                              id="group-name"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Privacy
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <Switch.Group as="div" className="flex items-center">
                              <Switch
                                checked={formData.openMeetings}
                                onChange={changeOpenMeetingsProcess}
                                className={classNames(
                                  formData.openMeetings ? 'bg-indigo-600' : 'bg-gray-200',
                                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                                )}
                              >
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    formData.openMeetings ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                  )}
                                />
                              </Switch>
                              <Switch.Label as="span" className="ml-3 text-sm">
                                <span className="font-normal text-gray-700">
                                  Open processing results and transcription
                                </span>
                              </Switch.Label>
                            </Switch.Group>
                            <div className={'pt-4 text-xs text-gray-400'}>
                              Group members can see processing results and transcription for meetings their had.
                            </div>
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Members
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <Combobox
                              as="div"
                              value={null}
                              onChange={(value) => {
                                changeMemberUuidProcess(value)
                              }}
                            >
                              <div className="relative">
                                <Combobox.Input
                                  className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  onChange={(event) => setQuery(event.target.value)}
                                  placeholder={'Find user...'}
                                  onClick={() => {
                                    moreBtn.current.click()
                                  }}
                                />
                                <Combobox.Button
                                  onClick={(e) => {
                                    moreBtn.current.click()
                                  }}
                                  ref={moreBtn}
                                  className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                                >
                                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Combobox.Button>

                                {filteredUsers.length > 0 && (
                                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {filteredUsers.map((user) => (
                                      <Combobox.Option
                                        key={user.uuid}
                                        value={user.uuid}
                                        className={({ active }) =>
                                          classNames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                          )
                                        }
                                      >
                                        {({ active, selected }) => (
                                          <>
                                            <p className="text-sm">
                                              {user.account.profile.fullName
                                                ? user.account.profile.fullName
                                                : 'Invited'}
                                            </p>
                                            <p className="whitespace-nowrap text-xs text-gray-400">
                                              {user.account.email}
                                            </p>

                                            {selected && (
                                              <span
                                                className={classNames(
                                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                                  active ? 'text-white' : 'text-indigo-600',
                                                )}
                                              >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </Combobox.Option>
                                    ))}
                                  </Combobox.Options>
                                )}
                              </div>
                            </Combobox>

                            {users && (
                              <ul role="list" className="mt-4 divide-y divide-gray-100">
                                {formData.members.map((member) => {
                                  const memberUser = users.find((user) => user.uuid === member.uuid)

                                  if (!memberUser) {
                                    return
                                  }

                                  return (
                                    <li key={`manager-${memberUser.uuid}`} className="py-4">
                                      <div className="flex items-center gap-x-3">
                                        <div className={'w-full flex flex-col'}>
                                          <p className="text-sm">
                                            {memberUser.account.profile.fullName
                                              ? memberUser.account.profile.fullName
                                              : 'Invited'}
                                          </p>
                                          <p className="whitespace-nowrap text-xs text-gray-400">
                                            {memberUser.account.email}
                                          </p>
                                        </div>
                                        <div>
                                          <Switch.Group as="div" className="flex items-center">
                                            <Switch
                                              checked={member.isManager}
                                              onChange={(value) => changeIsManagerProcess(member.uuid, value)}
                                              className={classNames(
                                                member.isManager ? 'bg-indigo-600' : 'bg-gray-200',
                                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                                              )}
                                            >
                                              <span
                                                aria-hidden="true"
                                                className={classNames(
                                                  member.isManager ? 'translate-x-5' : 'translate-x-0',
                                                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                                )}
                                              />
                                            </Switch>
                                            <Switch.Label as="span" className="ml-3 text-sm">
                                              <span className="font-normal text-gray-700">Manager</span>
                                            </Switch.Label>
                                          </Switch.Group>
                                        </div>
                                        <button
                                          type="button"
                                          className="relative text-gray-400 hover:text-gray-500"
                                          onClick={() => removeProcess(member.uuid)}
                                        >
                                          <span className="absolute -inset-2.5" />
                                          <span className="sr-only">Delete manager</span>
                                          <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                                        </button>
                                      </div>
                                    </li>
                                  )
                                })}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className={`ml-auto flex text-sm items-center justify-center rounded-md bg-white px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-gray-50 ring-2 ring-indigo-500`}
                          onClick={() => changeShowCreateGroupSidebarProcess(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500"
                        >
                          {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
                          Create
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default memo(Create)
