import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../../reducer-helper'
import { checkInvitation } from '../../../components/workspace/accept-invitation/slice'

const initialState = {
  data: {
    actionRequired: null,
    workspace: null,
    signUpForm: {
      fullName: '',
      password: '',
    },
    signInForm: {
      email: '',
      password: '',
    },
  },
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'acceptWorkspaceInvitation',
  initialState,
  extraReducers(builder) {
    builder.addCase(checkInvitation.fulfilled, (state, action) => {
      state.data.actionRequired = action.payload.data.actionRequired
      state.data.workspace = action.payload.data.workspace
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(checkInvitation.rejected, failed)
  },
  reducers: {
    changeFullName: (state, action) => {
      state.data.signUpForm.fullName = action.payload.fullName
    },
    changeSignUpPassword: (state, action) => {
      state.data.signUpForm.password = action.payload.password
    },
    changeEmail: (state, action) => {
      state.data.signInForm.email = action.payload.email
    },
    changeSignInPassword: (state, action) => {
      state.data.signInForm.password = action.payload.password
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetData: () => initialState,
  },
})

const selectSlice = (state) => state.acceptWorkspaceInvitation
export const selectData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const {
  changeRequestStatus,
  resetData,
  changeFullName,
  changeSignUpPassword,
  changeSignInPassword,
  changeEmail,
} = actions

export default reducer
