import React, { Fragment, memo, useEffect, useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/20/solid'
import { useDispatch, useSelector } from 'react-redux'
import { changeShowNavigationSidebar } from '../../../reducers/common'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import {
  changeRequestStatus,
  selectAllWorkspaces,
  selectShowSidebar as selectShowListSidebar,
  setSidebarState as setListSidebarState,
  workspacesUnloaded,
} from '../../../reducers/workspace/workspace-list'
import { acceptInvitation, getAllWorkspaces, switchWorkspace } from './slice'
import { selectRequestStatus } from '../../../reducers/workspace/workspace-list'
import Loader from '../../common/loader/Loader'
import { WORKSPACE_USER_ROLES, WORKSPACE_USER_STATUSES } from '../../../constants'
import { Dialog, Transition } from '@headlessui/react'
import { selectWorkspace } from '../../../reducers/workspace/workspace'
import {
  selectShowSidebar as selectShowCreateWorkspaceSidebar,
  setSidebarState as setCreateWorkspaceSidebarState,
} from '../../../reducers/workspace/create-form'

const tabsKeys = {
  ALL: 'all',
  OWN: 'own-workspace',
  INVITED: 'invited',
}

const tabs = [
  { key: tabsKeys.ALL, name: 'All' },
  { key: tabsKeys.OWN, name: 'Own' },
  { key: tabsKeys.INVITED, name: 'Invited' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function WorkspaceListDialog() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const workspaces = useSelector(selectAllWorkspaces)
  const showListSidebar = useSelector(selectShowListSidebar)
  const showCreateWorkspaceSidebar = useSelector(selectShowCreateWorkspaceSidebar)
  const currentWorkspace = useSelector(selectWorkspace)

  const hideListSidebar = (status) => {
    dispatch(setListSidebarState({ showSidebar: false }))
  }

  const showCreateWorkspaceSidebarProcess = () => {
    dispatch(setCreateWorkspaceSidebarState({ showSidebar: true }))
  }

  const closeAllPopups = () => {
    dispatch(setListSidebarState({ showSidebar: false }))
    dispatch(changeShowNavigationSidebar({ showNavigationSidebar: false }))
  }

  const [filteredWorkspaces, setFilteredWorkspaces] = useState(null)
  const [currentTab, setCurrentTab] = useState(tabs[0].key)

  useEffect(() => {
    if (showListSidebar) {
      dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      const fetchWorkspaces = dispatch(getAllWorkspaces())

      return () => {
        dispatch(workspacesUnloaded())
        fetchWorkspaces.abort()
      }
    } else {
      dispatch(workspacesUnloaded())
    }
  }, [showListSidebar])

  useEffect(() => {
    if (workspaces) {
      let filtered

      switch (currentTab) {
        case tabsKeys.ALL:
          filtered = [...workspaces]
          break
        case tabsKeys.OWN:
          filtered = [...workspaces.filter((workspace) => workspace.user.role === WORKSPACE_USER_ROLES.OWNER)]
          break
        case tabsKeys.INVITED:
          filtered = [...workspaces.filter((workspace) => workspace.user.role !== WORKSPACE_USER_ROLES.OWNER)]
          break
      }

      filtered.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))
      setFilteredWorkspaces(filtered)
    }
  }, [currentTab, workspaces])

  const switchWorkspaceProcess = (workspaceUuid) => {
    closeAllPopups()
    dispatch(switchWorkspace({ workspaceUuid }))
  }

  const acceptInvitationProcess = (workspaceUuid) => {
    closeAllPopups()
    dispatch(acceptInvitation({ workspaceUuid }))
  }

  return (
    <Transition.Root show={showListSidebar && !showCreateWorkspaceSidebar}>
      <Dialog as="div" className="relative z-50" onClose={() => hideListSidebar()}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-80">
                  <div className={'fixed inset-y-0'}>
                    <div
                      className={
                        'bg-white sm:flex sm:flex-col fixed sm:w-80 inset-y-0 overflow-y-auto border-black/10 border-r'
                      }
                    >
                      <header className="pb-4 pt-6 sm:pb-6">
                        <div className="mx-auto flex max-w-full flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                          <div>
                            <h1 className="text-base font-semibold leading-7 text-gray-900">Workspaces</h1>
                            <p className="text-sm text-gray-700">A list of your workspaces</p>
                          </div>
                        </div>
                      </header>
                      <div className="px-4 sm:px-6 lg:px-8 flow-root">
                        <div className="sm:block">
                          <nav className="flex flex-col w-64 gap-y-2" aria-label="Tabs">
                            <button
                              type="button"
                              onClick={() => showCreateWorkspaceSidebarProcess()}
                              className="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                              Create new workspace
                            </button>
                            {requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
                            {requestStatus === REQUEST_STATUSES.DONE && filteredWorkspaces && (
                              <>
                                <div className="pb-4 sm:flex sm:justify-start">
                                  <div className="sm:hidden">
                                    <label htmlFor="tabs" className="sr-only">
                                      Select a tab
                                    </label>
                                    <select
                                      id="tabs"
                                      name="tabs"
                                      className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                      onChange={(event) => {
                                        setCurrentTab(event.target.value)
                                      }}
                                      defaultValue={tabs.find((tab) => tab.key === currentTab).name}
                                    >
                                      {tabs.map((tab) => (
                                        <option key={tab.key} value={tab.key}>
                                          {tab.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="hidden sm:block pt-4">
                                    <nav className="flex flex-row justify-start" aria-label="Tabs">
                                      {tabs.map((tab) => (
                                        <button
                                          key={tab.key}
                                          onClick={() => setCurrentTab(tab.key)}
                                          className={classNames(
                                            tab.key === currentTab
                                              ? 'bg-gray-100 text-gray-700'
                                              : 'text-gray-500 hover:text-gray-700',
                                            'rounded-md px-3 py-1.5 text-sm font-medium',
                                          )}
                                          aria-current={tab.key === currentTab ? 'page' : undefined}
                                        >
                                          {tab.name}
                                        </button>
                                      ))}
                                    </nav>
                                  </div>
                                </div>
                                {filteredWorkspaces.map((workspace) => (
                                  <button
                                    key={workspace.uuid}
                                    onClick={() => {
                                      if (workspace.user.status === WORKSPACE_USER_STATUSES.ACTIVE) {
                                        switchWorkspaceProcess(workspace.uuid)
                                      }
                                      if (workspace.user.status === WORKSPACE_USER_STATUSES.INVITED) {
                                        acceptInvitationProcess(workspace.uuid)
                                      }
                                    }}
                                    className={classNames(
                                      workspace.uuid === currentWorkspace.uuid
                                        ? 'bg-indigo-100 text-indigo-700'
                                        : 'text-gray-500 hover:text-gray-700',
                                      'rounded-md px-2 py-2 text-sm font-medium',
                                    )}
                                    aria-current={currentWorkspace.uuid ? 'page' : undefined}
                                  >
                                    <div className={'flex flex-row items-center justify-between'}>
                                      <span>{workspace.name}</span>
                                      <div className={'flex flex-row items-center'}>
                                        {workspace.user.status === WORKSPACE_USER_STATUSES.ACTIVE && (
                                          <ChevronRightIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                                        )}
                                        {workspace.user.status === WORKSPACE_USER_STATUSES.INVITED && (
                                          <div className={'w-full text-left text-xs text-indigo-500'}>Accept</div>
                                        )}
                                      </div>
                                    </div>
                                  </button>
                                ))}
                              </>
                            )}
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default memo(WorkspaceListDialog)
