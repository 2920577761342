import { createSlice } from '@reduxjs/toolkit'
import { createNewTask } from '../../components/meeting/list/types/task/slice'

const initialState = {
  data: {
    name: '',
  },
  openForm: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'createMeetingTypeTask',
  initialState,
  extraReducers(builder) {
    builder.addCase(createNewTask.fulfilled, () => initialState)
  },
  reducers: {
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    setOpenForm: (state, action) => {
      state.openForm = action.payload.openForm
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.createMeetingTypeTask
export const selectFormData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status
export const selectOpenForm = (state) => selectSlice(state).openForm

const { reducer, actions } = slice

export const { changeRequestStatus, resetForm, changeName, setOpenForm } = actions

export default reducer
