import React, { memo } from 'react'
import { classNames } from '../../../../utils/className'

function Sidebar({ selectedTagUuid, selectTag, meeting }) {
  return (
    <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-48 lg:flex-none lg:border-0 lg:py-8">
      {meeting.uuid && (
        <nav className="flex-none px-4 sm:px-6 lg:px-0">
          <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
            {meeting.processingResults.map((result) => (
              <li key={result.tag.uuid}>
                <button
                  type="button"
                  onClick={() => selectTag(result.tag.uuid)}
                  className={classNames(
                    result.tag.uuid === selectedTagUuid
                      ? 'bg-gray-50 text-indigo-600'
                      : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                    'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold',
                  )}
                >
                  {result.tag.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </aside>
  )
}

export default memo(Sidebar)
