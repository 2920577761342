import React, { memo, useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Dialog, Switch, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import {
  setShowPopupState,
  selectShowPopup,
  selectRequestStatus,
  selectData,
  changeAtAmount,
  changeToAmount,
  changeRequestStatus,
  selectError,
  toggleRecharge,
} from '../../../../../reducers/workspace/settings/billing/auto-recharge-form'
import { REQUEST_STATUSES } from '../../../../../reducers/reducer-helper'
import Loader from '../../../../common/loader/Loader'
import { classNames } from '../../../../../utils/className'
import { rechargeUpdate } from './slice'
import NotificationPopup from '../../../../common/notification-popup/NotificationPopup'

function AutoRecharge() {
  const dispatch = useDispatch()

  const showPopup = useSelector(selectShowPopup)
  const requestStatus = useSelector(selectRequestStatus)
  const requestError = useSelector(selectError)
  const formData = useSelector(selectData)

  const [error, setError] = useState(null)

  const closeFormHandler = () => {
    dispatch(setShowPopupState({ showPopup: false }))
  }

  const updateRechargeProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      rechargeUpdate({ enabled: formData.enabled, amountAt: formData.rechargeAt, amountTo: formData.rechargeTo }),
    )
  }

  const changeAtAmountProcess = (event) => {
    dispatch(changeAtAmount({ rechargeAt: event.target.value }))
  }

  const changeToAmountProcess = (event) => {
    dispatch(changeToAmount({ rechargeTo: event.target.value }))
  }

  const toggleRechargeProcess = () => {
    dispatch(toggleRecharge())
  }

  useEffect(() => {
    if (requestError) {
      setError('Payment failed. Please make sure that provided payment methods is correct.')
    }
  }, [requestError])

  return (
    <Transition.Root show={showPopup} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={closeFormHandler}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg max-w-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full">
                <div className="bg-gray-50 px-6 py-4 rounded-t-lg">
                  <div className="flex items-start justify-between space-x-3">
                    <div className="space-y-1">
                      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                        Auto recharge
                      </Dialog.Title>
                      <p className="text-sm text-gray-500">Would you like to set up automatic recharge?</p>
                    </div>
                    <div className="flex h-7 items-center">
                      <button
                        type="button"
                        className="relative text-gray-400 hover:text-gray-500"
                        onClick={() => closeFormHandler()}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                {!!error && (
                  <div className={'p-6'}>
                    <NotificationPopup message={error} />
                  </div>
                )}
                <form className={'px-6 py-4'} onSubmit={(event) => updateRechargeProcess(event)}>
                  <div className={'flex flex-col space-y-4'}>
                    <div>
                      <Switch.Group as="div" className="flex items-center mt-4 mb-8">
                        <Switch
                          checked={formData.enabled}
                          onChange={toggleRechargeProcess}
                          className={classNames(
                            formData.enabled ? 'bg-indigo-600' : 'bg-gray-200',
                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              formData.enabled ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                            )}
                          />
                        </Switch>
                        <Switch.Label as="span" className="ml-3 text-sm">
                          <span className="font-normal text-gray-700">
                            Yes, automatically recharge my card when my credit balance falls below a threshold
                          </span>
                        </Switch.Label>
                      </Switch.Group>
                    </div>

                    <div className={formData.enabled ? '' : 'opacity-30 transition-all'}>
                      <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                        When credit balance goes below
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <input
                          disabled={!formData.enabled}
                          type="text"
                          name="price"
                          id="price"
                          value={formData.rechargeAt}
                          onChange={changeAtAmountProcess}
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="10"
                          aria-describedby="price-currency"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <span className="text-gray-500 sm:text-sm" id="price-currency">
                            USD
                          </span>
                        </div>
                      </div>
                      <div className={'text-xs text-gray-400 pt-1 pl-1'}>Enter an amount between $5 and $495</div>
                    </div>

                    <div className={formData.enabled ? '' : 'opacity-30 transition-all'}>
                      <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                        Bring credit balance back up to
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <input
                          disabled={!formData.enabled}
                          type="text"
                          name="price"
                          id="price"
                          value={formData.rechargeTo}
                          onChange={changeToAmountProcess}
                          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="10"
                          aria-describedby="price-currency"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <span className="text-gray-500 sm:text-sm" id="price-currency">
                            USD
                          </span>
                        </div>
                      </div>
                      <div className={'text-xs text-gray-400 pt-1 pl-1'}>Enter an amount between $15 and $500</div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-center space-x-4 py-6 mt-5 sm:mt-6">
                    <button
                      type={'button'}
                      onClick={(event) => {
                        event.preventDefault()
                        closeFormHandler()
                      }}
                      className={`ml-auto flex text-sm items-center justify-center rounded-md bg-white px-3 py-1 text-indigo-600 hover:text-indigo-600 hover:bg-gray-50 ring-2 ring-indigo-500`}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 ring-2 ring-indigo-600 hover:ring-indigo-500"
                    >
                      {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
                      Save settings
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default memo(AutoRecharge)
