import { createSlice } from '@reduxjs/toolkit'
import { ComponentRequestStatus } from '../../constants'
import { signIn } from '../../components/user/sign-in/slice'
import { failed } from '../reducer-helper'
import {
  resendConfirmationToken,
  createAccount,
  confirmSignUpEmail,
  createProfile,
} from '../../components/user/sign-up/slice'
import { initApplication } from '../../components/app/slice'
import {
  addProfileImage,
  confirmNewEmail,
  removeProfileImage,
  updateProfileInformation,
} from '../../components/user/settings/general/slice'
import { logout } from '../../components/common/protected/main-layout/slice'
import { acceptWithSignIn, acceptWithSignUp } from '../../components/workspace/accept-invitation/slice'
import { acceptLegal } from '../../components/legal/accept/slice'

const initialState = {
  user: null,
  error: null,
  status: null,
}

function success(state) {
  state.status = ComponentRequestStatus.SUCCESS
  delete state.error
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers(builder) {
    builder.addCase(initApplication.fulfilled, (state, action) => {
      state.user = action.payload.user
    })
    builder.addCase(acceptLegal.fulfilled, (state, action) => {
      state.user = action.payload.user
    })
    builder.addCase(createAccount.fulfilled, (state) => {
      success(state)
    })
    builder.addCase(createAccount.rejected, failed)
    builder.addCase(updateProfileInformation.fulfilled, (state, action) => {
      state.user.profile.fullName = action.payload.fullName
    })
    builder.addCase(confirmNewEmail.fulfilled, (state, action) => {
      state.user.email = action.payload.email
    })
    builder.addCase(confirmSignUpEmail.fulfilled, (state) => {
      success(state)
    })
    builder.addCase(confirmSignUpEmail.rejected, failed)
    builder.addCase(createProfile.fulfilled, (state, action) => {
      success(state)
      state.user = action.payload.user
    })
    builder.addCase(createProfile.rejected, failed)
    builder.addCase(signIn.fulfilled, (state, action) => {
      success(state)
      state.user = action.payload.user
    })
    builder.addCase(acceptWithSignUp.fulfilled, (state, action) => {
      success(state)
      state.user = action.payload.user
    })
    builder.addCase(acceptWithSignIn.fulfilled, (state, action) => {
      success(state)
      state.user = action.payload.user
    })
    builder.addCase(removeProfileImage.fulfilled, (state) => {
      success(state)
      state.user.profile.profileImage = null
    })
    builder.addCase(addProfileImage.fulfilled, (state, action) => {
      success(state)
      state.user.profile.profileImage = action.payload.profile.profileImage
    })
    builder.addCase(signIn.rejected, failed)
    builder.addCase(logout.fulfilled, (state) => {
      state.user = null
    })
    builder.addCase(resendConfirmationToken.rejected, failed)
    builder.addCase(resendConfirmationToken.fulfilled, success)
  },
})

const selectUserSlice = (state) => state.user
export const selectUser = (state) => selectUserSlice(state).user
export const selectIsLoading = (state) => selectUserSlice(state).status === ComponentRequestStatus.LOADING
export const selectError = (state) => selectUserSlice(state).error

const { reducer } = userSlice

export default reducer
